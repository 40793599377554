import "@google/model-viewer";
import { 
    Button,
    IconButton
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FiDownload } from "react-icons/fi"
import { AuthContext } from "../../context/AuthContext";

function DownloadPastPurchase({ dlUrl }) {
    const {currentUser} = React.useContext(AuthContext);
    const [downloadUrl, setDownloadUrl] = useState(false);

    const getModel = async () => {
        await fetch(`/api/getModel?download_url=${dlUrl}&uid=${currentUser.uid}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then((data) => {
                setDownloadUrl(data.response);
                return data;
            }).catch((err) => console.log("getOrders error", err));
    }

    return (
        <>
            {!downloadUrl &&
            <>
                <Button onClick={() => getModel()}>Get Model URL</Button>
            </>
            }
            {downloadUrl &&
                <>
                    <Button disabled>Get Model URL</Button>
                    <IconButton
                        icon={<FiDownload fontSize="1.25rem" />}
                        variant="ghost"
                        aria-label="Download glb file"
                        onClick={() => window.open(downloadUrl, "_blank")}
                    />
                </>
            }
        </>
    );
}

DownloadPastPurchase.propTypes = {
    dlUrl: PropTypes.string
}


export default DownloadPastPurchase;
