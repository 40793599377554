import { 
    Box,
    Image,
    Text,
    SimpleGrid,
    Heading,
    Badge,
    HStack,
    Button,
    Stack,
    Flex,
    AspectRatio,
    Skeleton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Container,
    useDisclosure,
    useBreakpointValue,
    useColorModeValue
} from "@chakra-ui/react";
import { getDatabase, ref, update } from "firebase/database";
// import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";

function SearchItem({resultData}) {
    const database = getDatabase();
    const {currentUser} = React.useContext(AuthContext);
    const [freeGlb, setFreeGlb] = useState(false);
    const { 
        isOpen, 
        onOpen, 
        onClose 
    } = useDisclosure();

    const createOrder = async (modelId) => {
        await fetch("/api/createOrder", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ uuid: `${currentUser.uid}1`, model_id: modelId, return_url: "https://ldp-studio-int.firebaseapp.com/cgTrader", uid: `${currentUser.uid}` }),
        })
            .then((res) => res.json())
            .then(async (data) => {
                // open up modal with steps 
                onOpen();
                // createCgTraderOrders in RTDB
                await update(ref(database, `/cg_trader_orders/${currentUser.uid}/${data.response.id}`), data.response);
                // open up download url in new window
                window.open(data.response.checkout_url, "_blank")
                return data;
            }).catch((err) => console.log("createOrder error", err));
    }

    const getModelFiles = async (modelId) => {
        onOpen();
        await fetch(`/api/getModelFiles?model_id=${modelId}&uid=${currentUser.uid}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then(async (data) => {
                await update(ref(database, `/cg_trader_free_purchases/${currentUser.uid}/${modelId}`), data.response);
                // return data;
                await data.response.files.map(async (file) => {
                    if (file.name.endsWith(".glb") || file.name.endsWith(".gltf") || file.name.endsWith(".zip")) {
                        await update(ref(database, `/cg_trader_free_purchases/${currentUser.uid}/${modelId}/glb_models`), file);
                        setFreeGlb(file);
                        return file;
                    }
                })
            }).catch((err) => console.log("getOrders error", err));
    }

    const getGlbFile = async (modelId) => {
        // window.open(freeGlb.download_url, "_blank")
        await fetch(`/api/getGlbFile?model_id=${modelId}&id=${freeGlb.id}&uid=${currentUser.uid}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            // .then((res) => res.json())
            .then((data) => {
                onClose();
                return window.open(data.url, "_blank");
            }).catch((err) => console.log("getGlbFile error", err));
    }

    return (
        <>
            <Stack
                spacing={useBreakpointValue({
                    base: "4",
                    md: "5",
                })}
                backgroundColor="white"
            >
                <Box position="relative">
                    <AspectRatio ratio={4 / 3}>
                        <Image
                            src={resultData.thumbnails[0]} 
                            alt={resultData.title}
                            draggable="false"
                            fallback={<Skeleton />}
                            borderRadius={useBreakpointValue({
                                base: "md",
                                md: "xl",
                            })}
                        />
                    </AspectRatio>
                </Box>

                <Stack height="250px">

                    <Stack spacing="1" padding="5px">
                        <Heading size="xs">
                            #{resultData.id}
                        </Heading>
                        <HStack>
                            {!resultData.prices.price_with_discount &&
                            <Badge variant="subtle" colorScheme="blue">
                                ${resultData.prices.download}
                            </Badge>
                            }
                            {(resultData.prices.price_with_discount && resultData.prices.download === resultData.prices.price_with_discount) &&
                            <Badge variant="subtle" colorScheme="blue">
                                ${resultData.prices.download}
                            </Badge>
                            }
                            {(resultData.prices.price_with_discount && resultData.prices.download !== resultData.prices.price_with_discount) &&
                            <Badge variant="subtle" colorScheme="blue">
                                <span style={{textDecoration: "line-through"}}>${resultData.prices.download}</span>
                            </Badge>
                            }
                            {(resultData.prices.price_with_discount && resultData.prices.download !== resultData.prices.price_with_discount) &&
                            <Badge variant="subtle" colorScheme="green">
                                    ${resultData.prices.price_with_discount}
                            </Badge>
                            }
                        </HStack>
                        <Text fontWeight="medium" color={useColorModeValue("gray.700", "gray.400")}>
                            {resultData.title}
                        </Text>
                    </Stack>

                    <HStack padding="5px">
                        <SimpleGrid columns={3} overflow="scroll">
                            {resultData.availableFileExtensions.map((ext) => 
                                <>
                                    {ext === "glb" || ext === "gltf" && 
                                        <Badge variant="subtle" colorScheme="green" mt="2" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                            {ext}
                                        </Badge>
                                    }
                                    {ext !== "glb" && ext !== "gltf" && 
                                        <Badge variant="subtle" colorScheme="red" mt="2" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                            {ext}
                                        </Badge>
                                    }
                                </>
                            )}
                        </SimpleGrid>
                    </HStack>

                </Stack>


                <Stack align="center">
                    {resultData.prices.download !== 0 &&
                        <Button isFullWidth colorScheme="blue" onClick={() => createOrder(resultData.id)}>Purchase #{resultData.id}</Button>
                    }
                    {(resultData.prices.download === 0 && !freeGlb) &&
                        <Button isFullWidth colorScheme="blue" onClick={() => getModelFiles(resultData.id)}>Download #{resultData.id}</Button>
                    }
                    {freeGlb &&
                        <Button isFullWidth colorScheme="blue" onClick={() => getGlbFile(resultData.id)}>Start {freeGlb.name} Download</Button>
                    }
                </Stack>

            </Stack>

            <Modal size="md" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader bg="blackAlpha.800">
                        <Heading size="sm" mb="2" color="white">Complete Download</Heading>
                    </ModalHeader>
                    <ModalCloseButton color="white" />
                  
                    <ModalBody bg="gray.50">

                        <Container
                            py={{
                                base: "4",
                                md: "8",
                            }}
                        >
                            {freeGlb &&
                                <Flex direction="column" jusify="center" align="center">
                                    <Button isFullWidth colorScheme="blue" onClick={() => getGlbFile(resultData.id)}>Download {freeGlb.name}</Button>
                                </Flex>
                            }
                            {!freeGlb &&
                                <Flex direction="column" jusify="center" align="center">
                                    <Loading size="medium" />
                                    <Button isFullWidth colorScheme="blue" disabled>Preparing {freeGlb.name} for Download...</Button>
                                </Flex>
                            }
                        </Container>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

SearchItem.propTypes = {
    resultData: PropTypes.object
}

export default SearchItem;
