import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom"
import {
    Card, CardImg, CardBody,
    CardTitle, CardSubtitle, Button,
} from "reactstrap";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";
import "./QrListCard.css";

function QrListCard({qrKey, qrDataAll, qrMeta}) {
    const {currentUser} = React.useContext(AuthContext);

    if (!qrMeta) {
        return <Loading size="small" />
    }

    if (!!qrMeta && !qrDataAll) {
        return (
            // <div>
            <Card className="qr-card">
                <CardImg top className="card-image" src={qrMeta.qr} alt={qrMeta.link} />  
                <CardBody>
                    <CardTitle className="qr-list-card-title" tag="h5"><span className="qr-title-span">{qrMeta.link}</span></CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{qrMeta.formattedDate}</CardSubtitle>
                    <Button tag={Link} to={`/qrlink/${currentUser.uid}/${qrKey}`}>Go to QR Link</Button>
                </CardBody>
            </Card>
            // </div>
        )
    }
    if (!!qrMeta && !!qrDataAll) {
        return (
            // <div>
            <Card className="qr-card">
                <CardImg top className="card-image" src={qrMeta.qr} alt={qrDataAll[qrKey].fileName} />  
                <CardBody>
                    <CardTitle className="qr-list-card-title" tag="h5"><span className="qr-title-span">{qrDataAll[qrKey].fileName}.{qrDataAll[qrKey].fileExt}</span></CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{qrMeta.formattedDate}</CardSubtitle>
                    <Button tag={Link} to={`/qrlink/${currentUser.uid}/${qrKey}`}>Go to QR Link</Button>
                </CardBody>
            </Card>
            // </div>
        )
    }
    return null;
}

QrListCard.propTypes = {
    qrKey: PropTypes.string, 
    qrDataAll: PropTypes.object, 
    qrMeta: PropTypes.object
}

export default QrListCard;