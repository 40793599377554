import detectEthereumProvider from "@metamask/detect-provider";
import { 
    getAuth, 
    signInWithCustomToken, 
} from "firebase/auth";
import { getDatabase, ref, get, child, update } from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { AuthContext } from "../../../context/AuthContext";
// import { currentConfig } from "../../App/App.js";
import "./JoinWhitelist.css";

function JoinWhitelist({ promoData, projectId }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const auth = getAuth();
    // const whitelistRef = ref(database, `/promo/${projectId}/whitelist/`);
    // const [newWhitelistKey, setNewWhitelistKey] = useState(push(whitelistRef).key);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [displayName, setDisplayName] = useState(" ");
    const [userRegistered, setUserRegistered] = useState(false);
    const [metamaskLoggedIn, setMetamaskLoggedIn] = useState(false);
    const [whitelistUsers, setWhitelistUsers] = useState(false);

    useEffect(() => {
        async function getWhitelistData() {
            const whitelist = await get(child(dbRef, `promo/${projectId}/whitelist`)).catch((error) => console.log(error));
            return whitelist.val();
        }

        getWhitelistData().then((whitelistData) => {
            if (whitelistData) {
                const wUsers = Object.keys(whitelistData.users);
                setWhitelistUsers(wUsers);
                if (currentUser) {
                    if (wUsers.includes(currentUser.uid)) {
                        setUserRegistered(true);
                    }
                }
            }

            if (currentUser) {
                setUserLoggedIn(true);
                if (currentUser.accountType === "metamask") {
                    setMetamaskLoggedIn(true);
                }

                const nameArr = currentUser.displayName.split(" ");
                const firstName = nameArr[0];
                setDisplayName(firstName);
            }
        });
    }, []);  

    const fonts = {
        robotoMono: "'Roboto Mono', monospace",
        belleza: "'Belleza', sans-serif", 
        raleway: "'Raleway', sans-serif", 
        montserrat: "'Montserrat', sans-serif",
        playfairDisplay: "'Playfair Display', serif",
        permanentMarker: "'Permanent Marker', cursive",
    };

    function toHex(stringToConvert) {
        return stringToConvert
            .split("")
            .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
            .join("");
    }

    const signInWithMetaMask = async () => {
        let ethereum;
        const provider = await detectEthereumProvider();
        if (!provider) {
            alert("Please install MetaMask");
        }
        ethereum = provider;
        // const requestedAccounts = await ethereum.request({ method: "eth_requestAccounts" });

        let nonceToSign;
        await fetch("/api/getNonceToSign", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ address: ethereum.selectedAddress }),
        })
            .then((res) => res.json())
            .then((data) => {
                nonceToSign = data.nonce;
            })
            .catch((error) => console.log("error after posting to getNonceToSign", error));
  
        const sig = await ethereum.request({
            method: "personal_sign",
            params: [
                `0x${toHex(nonceToSign)}`,
                ethereum.selectedAddress,
            ],
        });
  
        let customToken;
        await fetch("/api/verifySignedMessage", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ address: ethereum.selectedAddress, signature: sig }),
        })
            .then((res) => res.json())
            .then((data) => {
                customToken = data.token;
                return data.token;
            })
            .catch((error) => console.log("error after posting to verifySignedMessage", error));
  
        await signInWithCustomToken(auth, customToken);

        if (whitelistUsers) {
            if (!whitelistUsers.includes(ethereum.selectedAddress)) {
                await update(ref(database, `/promo/${projectId}/whitelist/users`), { [ethereum.selectedAddress]: true });
                await update(ref(database, `/users/${ethereum.selectedAddress}/projects/${projectId}`), { whitelist: true });
            }
        }

        if (!whitelistUsers) { 
            await update(ref(database, `/promo/${projectId}/whitelist/users`), { [ethereum.selectedAddress]: true });
            await update(ref(database, `/users/${ethereum.selectedAddress}/projects/${projectId}`), { whitelist: true });
        }

        setUserRegistered(true);
        // setNewWhitelistKey(push(whitelistRef).key);
    }

    const googleUserSubmit = async () => {
        if (currentUser) {
            await update(ref(database, `/promo/${projectId}/whitelist/users`), { [currentUser.uid]: true });
            await update(ref(database, `/users/${currentUser.uid}/projects/${projectId}`), { whitelist: true });
        }
        setUserRegistered(true);
        // setNewWhitelistKey(push(whitelistRef).key);
    }

    return (
        <div className="join-whitelist">
            <div className="join-whitelist-form">
                {(!userRegistered && !userLoggedIn && !metamaskLoggedIn) &&
            <>
                <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button" onClick={() => signInWithMetaMask()}><span className="promo-cta-button-text">Sync MetaMask to Join Whitelist <img className="google-icon" height="50px" width="50px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1024px-MetaMask_Fox.svg.png?20201112074605" alt="Metamask fox" /></span></Button>
                <h5 style={{textAlign: "center", marginTop: "30px"}}>MetaMask will open and ask you to sign a randomly generated number. We only save your public address. Joining the whitelist is easy and anonymous!</h5>
            </>
                }

                {(!!userRegistered && !!userLoggedIn && !!metamaskLoggedIn) &&
        <>
            <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button" disabled><span className="promo-cta-button-text">You Have Already Joined this Whitelist</span></Button>
            <h5 style={{textAlign: "center", marginTop: "30px"}}>You are also signed into LDP Studio through MetaMask! Feel free to explore the app.</h5>
        </>
                }

                {(!userRegistered && !!userLoggedIn && !metamaskLoggedIn) &&
        <>
            <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button" onClick={() => googleUserSubmit()}><span className="promo-cta-button-text">Join the Whitelist</span></Button>
        </>
                }

                {(!!userRegistered && !!userLoggedIn && !metamaskLoggedIn) &&
        <>
            <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button" disabled><span className="promo-cta-button-text">You Have Successfully Joined this Whitelist, {displayName}!</span></Button>
        </>
                }
            </div>
        </div>
    );
}

JoinWhitelist.propTypes = {
    promoData: PropTypes.object, 
    projectId: PropTypes.string
}

export default JoinWhitelist;
