import FileSaver from "file-saver";
import { getDatabase, ref, get, child } from "firebase/database";
import capitalize from "lodash-es/capitalize";
import React, { useState, useEffect } from "react";
import { FiLinkedin } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import VCardsJS from "vcards-js";
import Loading from "../Loading/Loading.js";
import "./VCardPage.css";

function VCardPage() {
    const database = getDatabase();
    const dbRef = ref(database);
    const [vCardData, setVCardData] = useState(false);
    const vCardFile = VCardsJS();
    const { uid } = useParams();

    useEffect(() => {
        async function getVCard() {
            const vCard = await get(child(dbRef, `vcards/${uid}`)).catch((error) => console.log(error));
            return vCard.val();
        }
        getVCard().then((vCardDataVal) => {
            if (vCardDataVal) {
                setVCardData(vCardDataVal);
                vCardFile.cellPhone = vCardDataVal.cellPhone;
                vCardFile.workAddress.city = vCardDataVal.city;
                vCardFile.email = vCardDataVal.email;
                vCardFile.firstName = vCardDataVal.firstName;
                vCardFile.lastName = vCardDataVal.lastName;
                // vCard.socialUrls['linkedIn'] = linkedIn;
                vCardFile.organization = vCardDataVal.organization;
                vCardFile.title = vCardDataVal.title;
                vCardFile.workAddress.stateProvince = vCardDataVal.stateProvince;
                // vCard.socialUrls['twitter'] = twitter;
                vCardFile.url = vCardDataVal.url;
                vCardFile.workEmail = vCardDataVal.workEmail;
                vCardFile.workPhone = vCardDataVal.workPhone;
                vCardFile.workUrl = vCardDataVal.workUrl;
                vCardFile.source = vCardDataVal.source;
                vCardFile.uid = vCardDataVal.uid;
                // vCard.photo.attachFromUrl(`${profilePhoto}`, 'JPEG');
            }
        })
    }, []);  

    const saveVCardFunction = (e) => {
        e.preventDefault();
        const blob = new Blob([ vCardFile.getFormattedString() ], {type: "text/vcard;charset=utf-8"});
        FileSaver.saveAs(blob, `${vCardData.firstName}-${vCardData.lastName}.vcf`);
    }

    if (!vCardData) {
        return <Loading size="medium" />
    }

    if (vCardData) {
        return (
            <div className="public-vcard-container">
                <div className="vcard-layout-container">
                    <div className="vcard-content">
                        <div className="left-side">
                            <img className="vcard-photo" src={vCardData.customPhoto} width="100%" alt="Headshot" />
                        </div>
                        <div className="other-side">
                            <div className="top-row">
                                <h3 className="name">{capitalize(vCardData.firstName)} {capitalize(vCardData.lastName)}</h3>
                                <div><span className="vcard-title">{vCardData.title}</span>, <span className="organization">{vCardData.organization}</span></div>
                                <div className="location">{vCardData.city}, {vCardData.stateProvince}</div>
                            </div>
                            <div className="middle-row">
                                <div className="location-and-role">
                                    <div className="work-contact-info">
                                        <div><span className="card-name-text">Work Phone:</span> {vCardData.workPhone}</div>
                                        <div><span className="card-name-text">Work Email:</span> {vCardData.workEmail}</div>
                                        <div><span className="card-name-text">{vCardData.organization} Website:</span> {vCardData.workUrl}</div>
                                    </div>
                                </div>
                                <div className="everything-else">
                                    <div className="personal-contact-info">
                                        <div><span className="card-name-text">Cell Phone:</span> {vCardData.cellPhone}</div>
                                        <div><span className="card-name-text">Email:</span> {vCardData.email}</div>
                                        <div><span className="card-name-text">Website:</span> {vCardData.url}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-row">
                                <div className="social-icons">
                                    <div className="social-rows">
                                        <div className="social-top-row">
                                            <a href={vCardData.linkedIn}><FiLinkedin className="social-icon" size={40} /></a>
                                            <a href={vCardData.twitter} className="twitter-icon"><FiTwitter className="social-icon" size={40} /></a>
                                        </div>
                                        <div className="social-bottom-row">
                                            <Button color="primary" size="sm" onClick={(e) => saveVCardFunction(e)}>Save vCard to File</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VCardPage;