import {
    Box,
    Button,
    Flex,
    Heading,
    Img,
    Stack,
    Text,
    Link,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import React from "react";
import { Link as RRLink } from "react-router-dom";
import { DurationSwitcher } from "./components/DurationSwitcher";
import { PricingCard } from "./components/PricingCard";
import { PricingCardSmall } from "./components/PricingCardSmall";
import { PaymentContext } from "../../../context/PaymentContext";
  
export const TwoCardsLayout = () => {
    const {
        paymentSchedule,
        handleSetSelectedPlan
    } = React.useContext(PaymentContext);

    return (
        <Box as="section" bg={mode("gray.100", "gray.800")} py="24">
            <Box
                maxW={{
                    base: "xl",
                    md: "5xl",
                }}
                mx="auto"
                px={{
                    base: "6",
                    md: "8",
                }}
            >
                <Flex
                    direction="column"
                    align={{
                        base: "flex-start",
                        md: "center",
                    }}
                    maxW="2xl"
                    mx="auto"
                >
                    <Heading
                        as="h1"
                        fontSize="3rem"
                        letterSpacing="tight"
                        fontWeight="extrabold"
                        textAlign={{
                            base: "start",
                            md: "center",
                        }}
                    >
                        Find the perfect plan for you
                    </Heading>
                    <Text
                        mt="2"
                        fontSize="xl"
                        textAlign={{
                            base: "left",
                            md: "center",
                        }}
                        color={mode("gray.600", "gray.400")}
                    >
                        Start building MetaSites for free, then add credits to go live
                    </Text>
                    <DurationSwitcher mt="10" />
                </Flex>
  
                <Flex
                    direction={{
                        base: "column",
                        lg: "row",
                    }}
                    maxW={{
                        base: "560px",
                        lg: "unset",
                    }}
                    mx="auto"
                    mt={20}
                    bg={mode("white", "gray.700")}
                    rounded="xl"
                >
                    {paymentSchedule === "yearly" && 
                    <>
                        <PricingCard
                            flex="1"
                            colorScheme="blue"
                            name="User"
                            description="For when you really just need one."
                            price="$250"
                            duration="Per year"
                            extras="Additional MetaSites: same price"
                            features={[
                                "1 MetaSite Token",
                                "1 Conversion Token (2D to AR model creation)",
                                "QR Link to MetaSite",
                                "Digital business card & vCard",
                            ]}
                            onClick={() => handleSetSelectedPlan("user")}
                        />
                        <Box
                            w={{
                                base: "unset",
                                lg: "1px",
                            }}
                            minH="0"
                            h={{
                                base: "1px",
                                lg: "unset",
                            }}
                            bg={mode("gray.100", "gray.600")}
                        />
                        <PricingCard
                            flex="1"
                            colorScheme="yellow"
                            name="Creator"
                            description="Perfect for a small business taking the leap into AR."
                            price="$3,500"
                            duration="Per year"
                            extras="Additional MetaSites: same price"
                            features={[
                                "20 MetaSite Tokens",
                                "20 Conversion Tokens (2D to AR model creation)",
                                "$14/month value (per MetaSite + AR model)",
                                "+ all 3 features from User tier",
                            ]}
                            onClick={() => handleSetSelectedPlan("creator20")}
                        />
                    </>
                    }

                    {paymentSchedule === "monthly" && 
                    <>
                        <PricingCard
                            flex="1"
                            colorScheme="blue"
                            name="Creator 5"
                            description="Use AR to refine your marketing at a better rate."
                            price="$150"
                            duration="Per month"
                            extras="Additional MetaSites: same price"
                            features={[
                                "5 MetaSite Tokens",
                                "5 Conversion Tokens (2D to AR model creation)",
                                "$30/month value (per MetaSite + AR model)",
                                "+ all 3 features from User tier",
                            ]}
                            onClick={() => handleSetSelectedPlan("creator5")}
                        />
                        <Box
                            w={{
                                base: "unset",
                                lg: "1px",
                            }}
                            minH="0"
                            h={{
                                base: "1px",
                                lg: "unset",
                            }}
                            bg={mode("gray.100", "gray.600")}
                        />
                        <PricingCard
                            flex="1"
                            colorScheme="yellow"
                            name="Creator 10"
                            description="Perfect for a small business taking the leap into AR."
                            price="$297"
                            duration="Per month"
                            extras="Additional MetaSites: same price"
                            features={[
                                "10 MetaSite Tokens",
                                "10 Conversion Tokens (2D to AR model creation)",
                                "$29/month value (per MetaSite + AR model)",
                                "+ all 3 features from User tier",
                            ]}
                            onClick={() => handleSetSelectedPlan("creator10")}
                        />
                    </>
                    }
                </Flex>

                <Flex
                    direction={{
                        base: "column",
                        lg: "row",
                    }}
                    maxW={{
                        base: "560px",
                        lg: "unset",
                    }}
                    mx="auto"
                    mt={20}
                    bg={mode("white", "gray.700")}
                    rounded="xl"
                >
                    <PricingCardSmall
                        flex="1"
                        colorScheme="blue"
                        name="Agency 200"
                        description="Overhaul your brand's marketing strategy."
                        price="$5,000"
                        duration="Per month"
                        extras="Additional MetaSites: $20 / 1 / month in package of 100"
                        features={[
                            "200 MetaSite Tokens",
                            "200 Conversion Tokens (2D to AR model creation)",
                            "$25/month value (per MetaSite + AR model)",
                            "+ all 3 features from User tier",
                        ]}
                        onClick={() => handleSetSelectedPlan("agency200")}
                    />
                    <Box
                        w={{
                            base: "unset",
                            lg: "1px",
                        }}
                        minH="0"
                        h={{
                            base: "1px",
                            lg: "unset",
                        }}
                        bg={mode("gray.100", "gray.600")}
                    />
                    <PricingCardSmall
                        flex="1"
                        colorScheme="blue"
                        name="Agency 500"
                        description="Overhaul your brand's marketing strategy."
                        price="$10,000"
                        duration="Per month"
                        extras="Additional MetaSites: $20 / 1 / month in package of 100"
                        features={[
                            "500 MetaSite Tokens",
                            "500 Conversion Tokens (2D to AR model creation)",
                            "$20/month value (per MetaSite + AR model)",
                            "+ all 3 features from User tier",
                        ]}
                        onClick={() => handleSetSelectedPlan("agency500")}
                    />
                    <Box
                        w={{
                            base: "unset",
                            lg: "1px",
                        }}
                        minH="0"
                        h={{
                            base: "1px",
                            lg: "unset",
                        }}
                        bg={mode("gray.100", "gray.600")}
                    />
                    <PricingCardSmall
                        flex="1"
                        colorScheme="yellow"
                        name="Institution 2000"
                        description="Perfect for a small business taking the leap into AR."
                        price="$25,000"
                        duration="Per month"
                        extras="Additional MetaSites: $20 / 1 / month in any quantity"
                        features={[
                            "2,000 MetaSite Tokens",
                            "2,000 Conversion Tokens (2D to AR model creation)",
                            "$12.50/month value (per MetaSite + AR model)",
                            "+ all 3 features from User tier",
                        ]}
                        onClick={() => handleSetSelectedPlan("institution")}
                    />
                </Flex>

                <Box mt="10" px="12" py="10" bg={mode("gray.200", "gray.700")} rounded="xl">
                    <Flex
                        align="center"
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                    >
                        <Stack w="full" align="center" direction="row" spacing="8">
                            <Img
                                w={{
                                    base: "6rem",
                                    md: "8rem",
                                }}
                                h={{
                                    base: "6rem",
                                    md: "8rem",
                                }}
                                rounded="full"
                                objectFit="cover"
                                src="https://images.unsplash.com/photo-1506880135364-e28660dc35fa?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mjd8fGxhZHklMjB3aXRoJTIwdGVsZXBob25lfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                                alt="Customer Service"
                            />
                            <Box maxW="400px">
                                <Text fontSize="xl" fontWeight="bold">
                                    Need help or have questions?
                                </Text>
                                <Text mt="2">
                                    24 hour response time within Discord for potential clients. Able to schedule demos within 1 week.
                                </Text>
                            </Box>
                        </Stack>
                        <Link as={RRLink} to={"/support"} target="_blank" _hover={{textDecoration: "none"}}>
                            <Button
                                colorScheme="blue"
                                size="lg"
                                mt={{
                                    base: "6",
                                    md: "0",
                                }}
                                w={{
                                    base: "full",
                                    md: "auto",
                                }}
                                minW="10rem"
                                flexShrink={0}
                                fontSize="md"
                            >
                            Book a demo
                            </Button>
                        </Link>
                    </Flex>
                </Box>
            </Box>
        </Box>
    )
}

export default TwoCardsLayout;
