import { ChevronDownIcon } from "@chakra-ui/icons";
import { 
    Button,
    IconButton, 
    ButtonGroup,
    Input,
    Textarea,
    Stack, 
    Flex, 
    Heading, 
    Icon, 
    Text,
    Select,
    Checkbox,
    FormLabel,
    FormControl,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    RadioGroup,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Divider,
    useDisclosure,
    useMediaQuery,
    Container,
    HStack,
    StackDivider,
    Box,
    Link,
    useToast
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { getDatabase, push, ref, get, child, update, query, orderByKey, limitToFirst } from "firebase/database";
import moment from "moment/min/moment-with-locales";
import React, { useState, useEffect, useCallback } from "react";
import { useForm, FormProvider, useFormState, Controller } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai"
import { BiErrorCircle } from "react-icons/bi";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { useParams, useLocation, Link as RRLink } from "react-router-dom";
import CurrentPageResults from "./ResultsScreens/CurrentPageResults/CurrentPageResults.js";
import StartDemo from "./StartDemo.js";
import { AuthContext } from "../../../context/AuthContext";
import { currentConfig } from "../../App/App.js";
// import { CustomSelect} from "../../Global/CustomSelect/CustomSelect.js";
// import { Option } from "../../Global/CustomSelect/Option.js";
import { RadioButton, RadioButtonGroup } from "../../Global/RadioButtonGroup/RadioButtonGroup.js"
import Loading from "../../Loading/Loading.js";
import LinkAssetUploader from "../FormParts/LinkAssetUploader/LinkAssetUploader.js";
import RichText from "../FormParts/RichText/RichText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../Global/styles/styles.css";

function ProjectLinks() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const location = useLocation();
    const { projectId } = useParams();
    const [linkKey, setLinkKey] = useState(push(ref(database, "links")).key);
    const [qrUrl, setQrUrl] = useState("");
    const [projectName, setProjectName] = useState("Loading...");
    const [hdrStatus, setHdrStatus] = useState(false);
    const [customImageStatus, setCustomImageStatus] = useState(false);
    const [nextLinkKey, setNextLinkKey] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [numberOfSearches, setNumberOfSearches] = useState(0);
    const [linksSearchResults, setLinksSearchResults] = useState({});
    const [linksSearchResultsKeys, setLinksSearchResultsKeys] = useState([]);
    const [placeholder, changePlaceholder] = useState("Enter search...");
    const [firstName, setFirstName] = useState(currentUser.displayName);
    const [dropdownStatus, changeDropdown] = useState("title");
    const [activeLoadingStatus, setActiveLoadingStatus] = useState(true);
    const [errorScreen, setErrorScreen] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [keyLoadStatus, setKeyLoadStatus] = useState({});
    const [pageDictionary, setPageDictionary] = useState({});
    const [dictionaryIndex, setDictionaryIndex] = useState(1);
    const [highestPageLoaded, setHighestPageLoaded] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageKeys, setCurrentPageKeys] = useState([]);
    const [currentPageLinks, setCurrentPageLinks] = useState({});
    const [decreasedPageKeys, setDecreasedPageKeys] = useState([]);
    const [decreasedPageLinks, setDecreasedPageLinks] = useState({});
    const [isFinishedKey, setIsFinishedKey] = useState(false);
    const [noLinks, setNoLinks] = useState(false);
    const [autoplayValue, setAutoplayValue] = useState("true");
    const [rotationValue, setRotationValue] = useState("none");
    const [zoomValue, setZoomValue] = useState("none");
    const [hdrOptions, setHdrOptions] = useState([]);
    const [openModalProp, setOpenModalProp] = useState(false);
    const [locationLinkIdProp, setLocationLinkIdProp] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const searchDictionary = {
        link: "Link",
        header: "CTA Header",
        title: "Title",
    };
    const [isLargerThan500] = useMediaQuery("(min-width: 500px)")
    const { register, setValue, setError, clearErrors, reset, handleSubmit, control } = useForm();
    const { errors } = useFormState({
        control
    });

    useEffect(() => {
        async function getUserProjectData() {
            const pData = await get(child(dbRef, `projects/${projectId}`)).catch((error) => console.log(error));
            return pData.val();
        }

        // async function getExampleProjectData() {
        //     const exampleData = await get(child(dbRef, "examples/projects/")).catch((error) => console.log(error));
        //     const exampleDataVal = exampleData.val();
        //     const exampleDataKeys = Object.keys(exampleDataVal);
        //     return exampleDataVal[exampleDataKeys[0]];
        // }

        async function getUserLinks() {
            const linksQuery = await get(
                query(ref(database, `projects/${projectId}/links`), orderByKey(), limitToFirst(1))
            );
            return linksQuery.val();
        }

        // async function getExampleLinks() {
        //     const exampleLinksData = await get(child(dbRef, "examples/links/")).catch((error) => console.log(error));
        //     return exampleLinksData.val();
        // }

        getUserProjectData().then((projectData) => {
            if (projectData) {
                // setProjectColor(projectData.projectColor);
                setProjectName(projectData.projectName);
            }
        })

        getUserLinks().then((userLinks) => {
            if (userLinks) {
                let firstLinkKey = Object.keys(userLinks)[0];
                setNextLinkKey(firstLinkKey);
                loadNext(firstLinkKey);
                setActiveLoadingStatus(false);
                setNoLinks(false);
            }
            if (!userLinks) {
                setNextLinkKey()
                setCurrentPageLinks({});
                setCurrentPageKeys([]);
                setActiveLoadingStatus(false);
                setNoLinks(true);
            }
        })

        // if (currentUser.role === "guest") {
        //     getExampleProjectData().then((exampleProjectData) => {
        //         if (exampleProjectData) {
        //             setProjectName(exampleProjectData.projectName);
        //         }
            
        //         const exampleLinksData = getExampleLinks();
        //         let exampleLinksDataKeys;
        //         if (exampleLinksData) {
        //             exampleLinksDataKeys = Object.keys(exampleLinksData);
        //             setCurrentPageLinks(exampleLinksData);
        //             setCurrentPageKeys(exampleLinksDataKeys);
        //             setActiveLoadingStatus(false);
        //         }
        //         else {
        //             setCurrentPageLinks({});
        //             setCurrentPageKeys([]);
        //             setActiveLoadingStatus(false);
        //         }
        //     })
        // }

        let size = 400;
        let bgColor = "FFFFFF";
        let urlName = `${currentConfig.hostedUrl}/monad/${projectId}/${linkKey}`
        setQrUrl(`https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`);
        setValue("qr", `https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`);

        setHdrOptions([
            {
                label: "Sunrise",
                value: "sunrise",
            },
            {
                label: "Pillars",
                value: "pillars",
            },
            {
                label: "Music Hall",
                value: "hall",
            },
            {
                label: "Aircraft Workshop",
                value: "workshop",
            },
            {
                label: "Creek",
                value: "creek",
            },
        ]);

        const nameArr = currentUser.displayName.split(" ");
        const name = nameArr[0];
        setFirstName(name);
        
        if (location.state) {
            setLocationLinkIdProp(location.state.locationLinkId);
            setOpenModalProp(location.state.openModal)
        }
    }, []);

    useEffect(() => {
    }, [autoplayValue, rotationValue, zoomValue, setAutoplayValue, setRotationValue, setZoomValue])

    const loadNext = useCallback(async function (key) {
        setActiveLoadingStatus(true);
        let nextPageNumber = currentPage + 1;

        if (nextPageNumber < dictionaryIndex) {
            const increasedCurrentPage = currentPage + 1;
            const nextData = pageDictionary[currentPage + 1];
            const nextKeys = Object.keys(nextData);

            setCurrentPage(increasedCurrentPage);
            setDecreasedPageKeys(nextKeys);
            setDecreasedPageLinks(nextData);
        }

        else {
            await fetch("/api/getLinks", {
                method: "POST",
                headers: { "Content-Type": "application/json", "access-control-allow-origin": `https://ldp-studio-int.firebaseapp.com${location.path}`, },
                body: JSON.stringify({ projectId: projectId, lastLinkKey: key, firstLoad: isFirstLoad, location: `https://ldp-studio-int.firebaseapp.com${location.path}` }),
            })
                .then((res) => res.json())
                .then((data) => {
                    const { nextLinks, nextLinksKeys, lastKey, finished } = data;

                    if (nextLinks !== "error") {
                        const keyLoadStatusCopy = keyLoadStatus;
                        keyLoadStatusCopy[key] = true;
                        const newKeyLoadObj = {
                            [lastKey]: false,
                        };
                        let finalKeyLoadStatus = Object.assign(keyLoadStatusCopy, newKeyLoadObj);
                        let finalKeyLoadStatusKeys = Object.keys(finalKeyLoadStatus);
                        finalKeyLoadStatusKeys.shift();
                        finalKeyLoadStatusKeys.pop();

                        let dictionaryCopy = pageDictionary;
                        let dictionaryIndexCopy = dictionaryIndex;
                        let increasedDictionaryIndex = dictionaryIndexCopy + 1;
                        dictionaryCopy[dictionaryIndex] = nextLinks;
                        setPageDictionary(dictionaryCopy);
                        setDictionaryIndex(increasedDictionaryIndex);

                        let increasedCurrentPage = currentPage + 1;
                        let increasedHighestPage = highestPageLoaded + 1;
                        if (isFirstLoad) {
                            increasedCurrentPage = currentPage;
                            increasedHighestPage = highestPageLoaded;
                        }

                        setCurrentPage(increasedCurrentPage);
                        setCurrentPageKeys(nextLinksKeys);
                        setCurrentPageLinks(nextLinks);
                        setHighestPageLoaded(increasedHighestPage);
                        setNextLinkKey(lastKey);

                        setKeyLoadStatus(Object.assign(keyLoadStatusCopy, newKeyLoadObj));

                        if (finished === true) {
                            setIsFinishedKey(increasedCurrentPage);
                        }
                    }

                    if (nextLinks === "error") {
                        setErrorScreen(true);
                    }
                })
                .catch((error) => console.log("error after posting to getNextLinks", error));
        }
        setIsFirstLoad(false);
        setActiveLoadingStatus(false);
    }, []);
    
    async function loadPrevious() {
        setActiveLoadingStatus(true);
        const decreasedCurrentPage = currentPage - 1;
        const prevData = pageDictionary[currentPage - 1];
        const prevDataKeys = Object.keys(prevData);
        setCurrentPage(decreasedCurrentPage);
        setDecreasedPageKeys(prevDataKeys);
        setDecreasedPageLinks(prevData);
        setActiveLoadingStatus(false);
    }

    const onFormSubmit = async(data) => {
        const currentCredits = currentUser.credits;
        const currentTokens = currentUser.tokens.metasite;
        let newTokenBalance;
        let newCreditsBalance;
        if (currentTokens > 0) {
            newTokenBalance = currentTokens - 1;
            newCreditsBalance = currentCredits;
        }
        if (currentTokens === 0) {
            newTokenBalance = currentTokens;
            newCreditsBalance = currentCredits - 500;
        }

        const { 
            title, 
            ctaLinkPath, 
            ctaHeader, 
            ctaSubheader, 
            description1, 
            description2, 
            richText,
            richTextDesktop, 
            glb, 
            usdz, 
            img, 
            qr, 
            hdr, 
            includeHdr, 
            includeCustomImage
        } = data;
        const { uid, displayName} = currentUser;
        const timestamp = moment().valueOf();
        const formattedDate = moment().format("MMMM Do YYYY, h:mm:ss a");
        const ctaHeaderLowercase = ctaHeader.toLowerCase();
        const titleLowercase = title.toLowerCase();
        let hdrInformation;
        if (typeof hdr === "undefined" || includeHdr === false) {
            hdrInformation = includeHdr;
        }
        else {
            hdrInformation = hdr;
        }
        let imgValue;
        let statusValue = "unattempted";
        if (typeof img === "undefined" || includeCustomImage === false) {
            imgValue = false;
            statusValue = "processing";
        }
        else {
            imgValue = img;
        }
        const linkData = {
            title,
            titleLowercase,
            ctaLinkPath,
            ctaHeader,
            ctaHeaderLowercase,
            ctaSubheader,
            demo: false,
            description1,
            description2,
            glbOnlyFlag: false,
            richText,
            richTextDesktop,
            rotation: rotationValue,
            zoom: zoomValue,
            autoplay: autoplayValue,
            uid,
            displayName,
            assets: {
                glb,
                usdz,
                img: imgValue,
                qr,
                hdr: hdrInformation,
            },
            createdAt: timestamp,
            archived: false,
            formattedDate,
            projectId,
            screenshot: statusValue,
        };

        const updates = {};
        updates["/links/" + linkKey] = linkData;  // write to links
        updates["/projects/" + projectId + "/links/" + linkKey] = linkData;  // write to projects/links
        updates["/users/" + uid + "/projects/" + projectId + "/links/" + linkKey] = { createdAt: timestamp };  // write to users/projects/links
        updates["/users/" + uid + "/credits/"] = newCreditsBalance;  // update credits with new value on creation of new link
        updates["/users/" + uid + "/tokens/metasite/"] = newTokenBalance;
        if (currentUser.credits !== newCreditsBalance) {
            currentUser.credits = newCreditsBalance;
        }
        if (currentUser.tokens.metasite !== newTokenBalance) {
            currentUser.tokens.metasite = newTokenBalance;
        }
        setCustomImageStatus(false);
        setHdrStatus(false);
        await update(ref(database), updates);
        reset({
            title: title,
            ctaLinkPath: ctaLinkPath, 
            ctaHeader: ctaHeader, 
            ctaSubheader: ctaSubheader, 
            description1: description1, 
            description2: description2, 
        });

        let currentKeysCopy = currentPageKeys;
        let currentLinksCopy = currentPageLinks;

        if (Object.keys(currentPageLinks).length === 0) {
            currentKeysCopy = [linkKey];
            currentLinksCopy = {[linkKey]: linkData};
        }
        if (Object.keys(currentPageLinks).length !== 0) {
            currentLinksCopy[linkKey] = linkData;
            currentKeysCopy[currentKeysCopy.length] = linkKey;
        }

        setCurrentPageKeys(currentKeysCopy);
        setCurrentPageLinks(currentLinksCopy);
        setLinkKey(push(ref(database, "links")).key);
        toast({
            title: "MetaSite Created",
            description: `"${title}" has been created.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
        onClose();
    }

    const search = async () => {
        setSearching(true);
        let searchType = "titleLowercase";
        if (dropdownStatus === "link") {
            searchType = "ctaLinkPath";
        }
        if (dropdownStatus === "header") {
            searchType = "ctaHeaderLowercase";
        }

        await fetch("/api/searchLinks", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ searchType: searchType, searchString: searchString, projectId: projectId }),
        })
            .then((res) => res.json())
            .then((data) => {
                const { results, resultsKeys } = data;
                if (resultsKeys !== "none") {
                    setLinksSearchResults(results);
                    setLinksSearchResultsKeys(resultsKeys);
                }
                if (resultsKeys === "none") {
                    setLinksSearchResults({});
                    setLinksSearchResultsKeys([]);
                }
            })
            .catch((error) => console.log("error after posting to searchLinks", error));
        const newSearchNumber = numberOfSearches + 1;
        setNumberOfSearches(newSearchNumber);
        setSearching(false);
    }

    const changeToLink = () => {
        changeDropdown("link");
        changePlaceholder("http://lovewildhorses.org");
    }

    const changeToHeader = () => {
        changeDropdown("header");
        changePlaceholder("Purchase Here!");
    }

    const changeToTitle = () => {
        changeDropdown("title");
        changePlaceholder("Asset 02_05");
    }

    // if (activeLoadingStatus) {
    //     return <Loading size="medium" />
    // }

    // if (!activeLoadingStatus) {
    return (
        <Container py="8" flex="1">
            {currentPageKeys.length !== 0 &&
                <Container py="8" flex="1">
                    <Stack
                        spacing={{
                            base: "8",
                            lg: "6",
                        }}
                    >
                        <Stack
                            spacing="4"
                            direction={{
                                base: "column",
                                lg: "row",
                            }}
                            justify="space-between"
                            align={{
                                base: "start",
                                lg: "center",
                            }}
                        >
                            <Stack spacing="1">
                                <Heading
                                    size="sm"
                                    fontWeight="medium"
                                >
                                    {projectName}
                                </Heading>
                                <Text color="muted">MetaSites belonging to the {`"${projectName}"`} project</Text>
                            </Stack>
                            {(currentUser.credits >= 500 || currentUser.tokens.metasite >= 1) &&
                                <HStack spacing="3">
                                    {!showSearch &&
                                    <Button variant="secondary" leftIcon={<BsSearch fontSize="1.25rem" />} onClick={() => setShowSearch(true)}>
                                        Search
                                    </Button>
                                    }
                                    {!!showSearch &&
                                        <Button variant="secondary" leftIcon={<AiFillCloseCircle fontSize="1.25rem" />} onClick={() => setShowSearch(false)}>
                                            Close Search
                                        </Button>
                                    }
                                    <Button variant="primary" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />} onClick={onOpen}>Create MetaSite</Button>
                                </HStack>
                            }
                            {(currentUser.tokens.metasite < 1 && currentUser.credits < 500) &&
                                <HStack spacing="3">
                                    {currentUser.role === "guest" &&
                                        <Stack
                                            direction={{
                                                base: "column",
                                                sm: "row",
                                            }}
                                            spacing={4}
                                        >
                                            <Tooltip label="You need at least one MetaSite Token to create a new MetaSite. Go to the Pricing page in the Account dropdown to upgrade your account." placement="auto">
                                                <span><Button variant="secondary" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />} disabled>Create MetaSite</Button></span>
                                            </Tooltip>
                                            <Link as={RRLink} to={"/tutorial"} _hover={{textDecoration: "none"}}>
                                                <Button variant="primary" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />}>Create Demo MetaSite</Button>
                                            </Link>
                                        </Stack>
                                    }
                                    {currentUser.role !== "guest" &&
                                        <Tooltip label="You need at least one MetaSite Token to create a new MetaSite. Go to the Pricing page in the Account dropdown to upgrade your account." placement="auto">
                                            <span><Button variant="primary" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />} disabled>Create MetaSite</Button></span>
                                        </Tooltip>
                                    }
                                </HStack>
                            }
                        </Stack>

                        {!!showSearch &&
                            <Stack spacing={3} p={3} width="100%">
                                <InputGroup>
                                    <InputLeftAddon px={0} py={0}>
                                        <Menu height="fit-content">
                                            {({ isOpen }) => (
                                                <>
                                                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                                                        {isOpen ? "Search by" : `${searchDictionary[dropdownStatus]}`}
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem onClick={() => changeToTitle()}>Title</MenuItem>
                                                        <MenuItem onClick={() => changeToHeader()}>CTA Header</MenuItem>
                                                        <MenuItem onClick={() => changeToLink()}>Link</MenuItem>
                                                    </MenuList>
                                                </>
                                            )}
                                        </Menu>
                                    </InputLeftAddon>
                                    <Input placeholder={placeholder} onChange={(event) => setSearchString(event.target.value)} onKeyUp={(e) => {
                                        if (e.code === "Enter") {
                                            search()
                                        }
                                    }
                                    }></Input>
                                    <InputRightAddon px={0} py={0}><Button onClick={() => search()} onKeyUp={(e) => {
                                        if (e.code === "Enter") {
                                            search()
                                        }
                                    }
                                    }><Icon as={BsSearch} mr={2} boxSize={5} />Search</Button></InputRightAddon>
                                </InputGroup>
                            </Stack>
                        }
                    </Stack>
                </Container>
            }
            
            {currentPageKeys.length !== 0 &&
                    <Flex direction="column">
                        <Stack>
                            {(currentPageKeys.length !== 0 && Object.keys(currentPageLinks).length !== 0 && !showSearch && !activeLoadingStatus) &&
                                <CurrentPageResults currentPageKeys={currentPageKeys} currentPageLinks={currentPageLinks} projectId={projectId} openModalProp={openModalProp} locationLinkIdProp={locationLinkIdProp} />
                            }

                            {((currentPage !== highestPageLoaded) && decreasedPageKeys.length !== 0 && Object.keys(decreasedPageLinks).length !== 0 && !showSearch && !activeLoadingStatus && currentPageKeys.length !== 0) &&
                                <CurrentPageResults currentPageKeys={decreasedPageKeys} currentPageLinks={decreasedPageLinks} projectId={projectId} />
                            }

                            {(!!showSearch && !searching && currentPageKeys.length !== 0) &&
                                <>
                                    {linksSearchResultsKeys.length !== 0 && Object.keys(linksSearchResults).length !== 0 &&
                                        <CurrentPageResults currentPageKeys={linksSearchResultsKeys} currentPageLinks={linksSearchResults} projectId={projectId} />
                                    }
                                </>
                            }

                            {!!searching &&
                                <Flex direction="column" justify="center"> 
                                    <a style={{color: "#000000"}} href ="https://www.youtube.com/watch?v=kKhzsx2gVgM"><h2 style={{textAlign: "center", width: "100%"}}>Searching...</h2></a>
                                </Flex>
                            }

                            {!!errorScreen && 
                                <Flex direction="column" justify="center"> 
                                    <h2 style={{textAlign: "center", width: "100%"}}>There was an error retrieving the next MetaSites.</h2>
                                    <h3 style={{textAlign: "center", width: "100%"}}> Please try again.</h3>
                                </Flex>
                            }

                            {!!showSearch && numberOfSearches > 0 && !searching && linksSearchResultsKeys.length === 0 &&
                                <Flex direction="column" justify="center"> 
                                    <h2 style={{textAlign: "center", width: "100%"}}>No results were found for this search.</h2>
                                    <h3 style={{textAlign: "center", width: "100%"}}> Please try again.</h3>
                                </Flex>
                            }
                        </Stack>

                        {!showSearch &&
                            <Flex width="100%" justify="center" mt={5}>
                                <HStack>
                                    <ButtonGroup isAttached variant="secondary">
                                        <IconButton
                                            value="left"
                                            aria-label="Show previous"
                                            icon={<FiArrowLeft fontSize="1.25rem" />}
                                            onClick={() => loadPrevious()}
                                            disabled={currentPage === 1}
                                        />
                                        <Button
                                            value="center"
                                            aria-label="Current Page"
                                            mx="-px">
                                            {currentPage}
                                        </Button>
                                        <IconButton
                                            value="right"
                                            aria-label="Show next"
                                            icon={<FiArrowRight fontSize="1.25rem" />}
                                            onClick={() => loadNext(nextLinkKey)} 
                                            disabled={!!isFinishedKey && (currentPage === isFinishedKey)}
                                        />
                                    </ButtonGroup>
                                </HStack>
                            </Flex>
                        }

                    </Flex>
            }

            {currentPageKeys.length === 0 && noLinks &&
                    <Flex direction="column" align="center">
                        {currentUser.role !== "guest" &&
                            <Flex direction="column" width="90%" mt="10">
                                <Heading size="3xl" mb="5">Hey there, {firstName}...</Heading>
                                <Text fontSize="xl" mb="3">It looks like you don&apos;t have any MetaSites yet. No worries!</Text>
                                <Divider mb={3} />
                                <Text fontSize="lg" mb="5">To get started, simply click the button below. Fill out the MetaSite&apos;s name and description then hit Submit. You will see your MetaSite appear on this page. If it does not appear, try refreshing the page.</Text>
                                {(currentUser.tokens.metasite > 0 || currentUser.credits >= 500) &&
                                    <Button variant="blueLDP" onClick={onOpen} width="fit-content">Create Your First MetaSite <Icon as={BsPlusCircleFill} ml={3} /></Button>
                                }
                                {(currentUser.tokens.metasite < 1 && currentUser.credits < 500) &&
                                    <Tooltip label="You need at least one MetaSite Token to create a new MetaSite. Go to the Pricing page in the Account dropdown to upgrade your account." placement="auto"><Button disabled width="fit-content">Create MetaSite  <Icon as={BsPlusCircleFill} ml={3} boxSize={7} /></Button></Tooltip>
                                }
                            </Flex>
                        }
                        {currentUser.role === "guest" &&
                            <Container py="8" flex="1">
                                <Stack
                                    spacing={{
                                        base: "8",
                                        lg: "6",
                                    }}
                                >
                                    <StartDemo />
                                </Stack>
                            </Container>
                        }
                    </Flex>
            }

            {currentPageKeys.length === 0 && !noLinks &&
                    <Flex height="100vh" width="100%" direction="column" alignItems="center" justifyContent="center">
                        <Loading size="medium" />
                    </Flex>
            }

            <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader bg="blackAlpha.800">
                        <Heading size="sm" mb="2" color="white"> Create MetaSite</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} reset={reset} control={control}>
                        <form onSubmit={handleSubmit(onFormSubmit)}> 
                            <ModalBody bg="gray.50">

                                <Container
                                    py={{
                                        base: "4",
                                        md: "8",
                                    }}>
                                    <Stack spacing="5" divider={<StackDivider />}>
                                                
                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                        Upload Assets
                                                </Text>
                                                <Text fontSize="sm">
                                                        Upload AR files for iOS and Android and an optional thumbnail image
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>
                                                        
                                                    <FormControl isRequired>
                                                        <FormLabel htmlFor="glb" >Android/Web File(.glb or .gltf):</FormLabel>
                                                        <LinkAssetUploader projectKey={projectId} newLinkKey={linkKey} required={true} inputName="glb" />
                                                    </FormControl>

                                                    <FormControl isRequired>
                                                        <FormLabel htmlFor="usdz">iOS File(.usdz or .reality):</FormLabel>
                                                        <LinkAssetUploader projectKey={projectId} newLinkKey={linkKey} required={true} inputName="usdz" />
                                                    </FormControl>

                                                    <Checkbox size='lg' colorScheme='blue' mb={4} mt={5} fontWeight="500" fontSize="md" fontFamily="Roboto Mono" {...register("includeCustomImage")} onChange={e => {
                                                        if (e.target.checked) {
                                                            setCustomImageStatus(true);
                                                        }
                                                        else {
                                                            setCustomImageStatus(false);
                                                        }
                                                    }} >
                                                        <FormLabel mt={2}>Upload Custom Image?</FormLabel>
                                                    </Checkbox>
                                                    {!!customImageStatus &&
                                                            <LinkAssetUploader projectKey={projectId} newLinkKey={linkKey} required={true} inputName="img" />
                                                    }
                                                </Stack>
                                            </Box>
                                        </Stack>

                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                        AR Options
                                                </Text>
                                                <Text fontSize="sm">
                                                        Optionally place your AR model in an environment or position it in space
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>

                                                    <Checkbox size='lg' colorScheme='blue' mb={4} mt={5} fontWeight="500" fontSize="md" {...register("includeHdr")} onChange={e => {
                                                        if (e.target.checked) {
                                                            setHdrStatus(true);
                                                        }
                                                        else {
                                                            setHdrStatus(false);
                                                        }
                                                    }} >
                                                        <FormLabel mt={2} mb={0}>Include HDR Background Image?</FormLabel>
                                                    </Checkbox>

                                                    {hdrStatus &&
                                                            <Select name="hdr" mb="10px !important" mt="5px !important" {...register("hdr")}>
                                                                {hdrOptions.map(o => (
                                                                    <option key={o.value} value={o.value}>{o.label}</option>
                                                                ))}
                                                            </Select>
                                                            // <CustomSelect
                                                            //     {...register("hdr")}
                                                            //     name="hdr"
                                                            //     colorScheme="blue"
                                                            //     value={colorMode}
                                                            //     onChange={setColorMode}
                                                            //     placeholder="Select a background"
                                                            // >
                                                            //     {hdrOptions.map(o => (
                                                            //         <Option value={o.value} key={o.value}>
                                                            //             <HStack>
                                                            //                 <Text>{o.label}</Text>
                                                            //             </HStack>
                                                            //         </Option>
                                                            //     ))}
                                                            // </CustomSelect>
                                                    }

                                                    <FormLabel>WebAR Options:</FormLabel>
                                                    <RadioGroup name="rotation" defaultValue={rotationValue}>
                                                        <RadioButtonGroup size="lg" name="rotation" defaultValue={rotationValue} onChange={(e) => setRotationValue(e)}>
                                                            <RadioButton value="left">{isLargerThan500 ? <Text>Rotate Left</Text> : <Text>Left</Text> }</RadioButton>
                                                            <RadioButton value="none">{isLargerThan500 ? <Text>No Rotation</Text> : <Text>None</Text> }</RadioButton>
                                                            <RadioButton value="right">{isLargerThan500 ? <Text>Rotate Right</Text> : <Text>Right</Text> }</RadioButton>
                                                        </RadioButtonGroup>
                                                    </RadioGroup>

                                                    <RadioGroup name="zoom" defaultValue={zoomValue}>
                                                        <RadioButtonGroup size="lg" name="zoom" defaultValue={zoomValue} onChange={(e) => setZoomValue(e)}>
                                                            <RadioButton value="out">{isLargerThan500 ? <Text>Zoom Out</Text> : <Text>Out</Text> }</RadioButton>
                                                            <RadioButton value="none">{isLargerThan500 ? <Text>No Zoom</Text> : <Text>None</Text> }</RadioButton>
                                                            <RadioButton value="in">{isLargerThan500 ? <Text>Zoom In</Text> : <Text>In</Text> }</RadioButton>
                                                        </RadioButtonGroup>
                                                    </RadioGroup>

                                                    <RadioGroup name="autoplay" defaultValue={autoplayValue}>
                                                        <RadioButtonGroup size="lg" name="autoplay" defaultValue={autoplayValue} onChange={(e) => setAutoplayValue(e)}>
                                                            <RadioButton value="true">{isLargerThan500 ? <Text>Autoplay Animation</Text> : <Text>Autoplay</Text> }</RadioButton>
                                                            <RadioButton value="false">{isLargerThan500 ? <Text>No Autoplay Animation</Text> : <Text>None</Text> }</RadioButton>
                                                        </RadioButtonGroup>
                                                    </RadioGroup>
                                                </Stack>
                                            </Box>
                                        </Stack>


                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                        The Basics
                                                </Text>
                                                <Text fontSize="sm">
                                                        Give your project a name and description 
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>
                                                        
                                                    <FormControl isRequired>
                                                        <FormLabel htmlFor="title">MetaSite Title (internal use only):</FormLabel>
                                                        <Input name="title" {...register("title", { required: "This is required."})} />
                                                        <ErrorMessage errors={errors} name="title" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                    </FormControl>

                                                    <FormControl isRequired>
                                                        <FormLabel htmlFor="ctaLinkPath">Call to Action Link:</FormLabel>
                                                        <Input className="input-styling" name="ctaLinkPath" {...register("ctaLinkPath", { validate: (value) => RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?").test(value) || "Not a valid URL"})}/>
                                                        <ErrorMessage errors={errors} name="ctaLinkPath" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>}/>
                                                    </FormControl>
                                                        
                                                    <FormControl isRequired>
                                                        <FormLabel htmlFor="ctaHeader">Call to Action Button Text:</FormLabel>
                                                        <Input className="input-styling" name="ctaHeader" {...register("ctaHeader", { required: "This is required."})} />
                                                        <ErrorMessage errors={errors} name="ctaHeader" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                    </FormControl>

                                                    <FormControl isRequired>
                                                        <FormLabel htmlFor="ctaSubheader">Call to Action Button Text (Line 2):</FormLabel>
                                                        <Input className="input-styling" name="ctaSubheader" {...register("ctaSubheader", { required: "This is required."})} />
                                                        <ErrorMessage errors={errors} name="ctaSubheader" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                    </FormControl>

                                                    <FormControl>

                                                        <FormLabel htmlFor="description1">Description First Paragraph:</FormLabel>
                                                        <Textarea className="input-styling" name="description1" {...register("description1")} />
                                                        <ErrorMessage errors={errors} name="description1" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                    </FormControl>

                                                    <FormControl>

                                                        <FormLabel htmlFor="description2">Description Second Paragraph:</FormLabel>
                                                        <Textarea className="input-styling" name="description2" {...register("description2")} />
                                                        <ErrorMessage errors={errors} name="description2" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel htmlFor="richText">Rich Text</FormLabel>
                                                        <Controller
                                                            render={({ field }) => <RichText {...field} />}
                                                            name="richText"
                                                            control={control}
                                                            defaultValue=""
                                                        />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel htmlFor="richTextDesktop">Rich Text - Desktop Only</FormLabel>
                                                        <Controller
                                                            render={({ field }) => <RichText {...field} />}
                                                            name="richTextDesktop"
                                                            control={control}
                                                            defaultValue=""
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Box>
                                        </Stack>

                                            
                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                        You&apos;re Done!
                                                </Text>
                                                <Text fontSize="sm">
                                                        Save this QR code to easily link to your MetaSite
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>
                                                        
                                                    <FormControl>
                                                        <FormLabel htmlFor="qr">QR File:</FormLabel>
                                                        <img src={qrUrl} alt="" height="200px" width="200px" />
                                                        <input type="hidden" {...register("qr")} />
                                                    </FormControl>
                                                </Stack>
                                            </Box>
                                        </Stack>



                                    </Stack>
                                </Container>
                            </ModalBody>

                            <ModalFooter bg="gray.50">
                                <Button 
                                    variant="primary" type="submit">Submit</Button>
                                <Button variant="outline" ml={3} onClick={onClose}>
                                        Close
                                </Button>
                            </ModalFooter>
                        </form>
                    </FormProvider>
                </ModalContent>
            </Modal>
        </Container>
    );
    // }
    // return null;
}

export default ProjectLinks;
