import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import PropTypes from "prop-types";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function MonadBarChart({ monadViewsData, ctaClicksData, bannerViewsData, title }) {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: `MetaSite Views & Clicks - ${title}`,
                font: {
                    size: 18,
                }
            },
        },
        scales: {
            x: {
                grid: {
  
                }
            },
            y: {
                grid: {
                    lineWidth: 2,
                },
            },
        },
        layout: {
            padding: 0,
        }
    }
  
    const labels = ["Past 90 Days"];
  
    const data = {
        labels,
        datasets: [
            {
                label: "Page Views",
                data: monadViewsData.values,
                backgroundColor: "#3182ce",
            },
            {
                label: "CTA Clicks",
                data: ctaClicksData.values,
                backgroundColor: "rgba(0, 0, 0, 0.80)",
            },
            {
                label: "AR Scene Views",
                data: bannerViewsData.values,
                backgroundColor: "#ffc107",
            },
        ],
    };
  
    return (
        <>
            <Bar options={options} data={data} />
        </>
    );
}

MonadBarChart.propTypes = {
    monadViewsData: PropTypes.object, 
    ctaClicksData: PropTypes.object, 
    bannerViewsData: PropTypes.object, 
    title: PropTypes.string,
}

export default MonadBarChart;