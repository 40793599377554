import { getDatabase, ref, child, get } from "firebase/database";
import React, { useState, useEffect } from "react";
import TwoCardsLayoutWrapper from "./TwoCards/TwoCardsLayoutWrapper.js";

function Pricing() {
    const database = getDatabase();
    const dbRef = ref(database);
    const [roles, setRoles] = useState();

    useEffect(() => {
        async function getRoles() {
            const rolesRes = await get(child(dbRef, "roles/")).catch((error) => console.log(error));
            return rolesRes.val();
        }
        getRoles().then((userRoles) => {
            setRoles(userRoles);
        })
    }, [])

    return (
        <>
            {!!roles &&
                <TwoCardsLayoutWrapper />
            }
        </>
    );
}

export default Pricing;