import { Button, Divider, Flex, Heading, Icon, Link, Text, Container, Stack } from "@chakra-ui/react"
import React from "react";
import { BsClipboardCheck } from "react-icons/bs";
import { Link as RRLink } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

function EmptyPromptGlobalLinks() {
    const {currentUser} = React.useContext(AuthContext);
    const nameArr = currentUser.displayName.split(" ");
    const firstName = nameArr[0];

    return (
        <Container py="8" flex="1">
            <Container py="8" flex="1">
                <Stack
                    spacing={{
                        base: "8",
                        lg: "6",
                    }}
                >

                    {/* TODO: Add a different version of content to reflect demo implementation for guests */}

                    <Flex direction="column" align="center">
                        <Flex direction="column" width="90%" mt="10">
                            <Heading size="3xl" mb="5">Hi, {firstName}!</Heading>
                            <Text fontSize="xl" mb="3">It looks like you don &lsquo;t have any MetaSites yet...</Text>
                            <Divider mb={3} />
                            <Text fontSize="lg" mb="5">To get started, simply click the button below to return to the Projects page. Create a project or select an existing project and then click the &quot;Go to Project&quot; button. From there, you will be led through instructions to create your first MetaSite.</Text>
                            <Flex width="fit-content">
                                <Link as={RRLink} to="/projects"><Button>Return to Projects  <Icon as={BsClipboardCheck} ml={3} /></Button></Link>
                            </Flex>
                        </Flex>
                    </Flex>
                </Stack>
            </Container>
        </Container>
    );
}

export default EmptyPromptGlobalLinks;
