import { getDatabase, ref, get, child } from "firebase/database";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MonadSplitter from "./MonadSplitter.js";
import MonadSplitterDemo from "./MonadSplitterDemo.js";
import NotFound from "../App/NotFound.js";
import Loading from "../Loading/Loading.js";
import "./Monad.css";

function Monad() {
    const { projectId, linkId } = useParams();
    const database = getDatabase();
    const dbRef = ref(database);
    const [monadData, setMonadData] = useState(false);
    const [assetsData, setAssetsData] = useState(false);
    const [error, setError] = useState(false);
    const [mobileOS, setMobileOS] = useState(false);
    const [loading, setLoading] = useState(true);

    const getMobileOS = () => {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "Android";
        }
        else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) {
            return "iOS";
        }
        return "Other";
    }

    useEffect(() => {
        const os = getMobileOS();
        setMobileOS(os);

        async function getLinkData() {
            await get(child(dbRef, `links/${linkId}`)).then((lData) => {
                const lDataVal = lData.val();
                setMonadData(lDataVal);
                return lDataVal;
            }).catch((error) => {
                console.log(error);
                setError(true);
            })
        }

        async function getLinkAssetsData() {
            await get(child(dbRef, `assets_data/${projectId}/${linkId}`)).then((aData) => {
                const aDataVal = aData.val();
                setAssetsData(aDataVal);
                return aDataVal;
            }).catch((error) => {
                console.log(error);
                setError(true);
            })
        }

        getLinkData();
        getLinkAssetsData();

        setLoading(false);
    }, []);  

    useEffect(() => {
        console.log("error in Monad", error);
    }, [error])


    if (loading) {
        return <Loading size="fullscreen" />
    }

    if (error) {
        return <NotFound />
    }

    if (!loading && !error) {
        return (
            <>
                {monadData.demo === false && 
                    <MonadSplitter monadData={monadData} assetsData={assetsData} mobileOS={mobileOS} projectId={projectId} linkId={linkId} />
                }
                
                {monadData.demo === true && 
                    <MonadSplitterDemo monadData={monadData} assetsData={assetsData} mobileOS={mobileOS} projectId={projectId} linkId={linkId} />
                }
            </>
        )
    }
}
  
export default Monad;
