import "@google/model-viewer";
import { 
    Text, 
    Container, 
    Heading,
    Box,
    Button,
    Flex,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import { getDatabase, ref, child, get } from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import CgTraderSearchScreen from "./CgTraderSearchScreen";
import CgTraderSyncScreen from "./CgTraderSyncScreen";
import { AuthContext } from "../../context/AuthContext";
import "./CgTrader.css";

function CgTrader({ cgTraderUser }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [dbPurchases, setDbPurchases] = useState(false);
    const [freePurchases, setFreePurchases] = useState(false);
    const [pageMode, setPageMode] = useState("search");

    useEffect(() => {
        async function getCgTraderUidData() {
            const cgTraderUidData = await get(child(dbRef, `cg_trader_purchases/${currentUser.uid}`)).catch((error) => console.log(error));
            if (cgTraderUidData) {
                return cgTraderUidData.val(); 
            }
            if (!cgTraderUidData) {
                return false;            
            }
        }

        async function getFreeUidData() {
            const freeUidData = await get(child(dbRef, `cg_trader_free_purchases/${currentUser.uid}`)).catch((error) => console.log(error));
            if (freeUidData) {
                return freeUidData.val(); 
            }
            if (!freeUidData) {
                return false;            
            }
        }

        async function getAllData() {
            const dbData = await getCgTraderUidData();
            const dbFreeData = await getFreeUidData();
            setDbPurchases(dbData);
            setFreePurchases(dbFreeData);
        }

        return getAllData();
    }, []);

    return (
        <>
            <Container py="8" flex="1">

                <Box as="section" bg={mode("gray.50", "gray.800")} pt="24" pb="0" overflow="hidden">
                    <Box maxW={{ base: "xl", md: "7xl" }} mx="auto" px={{ base: "6", md: "8" }}>
                        <Flex
                            align="flex-start"
                            direction={{ base: "column", lg: "row" }}
                            justify="space-between"
                            mb="20"
                        >
                            <Box flex="1" maxW={{ lg: "xl" }} pt="6">
                                <Heading as="h1" size="3xl" mt="8" fontWeight="extrabold">
                                    What&apos;s Your Perfect 3D Model?
                                </Heading>
                                <Text color={mode("gray.600", "gray.400")} mt="5" fontSize="xl">
                                    Search for a new model or import past purchases. We&apos;ve created filters to help you find models you can use inside our app today.
                                </Text>
                                <Button
                                    mt="8"
                                    mr="5"
                                    minW="10rem"
                                    colorScheme="blue"
                                    size="lg"
                                    height="14"
                                    px="8"
                                    fontSize="md"
                                    fontWeight="bold"
                                    onClick={() => setPageMode("search")}
                                >
                                    Search Models
                                </Button>
                                <Button
                                    mt="8"
                                    minW="10rem"
                                    variant="secondary"
                                    size="lg"
                                    height="14"
                                    px="8"
                                    fontSize="md"
                                    fontWeight="bold"
                                    onClick={() => setPageMode("sync")}
                                >
                                    Past Purchases
                                </Button>
                            </Box>
                            <Box boxSize={{ base: "20", lg: "8" }} />
                            <Box w="40rem" h="500px">
                                <model-viewer
                                    id="model-viewer-cgtrader"
                                    src="https://www.googleapis.com/download/storage/v1/b/ldp-studio-int.appspot.com/o/monadAssets%2F-MwURkxNZicuXDeQM8_w%2F-MwUS0NH2Yv_G_keLdW9%2Fglb%2Fhorse-god.glb?generation=1645500369547784&alt=media"
                                    alt="A 3D model of an astronaut"
                                    ar
                                    autoplay
                                    camera-controls>
                                </model-viewer>
                            </Box>
                        </Flex>
                        <Box>
                        </Box>
                    </Box>
                </Box>

                {pageMode === "search" &&
                    <CgTraderSearchScreen />
                }

                {(cgTraderUser && dbPurchases && pageMode === "sync") &&
                    <CgTraderSyncScreen dbPurchases={dbPurchases} cgTraderUser={cgTraderUser} freePurchases={freePurchases} />
                }

            </Container>
        </>
    );
}

export default CgTrader;

CgTrader.propTypes = {
    cgTraderUser: PropTypes.object
}