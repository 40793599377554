import { 
    Image,
    Button, 
    Flex, 
    Heading, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure 
} from "@chakra-ui/react"
import PropTypes from "prop-types";
import React, { useState } from "react";
import { BsFillBarChartFill } from "react-icons/bs";
import DeviceDoughnutViews from "./Charts/DeviceDonutViews";
import DeviceDoughnutCta from "./Charts/DeviceDoughnutCta";
import MonadBarChart from "./Charts/MonadBarChart";
import RegionDoughnut from "./Charts/RegionDoughnut";
import discoloading from "../../assets/discoloading.gif";
import ldplogofooter from "../../assets/ldp-logo-footer.png";
// import { currentConfig } from "../App/App.js";

function GlobalLinksAnalytics({linkId, projectId, title}) {
    const [ctaClicksData, setCtaClicksData] = useState({
        labels: [],
        values: [],
    });
    const [monadViewsData, setMonadViewsData] = useState({
        labels: [],
        values: [],
    });
    const [bannerViewsData, setBannerViewsData] = useState({
        labels: [],
        values: [],
    });
    const [ctaDeviceData, setCtaDeviceData] = useState({
        labels: [],
        values: [],
    });
    const [deviceViewsData, setDeviceViewsData] = useState({
        labels: [],
        values: [],
    });
    const [regionViewsData, setRegionViewsData] = useState({
        labels: [],
        values: [],
    });
    const [ctaRegionData, setCtaRegionData] = useState({
        labels: [],
        values: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const retrieveDataFunction = async (projectId, linkId) => {
        await fetch("/api/getCtaClickedReport", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ startDate: "90daysAgo", endDate: "today", projectId: projectId, linkId: linkId } 
            ),
        })
            .then((res) => res.json())
            .then((data) => {
                const resultsData = data.results[0];
                const rows = resultsData.rows;

                let labels = [];
                let values = [];
                rows.forEach((row) => {
                    labels.push(row.dimensionValues[0].value);
                    values.push(row.metricValues[0].value);
                });
                setCtaClicksData({
                    ...ctaClicksData,
                    labels,
                    values,
                });
            })
            .catch((error) => console.log("error after posting to ctaClickedReport", error));


        await fetch("/api/getMonadPageViews", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ startDate: "90daysAgo", endDate: "today", projectId: projectId, linkId: linkId } 
            ),
        })
            .then((res) => res.json())
            .then((data) => {
                const resultsData = data.results[0];
                const rows = resultsData.rows; // queryResult

                let labels = [];
                let values = [];
                rows.forEach((row) => {
                    labels.push(row.dimensionValues[0].value);
                    values.push(row.metricValues[0].value);
                });
                setMonadViewsData({
                    ...monadViewsData,
                    labels,
                    values,
                });
            })
            .catch((error) => console.log("error after posting to getMonadPageViews", error));

    
        await fetch("/api/getMonadBannerViews", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ startDate: "90daysAgo", endDate: "today", projectId: projectId, linkId: linkId } 
            ),
        })
            .then((res) => res.json())
            .then((data) => {
                const resultsData = data.results[0];
                const rows = resultsData.rows; // queryResult

                let labels = [];
                let values = [];
                rows.forEach((row) => {
                    labels.push(row.dimensionValues[0].value);
                    values.push(row.metricValues[0].value);
                });
                setBannerViewsData({
                    ...bannerViewsData,
                    labels,
                    values,
                });
            })
            .catch((error) => console.log("error after posting to getMonadBannerViews", error));

        await fetch("/api/getCtaDeviceReport", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ startDate: "90daysAgo", endDate: "today", projectId: projectId, linkId: linkId } 
            ),
        })
            .then((res) => res.json())
            .then((data) => {
                const resultsData = data.results[0];
                const rows = resultsData.rows; // queryResult

                let labels = [];
                let values = [];
                rows.forEach((row) => {
                    labels.push(row.dimensionValues[0].value);
                    values.push(row.metricValues[0].value);
                });
                setCtaDeviceData({
                    ...ctaDeviceData,
                    labels,
                    values,
                });
            })
            .catch((error) => console.log("error after posting to ctaDeviceReport", error));

        await fetch("/api/getCtaRegionReport", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ startDate: "90daysAgo", endDate: "today", projectId: projectId, linkId: linkId } 
            ),
        })
            .then((res) => res.json())
            .then((data) => {
                const resultsData = data.results[0];
                const rows = resultsData.rows; // queryResult

                let labels = [];
                let values = [];
                rows.forEach((row) => {
                    labels.push(row.dimensionValues[0].value);
                    values.push(row.metricValues[0].value);
                });
                setCtaRegionData({
                    ...ctaRegionData,
                    labels,
                    values,
                });
            })
            .catch((error) => console.log("error after posting to ctaRegionReport", error));

        await fetch("/api/getDevicePageViews", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ startDate: "90daysAgo", endDate: "today", projectId: projectId, linkId: linkId } 
            ),
        })
            .then((res) => res.json())
            .then((data) => {
                const resultsData = data.results[0];
                const rows = resultsData.rows; // queryResult
    
                let labels = [];
                let values = [];
                rows.forEach((row) => {
                    labels.push(row.dimensionValues[0].value);
                    values.push(row.metricValues[0].value);
                });
                setDeviceViewsData({
                    ...deviceViewsData,
                    labels,
                    values,
                });
            })
            .catch((error) => console.log("error after posting to getDevicePageViews", error));

        await fetch("/api/getRegionPageViews", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ startDate: "90daysAgo", endDate: "today", projectId: projectId, linkId: linkId } 
            ),
        })
            .then((res) => res.json())
            .then((data) => {
                const resultsData = data.results[0];
                const rows = resultsData.rows; // queryResult
        
                let labels = [];
                let values = [];
                rows.forEach((row) => {
                    labels.push(row.dimensionValues[0].value);
                    values.push(row.metricValues[0].value);
                });
                setRegionViewsData({
                    ...regionViewsData,
                    labels,
                    values,
                });
            })
            .catch((error) => console.log("error after posting to getRegionPageViews", error));

        setIsLoading(false);
    }

    const analyticsModalFunction = async (projectId, linkId) => {
        onOpen();
        await retrieveDataFunction(projectId, linkId);
        setIsLoading(false);
    }

    return (
        <> 
            <Button variant="secondary" onClick={() => analyticsModalFunction(projectId, linkId)}><BsFillBarChartFill /></Button>

            <Modal size="xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader bg="blackAlpha.800">
                        <Heading size="sm" mb="2" color="white">{title}</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody bg="gray.50">
                        <Flex direction="column" style={{overflow: "scroll"}}>
                            {!isLoading && 
                            <Flex direction="column" p={5}>
                                <Flex direction="column" width="100%" my={5}>
                                    <Heading size="xs" mb="2">MetaSite Views & Clicks</Heading>
                                    <Flex direction="row">
                                        {(monadViewsData.values.length > 0) &&
                                        <MonadBarChart monadViewsData={monadViewsData} ctaClicksData={ctaClicksData} bannerViewsData={bannerViewsData} title={title} />
                                        }
                                        {(monadViewsData.values.length === 0) &&
                                            <h5 style={{fontFamily: "Produkt", backgroundColor: "#fffaf0", padding: "30px", border: "2px solid #000000"}}>Not enough data to render MetaSite Views & Clicks report</h5>
                                        }
                                    </Flex>
                                </Flex>

                                <Flex direction="column" my={5}>
                                    <Heading size="xs" mb="2">Views by Device & Region</Heading>
                                    <Flex direction="row">
                                        {(deviceViewsData.values.length > 0) &&
                                            <DeviceDoughnutViews reportData={deviceViewsData} />
                                        }
                                        {(deviceViewsData.values.length === 0) &&
                                            <h5 style={{fontFamily: "Produkt", textAlign: "center", backgroundColor: "#fffaf0", padding: "30px", border: "2px solid #000000", marginRight: "30px"}}>Not enough data to render Views by Device report</h5>
                                        }
                                        {(regionViewsData.values.length > 0) &&
                                            <RegionDoughnut reportData={regionViewsData} />
                                        }
                                        {(regionViewsData.values.length === 0) &&
                                            <h5 style={{fontFamily: "Produkt", textAlign: "center", backgroundColor: "#fffaf0", padding: "30px", border: "2px solid #000000"}}>Not enough data to render Views by Region report</h5>
                                        }
                                    </Flex>
                                </Flex>

                                <Flex direction="column" my={5}>
                                    <Heading size="xs" mb="2">Clicks by Device & Region</Heading>
                                    <Flex direction="row">
                                        {(ctaDeviceData.values.length > 0) &&
                                        <DeviceDoughnutCta reportData={ctaDeviceData} />
                                        }
                                        {(ctaDeviceData.values.length === 0) &&
                                        <h5 style={{fontFamily: "Produkt", textAlign: "center", backgroundColor: "#fffaf0", padding: "30px", border: "2px solid #000000", marginRight: "20px"}}>Not enough data to render CTA Clicks by Device report</h5>
                                        }
                                        {(ctaRegionData.values.length > 0) &&
                                        <RegionDoughnut reportData={ctaRegionData} />
                                        }
                                        {(ctaRegionData.values.length === 0) &&
                                        <h5 style={{fontFamily: "Produkt", textAlign: "center", backgroundColor: "#fffaf0", padding: "30px", border: "2px solid #000000"}}>Not enough data to render CTA Clicks by Region report</h5>
                                        }
                                    </Flex>
                                </Flex>

                            </Flex>
                            }

                            {!!isLoading &&
                            <Flex direction="column">
                                <Image
                                    src={discoloading}
                                    fallbackSrc={ldplogofooter}
                                    alt="Loading Spinner"
                                    mr={3}
                                />
                                {(monadViewsData.values.length > 0) &&
                                    <Heading size="xs" mb="2" fontFamily="Produkt">MetaSite Views & Clicks report loaded...</Heading>
                                }
                                {(deviceViewsData.values.length > 0) &&
                                    <Heading size="xs" mb="2" fontFamily="Produkt">Views by Device report loaded...</Heading>
                                }
                                {(regionViewsData.values.length > 0) &&
                                    <Heading size="xs" mb="2" fontFamily="Produkt">Views by Region report loaded...</Heading>
                                }
                                {(ctaDeviceData.values.length > 0) &&
                                    <Heading size="xs" mb="2" fontFamily="Produkt">Clicks by Device report loaded...</Heading>
                                }
                                {(ctaRegionData.values.length > 0) &&
                                    <Heading size="xs" mb="2" fontFamily="Produkt">Clicks by Region report loaded...</Heading>
                                }
                            </Flex>
                            }
                        </Flex>
                    </ModalBody>

                    <ModalFooter bg="gray.50">
                        <Button variant="outline" ml={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

GlobalLinksAnalytics.propTypes = {
    linkId: PropTypes.string, 
    projectId: PropTypes.string, 
    title: PropTypes.string,
}

export default GlobalLinksAnalytics;
