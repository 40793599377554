import { 
    Wrap,
    Tag,
    Icon,
    Avatar,
    Button, 
    Divider,
    Stack, 
    Flex, 
    Badge,
    Heading, 
    Text,
    Link,
    Container,
    HStack,
    SimpleGrid,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    VStack,
    useDisclosure,
    useBreakpointValue,
    useColorModeValue,
    useMediaQuery
} from "@chakra-ui/react"
import { getDatabase, ref, get, update, child } from "firebase/database";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { GoCalendar, GoMail } from "react-icons/go";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { Link as RRLink } from "react-router-dom";
import CreditsToToken from "./CreditsToToken";
import EthLonger from "./EthLonger.js";
import AccountStripe from "./Stripe/AccountStripe.js";
import TokensToCredits from "./TokensToCredits";
// import ldplogofooter from "../../assets/ldp-logo-footer.png";
import { AuthContext } from "../../context/AuthContext";

function Account() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [metamaskId, setMetamaskId] = useState(false);
    const [currentForm, setCurrentForm] = useState(false);
    const [metasiteTokens, setMetasiteTokens] = useState(false);
    const [conversionTokens, setConversionTokens] = useState(false);
    const [userCredits, setUserCredits] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [stripeInvoices, setStripeInvoices] = useState(false);
    const [stripeInvoicesKeys, setStripeInvoicesKeys] = useState(false);
    const [isLargerThan550] = useMediaQuery("(min-width: 550px)")
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { register, setValue, setError, clearErrors, reset, handleSubmit, control } = useForm();

    useEffect(() => {
        async function getMetamaskId() {
            const mId = await get(child(dbRef, `users/${currentUser.uid}/metamaskId`)).catch((error) => console.log(error));
            if (mId) {
                return mId.val();
            }
        }

        async function getTokens(uid, type) {
            const tokenResult = await get(child(dbRef, `users/${uid}/tokens/${type}`)).catch((error) => console.log(error));
            return tokenResult.val();
        }

        async function getCredits(uid) {
            const creditsResult = await get(child(dbRef, `users/${uid}/credits`)).catch((error) => console.log(error));
            return creditsResult.val();
        }

        async function getInvoices() {
            const invoices = await get(child(dbRef, `stripe_customers/${currentUser.uid}/invoices`)).catch((error) => console.log(error));
            return invoices.val();
        }

        getMetamaskId().then((mIdVal) => {
            if (mIdVal) {
                setMetamaskId(mIdVal);
            }
            else {
                setMetamaskId("none");
            }
        })
        getTokens(currentUser.uid, "metasite").then((currentMetasiteTokens) => {
            return setMetasiteTokens(currentMetasiteTokens);
        });
        getTokens(currentUser.uid, "conversion").then((currentConversionTokens) => {
            return setConversionTokens(currentConversionTokens);
        });
        getCredits(currentUser.uid).then((currentUserCredits) => {
            return setUserCredits(currentUserCredits);
        });
        getInvoices().then((allInvoices) => {
            setStripeInvoices(allInvoices);
            if (allInvoices) {
                setStripeInvoicesKeys(Object.keys(allInvoices));
            }
        })

    }, [])

    const onFormSubmit = async (data) => {
        let tokenCost = 250;
        let tokenName = "conversion"
        let tokenCount = conversionTokens;
        if (currentForm === "creditToMetasite" || currentForm === "metasiteToCredit") {
            tokenCost = 500;
            tokenName = "metasite"
            tokenCount = metasiteTokens;
        }

        if (currentForm === "metasiteToCredit" || currentForm === "conversionToCredit") {
            const tokensToRefund = data[currentForm];
            const tokensToRefundNumber = parseInt(tokensToRefund);
            const newCreditsTotal = userCredits + (tokensToRefund * tokenCost);
            const newTokensTotal = tokenCount - tokensToRefundNumber;

            const updates = {};
            updates["/users/" + currentUser.uid + "/credits/"] = newCreditsTotal;
            updates["/users/" + currentUser.uid + "/tokens/" + tokenName] = newTokensTotal;
            await update(ref(database), updates);
        }

        if (currentForm === "creditToMetasite" || currentForm === "creditToConversion") {
            const tokensToBuy = data[currentForm];
            const tokensToBuyNumber = parseInt(tokensToBuy);
            const newCreditsTotal = userCredits - (tokensToBuyNumber * tokenCost);
            const newTokensTotal = tokenCount + tokensToBuyNumber;
       
            const updates = {};
            updates["/users/" + currentUser.uid + "/credits/"] = newCreditsTotal;
            updates["/users/" + currentUser.uid + "/tokens/" + tokenName] = newTokensTotal;
            await update(ref(database), updates);
        }

        setSubmitted(!submitted);
        setCurrentForm(false);
        onClose();
    }

    const currentFormFunction = async (formTitle) => {
        setCurrentForm(formTitle);
        onOpen();
    }

    return (
        <Container py="8">
            <Container py="8">
                <VStack
                    spacing={25}
                >

                    <Box as="section" 
                        bg="white"
                        mx="auto"
                        rounded={{
                            sm: "lg",
                        }}
                        shadow={{
                            md: "base",
                        }}
                        width="100%"
                    >
                        <SimpleGrid
                            width="100%"
                            minChildWidth={["100%", "100%", "540px"]}
                            rowGap="8"
                        >
                            <Box
                                bg="white"
                                mx="auto"
                                p={{
                                    base: "6",
                                    md: "8",
                                }}>
                                <Stack
                                    direction={{
                                        base: "column",
                                        md: "row",
                                    }}
                                    spacing={{
                                        base: "4",
                                        md: "10",
                                    }}
                                >
                                    <Avatar
                                        name={currentUser.displayName}
                                        src={currentUser.photoURL}
                                        size="2xl"
                                    />
                                    <Box width="full">
                                        <Flex justifyContent="space-between" alignItems="center">
                                            <Heading size="md" fontWeight="extrabold" letterSpacing="tight" marginEnd="6">
                                                {currentUser.displayName}
                                            </Heading>
                                        </Flex>
                                        {currentUser.role === "guest" && 
                                            <Badge variant="subtle" colorScheme="orange">
                                                Guest
                                            </Badge>
                                        }
                                        {currentUser.role === "user" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                User
                                            </Badge>
                                        }
                                        {currentUser.role === "creator" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                Creator
                                            </Badge>
                                        }
                                        {currentUser.role === "agency" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                Agency
                                            </Badge>
                                        }
                                        {currentUser.role === "institution" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                Institution
                                            </Badge>
                                        }
                                        {currentUser.role === "superadmin" && 
                                            <Badge variant="subtle" colorScheme="red">
                                                Superadmin
                                            </Badge>
                                        }
                                        <Stack spacing="1" mt="2">
                                            <HStack fontSize="md">
                                                <Icon as={GoMail} color="gray.500" />
                                                <Text>{currentUser.email}</Text>
                                            </HStack>
                                            <HStack fontSize="md">
                                                <Icon as={GoCalendar} color="gray.500" />
                                                <Text>{currentUser.metadata.creationTime}</Text>
                                            </HStack>
                                        </Stack>

                                        <Text fontWeight="semibold" mt="8" mb="2">
                                            Credits & Tokens
                                        </Text>
                                        <Wrap shouldWrapChildren>
                                            <Tag>{userCredits.toLocaleString("en-US")} credits</Tag>
                                            <Tag>{metasiteTokens} MetaSite tokens</Tag>
                                            <Tag>{conversionTokens} conversion tokens</Tag>
                                        </Wrap>
                                    </Box>
                                </Stack>
                            </Box>

                            
                            {isLargerThan550 &&
                            <Box
                                bg="white"
                                mx="auto"
                                p={{
                                    base: "6",
                                    md: "8",
                                }}
                                width="100%">
                                <Stack
                                    direction={{
                                        base: "row",
                                        md: "row",
                                    }}
                                    spacing={{
                                        base: "4",
                                        md: "10",
                                    }}
                                    width="100%"
                                    justify="center"
                                >
                                    <Stack direction='row' height="100%" p={4}>
                                        <Divider height="200px !important" color="black" orientation='vertical' mr={10} />
                                        <EthLonger metamaskId={metamaskId} />
                                    </Stack>
                                </Stack>
                            </Box>
                            }

                        </SimpleGrid>
                    </Box>

                    <Box
                        bg="bg-accent"
                        color="on-accent"
                        borderRadius="2xl"
                        px={{
                            base: "6",
                            md: "12",
                            lg: "16",
                        }}
                        py={{
                            base: "4",
                            md: "6",
                            lg: "10",
                        }}
                        width="100%"
                    >
                        <SimpleGrid
                            columns={{
                                base: 1,
                                md: 3,
                            }}
                            rowGap="8"
                        >
                            <Stack spacing="3" textAlign="center">
                                <Heading
                                    size="md"
                                >
                                    {userCredits.toLocaleString("en-US")}
                                </Heading>
                                <Text fontSize="lg" fontWeight="medium" color="blue.50">
                                    Credits
                                </Text>
                                <Flex width="100%" justify="center">
                                    <Divider color="gray.50" width="30px" />
                                </Flex>
                                <Flex justify="center">
                                    <Menu>
                                        {({ isOpen }) => (
                                            <>
                                                <MenuButton size="sm" mt={2} isActive={isOpen} as={Button} variant="outline" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                                    {isOpen ? "Close" : "Convert Credits"}
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem color="blackAlpha.800" disabled={currentUser.credits < 500} onClick={() => currentFormFunction("creditToMetasite")}>Credit to MetaSite</MenuItem>
                                                    <MenuItem color="blackAlpha.800" disabled={currentUser.credits < 250} onClick={() => currentFormFunction("creditToConversion")}> Credit to Conversion</MenuItem>
                                                </MenuList>
                                            </>
                                        )}
                                    </Menu>
                                </Flex>
                            </Stack>

                            <Stack spacing="3" textAlign="center">
                                <Heading
                                    size="md"
                                >
                                    {metasiteTokens}
                                </Heading>
                                <Text fontSize="lg" fontWeight="medium" color="blue.50">
                                    Metasite Tokens
                                </Text>
                                <Flex width="100%" justify="center">
                                    <Divider color="gray.50" width="30px" />
                                </Flex>
                                <Flex justify="center">
                                    <Menu>
                                        {({ isOpen }) => (
                                            <>
                                                <MenuButton size="sm" mt={2} isActive={isOpen} as={Button} variant="outline" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                                    {isOpen ? "Close" : "Convert Tokens"}
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem color="blackAlpha.800" disabled={currentUser.tokens.metasite > 0} onClick={() => currentFormFunction("metasiteToCredit")}> MetaSite to Credit</MenuItem>
                                                </MenuList>
                                            </>
                                        )}
                                    </Menu>
                                </Flex>
                            </Stack>

                            <Stack spacing="3" textAlign="center">
                                <Heading
                                    size="md"
                                >
                                    {conversionTokens}
                                </Heading>
                                <Text fontSize="lg" fontWeight="medium" color="blue.50">
                                    Conversion Tokens
                                </Text>
                                <Flex width="100%" justify="center">
                                    <Divider color="gray.50" width="30px" />
                                </Flex>
                                <Flex justify="center">
                                    <Menu>
                                        {({ isOpen }) => (
                                            <>
                                                <MenuButton size="sm" mt={2} isActive={isOpen} as={Button} variant="outline" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                                    {isOpen ? "Close" : "Convert Tokens"}
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem color="blackAlpha.800" disabled={currentUser.tokens.conversion > 0} onClick={() => currentFormFunction("conversionToCredit")}> Conversion to Credit</MenuItem>
                                                </MenuList>
                                            </>
                                        )}
                                    </Menu>
                                </Flex>
                            </Stack>
                        </SimpleGrid>
                    </Box>

                    <Box
                        bg="bg-surface"
                        boxShadow={useColorModeValue("sm", "sm-dark")}
                        borderRadius="xl"
                        px={{
                            base: "6",
                            lg: "16",
                        }}
                        py={{
                            base: "10",
                            lg: "16",
                        }}
                    >
                        <Stack
                            spacing="8"
                            direction={{
                                base: "column",
                                lg: "row",
                            }}
                            justify="space-between"
                        >
                            <Stack spacing="4" maxW="2xl">
                                <Heading size="sm">Need More Credits?</Heading>
                                <Text
                                    color="muted"
                                    fontSize={useBreakpointValue({
                                        base: "lg",
                                        lg: "xl",
                                    })}
                                >
                                    Head on over to our Pricing page to look over your options and decide on the best solution for your needs.
                                </Text>
                            </Stack>
                            <Stack
                                spacing="3"
                                direction={{
                                    base: "column",
                                    sm: "row",
                                }}
                                justify={{
                                    base: "start",
                                }}
                            >
                                <Button variant="secondary" size="lg">
                                    Contact Us
                                </Button>
                                <Link as={RRLink} to="/pricing"><Button variant="primary" size="lg">
                                    Go to Pricing Page
                                </Button></Link>
                            </Stack>
                        </Stack>
                    </Box>

                    {!!stripeInvoices &&
                        <AccountStripe stripeInvoices={stripeInvoices} stripeInvoicesKeys={stripeInvoicesKeys} />
                    }


                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} reset={reset} control={control}>
                                <form onSubmit={handleSubmit(onFormSubmit)}> 
                                    <ModalHeader bg="blackAlpha.800">
                                        {currentForm === "metasiteToCredit" &&
                                        <Heading size="sm" mb="2" color="white">
                                                MetaSite Tokens to Credits 
                                        </Heading>
                                        }
                                        {currentForm === "conversionToCredit" &&
                                        <Heading size="sm" mb="2" color="white">
                                                Conversion Tokens to Credits 
                                        </Heading>
                                        }
                                        {currentForm === "creditToMetasite" &&
                                        <Heading size="sm" mb="2" color="white">
                                                Credits to MetaSite Tokens
                                        </Heading>
                                        }
                                        {currentForm === "creditToConversion" &&
                                        <Heading size="sm" mb="2" color="white">
                                                Credits to Conversion Tokens
                                        </Heading>
                                        }
                                    </ModalHeader>
                                    <ModalCloseButton color="white" />
                                    <ModalBody bg="gray.50">
                                        {(currentForm === "metasiteToCredit") &&
                                        <TokensToCredits tokenType="metasite" currentCredits={userCredits} currentTokens={metasiteTokens} fieldName={currentForm} />
                                        }

                                        {(currentForm === "conversionToCredit") &&
                                        <TokensToCredits tokenType="conversion" currentCredits={userCredits} currentTokens={conversionTokens} fieldName={currentForm} />
                                        }

                                        {(currentForm === "creditToMetasite") &&
                                        <CreditsToToken tokenType="metasite" currentCredits={userCredits} currentTokens={metasiteTokens} fieldName={currentForm} />
                                        }

                                        {(currentForm === "creditToConversion") &&
                                        <CreditsToToken tokenType="conversion" currentCredits={userCredits} currentTokens={conversionTokens} fieldName={currentForm} />
                                        }
                                    </ModalBody>

                                    <ModalFooter bg="gray.50">
                                        <Button variant="primary" type="submit">Submit</Button>
                                        <Button variant="outline" ml={3} onClick={onClose}>
                                        Close
                                        </Button>
                                    </ModalFooter>
                                </form>
                            </FormProvider>
                        </ModalContent>
                    </Modal>

                </VStack>
            </Container>
        </Container>
    );
}

export default Account;
