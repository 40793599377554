import { ErrorMessage } from "@hookform/error-message";
import FileSaver from "file-saver";
import { getDatabase, ref, update } from "firebase/database";
import { getStorage, ref as cloudStorageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone"
import { useForm, FormProvider, useFormState } from "react-hook-form";
import { BiErrorCircle } from "react-icons/bi";
import { BsPlusCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "reactstrap";
import VCardsJS from "vcards-js";
import { AuthContext } from "../../context/AuthContext";
import { currentConfig } from "../App/App.js";
import Loading from "../Loading/Loading.js";
import "./VCardForm.css";

function VCardForm({ vCardData }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const storage = getStorage();
    const [qrUrl, setQrUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(false);
    const [bigError, setBigError] = useState(false);
    const [hasVCardData, setHasVCardData] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [saveTooltipOpen, setSaveTooltipOpen] = useState(false);
    const vCard = VCardsJS();

    const { register, setValue, setError, clearErrors, handleSubmit, reset, control } = useForm({
        defaultValues: {
            firstName: vCardData.firstName,
            lastName: vCardData.lastName,
            title: vCardData.title,
            organization: vCardData.organization,
            email: vCardData.email,
            workEmail: vCardData.workEmail,
            cellPhone: vCardData.cellPhone,
            workPhone: vCardData.workPhone,
            city: vCardData.city,
            state: vCardData.state,
            website: vCardData.website,
            workWebsite: vCardData.workWebsite,
            linkedIn: vCardData.linkedIn,
            twitter: vCardData.twitter,
            qr: vCardData.qr,
        }
    });
    const { errors } = useFormState({
        control
    });

    useEffect(() => {
        if (vCardData.lastName !== "") {
            setHasVCardData(true);
        }
        let size = 400;
        let bgColor = "FFFFFF";
        let urlName = `${currentConfig.hostedUrl}/vcard/${currentUser.uid}`
        setQrUrl(`https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`);
        setValue("qr", `https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`);
        reset(vCardData);
    }, []);

    const saveVCardFunction = (e) => {
        e.preventDefault();
        const blob = new Blob([ vCard.getFormattedString() ], {type: "text/vcard;charset=utf-8"});
        FileSaver.saveAs(blob, `${currentUser.displayName}.vcf`);
    }

    const onFormSubmit = async(data) => {
        const { cellPhone, city, email, firstName, lastName, linkedIn, organization, qr, stateProvince, title, twitter, url, workEmail, workPhone, workUrl, customPhoto } = data;
        const { uid } = currentUser;
        // const timestamp = moment().valueOf();
        // const formattedDate = moment().format("MMMM Do YYYY, h:mm:ss a");
        vCard.cellPhone = cellPhone;
        vCard.workAddress.city = city;
        vCard.email = email;
        vCard.firstName = firstName;
        vCard.lastName = lastName;
        // vCard.socialUrls['linkedIn'] = linkedIn;
        vCard.organization = organization;
        vCard.title = title;
        vCard.workAddress.stateProvince = stateProvince;
        // vCard.socialUrls['twitter'] = twitter;
        vCard.url = url;
        vCard.workEmail = workEmail;
        vCard.workPhone = workPhone;
        vCard.workUrl = workUrl;
        vCard.source = `${currentConfig.hostedUrl}/vcard/${currentUser.uid}`;
        vCard.uid = currentUser.uid;
        // vCard.photo.attachFromUrl(`${profilePhoto}`, 'JPEG');

        const vCardData = {
            cellPhone,
            city,
            email,
            firstName,
            lastName,
            linkedIn,
            organization,
            title,
            twitter,
            url,
            workEmail,
            workPhone,
            workUrl,
            stateProvince,
            source: `${currentConfig.hostedUrl}/vcard/${currentUser.uid}`,
            uid,
            qr,
            photo: currentUser.photoURL,
            customPhoto: customPhoto,
        }

        const updates = {};
        updates["/vcards/" + uid] = vCardData;  
        updates["/users/" + uid + "/vCard/"] = true;
        await update(ref(database), updates);
        setHasVCardData(true);
    }

    function tooltipToggle() {
        setTooltipOpen(!tooltipOpen);
    }

    function saveTooltipToggle() {
        setSaveTooltipOpen(!saveTooltipOpen);
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles?.[0];
        const storageRef = cloudStorageRef(storage, `vCardAssets/${currentUser.uid}/${file.name}`);
        const fileTypeArr = file.name.split(".");
        const fileType = fileTypeArr[1];

        if (!file) {
            return
        }
  
        try {
            if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
                setBigError(false);
                setIsLoading(true);
                await uploadBytes(storageRef, file).then(async(snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        setValue("customPhoto", url);
                    });
                    setIsLoading(false);
                    setFileName(file.name);
                });
            }
            else {
                setIsLoading(false);
                setBigError("Please upload an image file");
            }
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setBigError("Unable to upload file. Please try again.");
        }
    }, [])


    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    if (!vCardData) {
        return <Loading size="medium" />
    }

    if (vCardData) {
        return (
            <div>
                <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} control={control}>
                    <form onSubmit={handleSubmit(onFormSubmit)} className="create-vcard-form">
                        <h1 className="create-vcard-title">Create vCard</h1>

                        <label htmlFor="firstName" className="link-card-label">First Name:</label>
                        <input name="firstName" className="input-styling" {...register("firstName", { required: "This is required."})}/>
                        <ErrorMessage errors={errors} name="firstName" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>}/>
      
                        <label htmlFor="lastName" className="link-card-label">Last Name:</label>
                        <input name="lastName" className="input-styling" {...register("lastName", { required: "This is required."})} />
                        <ErrorMessage errors={errors} name="lastName" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="title" className="link-card-label">Title:</label>
                        <input name="title" className="input-styling" {...register("title", { required: "This is required."})} />
                        <ErrorMessage errors={errors} name="title" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="organization" className="link-card-label">Organization:</label>
                        <input name="organization" className="input-styling" {...register("organization", { required: "This is required."})} />
                        <ErrorMessage errors={errors} name="organization" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="email" className="link-card-label">Email:</label>
                        <input name="email" className="input-styling" {...register("email", { required: "This is required."})} />
                        <ErrorMessage errors={errors} name="email" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="workEmail" className="link-card-label">Work Email:</label>
                        <input name="workEmail" className="input-styling" {...register("workEmail")} />
                        <ErrorMessage errors={errors} name="workEmail" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="cellPhone" className="link-card-label">Cell Phone:</label>
                        <input name="cellPhone" className="input-styling" {...register("cellPhone", { required: "This is required."})} />
                        <ErrorMessage errors={errors} name="cellPhone" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="workPhone" className="link-card-label">Work Phone:</label>
                        <input name="workPhone" className="input-styling" {...register("workPhone")} />
                        <ErrorMessage errors={errors} name="workPhone" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="city" className="link-card-label">City:</label>
                        <input name="city" className="input-styling" {...register("city", { required: "This is required."})} />
                        <ErrorMessage errors={errors} name="city" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="stateProvince" className="link-card-label">State:</label>
                        <input name="stateProvince" className="input-styling" {...register("stateProvince", { required: "This is required."})} />
                        <ErrorMessage errors={errors} name="city" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="url" className="link-card-label">Website:</label>
                        <input name="url" className="input-styling" {...register("url")} />
                        <ErrorMessage errors={errors} name="url" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="workUrl" className="link-card-label">Work Website:</label>
                        <input name="workUrl" className="input-styling" {...register("workUrl")} />
                        <ErrorMessage errors={errors} name="workUrl" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="linkedIn" className="link-card-label">LinkedIn:</label>
                        <input name="linkedIn" className="input-styling" {...register("linkedIn")} />
                        <ErrorMessage errors={errors} name="linkedIn" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />

                        <label htmlFor="twitter" className="link-card-label">Twitter:</label>
                        <input name="twitter" className="input-styling" {...register("twitter")} />
                        <ErrorMessage errors={errors} name="twitter" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />
            
                        <div className="upload-section">
                            <label htmlFor="customPhoto" className="link-card-label">Profile Photo:</label>
                            <div {...getRootProps()}>
                                {bigError && !fileName &&
                    <div className="upload-container">
                        <h1>{bigError}</h1>
                    </div>
                                }
                                {fileName && 
                    <div className="upload-container">
                        <h1>{fileName}</h1>
                    </div>
                                }
                                {!isLoading && !fileName && !bigError &&
                    <div className="upload-container">
                        <h1 className="drag-left">Drag </h1><BsPlusCircleFill className="plus-sign" /><h1 className="drag-right"> Drop</h1>
                    </div>
                                }
                                {isLoading && !fileName &&
                    <div className="upload-container">
                        <h1>Uploading...</h1>
                    </div>
                                }
                                <input {...register("customPhoto")} {...getInputProps()} />
                            </div>
                            <ErrorMessage errors={errors} name="customPhoto" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />
                        </div>

                        <label htmlFor="qr" className="link-card-label">QR File:</label>
                        <img src={qrUrl} alt="" height="200px" width="200px" />
                        <input type="hidden" {...register("qr")} />

                        <div className="submit-button">
                            <Button type="submit">Submit</Button>
                        </div>
                        {!!hasVCardData &&
            <div className="submit-button">
                <Button onClick={(e) => saveVCardFunction(e)}>Save vCard to File</Button>
            </div>
                        }
                        {!hasVCardData &&
            <div className="submit-button">
                <span href="#" id="save-vcard-tooltip-trigger"><Button disabled>Save vCard to File</Button></span>
                <Tooltip isOpen={saveTooltipOpen} target="save-vcard-tooltip-trigger" toggle={() => saveTooltipToggle()}>
              Fill out the form in order to save your vCard.
                </Tooltip>
            </div>
                        }
                        {!!hasVCardData &&
            <div className="submit-button">
                <Link to={`/vcard/${currentUser.uid}`}><Button>View Digital Business Card</Button></Link>
            </div>
                        }
                        {!hasVCardData &&
            <div className="submit-button">
                <span href="#" id="vcard-tooltip-trigger"><Button disabled>View Digital Business Card</Button></span>
                <Tooltip isOpen={tooltipOpen} target="vcard-tooltip-trigger" toggle={() => tooltipToggle()}>
              Fill out the form in order to view your vCard.
                </Tooltip>
            </div>
                        }
                    </form>
                </FormProvider>
            </div>
        );
    }
    return null;
}

VCardForm.propTypes = {
    vCardData: PropTypes.object
}

export default VCardForm;