import { getDatabase, ref, get, child } from "firebase/database";
import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import "./ModelRender.css";


function ModelRender() {
    const { projectId, linkId } = useParams();
    const database = getDatabase();
    const [monadData, setMonadData] = useState(false);
    const [assetsData, setAssetsData] = useState(false);

    useEffect(() => {
        const dbRef = ref(database);

        async function getLinkData() {
            const lData = get(child(dbRef, `links/${linkId}`)).catch((error) => console.error(error));
            return lData.val();
        }

        async function getLinkAssetsData() {
            const lAssetsData = get(child(dbRef, `assets_data/${projectId}/${linkId}`)).catch((error) => console.error(error));
            return lAssetsData.val();
        }

        getLinkData().then((linkData) => {
            setMonadData(linkData);
        })
        
        getLinkAssetsData().then((linkAssetsData) => {
            setAssetsData(linkAssetsData);
        })

    
    }, [database, linkId, projectId]);  

    if (!!monadData && !!assetsData) {
        return (
            <div id="desktop-container">
                <div id="desktop-wrapper">
                    <div id="desktop-content-container">
    
                        <div id="ar-container">
                            <model-viewer
                                id="model-viewer-desktop"
                                src={assetsData.glb.link}
                                alt="A 3D model of an astronaut"
                                ar
                                autoplay
                                camera-controls
                                shadow-intensity="1" 
                            >
                            </model-viewer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}
  

export default ModelRender;
