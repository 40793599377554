import { getDatabase, ref, child, remove, get } from "firebase/database";
import React, { useState, useEffect } from "react";
import { BsTrash } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Jumbotron } from "reactstrap";
import { AuthContext } from "../../../../../context/AuthContext";
import "./BadData.css";


function BadData() {
    const { projectId, linkId } = useParams();
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const navigate = useNavigate();
    const [renderDelete, setRenderDelete] = useState(false);

    useEffect(() => {
        async function getAssetsDataKeys() {
            const assetsData = get(child(dbRef, `assets_data/${projectId}/${linkId}`)).catch((error) => console.log(error));
            const assetsDataVal = assetsData.val();
            return Object.keys(assetsDataVal);
        }
        
        getAssetsDataKeys().then((assetsDataKeys) => {
            if (assetsDataKeys.length === 3) {
                return setRenderDelete(false);
            }
            if (assetsDataKeys.length !== 3) {
                return setRenderDelete(true);
            }
        })
    }, []);  

    const deleteBadData = async() => {
        await remove(ref(database, "links/" + linkId));
        await remove(ref(database, "projects/" + projectId + "/links/" + linkId));
        await remove(ref(database, "users/" + currentUser.uid + "/projects/" + projectId + "/links/" + linkId));
        await remove(ref(database, "assets_data/" + projectId + "/" + linkId));
        return navigate(-1);
    }
    if (!renderDelete) {
        return (
            <div className="bad-data-container">
                <div className="bad-data-content-container">
                    <h2>You do not have permission to modify this data.</h2>
                </div>
            </div>
        );
    }
    if (renderDelete) {
        return (
            <div className="bad-data-container">
                <div className="bad-data-content-container">
                    <Jumbotron>
                        <h1 className="display-3">Oh, <span role="img" aria-label="Poop">💩.</span> Something went wrong.</h1>
                        <p className="lead">An error on our side occurred while attempting to create your last MetaSite.</p>
                        <p>Click the delete button below to delete all data associated with the last attempt at creating a MetaSite. Upon clicking the button we&apos;ll also refund you the credits you used to create the broken MetaSite and you&apos;ll be redirected back to your list of MetaSites where you can start the process over again. It&apos;s quite rare that this occurs and you should be able to use the same input without issue this time. If you&apos;re still having problems, reach out to us for support.</p>
                        <p className="lead">
                            <Button onClick={() => deleteBadData()}>Delete Bad Data  <BsTrash /></Button>
                        </p>
                        <hr className="my-2" />
                    </Jumbotron>
                </div>
            </div>
        );
    }
}


export default BadData;