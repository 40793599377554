import { 
    Box, 
    Flex, 
    Heading, 
    Stack,
    SimpleGrid,
    useColorModeValue as mode 
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getDatabase, ref, get, child } from "firebase/database";
import capitalize from "lodash-es/capitalize";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import StripeCheckout from "./StripeCheckout.js";
import { AuthContext } from "../../context/AuthContext";
import { currentConfig } from "../App/App.js";
import Loading from "../Loading/Loading";
import "./Stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// const stripeKey = currentConfig.stripeKey;
// console.log("stripeKey", stripeKey);
// const stripePromise = loadStripe(stripeKey);
// const stripePromise = loadStripe("pk_test_51K23WoKLuXMdIPEzw2aWoeArceWWKQxwAorkPAi71omrvNY61WnPXYR3BFEOFVzSzpa0bPffgwjmXwKkjWX4mKNJ00VAmTnyli");

export default function Stripe() {
    const stripeKey = currentConfig.stripeKey;
    const stripePromise = loadStripe(stripeKey);
    const {currentUser} = React.useContext(AuthContext);
    const { purchaseType } = useParams();
    const database = getDatabase();
    const dbRef = ref(database);
    const location = useLocation();
    const paymentSchedule = location.state.paymentSchedule;
    const [clientSecret, setClientSecret] = useState("");
    const [data, setData] = useState(false);
    const [total, setTotal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [capitalizedPurchaseType, setCapitalizedPurchaseType] = useState(purchaseType);
    const [capitalizedProduct, setCapitalizedProduct] = useState(purchaseType);

    useEffect(() => {
        if (purchaseType === "user" || purchaseType === "institution") {
            setCapitalizedPurchaseType(capitalize(purchaseType));
        }
        if (purchaseType === "agency200" || purchaseType === "agency500") {
            setCapitalizedPurchaseType("Agency");
        }
        if (purchaseType === "creator5" || purchaseType === "creator10" || purchaseType === "creator20") {
            setCapitalizedPurchaseType("Creator");
        }

        setCapitalizedProduct(capitalize(purchaseType));

        async function getStripeId() {
            const stripeIdResponse = await get(child(dbRef, `users/${currentUser.uid}/stripeId`)).catch((error) => console.log(error));
            return stripeIdResponse.val();
        }

        async function createSubscription(stripeIdVal) {
            const subscription = await fetch("/api/createSubscription", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ purchaseType: purchaseType, uid: currentUser.uid, stripeId: stripeIdVal, userEmail: currentUser.email, paymentInterval: paymentSchedule }),
            })
            return subscription.json();
        }

        return getStripeId().then(async(stripeIdVal) => {
            await createSubscription(stripeIdVal).then(async(stripeData) => {
                setClientSecret(stripeData.clientSecret);
                setData(stripeData);
                setTotal(stripeData.totalPrice);
                setLoading(false);
            }).catch((error) => console.log("error creating subscription", error))
        });
    }, []);

    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret,
        appearance,
    };

    if (loading) {
        return (
            <div className="stripe-loading">
                <Loading size="medium" />
            </div>
        );
    }

    if (!loading) {
        return (
            <Flex py="8" ml={5} mr={5} direction="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <SimpleGrid columns={{base: 1, md: 2}} spacing={5} width="100%">
                    <Flex direction="column" margin="50px">
                        <Heading as="h1" size="lg" lineHeight="1" fontWeight="extrabold" letterSpacing="tight">
                            <Stack>
                                <Box>
                                    Upgrade your account to
                                    <Box as="mark" color={mode("blue.500", "blue.300")} bg="transparent">
                                        {` ${capitalizedPurchaseType}.`}
                                    </Box>
                                </Box> 
                            </Stack>
                        </Heading>
                        <Heading size="xs" mt={5}>
                            You will be charged {`$${total.toLocaleString("en-US")}`} today to upgrade to the {capitalizedProduct} tier.
                        </Heading>
                    </Flex>
                    <Flex direction="column" justifyContent="center" alignItems="center">
                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <StripeCheckout purchaseType={purchaseType} id={data.paymentIntentId} />
                            </Elements>
                        )}
                    </Flex>
                </SimpleGrid>
            </Flex>
        );
    }
    return null;
}
