import {
    Box,
    Button,
    createIcon,
    Flex,
    List,
    ListItem,
    Text,
    Link,
    HStack,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react"
import { Link as RRLink, useNavigate } from "react-router-dom";
import { CustomSelect} from "../../Global/CustomSelect/CustomSelect.js";
import { Option } from "../../Global/CustomSelect/Option.js";
const CheckIcon = createIcon({
    viewBox: "0 0 17 12",
    d: "M0 5.82857L1.64571 4.11429L5.48571 7.2L14.8114 0L16.4571 1.71429L5.48571 12L0 5.82857Z",
})
  
const PricingDetail = (props) => {
    const { children, ...rest } = props
    return (
        <ListItem display="flex" alignItems="flex-start" fontWeight="medium" maxW="260px" {...rest}>
            <CheckIcon mr="4" mt="1" color={mode("blue.500", "blue.300")} />
            <Text as="span" display="inline-block">
                {children}
            </Text>
        </ListItem>
    )
}

PricingDetail.propTypes = {
    children: PropTypes.string,
}
  
const PopularBadge = (props) => (
    <Box
        whiteSpace="nowrap"
        top="-4"
        left="50%"
        transform="translateX(-50%)"
        pos="absolute"
        rounded="md"
        fontWeight="bold"
        fontSize="sm"
        px="4"
        py="1"
        textTransform="uppercase"
        bg="blue.500"
        color="white"
        {...props}
    />
)
  
const PriceDisplay = (props) => {
    const { currency, price } = props
    return (
        <Flex w="100%" align="center" justify="center" my="5" fontWeight="extrabold">
            <Text fontSize="4xl" mr="2">
                {currency}
            </Text>
            <Text fontSize="72px" lineHeight="1" letterSpacing="tight">
                {price}
            </Text>
        </Flex>
    )
}

PriceDisplay.propTypes = {
    currency: PropTypes.string,
    price: PropTypes.number
}
  
const PricingWrapper = (props) => {
    const { highlight, ...rest } = props
    const popularStyles = {
        borderWidth: "2px",
        borderColor: "blue.500",
        zIndex: 1,
        px: "8",
        pt: "12",
        pb: "10",
        top: {
            lg: "-8",
        },
    }
    const styles = highlight ? popularStyles : null
    return (
        <Box
            w="full"
            maxW="md"
            mx="auto"
            bg={mode("white", "gray.700")}
            px="10"
            pt="8"
            pb="8"
            rounded="lg"
            shadow="base"
            position="relative"
            {...styles}
            {...rest}
        />
    )
}

PricingWrapper.propTypes = {
    highlight: PropTypes.bool,
}
  
export const PricingCard = (props) => {
    const { onClick, features, name, description, price, popular, link, form, ...rest } = props;
    const [numberOfCredits, setNumberOfCredits] = useState(0);
    const creditsAmounts = [250, 500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000, 50000, 100000];
    const navigate = useNavigate();

    return (
        <PricingWrapper highlight={popular} {...rest}>
            {popular && <PopularBadge>Most Popular</PopularBadge>}
  
            <Flex direction="column" justify="center">
                <Text align="center" fontSize="2xl" fontWeight="bold">
                    {name}
                </Text>
                <Text align="center" mt="2" color={mode("gray.600", "gray.400")} maxW="16rem" mx="auto">
                    {description}
                </Text>
                <PriceDisplay currency="$" price={price} />
                {/* <Box
                    as="button"
                    display="inline-flex"
                    mx="auto"
                    color={mode("blue.500", "blue.300")}
                    fontWeight="semibold"
                >
                    Switch to yearly billing
                </Box> */}
            </Flex>
  
            <List 
                stylePosition="outside" 
                // mt="8" 
                spacing={4}>
                {features.map((feature, idx) => (
                    <PricingDetail key={idx}>{feature}</PricingDetail>
                ))}
            </List>
            
            {!form &&
                <Link as={RRLink} to={link}>
                    <Button
                        minH="3.5rem"
                        rounded="lg"
                        fontWeight="bold"
                        colorScheme={popular ? "blue" : "gray"}
                        mt="8"
                        w="100%"
                        onClick={onClick}
                    >
                    Buy Token
                    </Button>
                </Link>
            }
            {!!form && 
            <Box mt={5}>
                <CustomSelect
                    // {...register("creditsAmount")}
                    name="creditsAmount"
                    colorScheme="blue"
                    value={numberOfCredits}
                    onChange={setNumberOfCredits}
                    placeholder="# of Credits"
                >
                    {creditsAmounts.map(o => (
                        <Option value={o} key={o}>
                            <HStack>
                                <Text>{o}</Text>
                            </HStack>
                        </Option>
                    ))}
                </CustomSelect>
                <Button
                    minH="3.5rem"
                    rounded="lg"
                    fontWeight="bold"
                    colorScheme={popular ? "blue" : "gray"}
                    mt="8"
                    w="100%"
                    onClick={() => navigate(`/stripe/${numberOfCredits}`)}
                >
                Buy Credits
                </Button>
            </Box>
            }
        </PricingWrapper>
    )
}

PricingCard.propTypes = {
    onClick: PropTypes.func, 
    features: PropTypes.array,
    name: PropTypes.string, 
    description: PropTypes.string, 
    price: PropTypes.number, 
    popular: PropTypes.bool,
    link: PropTypes.string,
    form: PropTypes.bool,
}