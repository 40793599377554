import { 
    getDatabase, 
    ref, 
    push,
    child,
    update
} from "firebase/database";
import moment from "moment/min/moment-with-locales";
import React, { useState, useEffect } from "react";
import DemoAuthenticated from "./DemoAuthenticated.js";
import { DemoContext } from "../../../context/DemoContext";

function DemoAuthenticatedWrapper() {
    const database = getDatabase();
    const [selectedModel, setSelectedModel] = useState(null);
    const [demoPath, setDemoPath] = useState("example");
    const [demoKey, setDemoKey] = useState(localStorage.getItem("localDemoKey"));
    const [currentStage, setCurrentStage] = useState("orientation");
    const [modelTabIndex, setModelTabIndex] = useState(0);
    const [formTabIndex, setFormTabIndex] = useState(0);
    const [demoSessionData, setDemoSessionData] = useState(false);
    const [qrUrl, setQrUrl] = useState("");
    const [customImageStatus, setCustomImageStatus] = useState(false);
    const [hdrStatus, setHdrStatus] = useState(false);
    const [purchaseConfirmed, setPurchaseConfirmed] = useState(false);
    const [confirmAttempted, setConfirmAttempted] = useState(false);
    const [purchaseId, setPurchaseId] = useState(false);
    const [freeGlb, setFreeGlb] = useState(false);
    const [selectedExampleName, setSelectedExampleName] = useState("mixer");

    useEffect(() => {
        const generatedDemoKey = push(ref(database, "demo_sessions")).key;
        handleSetDemoKey(generatedDemoKey);
        handleSetDemoPath("example");
        handleSetCurrentStage("orientation");
        handleSetModelTabIndex(0);
        handleSetFormTabIndex(0);
    }, [])

    const handleSetQrUrl = async (url) => {
        setQrUrl(url);
        await update(ref(database, "/demo_sessions/" + demoKey + "/link_data/assets"), { qr: url });
    }

    const handleSelectModel = async (modelData) => {
        setSelectedModel(modelData);
        await update(ref(database, "/demo_sessions/" + demoKey), {selectedModel: modelData});
    }

    const handleSetDemoPath = async (option) => {
        setDemoPath(option);
        await update(ref(database, "/demo_sessions/" + demoKey), {demoPath: option, demoKey: demoKey, currentStage: currentStage});
    }

    const handleSetDemoKey = async (key) => {
        setDemoKey(key);
        await update(ref(database, "/demo_sessions/" + demoKey), {demoKey: demoKey});
    }

    const handleSetCurrentStage = async (stage) => {
        setCurrentStage(stage);
        await update(ref(database, "/demo_sessions/" + demoKey), {currentStage: stage});
    }

    const handleSetModelTabIndex = async (idx) => {
        setModelTabIndex(idx);
        await update(ref(database, "/demo_sessions/" + demoKey), {modelTabIndex: idx});
    }

    const handleSetFormTabIndex = async (idx) => {
        setFormTabIndex(idx);
        await update(ref(database, "/demo_sessions/" + demoKey), {formTabIndex: idx});
    }

    const handleSetDemoSessionData = (data) => {
        setDemoSessionData(data);
    }

    const handleSetCustomImageStatus = (data) => {
        setCustomImageStatus(data);
    }

    const handleSetHdrStatus = (data) => {
        setHdrStatus(data);
    }

    const handleSetPurchaseConfirmed = (data) => {
        setPurchaseConfirmed(data);
    }

    const handleSetConfirmAttempted = (data) => {
        setConfirmAttempted(data);
    }

    const handleSetPurchaseId = (data) => {
        setPurchaseId(data);
    }
    
    const handleSetFreeGlb = (data) => {
        setFreeGlb(data);
    }

    const handleSetSelectedExampleName = (data) => {
        setSelectedExampleName(data);
    }

    const transferDemoAssets = async (demoData, user) => {
        const newTransferKey = push(child(ref(database), `demo_sessions/${demoKey}/transfers`)).key;

        let transferData = {
            fromUser: "demo",
            toUser: user.uid,
            createdAt: moment().valueOf(),
            projectId: user.uid,
            linkId: demoKey,
            glbName: demoData.assets.glb,
            screenshotName: "screenshot.png"
        };

        if (demoData.assets.img) {
            transferData.imgName = demoData.assets.img;
        }

        if (demoData.assets.usdz) {
            transferData.usdzName = demoData.assets.usdz;
        }

        await update(ref(database, "/demo_sessions/" + demoKey + "/transfers/" + newTransferKey), transferData);
    }

    const props = {
        selectedModel,
        demoPath,
        demoKey,
        currentStage, 
        modelTabIndex,
        formTabIndex,
        demoSessionData,
        qrUrl,
        customImageStatus, 
        hdrStatus, 
        purchaseConfirmed, 
        confirmAttempted, 
        purchaseId, 
        freeGlb, 
        selectedExampleName,
        handleSelectModel,
        handleSetDemoPath,
        handleSetDemoKey,
        handleSetCurrentStage,
        handleSetModelTabIndex,
        handleSetFormTabIndex,
        handleSetDemoSessionData,
        handleSetQrUrl,
        handleSetCustomImageStatus, 
        handleSetHdrStatus, 
        handleSetPurchaseConfirmed, 
        handleSetConfirmAttempted, 
        handleSetPurchaseId, 
        handleSetFreeGlb, 
        handleSetSelectedExampleName,
        transferDemoAssets
    }

    return (
        <DemoContext.Provider value={props}>
            {demoPath && demoKey && currentStage &&
                <DemoAuthenticated />
            }
        </DemoContext.Provider>
    );
}

export default DemoAuthenticatedWrapper;
