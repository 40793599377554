import { 
    Flex,
    Button,
    Text,
    Icon,
    Stack,
    Box,
    Link,
    SimpleGrid,
    Divider,
    Heading,
    HStack,
    useToast,
    useColorModeValue as mode
} from "@chakra-ui/react";
import { 
    getDatabase, 
    ref,
    update, 
    child, 
    get
} from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { 
    FaShareSquare, 
    FaRegNewspaper
} from "react-icons/fa";
import { Link as RRLink } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { DemoContext } from "../../../context/DemoContext";
import Loading from "../../Loading/Loading.js";
import PricingPromo from "../../Pricing/components/PricingPromo.js";
import "./DemoAuthenticated.css";

function PublishDemoAuthenticated({ projectId, linkKey, firstName }) {
    const {currentUser} = React.useContext(AuthContext);
    const {
        currentStage,
        formTabIndex
    } = React.useContext(DemoContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const toast = useToast();
    const [metasiteTokens, setMetasiteTokens] = useState(false);
    const [userCredits, setUserCredits] = useState(false)
    const [isConverting, setIsConverting] = useState(false);
    const [loading, setLoading] = useState(true);
    const { handleSubmit } = useForm();
    const viewMetasiteButton = useRef(null);

    useEffect(() => {
        if (currentStage === "form" && formTabIndex === 1) {
            const modelDom = document.getElementById("form_button");
            let firstChild = modelDom.firstChild;
            return firstChild.click();
        }
    }, [currentStage, formTabIndex])

    async function autoclickViewMetasite() {
        setTimeout(() => {
            viewMetasiteButton.current.click();
        }, 5000); //miliseconds
    }

    useEffect(() => {
        if (currentStage === "form" && formTabIndex === 1) {
            autoclickViewMetasite();
        }
    }, [formTabIndex])

    useEffect(() => {
        async function getTokens(uid, type) {
            const tokenResult = await get(child(dbRef, `users/${uid}/tokens/${type}`)).catch((error) => console.log(error));
            const tokenResultVal = tokenResult.val();
            return tokenResultVal;
        }

        async function getCredits(uid) {
            const creditsResult = await get(child(dbRef, `users/${uid}/credits`)).catch((error) => console.log(error));
            const creditsResultVal = creditsResult.val();
            return creditsResultVal;
        }

        getTokens(currentUser.uid, "metasite").then((currentMetasiteTokens) => {
            return setMetasiteTokens(currentMetasiteTokens);
        });

        getCredits(currentUser.uid).then((currentUserCredits) => {
            return setUserCredits(currentUserCredits);
        });

        setLoading(false);
    }, [])

    const onFormSubmit = async () => {
        setIsConverting(true);
        if (metasiteTokens >= 1) {
            const newMetasiteTokens = metasiteTokens - 1;
            await update(ref(database, "/users/" + currentUser.uid + "/tokens/"), {metasite: newMetasiteTokens});
        }

        if (metasiteTokens === 0) {
            const newCredits = userCredits - 500;
            await update(ref(database, "/users/" + currentUser.uid), {credits: newCredits});
        }

        await update(ref(database, "/projects/" + projectId + "/links/" + linkKey), {demo: false});
        await update(ref(database, "/links/" + linkKey), {demo: false});
        setIsConverting(false);

        toast({
            title: "MetaSite Upgraded",
            description: "Your MetaSite is now live!",
            // status: "error",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
    }

    if (!loading) {
        return (
            <Flex direction="column" width="100%" mb={10} mt={10}>
                <SimpleGrid columns={{base: 1, lg: 2}} spacing={10} mb={10} mt={10}>
                    <Box>
                        <form onSubmit={handleSubmit(onFormSubmit)}>
                            <Heading as="h1" size="lg" lineHeight="1" fontWeight="extrabold" letterSpacing="tight">
                                <Stack>
                                    <Box>
                                        Your MetaSite
                                    </Box> 
                                    {/* <Box>
                                        is Ready,
                                    </Box>  */}
                                    <HStack mb="6px">
                                        <Box>
                                            is Ready,
                                        </Box> 
                                        <Box as="mark" color={mode("blue.500", "blue.300")} bg="transparent">
                                            {firstName}
                                        </Box>
                                        <Box>
                                            !
                                        </Box>
                                    </HStack>
                                </Stack>
                            </Heading>
                            <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5" fontWeight="medium">
                                You can now view your MetaSite any time you log into the app with this account.
                            </Text>
                            {!isConverting && 
                                <>
                                    {(metasiteTokens === 0 || userCredits < 500) &&
                                        <>
                                            <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5" fontWeight="medium">
                                                You have {metasiteTokens} MetaSite Tokens & {userCredits} credits. You do not have enough credits to upgrade a MetaSite. MetaSites become live immediately upon payment.
                                            </Text>
                                            <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5">
                                                Once your MetaSite is live you will have access to all the app&apos;s features including total control over the MetaSite&apos;s content and analytics on the MetaSite&apos;s performance.
                                            </Text>
                                        </>
                                    }
                                    {(metasiteTokens >= 1 || userCredits >= 500) &&
                                        <>
                                            <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5" fontWeight="medium">
                                                You have {metasiteTokens} MetaSite Tokens & {userCredits} credits.
                                            </Text>
                                            <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5" fontWeight="medium">
                                                Click the button below to apply one of your MetaSite Tokens to this MetaSite. Immediately after upgrading you will be able to share the link with anyone.
                                            </Text>
                                            <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5">
                                                Once your MetaSite is live you will have access to all the app&apos;s features including total control over the MetaSite&apos;s content and analytics on the MetaSite&apos;s performance.
                                            </Text>
                                        </>
                                    }
                                </>
                            }
                    
                            <Stack
                                direction={{
                                    base: "column",
                                    sm: "row",
                                }}
                                spacing="4"
                                mt="8"
                            >
                                <Link as={RRLink} to={`/monad/${currentUser.uid}/${linkKey}`} target="_blank" textDecoration="none" _hover={{textDecoration: "none"}} ref={viewMetasiteButton}>
                                    <Button size="lg" colorScheme="blue" fontSize="md">
                                        View MetaSite
                                        <Icon as={FaRegNewspaper} ml={3} boxSize={4} />
                                    </Button>
                                </Link>

                                {(metasiteTokens >= 1 || userCredits >= 500) &&
                                    <Button               
                                        size="lg"
                                        bg="white"
                                        color="gray.800"
                                        _hover={{
                                            bg: "gray.50",
                                        }}
                                        shadow="base"
                                        fontSize="md"
                                        disabled={metasiteTokens === 0 || userCredits < 500}
                                        type="submit"
                                    > 
                                        Upgrade MetaSite
                                        <Icon as={FaShareSquare} ml={3} boxSize={4} />
                                    </Button>
                                }

                            </Stack>
                        </form>
                    </Box>

                    <Box borderRadius="xl" boxShadow="md">
                        <model-viewer
                            id="model-viewer-demo-promo"
                            src="https://www.googleapis.com/download/storage/v1/b/ldp-studio-int.appspot.com/o/monadAssets%2F-MwURkxNZicuXDeQM8_w%2F-MwUS0NH2Yv_G_keLdW9%2Fglb%2Fhorse-god.glb?generation=1645500369547784&alt=media"
                            alt="A 3D model of an astronaut"
                            skybox-image="https://modelviewer.dev/shared-assets/environments/spruit_sunrise_1k_HDR.hdr"
                            ar
                            autoplay
                            camera-controls>
                        </model-viewer>
                    </Box>
                </SimpleGrid>
             

                {(metasiteTokens === 0 || userCredits < 500) &&
                    <>
                        <SimpleGrid 
                            columns={3}
                            spacing={10} 
                            mb={10}
                            mt={5}
                            pb={5}
                            pt={5}
                        >
                            <Box></Box>
                            <Box>
                                <Divider style={{borderBottomWidth: "6px", borderColor: "#d4af37"}} />
                            </Box>
                            <Box></Box>
                        </SimpleGrid>
                        <PricingPromo />
                    </>
                }

            </Flex>
        )
    }

    if (loading) {
        return <Loading size="medium" />
    }
}

PublishDemoAuthenticated.propTypes = {
    projectId: PropTypes.string, 
    linkKey: PropTypes.string,
    firstName: PropTypes.string
}

export default PublishDemoAuthenticated;
