import "@google/model-viewer";
import { 
    Text, 
    Container, 
    Heading,
    Box,
    Button,
    FormLabel,
    Flex,
    Tabs,
    Tab,
    TabPanel,
    TabPanels,
    TabList,
    SimpleGrid,
    Icon,
    Stack,
    FormControl,
    Input,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    AccordionIcon,
    useColorModeValue as mode
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { 
    useFormState,
    useFormContext
} from "react-hook-form";
import { BiErrorCircle } from "react-icons/bi";
import { 
    FaArrowCircleRight
} from "react-icons/fa";
import { AuthContext } from "../../../../context/AuthContext";
import { DemoContext } from "../../../../context/DemoContext";
import SignInForm from "../../../UnauthenticatedApp/SignInForm.js";
import PublishDemoAuthenticated from "../../DemoAuthenticated/PublishDemoAuthenticated.js";
import PublishDemoUnauthenticated from "../../DemoUnauthenticated/PublishDemoUnauthenticated.js";
import "../../DemoUnauthenticated/DemoUnauthenticated.css";

function Form({ authenticated }) {
    const { currentUser } = React.useContext(AuthContext);
    const {
        demoKey, 
        currentStage,
        formTabIndex,
        handleSetFormTabIndex
    } = React.useContext(DemoContext);
    const [firstName, setFirstName] = useState("");
    const { register, control } = useFormContext();
    const { errors } = useFormState({
        control
    });

    useEffect(() => {
        if (currentStage === "form") {
            const modelDom = document.getElementById("form_button");
            let firstChild = modelDom.firstChild;
            return firstChild.click();
        }
    }, [currentStage])

    useEffect(() => {
        if (currentUser) {
            const nameArr = currentUser.displayName.split(" ");
            const name = nameArr[0];
            setFirstName(name);
        }
    }, [currentUser])

    return (
        <AccordionItem>
            <h2 id="form_button">
                <AccordionButton id="form_button" _expanded={{ bg: "brand.100", color: "whiteAlpha.900", fontSize: "20px" }} style={{ fontSize: "20px" }}>
                    <Box flex='1' textAlign='left'>
                        MetaSite Content
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel minHeight="100vh" bg="gray.50">
                <Container py="8" flex="1">
                    <Tabs index={formTabIndex} isFitted>

                        <TabList mb='1em'>
                            <Tab>
                                <Heading size="xs">Details</Heading>
                            </Tab>
                            <Tab>
                                <Heading size="xs">View MetaSite</Heading>
                            </Tab>
                        </TabList>
                        <TabPanels>

                            {/* First Tab Form */}
                            <TabPanel>
                                <Box minHeight='500px'>
                                    <Flex direction="column" height="100%" justifyContent="space-between">
                                        <Box>
                                            <Stack
                                                direction={{
                                                    base: "column",
                                                    lg: "row",
                                                }}
                                                spacing={{
                                                    base: "5",
                                                    lg: "8",
                                                }}
                                                justify="space-between">
                                                <Box flexShrink={0} width={{
                                                    base: "100%",
                                                    lg: "25%",
                                                }}>
                                                    <Text fontSize="lg" fontWeight="medium" mb={2}>
                                                        The Basics
                                                    </Text>
                                                    <Text fontSize="sm">
                                                        Give your project a name and description 
                                                    </Text>
                                                </Box>
                                                <Box
                                                    bg="bg-surface"
                                                    boxShadow="sm"
                                                    borderRadius="lg"
                                                    flex="1"
                                                    maxWidth="3xl">
                                                    <Stack
                                                        spacing="5"
                                                        px={{
                                                            base: "4",
                                                            md: "6",
                                                        }}
                                                        py={{
                                                            base: "5",
                                                            md: "6",
                                                        }}>
                    
                                                        <FormControl isRequired>
                                                            <FormLabel htmlFor="title">MetaSite Title (internal use only):</FormLabel>
                                                            <Input name="title" {...register("title", { required: "This is required."})} />
                                                            <ErrorMessage errors={errors} name="title" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                        </FormControl>

                                                        <FormControl isRequired>
                                                            <FormLabel htmlFor="ctaLinkPath">Call to Action Link:</FormLabel>
                                                            <Input className="input-styling" name="ctaLinkPath" {...register("ctaLinkPath", { validate: (value) => RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?").test(value) || "Not a valid URL"})}/>
                                                            <ErrorMessage errors={errors} name="ctaLinkPath" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>}/>
                                                        </FormControl>
                    
                                                        <FormControl isRequired>
                                                            <FormLabel htmlFor="ctaHeader">Call to Action Button Text:</FormLabel>
                                                            <Input className="input-styling" name="ctaHeader" {...register("ctaHeader", { required: "This is required."})} />
                                                            <ErrorMessage errors={errors} name="ctaHeader" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                        </FormControl>

                                                        <FormControl isRequired>
                                                            <FormLabel htmlFor="ctaSubheader">Call to Action Button Text (Line 2):</FormLabel>
                                                            <Input className="input-styling" name="ctaSubheader" {...register("ctaSubheader", { required: "This is required."})} />
                                                            <ErrorMessage errors={errors} name="ctaSubheader" render={({ message }) => <p className="error-message"><Icon mr={3} as={BiErrorCircle} /> {message} </p>} />
                                                        </FormControl>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        <Flex width="100%" justify="flex-end">
                                            <Button type="submit" variant="secondary" mt={5} onClick={() => handleSetFormTabIndex(1)}>Next Step <Icon as={FaArrowCircleRight} ml={3} /></Button>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </TabPanel>

                            {/* Second Tab Form */}
                            <TabPanel>
                                {!currentUser &&
                                    <SimpleGrid columns={{base: 1, md: 2}} spacing={10}>
                                        <Box>
                                            <SignInForm homepage={false} />
                                        </Box>
                                        <Box>
                                            <Flex direction="column" height={{base: "fit-content", md: "100%"}} justifyContent="space-between">
                                                <Box>
                                                    <Flex direction="column">
                                                        <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5">
                                                            Almost there!
                                                        </Text>
                                                        <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5">
                                                            Just one more click and you&apos;re done.
                                                        </Text>
                                                        <Text color={mode("gray.600", "gray.400")} fontSize="lg" mt="5">
                                                            Although no payment is required to view your MetaSite, you must be signed in to view it. Please complete the one-click sign in with your Google or MetaMask account. 
                                                        </Text>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </SimpleGrid>
                                }

                                {currentUser && !authenticated &&
                                    <PublishDemoUnauthenticated projectId={currentUser.uid} linkKey={demoKey} firstName={firstName} />
                                }
                                {currentUser && authenticated &&
                                    <PublishDemoAuthenticated projectId={currentUser.uid} linkKey={demoKey} firstName={firstName} />
                                }
                                
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </AccordionPanel>
        </AccordionItem>
    );
}

Form.propTypes = {
    authenticated: PropTypes.bool
}

export default Form;
