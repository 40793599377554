import { Button, HStack, Icon, Link, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { Link as RRLink } from "react-router-dom";

function NavButton({ icon, label, path, ...buttonProps }) {
    return (
        <Link as={RRLink} to={path} textDecoration="none" _hover={{textDecoration: "none"}}>
            <Button variant="ghost-on-accent" justifyContent="start" width="100%" {...buttonProps}>
                <HStack spacing="3">
                    <Icon as={icon} boxSize="6" color="on-accent-subtle" />
                    <Text>{label}</Text>
                </HStack>
            </Button>
        </Link>
    )
}

NavButton.propTypes = {
    icon: PropTypes.func,
    label: PropTypes.string,
    path: PropTypes.string,
};

export default NavButton;
