import "@google/model-viewer";
import { 
    Text, 
    HStack,
    Box,
    Badge,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
    Icon,
    Container,
    Stack,
    Heading,
    Flex,
    IconButton,
    Link
} from "@chakra-ui/react";
import { getDatabase, ref, get, child } from "firebase/database";
import moment from "moment/min/moment-with-locales";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import { IoArrowDown } from "react-icons/io5";
import { AuthContext } from "../../../context/AuthContext";
import { currentConfig } from "../../App/App.js";

function AccountStripe({ stripeInvoices, stripeInvoicesKeys }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [stripeSubscriptions, setStripeSubscriptions] = useState(false);
    const [portalUrl, setPortalUrl] = useState("https://billing.stripe.com/p/login/14kbJhfeB0x4bJKdQQ");

    useEffect(() => {
        async function getSubscriptions() {
            const subscriptions = await get(child(dbRef, `stripe_customers/${currentUser.uid}/subscriptions`)).catch((error) => console.log(error));
            return subscriptions.val();
        }

        getSubscriptions().then((allSubscriptions) => {
            setStripeSubscriptions(allSubscriptions);
        })

        if (currentConfig.projectId !== "ldp-studio-prod") {
            setPortalUrl("https://billing.stripe.com/p/login/test_5kA03Oe8T2FHeJO9AA");
        }
    }, [])

    return (
        <Container py="8" flex="1">
            <Container py="8" flex="1">
                <Stack
                    spacing={{
                        base: "8",
                        lg: "6",
                    }}
                >
                    <Stack
                        spacing="4"
                        direction={{
                            base: "column",
                            lg: "row",
                        }}
                        justify="space-between"
                        align={{
                            base: "start",
                            lg: "center",
                        }}
                    >
                        <Stack spacing="1">
                            <Heading
                                size="sm"
                                fontWeight="medium"
                            >
                            Stripe Billing
                            </Heading>
                            <Text color="muted">History of invoices, payments, & subscriptions</Text>
                        </Stack>

                        <HStack spacing="3">
                            <Link href={portalUrl} isExternal _hover={{textDecoration: "none"}}>
                                <Button variant="primary" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />}>Go to Subscription Portal</Button>
                            </Link>
                        </HStack>

                    </Stack>
                </Stack>
            </Container>

            <Flex direction="column">
                <>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>
                                    <HStack spacing="3">
                                        <HStack spacing="1">
                                            <Text>Date Created</Text>
                                            <Icon as={IoArrowDown} color="muted" boxSize="4" />
                                        </HStack>
                                    </HStack>
                                </Th>
                                <Th>Status</Th>
                                <Th>Plan</Th>
                                <Th>Amount</Th>
                                <Th>Period Start</Th>
                                <Th>Period End</Th>
                                <Th>URL</Th>
                                <Th>PDF</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {stripeInvoicesKeys &&
                                stripeInvoicesKeys.map((invoiceKey) => (
                                    <Tr key={invoiceKey}>
                                        <Td>
                                            <HStack spacing="3">
                                                <Box>
                                                    <Text fontWeight="medium">{moment.unix(stripeInvoices[invoiceKey].created).format("MM/DD/YY")}</Text>
                                                </Box>
                                            </HStack>
                                        </Td>
                                        <Td>
                                            <Badge size="sm" colorScheme={stripeInvoices[invoiceKey].status === "paid" ? "green" : "red"}>
                                                {stripeInvoices[invoiceKey].status}
                                            </Badge>
                                        </Td>
                                        <Td>
                                            {stripeSubscriptions[stripeInvoices[invoiceKey].subscription] &&
                                                <Badge size="sm" colorScheme="blue">
                                                    {stripeSubscriptions[stripeInvoices[invoiceKey].subscription].subscriptionPlan}
                                                </Badge>
                                            }
                                        </Td>
                                        <Td>
                                            <Text color="muted">
                                                ${(stripeInvoices[invoiceKey].amount_due /100).toLocaleString("en-US")}
                                            </Text>
                                        </Td>
                                        <Td>
                                            {stripeSubscriptions[stripeInvoices[invoiceKey].subscription] &&
                                                <Text color="muted">
                                                    {moment.unix(stripeSubscriptions[stripeInvoices[invoiceKey].subscription].currentPeriodStart).format("MM/DD/YY")}
                                                </Text>
                                            }   
                                        </Td>
                                        <Td> 
                                            {stripeSubscriptions[stripeInvoices[invoiceKey].subscription] &&
                                                <Text color="muted">
                                                    {moment.unix(stripeSubscriptions[stripeInvoices[invoiceKey].subscription].currentPeriodEnd).format("MM/DD/YY")}
                                                </Text>
                                            }
                                        </Td>
                                        <Td>
                                            <Button
                                                variant="primary"
                                                aria-label="Stripe invoice URL"
                                                onClick={() => window.open(stripeInvoices[invoiceKey].hosted_invoice_url, "_blank")}
                                            >
                                                Go to Stripe
                                            </Button>
                                        </Td>
                                        <Td>
                                            <IconButton
                                                icon={<FiDownload fontSize="1.25rem" />}
                                                variant="ghost"
                                                aria-label="Download invoice"
                                                onClick={() => window.open(stripeInvoices[invoiceKey].invoice_pdf, "_blank")}
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </>
            </Flex>
        </Container>
    );
}

AccountStripe.propTypes = {
    stripeInvoices: PropTypes.object, 
    stripeInvoicesKeys: PropTypes.array
}

export default AccountStripe;
