import { 
    Button, 
    AlertDialog, 
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader, 
    AlertDialogBody, 
    AlertDialogFooter, 
    // Icon,
    Heading,
    Text,
    MenuItem,
    useDisclosure,
    useToast 
} from "@chakra-ui/react";
import { getDatabase, ref, update } from "firebase/database";
import PropTypes from "prop-types";
import React from "react";
// import { FaTrashAlt } from "react-icons/fa";
import { AuthContext } from "../../../../context/AuthContext";

function DeleteLinkDialog({ projectId, linkKey, linkName }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const cancelRef = React.useRef();

    const permanentlyDeleteLink = async(projectId, linkKey) => {
        const updates = {};
        updates["/links/" + linkKey] = null;  // delete from links
        updates["/projects/" + projectId + "/links/" + linkKey] = null;  // delete from projects/links
        updates["/users/" + currentUser.uid + "/projects/" + projectId + "/links/" + linkKey] = null;  // delete from users/projects/links
        await update(ref(database), updates);

        toast({
            title: "MetaSite Deleted",
            description: `"${linkName}" has been permanently deleted.`,
            status: "error",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
        onClose();
    }

    return (
        <>  
            <MenuItem colorScheme="red" onClick={onOpen}>
                {/* <Icon as={FaTrashAlt} mr={5} />  */}
                Permanently Delete
            </MenuItem>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                            <Heading size="sm">Permanently Delete</Heading>
                            <Heading size="xs">&quot;{linkName}&quot;</Heading>
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>Are you sure? You can&apos;t undo this action afterwards. If you only want to disable your link please opt to archive it instead.</Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                            </Button>
                            <Button bg='red' onClick={() => permanentlyDeleteLink(projectId, linkKey)} ml={3}>
                  Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

DeleteLinkDialog.propTypes = {
    projectId: PropTypes.string, 
    linkKey: PropTypes.string, 
    linkName: PropTypes.string, 
}

export default DeleteLinkDialog;