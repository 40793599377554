import "@google/model-viewer";
import { 
    Text,
    Heading,
    Box,
    FormLabel,
    FormControl,
    Flex,
    Button,
    SimpleGrid,
    Divider,
    Icon,
    HStack,
    VStack,
    Image,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    AccordionIcon,
    Container,
    Stack,
    Link,
    useBreakpointValue,
    useColorModeValue as mode
} from "@chakra-ui/react";
import { 
    useInView
    // AnimatePresence, 
    // motion 
} from "framer-motion";
import React, { useState, useEffect, useRef } from "react";
import { 
    FaCube, 
    FaChessKnight, 
    FaFileUpload, 
    FaArrowCircleRight, 
    FaPencilAlt, 
    FaRegNewspaper, 
    FaMagic
} from "react-icons/fa";
import arprint from "../../../../assets/arprint.gif";
import catmetasite from "../../../../assets/catmetasite.gif";
import formfill from "../../../../assets/formfill.gif";
import { DemoContext } from "../../../../context/DemoContext";
import { CustomSelect} from "../../../Global/CustomSelect/CustomSelect.js";
import { Option } from "../../../Global/CustomSelect/Option.js";
import "../../DemoUnauthenticated/DemoUnauthenticated.css";

function Orientation() {
    const [heroWord, setHeroWord] = useState("Dream");
    const {
        demoPath, 
        currentStage, 
        handleSetDemoPath, 
        handleSetCurrentStage,
        handleSetModelTabIndex,
    } = React.useContext(DemoContext);

    useEffect(() => {
        const words = ["Dream", "Dream", "Play", "Profit"];
        let idx = 0;
        const interval = setInterval(() => {
            setHeroWord(words[idx]);
            if (idx === 3) {
                idx = 0
            }
            if (idx !== 3) {
                idx = idx + 1;
            }
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const step1Ref = useRef(null);
    const step2Ref = useRef(null);
    const step3Ref = useRef(null);
    const step4Ref = useRef(null);
    const letsDoThisRef = useRef(null);
    const step1InView = useInView(step1Ref, { once: true });
    const step2InView = useInView(step2Ref, { once: true });
    const step3InView = useInView(step3Ref, { once: true });
    const step4InView = useInView(step4Ref, { once: true });
    const letsDoThisInView = useInView(letsDoThisRef, { once: true });

    useEffect(() => {
        if (currentStage === "orientation") {
            const modelDom = document.getElementById("orientation_button");
            let firstChild = modelDom.firstChild;
            firstChild.click();
        }
    }, [currentStage])

    useEffect(() => {
        if (letsDoThisInView) {
            setTimeout(() => {
                if (currentStage === "orientation") {
                    letsDoThisRef.current.click();
                }
            }, 5000); //miliseconds
        }
    }, [letsDoThisInView])

    const setStageAfterOrientation = () => {
        if (demoPath === "example") {
            handleSetDemoPath("example");
        }
        handleSetCurrentStage("model")
        handleSetModelTabIndex(0);
    }

    const getStartedClick = () => {
        step1Ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    return (
        <AccordionItem id="0">
            <h2 id="orientation_button">
                <AccordionButton _expanded={{ bg: "brand.100", color: "whiteAlpha.900", fontSize: "20px" }} style={{ fontSize: "20px" }}>
                    <Box flex='1' textAlign='left'>
                        Orientation
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pt="0" pl="0" pr="0" minHeight="100vh">
                {/* <Hero /> */}
                <Box
                    as="section"
                    bg={mode("gray.50", "gray.800")}
                    pb="24"
                    pos="relative"
                    px={{
                        base: "6",
                        lg: "12",
                    }}
                    height={{       
                        base: "fit-content",
                        lg: "100vh"
                    }}
                >   

                    {/* left side */}
                    <Box 
                        maxW="7xl" 
                        mx="auto" 
                        height={{       
                            base: "fit-content",
                            lg: "100%"
                        }}
                    >  

                        {/* text and buttons */}
                        <Box
                            maxW={{
                                lg: "md",
                                xl: "xl",
                            }}
                            pt={{
                                base: "20",
                                lg: "80",
                            }}
                            pb={{
                                base: "16",
                                lg: "24",
                            }}
                        >
                            <Heading as="h1" size="3xl" lineHeight="1" fontWeight="extrabold" letterSpacing="tight">
                                <Box mb="6px">
                                    Ready to
                                </Box>
                                <Box as="mark" color={mode("blue.500", "blue.300")} bg="transparent">
                                    {heroWord}
                                </Box>
                                <Box mt="6px">
                                    With Us?
                                </Box>
                            </Heading>
                            <Text color={mode("gray.600", "gray.400")} mt="5" fontSize="xl">
                                We&apos;ll start with a brief orientation to let you know what to expect each step of the way.
                            </Text>
                            <Text color={mode("gray.600", "gray.400")} mt="5" fontSize="xl">
                                You&apos;ll choose whether you want us to help you search for your perfect 3D model or upload your own. 
                            </Text>
                            <Text color={mode("gray.600", "gray.400")} mt="5" fontSize="xl">
                                Within minutes, you&apos;ll be building!
                            </Text>
                            <Stack
                                direction={{
                                    base: "column",
                                    sm: "row",
                                }}
                                spacing="4"
                                mt="8"
                            >
                                <Button onClick={() => getStartedClick()} size="lg" colorScheme="blue" height="14" px="8" fontSize="md">
                                    Get Started Now
                                </Button>
                                <Link href="https://ldp.studio" isExternal width={{base: "100%", md: "fit-content"}}>
                                    <Button
                                        size="lg"
                                        bg="white"
                                        color="gray.800"
                                        _hover={{
                                            bg: "gray.50",
                                        }}
                                        height="14"
                                        px="8"
                                        shadow="base"
                                        fontSize="md"
                                    >
                                        Learn More
                                    </Button>
                                </Link>
                            </Stack>
                        </Box>

                    </Box>
            
                    {/* right side */}
                    <Box
                        pos={{
                            lg: "absolute",
                        }}
                        insetY={{
                            lg: "0",
                        }}
                        insetEnd={{
                            lg: "0",
                        }}
                        bg="gray.50"
                        w={{
                            base: "full",
                            lg: "50%",
                        }}
                        // height="100%"
                        height={{
                            base: "96",
                            lg: "full",
                        }}
                        sx={{
                            clipPath: {
                                lg: "polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%)",
                            },
                        }}
                    >
                        <Box w="100%" h="100%">
                            <model-viewer
                                id="model-viewer-cgtrader-demo"
                                src="https://www.googleapis.com/download/storage/v1/b/ldp-studio-int.appspot.com/o/monadAssets%2F-MwURkxNZicuXDeQM8_w%2F-MwUS0NH2Yv_G_keLdW9%2Fglb%2Fhorse-god.glb?generation=1645500369547784&alt=media"
                                alt="A 3D model of an astronaut"
                                skybox-image="https://modelviewer.dev/shared-assets/environments/spruit_sunrise_1k_HDR.hdr"
                                ar
                                autoplay
                                camera-controls>
                            </model-viewer>
                        </Box>
                    </Box>

                </Box>

                <Box as="section" ref={step1Ref} bg="bg-accent" color="on-accent" py={{ base: "3", md: "6" }}>
                    <span    
                        style={{
                            transform: step1InView ? "none" : "translateX(-200px)",
                            opacity: step1InView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                        }}
                    >
                        <Container>
                            <Flex align="center" textAlign="center">
                                <HStack spacing="3">
                                    <Icon as={FaCube} boxSize={8} mr={3} />
                                    <Heading size={useBreakpointValue({ base: "md", md: "lg" })}>
                                        Select 3D Model
                                    </Heading>
                                </HStack>
                            </Flex>
                        </Container>
                    </span>
                </Box>
                
                <Flex
                    direction="column"
                    align="center"
                    bg={mode("gray.50", "gray.800")}
                    pt={10}
                    pb={10}
                    px={{
                        base: "6",
                        lg: "12",
                    }}
                >
                    <Flex direction="column" width="85%">
                        <Flex direction="column">
                            <Box width="md" color="bg-accent" mt={10}>
                                <Heading size="sm">
                                    Step 1:
                                </Heading>
                            </Box>
                            <Text color={mode("gray.600", "gray.400")} fontSize="xl" textAlign="justify" mt={5}>
                                Augmented reality can be confusing, but it doesn&apos;t have to be!
                            </Text>
                            <Text color={mode("gray.600", "gray.400")} fontSize="xl" textAlign="justify" mt={3} mb={10}>
                                We&apos;ve architected 2 different paths to select a 3D model for your augmented reality page.
                            </Text>
                        </Flex>

                        <SimpleGrid 
                            columns={3}
                            spacing={10} 
                            mb={10}
                            pb={5}
                        >
                            <Box></Box>
                            <Box>
                                <Divider style={{borderBottomWidth: "6px", borderColor: "#d4af37"}} />
                            </Box>
                            <Box></Box>
                        </SimpleGrid>

                        <SimpleGrid 
                            columns={{
                                base: 1,
                                lg: 2
                                // lg: 3
                            }} 
                            spacing={20}>
                            <Box>
                                <VStack mb={2}>
                                    <Flex width="100%" direction="column" justify="flex-start">
                                        <Heading size="2xs" color="brand.600" opacity="85%">Option #1:</Heading>
                                    </Flex>
                                    <Flex width="100%" direction="row" justify="flex-start">
                                        <Heading size="xs" color="brand.600">Use a Demo Model</Heading>
                                        <Icon as={FaChessKnight} color="brand.600" boxSize={7} ml={3} />
                                    </Flex>
                                </VStack>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" pt={3}>We&apos;ve uploaded some of our favorite models for users to try out.</Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={3}>This is the easiest path and the one recommended if you are new to augmented reality file types. This option is just one click and 4 form fields to completion.</Text>
                            </Box>
                            <Box>
                                <VStack mb={2}>
                                    <Flex width="100%" direction="column" justify="flex-start">
                                        <Heading size="2xs" color="brand.600" opacity="85%">Option #2:</Heading>
                                    </Flex>
                                    <Flex width="100%" direction="row" justify="flex-start">
                                        <Heading size="xs" color="brand.600">Upload Your Own</Heading>
                                        <Icon as={FaFileUpload} color="brand.600" boxSize={7} ml={3} />
                                    </Flex>
                                </VStack>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" pt={3}>Select this path if you already have a .glb file you would like to use for your MetaSite.</Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={3}>If you have the file it&apos;s really as easy as just dragging and dropping. Upload an optional .usdz file for an even better experience!</Text>
                            </Box>
                            {/* <Box>
                                <HStack mb={5}>
                                    <Heading size="xs" color="blackAlpha.800">Search for a 3D Model</Heading>
                                    <Icon as={FaSearch} boxSize={5} />
                                </HStack>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" pt={3}>Need something specific?</Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={3}>We&apos;ve partnered with CgTrader, an online marketplace for 3D model files, to let you search for (free!) 3D model files compatible with our platform.</Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={3}>While there are a couple more steps in this path, you will be able to find a model for your search terms.</Text>
                            </Box> */}
                        </SimpleGrid>

                        <Box mt={12} mb={8} width={{base: "100%", lg: "50%"}}>
                            <FormControl isRequired>
                                <FormLabel>
                                    Choose your path:
                                </FormLabel>
                                <CustomSelect
                                    name="demoPath"
                                    colorScheme="blue"
                                    value={demoPath}
                                    onChange={handleSetDemoPath}
                                    placeholder="Choose your path"
                                    size="lg"
                                >
                                    <Option value="example">
                                        <HStack>
                                            <Icon as={FaChessKnight} />
                                            <Text>Use a demo model</Text>
                                        </HStack>
                                    </Option>
                                    <Option value="upload">
                                        <HStack>
                                            <Icon as={FaFileUpload} />
                                            <Text>Upload your own model</Text>
                                        </HStack>
                                    </Option>
                                    {/* <Option value="search">
                                        <HStack>
                                            <Icon as={FaSearch} />
                                            <Text>Search for a specific model</Text>
                                        </HStack>
                                    </Option> */}
                                </CustomSelect>
                            </FormControl>
                        </Box>
                    </Flex>
                </Flex>  

                <Box as="section" ref={step2Ref} bg="on-accent" color="bg-accent" py={{ base: "3", md: "6" }} borderTop="2px solid #3182ce" borderBottom="2px solid #3182ce">
                    <span    
                        style={{
                            transform: step2InView ? "none" : "translateX(-200px)",
                            opacity: step2InView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                        }}
                    >
                        <Container>
                            <Flex align="center" textAlign="center">
                                <HStack spacing="3">
                                    <Icon as={FaPencilAlt} boxSize={8} mr={3} />
                                    <Heading size={useBreakpointValue({ base: "md", md: "lg" })}>
                                        Give Us a Few Details
                                    </Heading>
                                </HStack>
                            </Flex>
                        </Container>
                    </span>
                </Box>

                <Flex
                    direction="column"
                    align="center"
                    bg={mode("gray.50", "gray.800")}
                    pt={10}
                    pb={10}
                    px={{
                        base: "6",
                        lg: "12",
                    }}
                >
                    <Flex direction="column" width="85%">
                        <SimpleGrid columns={{base: 1, lg: 2}} spacing={20} mt={10} mb={10}>
                            <Box>
                                <Image src={formfill} width="100%" borderRadius="xl" boxShadow="md" />
                            </Box>
                            <Flex direction="column">
                                <Box width="md" color="bg-accent">
                                    <Heading size="sm">
                                        Step 2:
                                    </Heading>
                                </Box>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    Fill out a simple form. The primary 2 things we need are:
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    1) the URL you want to route your viewers to, and
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    2) the marketing copy you want on this Call to Action button.
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    This text is meant to entice viewers into clicking the button so they are routed to the chosen URL.
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    Creating an augmented reality landing page in our app is always as simple as these two steps!
                                </Text>
                            </Flex>
                        </SimpleGrid>
                    </Flex>
                </Flex>

                <Box as="section" ref={step3Ref} bg="bg-accent" color="on-accent" py={{ base: "3", md: "6" }}>
                    <span    
                        style={{
                            transform: step3InView ? "none" : "translateX(-200px)",
                            opacity: step3InView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                        }}
                    >
                        <Container>
                            <Flex align="center" textAlign="center">
                                <HStack spacing="3">
                                    <Icon as={FaRegNewspaper} boxSize={8} mr={3} />
                                    <Heading size={useBreakpointValue({ base: "md", md: "lg" })}>
                                        View MetaSite
                                    </Heading>
                                </HStack>
                            </Flex>
                        </Container>
                    </span>
                </Box>

                <Flex
                    direction="column"
                    align="center"
                    bg={mode("gray.50", "gray.800")}
                    pt={10}
                    pb={10}
                    px={{
                        base: "6",
                        lg: "12",
                    }}
                >
                    <Flex direction="column" width="85%">
                        <SimpleGrid columns={{base: 1, lg: 2}} spacing={20} mt={10} mb={10} display={{base: "flex", lg: "grid"}} flexDirection={{base: "column-reverse", lg: "row"}}>
                            <Flex direction="column">
                                <Box width="md" color="bg-accent">
                                    <Heading size="sm">
                                        Step 3:
                                    </Heading>
                                </Box>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    We&apos;ll generate a preview of what your MetaSite looks like on various devices.
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    Want to see the magic in your own hands?
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    At this stage you can create a free account to save your work and view your MetaSite in the browser on both desktop and mobile.
                                </Text>
                            </Flex>
                            <Box>
                                <Image src={catmetasite} width="100%" borderRadius="xl" boxShadow="md" />
                            </Box>
                        </SimpleGrid>
                    </Flex>
                </Flex>
                
                <Box as="section" ref={step4Ref} bg="on-accent" color="bg-accent" py={{ base: "3", md: "6" }} borderTop="2px solid #3182ce" borderBottom="2px solid #3182ce">
                    <span
                        style={{
                            transform: step4InView ? "none" : "translateX(-200px)",
                            opacity: step4InView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                        }}
                    >
                        <Container>
                            <Flex align="center" textAlign="center">
                                <HStack spacing="3">
                                    <Icon as={FaMagic} boxSize={8} mr={3} />
                                    <Heading size={useBreakpointValue({ base: "md", md: "lg" })}>
                                        Publish MetaSite
                                    </Heading>
                                </HStack>
                            </Flex>
                        </Container>
                    </span>
                </Box>

                <Flex
                    direction="column"
                    align="center"
                    bg={mode("gray.50", "gray.800")}
                    pt={10}
                    pb={10}
                    px={{
                        base: "6",
                        lg: "12",
                    }}
                >
                    <Flex direction="column" width="85%">
                        <SimpleGrid columns={{base: 1, lg: 2}} spacing={20} mt={10}>
                            <Box display="flex" alignItems="center"> 
                                <Image src={arprint} width="100%" borderRadius="xl" boxShadow="md" />
                            </Box>
                            <Flex direction="column">
                                <Box width="md" color="bg-accent">
                                    <Heading size="sm">
                                        Step 4:
                                    </Heading>
                                </Box>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg"  textAlign="justify" mt={5}>
                                    Show the world your vision!
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    Now that your work is safely saved in your registered account you can choose to publish it at any time. 
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    Making a MetaSite live and sharable with others is the only step that costs money. Sign up for one of our plans and we will give you a two week free trial to start.
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    MetaSites come with access to our app. Once logged in to your account, you can change the content of your MetaSite at any time.
                                </Text>
                                <Text color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={5}>
                                    Analytics on metrics such as total traffic, CTA clicks, and AR scene views are included within the app and presented through easy-to-interpret graphs and charts.
                                </Text>
                            </Flex>
                        </SimpleGrid>

                        {/* <SimpleGrid columns={2} spacing={10} mt={10}>
                            <Box>
                                <Divider style={{borderBottomWidth: "8px", borderColor: "#d4af37"}} />
                            </Box>
                            <Box></Box>
                        </SimpleGrid>
                        <Flex direction="column">
                            <Text color={mode("gray.600", "gray.400")} fontSize="xl" textAlign="justify" mt={10}>
                                You have selected the {demoPath} path. 
                            </Text>
                            <Text color={mode("gray.600", "gray.400")} fontSize="xl" textAlign="justify" mt={3} mb={10}>
                                If this is correct, click the button below to continue. If you&apos;d like to choose a different path, use the dropdown below then click the button.
                            </Text>
                            <Box mt={8} width="50%">
                                <FormLabel>
                                Choose your path:
                                </FormLabel>
                                <CustomSelect
                                    name="demoPath"
                                    colorScheme="blue"
                                    value={demoPath}
                                    onChange={handleSetDemoPath}
                                    placeholder="Choose your path"
                                    size="lg"
                                    borderColor="rgb(212, 175, 55)"
                                    borderWidth="2px"
                                >
                                    <Option value="upload">
                                        <HStack>
                                            <Icon as={FaFileUpload} />
                                            <Text>Upload your own model</Text>
                                        </HStack>
                                    </Option>
                                    <Option value="example">
                                        <HStack>
                                            <Icon as={FaChessKnight} />
                                            <Text>Use an example model</Text>
                                        </HStack>
                                    </Option>
                                    <Option value="search">
                                        <HStack>
                                            <Icon as={FaSearch} />
                                            <Text>Search for a specific model</Text>
                                        </HStack>
                                    </Option>
                                </CustomSelect>
                            </Box>
                        </Flex> */}

                        <Flex direction="row" justify="flex-end">
                            <Button size="lg" colorScheme="blue" fontSize="md" mt={5} onClick={() => setStageAfterOrientation()} ref={letsDoThisRef}>Let&apos;s Start This <Icon as={FaArrowCircleRight} ml={3} /></Button>
                        </Flex>
                    </Flex>
                </Flex>
                
            </AccordionPanel>
        </AccordionItem>
    );
}

export default Orientation;
