import { Stack, SimpleGrid } from "@chakra-ui/react"
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard.js";
import Loading from "../Loading/Loading.js";

function ProjectCardsList({ allProjectsData }) {
    const [allProjectsKeys, setAllProjectsKeys] = useState(false);

    useEffect(() => {
        const keys = Object.keys(allProjectsData);
        setAllProjectsKeys(keys);
    }, [allProjectsData])

    if (allProjectsKeys.length === 0) {
        return <Loading size="small" />
    }

    if (allProjectsKeys.length > 0) {
        return (
            <Stack
                spacing={{
                    base: "5",
                    lg: "6",
                }}
            >
                <SimpleGrid
                    columns={{
                        base: 1,
                        md: 3,
                    }}
                    gap="6"
                >
                    {allProjectsKeys.map((projectKey, idx) =>
                        <ProjectCard projectKey={projectKey} projectData={allProjectsData[projectKey]} key={idx} />
                    )}
                </SimpleGrid>
            </Stack>
        );
    }

    return null;
}

ProjectCardsList.propTypes = {
    allProjectsData: PropTypes.object,
}

export default ProjectCardsList;
