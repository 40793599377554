import { 
    Button, 
    Divider, 
    Flex, 
    Heading, 
    Text, 
    Container,
    Box,
    Stack
} from "@chakra-ui/react";
import React from "react";
import { InlineWidget } from "react-calendly";
import { MdOutlineEmail } from "react-icons/md";
import { AuthContext } from "../../context/AuthContext";

function Support() {
    const {currentUser} = React.useContext(AuthContext);
    const subject = `LDP app support request from ${currentUser.displayName}`;
    let prompt = "Let us know what you are having an issue with. Screenshots are helpful! We will get back to you ASAP."

    return (
        <Container py="8" flex="1">
            <Container py="8" flex="1">
                <Stack
                    spacing={{
                        base: "8",
                        lg: "6",
                    }}
                >        
                    <Box
                        minH="3xs"
                        bg="bg-surface"
                        boxShadow="sm"
                        borderRadius="lg"
                        padding={7}
                    >
                        <Flex direction="column">
                            <Flex direction="row" alignItems="center"  mb={5}>
                                <Heading size="md">Need Some Help?</Heading>
                            </Flex>
                            <Text fontSize="lg">The app can be confusing at first. We understand and are available to help get you unstuck.</Text>
                            <Divider mb={3} mt={3} />
                            <Text fontSize="md" mb={6}>Click the button below to send us an email with any questions you may have or requests for assistance. Or, use the scheduler below to book some time to get live technical help over Zoom.</Text>
                            <Stack
                                direction={{
                                    base: "column",
                                    sm: "row",
                                }}
                                spacing={4}
                            >
                                <a href={`mailto:imagine@ldp.studio?subject=${subject}&body=${prompt}`}>
                                    <Button size="md" colorScheme="blue" fontSize="md" leftIcon={<MdOutlineEmail fontSize="1.25rem" />}>
                                        Email LDP Support
                                    </Button>
                                </a>
                            </Stack>

                        </Flex>
                        <InlineWidget url="https://calendly.com/ldpstudio/15min" />
                    </Box>
                </Stack>
            </Container>
        </Container>
    );
}

export default Support;
