import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { currentConfig } from "../App/App.js";

export default function StripeCheckout({ purchaseType, id }) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {currentUser} = React.useContext(AuthContext);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }
        async function getPaymentIntent() {
            await stripe.retrievePaymentIntent(clientSecret).then(async({ paymentIntent }) => {
                switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!")
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
                }
            });
        }
        getPaymentIntent();
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `https://${currentConfig.projectId}.firebaseapp.com/success/${currentUser.uid}/${id}/${purchaseType}`,
                // return_url: `http://localhost:3000/success/${currentUser.uid}/${id}/${purchaseType}`,  // use this link for testing on localhost
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    };

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" />
                <button disabled={isLoading || !stripe || !elements} id="stripe-submit">
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </form>
        </>
    );
}

StripeCheckout.propTypes = {
    purchaseType: PropTypes.string, 
    id: PropTypes.string
}