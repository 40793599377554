import {
    FormLabel,
    Select,
    Stack
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

function CreditsToToken({ tokenType, currentCredits, fieldName }) {
    const [potentialConversionCredits, setPotentialConversionCredits] = useState(false);

    const { register } = useFormContext();

    useEffect(() => {  
        let tokenCost = 250;
        if (tokenType === "metasite") {
            tokenCost = 500;
        }  
        const maxCredits = Math.floor(currentCredits / tokenCost);
        const arr = Array.from({length: maxCredits}, (_, i) => i + 1);
        setPotentialConversionCredits(arr);
    }, [currentCredits, tokenType]);  

    return (
        <Stack>
            {potentialConversionCredits &&
        <>
            <FormLabel htmlFor={`${fieldName}`}>Select the number of tokens to convert credits into:</FormLabel>
            <Select {...register(`${fieldName}`)}>
                {potentialConversionCredits.map(num => (
                    <option key={num} value={num}>{num}</option>
                ))}
            </Select>
        </>
            }
        </Stack>
    );
}

CreditsToToken.propTypes = {
    tokenType: PropTypes.string,
    currentCredits: PropTypes.number,
    fieldName: PropTypes.string,
};

export default CreditsToToken;