import "@google/model-viewer";
import { 
    Text, 
    Container, 
    Heading,
    Box,
    Button,
    FormLabel,
    Flex,
    Tabs,
    Tab,
    TabPanel,
    TabPanels,
    TabList,
    SimpleGrid,
    Icon,
    HStack,
    VStack,
    Stack,
    Skeleton,
    Badge,
    AspectRatio,
    Image,
    FormControl,
    Checkbox,
    Select,
    AccordionPanel,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    useColorModeValue as mode
} from "@chakra-ui/react";
import { 
    getDatabase, 
    update,
    ref,
    get,
    child
} from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { 
    useFormContext,
} from "react-hook-form";
import { 
    FaArrowCircleLeft,
    FaArrowCircleRight, 
    FaUserCheck 
} from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { AuthContext } from "../../../../context/AuthContext";
import { DemoContext } from "../../../../context/DemoContext";
import NewUserCard from "../../../Global/NewUserCard/NewUserCard.js";
import { RadioCard, RadioCardGroup } from "../../../Global/RadioCardGroup/RadioCardGroup.js";
import SignInForm from "../../../UnauthenticatedApp/SignInForm.js";
import DemoCgTrader from "../CgTrader/DemoCgTrader.js";
import DemoAssetUploader from "../FormComponents/DemoAssetUploader.js";
import "../../DemoUnauthenticated/DemoUnauthenticated.css";

function Model({ authenticated }) {
    const {currentUser} = React.useContext(AuthContext);
    const {
        demoPath, 
        demoKey, 
        currentStage,
        selectedModel,
        modelTabIndex,
        customImageStatus, 
        hdrStatus, 
        purchaseConfirmed, 
        confirmAttempted, 
        purchaseId, 
        freeGlb, 
        selectedExampleName,
        handleSetCurrentStage,
        handleSetModelTabIndex,
        handleSetFormTabIndex,
        handleSetCustomImageStatus, 
        handleSetHdrStatus, 
        handleSetPurchaseConfirmed, 
        handleSetConfirmAttempted, 
        handleSetPurchaseId, 
        handleSetFreeGlb, 
        handleSetSelectedExampleName,
    } = React.useContext(DemoContext);
    const storage = getStorage();
    const database = getDatabase();
    const dbRef = ref(database);
    const [allExampleModels, setAllExampleModels] = useState(false);
    const [allExampleModelsKeys, setAllExampleModelsKeys] = useState(false);
    const [hdrOptions, setHdrOptions] = useState(false);

    const { register, setValue } = useFormContext();

    useEffect(() => {
        setValue("usdz", false);

        setValue("rotation", "none");
        setValue("zoom", "none");
        setValue("autoplay", "false");
        
        setHdrOptions([
            {
                label: "Sunrise",
                value: "sunrise",
            },
            {
                label: "Pillars",
                value: "pillars",
            },
            {
                label: "Music Hall",
                value: "hall",
            },
            {
                label: "Aircraft Workshop",
                value: "workshop",
            },
            {
                label: "Creek",
                value: "creek",
            },
        ]);

        async function getAllExampleModels() {
            const result = await get(child(dbRef, "/assets_data/examples/")).catch((error) => console.error(error));
            const resultVal = result.val();
            setAllExampleModels(resultVal);
            setAllExampleModelsKeys(Object.keys(resultVal));
            return resultVal;
        }

        if (demoPath === "example") {
            getAllExampleModels();
            setValue("glb", "mixer.glb");
            setValue("img", "mixer.png");
            setValue("usdz", "mixer.usdz");
            setValue("hdr", false);
        }
    }, [demoPath])

    useEffect(() => {
        if (currentStage === "model") {
            const modelDom = document.getElementById("model_button");
            let firstChild = modelDom.firstChild;
            firstChild.click();

            if (authenticated === true) {
                handleSetModelTabIndex(1)
            }
        }
    }, [currentStage])

    useEffect(() => {
        if (purchaseConfirmed) {
            handleSetCurrentStage("form");
        }
    }, [purchaseConfirmed])

    useEffect(() => {
        if (demoPath === "example") {
            setValue("glb", `${selectedExampleName}.glb`);
            setValue("img", `${selectedExampleName}.png`);
            setValue("usdz", `${selectedExampleName}.usdz`);
            setValue("hdr", false);
        }
    }, [selectedExampleName])

    useEffect(() => {
        if (freeGlb) {
            getGlbFile(selectedModel.id);
        }
    }, [freeGlb])

    // ***SEARCH FUNCTIONS***

    const createOrder = async (modelId) => {
        await fetch("/api/createOrder", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ uuid: `${currentUser.uid}`, model_id: modelId, return_url: "https://ldp-studio-int.firebaseapp.com/cgTrader", uid: `${currentUser.uid}` }),
        })
            .then((res) => res.json())
            .then(async (data) => {

                // createCgTraderOrders in RTDB
                handleSetPurchaseId(data.response.id);
                await update(ref(database, `/cg_trader_orders/${currentUser.uid}/${data.response.id}`), data.response);
                // open up download url in new window

                window.open(data.response.checkout_url, "_blank")
                return data;
            }).catch((err) => console.log("createOrder error", err));
    }

    const getModelFiles = async (modelId) => {
        await fetch(`/api/getModelFiles?model_id=${modelId}&uid=${currentUser.uid}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then(async (data) => {
                await update(ref(database, `/cg_trader_free_purchases/${currentUser.uid}/${modelId}`), data.response);
                await data.response.files.map(async (file) => {
                    if (file.name.endsWith(".glb") || file.name.endsWith(".gltf") || file.name.endsWith(".zip")) {
                        await update(ref(database, `/cg_trader_free_purchases/${currentUser.uid}/${modelId}/glb_models`), file);
                        handleSetFreeGlb(file);
                        return file;
                    }
                })
            }).catch((err) => console.log("getOrders error", err));
    }

    const getModelFilesPaid = async (modelId) => {
        await fetch(`/api/getModelFiles?model_id=${modelId}&uid=${currentUser.uid}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then(async (data) => {
                await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${modelId}`), data.response);
                await data.response.files.map(async (file) => {
                    if (file.name.endsWith(".glb") || file.name.endsWith(".gltf") || file.name.endsWith(".zip")) {
                        await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${modelId}/glb_models`), file);
                        handleSetFreeGlb(file);
                        await fetch(`/api/getGlbFile?model_id=${modelId}&id=${file.id}&uid=${currentUser.uid}`, {
                            method: "GET",
                            headers: { "Content-Type": "application/json" },
                        })
                            .then((res) => res.json())
                            .then(async (glbFile) => {
                                handleSetPurchaseConfirmed(true);           
                                const glbRef = storageRef(storage, `monadDemoAssets/${demoKey}/glb/${file.name}`);
                
                                let blob = await fetch(glbFile.url).then((r) => {
                                    const blobette = r.blob();
                                    return blobette;
                                })
                
                                await uploadBytes(glbRef, blob).then(async (res) => {
                                    setValue("glb", file.name);                                    
                                    return res;
                                }).catch((err) => console.log("blob upload error", err));
                
                            }).catch((err) => console.log("getGlbFile error", err));
                    }
                })
            }).catch((err) => console.log("getOrders error", err));
    }

    const getGlbFile = async (modelId) => {
        await fetch(`/api/getGlbFile?model_id=${modelId}&id=${freeGlb.id}&uid=${currentUser.uid}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then(async (glbFile) => {
                handleSetPurchaseConfirmed(true);
                const glbRef = storageRef(storage, `monadDemoAssets/${demoKey}/glb/${freeGlb.name}`);

                let blob = await fetch(glbFile.url).then((r) => {
                    const blobette = r.blob();
                    return blobette;
                })

                await uploadBytes(glbRef, blob).then((res) => {
                    setValue("glb", freeGlb.name);   
                    return res;
                }).catch((err) => console.log("blob upload error", err));

            }).catch((err) => console.log("getGlbFile error", err));
    }

    const prepareForConfirmPanel = async () => {
        if (selectedModel) {
            if (selectedModel.prices.download === 0) {
                await getModelFiles(selectedModel.id);
            }

            if (selectedModel.prices.download !== 0) {
                await createOrder(selectedModel.id);
            }
            handleSetCurrentStage("form");
        }
    }

    const confirmOrder = async () => {
        handleSetConfirmAttempted(true);
        await fetch(`/api/getOrders?uid=${currentUser.uid}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => res.json())
            .then(async (data) => {
                const purchases = data.response.orders;

                function filterByStatus(item) {
                    if (item.status === "paid") {
                        return true
                    }
                    return false;
                }

                const paid = purchases.filter(filterByStatus)
                paid.map(async (order) => {
                    if (order.id === purchaseId) {
                        await getModelFilesPaid(selectedModel.id);
                        return order;
                    }
                })
            }).catch((err) => console.log("getOrders error", err));
    }

    const startFormUploadExample = async () => {
        if (demoPath === "example") {
            await update(ref(database, "/demo_sessions/" + demoKey + "/copy_example/" + selectedExampleName), {linkId: demoKey, exampleName: selectedExampleName});
        }
        handleSetFormTabIndex(0);
        handleSetCurrentStage("form");
    }

    return (
        <AccordionItem>
            <h2 id="model_button">
                <AccordionButton _expanded={{ bg: "brand.100", color: "whiteAlpha.900", fontSize: "20px" }} style={{ fontSize: "20px" }}>
                    <Box flex='1' textAlign='left'>
                        Select 3D Model
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel minHeight="100vh" bg="gray.50">
                <>
                    <Container py="8" flex="1">

                        {demoPath === "search" &&
                                <Tabs index={modelTabIndex} isFitted>
                                    <TabList mb='1em'>
                                        <Tab>
                                            <Heading size="xs">Register</Heading>
                                        </Tab>
                                        <Tab>
                                            <Heading size="xs">Search</Heading>
                                        </Tab>
                                        <Tab>
                                            <Heading size="xs">Select</Heading>
                                        </Tab>
                                    </TabList>

                                    <TabPanels>

                                        {/* First Tab Model - Search */}
                                        <TabPanel>  
                                            <SimpleGrid columns={2} spacing={10}>
                                                <Box minHeight='350px'>
                                                    {!currentUser &&
                                                        <SignInForm homepage={false} />
                                                    }
                                                    {currentUser &&
                                                        <NewUserCard />
                                                    }
                                                </Box>
                                                <Box minHeight='500px'>
                                                    <Flex direction="column" height="100%" justifyContent="space-between">
                                                        <Box>
                                                            <HStack>
                                                                <Icon as={FaUserCheck} boxSize={8} mr={3} />
                                                                <Heading as="h3" fontSize="28px">
                                                                    Register
                                                                </Heading>
                                                            </HStack>
                                                            {!currentUser &&
                                                            <VStack>
                                                                <Text mt="5" color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify">
                                                                    Register for a free account in order to search for models to use in your MetaSite. 
                                                                </Text>
                                                                <Text mt="5" color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify">
                                                                    All it takes is one click and you&apos;ll be able to search thousands of models! 
                                                                </Text>
                                                            </VStack>
                                                            }
                                                            {currentUser &&
                                                            <VStack>
                                                                <Text mt="5" color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify">
                                                                    Thank you for registering! Click the &quot;Next Step&quot; button to begin your search for the perfect 3D model.
                                                                </Text>
                                                            </VStack>
                                                            }
                                                        </Box>
                                                        <Flex width="100%" justify="flex-end">
                                                            <Button variant="secondary" mt={5} onClick={() => handleSetModelTabIndex(1)}>Next Step <Icon as={FaArrowCircleRight} ml={3} /></Button>
                                                        </Flex>
                                                    </Flex>
                                                </Box>
                                            </SimpleGrid>
                                        </TabPanel>

                                        {/* Second Tab Model - Search */}
                                        <TabPanel>
                                            <Box minHeight='500px'>
                                                <Flex direction="column" height="100%" justifyContent="space-between">
                                                    <Box>
                                                        {currentUser &&
                                                            <DemoCgTrader />
                                                        }
                                                    </Box>
                                                    <Flex width="100%" justify="flex-end">
                                                        <Button variant="secondary" mt={5} onClick={() => handleSetModelTabIndex(2)}>Next Step <Icon as={FaArrowCircleRight} ml={3} /></Button>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        </TabPanel>

                                        {/* Third Tab Model - Search */}
                                        <TabPanel>
                                            {selectedModel &&
                                            <SimpleGrid columns={{base: 1, md: 2}} spacing={10}>
                                                <Box minHeight='500px'>
                                           
                                                    <Stack
                                                        spacing="5"
                                                        backgroundColor="white"
                                                    >
                                                        <Box position="relative">
                                                            <AspectRatio ratio={4 / 3}>
                                                                <Image
                                                                    src={selectedModel.thumbnails[0]} 
                                                                    alt={selectedModel.title}
                                                                    draggable="false"
                                                                    fallback={<Skeleton />}
                                                                    borderRadius="xl"
                                                                />
                                                            </AspectRatio>
                                                        </Box>

                                                        <Stack>

                                                            <Stack spacing="1" padding="5px">
                                                                <Heading size="xs" mb={3} pl={5} pr={5}>
                                                                        Model #{selectedModel.id}
                                                                </Heading>
                                                                <HStack pl={5} pr={5}>
                                                                    {!selectedModel.prices.price_with_discount &&
                                                                            <Badge variant="subtle" colorScheme="blue" fontSize='1.2em'>
                                                                                ${selectedModel.prices.download}
                                                                            </Badge>
                                                                    }
                                                                    {(selectedModel.prices.price_with_discount && selectedModel.prices.download === selectedModel.prices.price_with_discount) &&
                                                                            <Badge variant="subtle" colorScheme="blue" fontSize='1.2em'>
                                                                                ${selectedModel.prices.download}
                                                                            </Badge>
                                                                    }
                                                                    {(selectedModel.prices.price_with_discount && selectedModel.prices.download !== selectedModel.prices.price_with_discount) &&
                                                                            <Badge variant="subtle" colorScheme="blue" fontSize='1.2em'>
                                                                                <span style={{textDecoration: "line-through"}}>${selectedModel.prices.download}</span>
                                                                            </Badge>
                                                                    }
                                                                    {(selectedModel.prices.price_with_discount && selectedModel.prices.download !== selectedModel.prices.price_with_discount) &&
                                                                            <Badge variant="subtle" colorScheme="green" fontSize='1.2em'>
                                                                                    ${selectedModel.prices.price_with_discount}
                                                                            </Badge>
                                                                    }
                                                                </HStack>
                                                                <Text fontWeight="medium" fontSize="xl" color="gray.800" mt={10} pl={5} pr={5}>
                                                                        &quot;{selectedModel.title}&quot;
                                                                </Text>
                                                            </Stack>

                                                            <HStack padding="5px">
                                                                <SimpleGrid columns={3} overflow="scroll" padding={5}>
                                                                    {selectedModel.availableFileExtensions.map((ext) => 
                                                                        <>
                                                                            {ext === "glb" || ext === "gltf" && 
                                                                                    <Badge variant="subtle" colorScheme="green" mt="2" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize='1.2em'>
                                                                                        {ext}
                                                                                    </Badge>
                                                                            }
                                                                            {ext !== "glb" && ext !== "gltf" && 
                                                                                    <Badge variant="subtle" colorScheme="red" mt="2" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontSize='1.2em'>
                                                                                        {ext}
                                                                                    </Badge>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </SimpleGrid>
                                                            </HStack>

                                                        </Stack>
                                                    </Stack>
                                             
                                                </Box>

                                                <Box>
                                                    <Flex direction="column" height="100%" justifyContent="space-between">
                                                        {/* {selectedModel && */}
                                                        <VStack>
                                                            <HStack width="100%">
                                                                <Heading mt={10} size="xs" textAlign="left">
                                                                    Great Choice!
                                                                </Heading>
                                                            </HStack>
                                                            <Text mt={8} color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" width="100%">
                                                                You have chosen model #{selectedModel.id}, &quot;{selectedModel.title}&quot;.
                                                            </Text>
                                                            {selectedModel.prices.download !== 0 && 
                                                                <>
                                                                    {!purchaseConfirmed &&
                                                                        <Flex direction="column" justify="space-around" align="center">
                                                                            {!confirmAttempted &&
                                                                            <Flex direction="column">
                                                                                <Heading mt={10} size="xs" textAlign="left">
                                                                                    Almost there!
                                                                                </Heading>
                                                                                <Text fontWeight="medium" color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={10}>
                                                                                    Click the button below after completing your purchase with CgTrader in the other window. The order of the purchase is #{purchaseId}.
                                                                                </Text>
                                                                            </Flex>
                                                                            }
                                                                            {confirmAttempted &&
                                                                            <Flex direction="column">
                                                                                <Heading mt={10} size="xs" textAlign="left">
                                                                                    Confirmation Failed
                                                                                </Heading>
                                                                                <Text fontWeight="medium" color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify" mt={10}>
                                                                                    Please complete purchase #{purchaseId} and then click the button again to confirm.
                                                                                </Text>
                                                                            </Flex>
                                                                            }
                                                                            <Button onClick={() => confirmOrder()}>Confirm Purchase</Button>
                                                                        </Flex>
                                                                    }
                                                                    {purchaseConfirmed &&
                                                                        <Flex direction="column" justify="center" align="center">
                                                                            <Heading mt={10} size="xs" textAlign="left">
                                                                                Confirmed!
                                                                            </Heading>
                                                                        </Flex>
                                                                    }
                                                                </>
                                                            }
                                                            {selectedModel.prices.download === 0 && 
                                                                <Text mt="5" color={mode("gray.600", "gray.400")} fontSize="lg" textAlign="justify">
                                                                    If this looks correct, please continue to the next step to proceed. If not, return to the last panel and select a different model or initiated a new search.
                                                                </Text>
                                                            }
                                                        </VStack>
                                                        {/* } */}
                                                        <Flex width="100%" direction={{base: "column", md: "row"}} justifyContent="space-between">
                                                            <Button variant="secondary" mt={5} onClick={() => handleSetModelTabIndex(1)}>Change Selection <Icon as={FaArrowCircleLeft} ml={3} /></Button>
                                                            <Button variant="secondary" mt={5} onClick={() => prepareForConfirmPanel()}>Next Step <Icon as={FaArrowCircleRight} ml={3} /></Button>
                                                        </Flex>
                                                    </Flex>
                                                </Box>
                                            </SimpleGrid>
                                            }
                                            {!selectedModel &&
                                                <VStack width="100%">
                                                    <Flex direction="column" justify="center" align="center" width="100%">
                                                        <Text fontWeight="medium" color={mode("gray.600", "gray.400")} fontSize="xl" mt={10}>
                                                            No 3D model has been selected. Please return to the previous panel to search and select a model.
                                                        </Text>
                                                        <Button mt={10} size="lg" colorScheme="blue" fontSize="md" onClick={() => handleSetModelTabIndex(1)}>
                                                            Return to Search 
                                                            <Icon as={FiSearch} ml={3} />
                                                        </Button>
                                                    </Flex>
                                                </VStack>                                                                                  
                                            }
                                        </TabPanel>

                                    </TabPanels>
                                </Tabs>
                        }

                        {(demoPath === "upload" || demoPath === "example") &&
                                <Tabs index={modelTabIndex} isFitted>
                                    <TabList mb='1em'>
                                        <Tab>
                                            {demoPath === "upload" &&
                                                <Heading size="xs">Upload</Heading>
                                            }
                                            {demoPath === "example" &&
                                                <Heading size="xs">Select Model</Heading>
                                            }
                                        </Tab>
                                    </TabList>
                                    
                                    <TabPanels>

                                        {/* First Tab Model - Upload/Example */}
                                        <TabPanel>  
                                            <Box>
                                                <Flex direction="column" height="100%" justifyContent="space-between">

                                                    <Box>
                                                        <Stack
                                                            direction={{
                                                                base: "column",
                                                                lg: "row",
                                                            }}
                                                            spacing={{
                                                                base: "5",
                                                                lg: "8",
                                                            }}
                                                            justify="space-between">
                                                            <Box flexShrink={0} width={{
                                                                base: "100%",
                                                                lg: "25%",
                                                            }}>
                                                                {demoPath === "upload" &&
                                                                    <>
                                                                        <Text fontSize="lg" fontWeight="medium" mb={2}>
                                                                            Upload Assets
                                                                        </Text>
                                                                        <Text fontSize="sm" mb={1}>
                                                                            Upload AR files for iOS and Android.
                                                                        </Text>
                                                                        <Text fontSize="sm">
                                                                            A .glb/.gltf is required but the .usdz and thumbnail image are optional. 
                                                                        </Text>
                                                                    </>
                                                                }
                                                                {demoPath === "example" &&
                                                                    <>
                                                                        <Text fontSize="lg" fontWeight="medium" mb={2}>
                                                                            Select Example
                                                                        </Text>
                                                                        <Text fontSize="sm" mb={1}>
                                                                            Click on a card to choose one of the examples.
                                                                        </Text>
                                                                        <Text fontSize="sm">
                                                                            We will copy the 3D model file and other assets over to your MetaSite.
                                                                        </Text>
                                                                    </>
                                                                }
                                                            </Box>
                                                            <Box
                                                                bg="bg-surface"
                                                                boxShadow="sm"
                                                                borderRadius="lg"
                                                                flex="1"
                                                                maxWidth="3xl">
                                                            
                                                                {demoPath === "upload" &&
                                                                    <Stack
                                                                        spacing="5"
                                                                        px={{
                                                                            base: "4",
                                                                            md: "6",
                                                                        }}
                                                                        py={{
                                                                            base: "5",
                                                                            md: "6",
                                                                        }}>
                                                                        
                                                                        <FormControl isRequired>
                                                                            <FormLabel htmlFor="glb" >Android/Web File (.glb or .gltf):</FormLabel>
                                                                            <DemoAssetUploader newLinkKey={demoKey} required={true} inputName="glb" />
                                                                        </FormControl>

                                                                        <FormControl>
                                                                            <FormLabel htmlFor="usdz">iOS File (.usdz or .reality):</FormLabel>
                                                                            <DemoAssetUploader newLinkKey={demoKey} required={false} inputName="usdz" />
                                                                        </FormControl>

                                                                        <Checkbox size='lg' colorScheme='blue' mb={4} mt={5} fontWeight="500" fontSize="md" {...register("includeCustomImage")} onChange={e => {
                                                                            if (e.target.checked) {
                                                                                handleSetCustomImageStatus(true);
                                                                            }
                                                                            else {
                                                                                handleSetCustomImageStatus(false);
                                                                            }
                                                                        }} >
                                                                            <FormLabel mt={2}>Upload Custom Image?</FormLabel>
                                                                        </Checkbox>
                                                                        
                                                                        {!!customImageStatus &&
                                                                            <DemoAssetUploader newLinkKey={demoKey} required={false} inputName="img" />
                                                                        }

                                                                        <Checkbox size='lg' colorScheme='blue' mb={4} mt={5} fontWeight="500" fontSize="md" fontFamily="Roboto Mono" {...register("includeHdr")} onChange={e => {
                                                                            if (e.target.checked) {
                                                                                handleSetHdrStatus(true);
                                                                            }
                                                                            else {
                                                                                handleSetHdrStatus(false);
                                                                            }
                                                                        }} >
                                                                            <FormLabel mt={2} mb={0}>Include HDR Background Image?</FormLabel>
                                                                        </Checkbox>
                                                                        
                                                                        
                                                                        {hdrStatus &&
                                                                        <Select name="hdr" mb="10px !important" mt="5px !important" {...register("hdr")}>
                                                                            {hdrOptions.map(o => (
                                                                                <option key={o.value} value={o.value}>{o.label}</option>
                                                                            ))}
                                                                        </Select>
                                                                        }

                                                                    </Stack>
                                                                }

                                                                {demoPath === "example" && allExampleModelsKeys && allExampleModels &&
                                                                    <Stack
                                                                        spacing="5"
                                                                        px={{
                                                                            base: "4",
                                                                            md: "6",
                                                                        }}
                                                                        py={{
                                                                            base: "5",
                                                                            md: "6",
                                                                        }}>
                                                                        {allExampleModelsKeys &&
                                                                            <RadioCardGroup defaultValue={selectedExampleName} spacing="3">
                                                                                {allExampleModelsKeys.map((exampleName) => (
                                                                                    <RadioCard key={exampleName} value={exampleName} onClick={() => handleSetSelectedExampleName(exampleName)}>
                                                                                        <FormLabel>
                                                                                            {allExampleModels[exampleName].title}
                                                                                        </FormLabel>
                                                                                        <HStack>
                                                                                            <Image height="200px" src={allExampleModels[exampleName].img.link} />
                                                                                        </HStack>
                                                                                    </RadioCard>
                                                                                ))}
                                                                            </RadioCardGroup>
                                                                        }
                                                                    </Stack>
                                                                }
                                                            </Box>
                                                        </Stack>
                                                    </Box>

                                                    <Flex width="100%" justify="flex-end">
                                                        <Button variant="secondary" mt={5} onClick={() => startFormUploadExample()}>Next Step <Icon as={FaArrowCircleRight} ml={3} /></Button>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                        }

                    </Container>
                </>
            </AccordionPanel>
        </AccordionItem>
                    
    );
}

Model.propTypes = {
    authenticated: PropTypes.bool
}

export default Model;
