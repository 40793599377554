import { ErrorMessage } from "@hookform/error-message";
import { getDatabase, push, ref, update } from "firebase/database";
import { getStorage, ref as cloudStorageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment/min/moment-with-locales";
import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone"
import {useForm, FormProvider, useFormState, Controller } from "react-hook-form";
import { BiErrorCircle } from "react-icons/bi";
import { BsPlusCircleFill } from "react-icons/bs";
import NumberFormat from "react-number-format";
import { Button } from "reactstrap";
import { AuthContext } from "../../context/AuthContext";
import { currentConfig } from "../App/App.js";
import "./QrForm.css";

function QrForm() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const storage = getStorage();
    const [qrUrl, setQrUrl] = useState("");
    const [imgHeight, setImgHeight] = useState(false);
    const [imgWidth, setImgWidth] = useState(false);
    const [percent, setPercent] = useState(100);
    const [aspectRatio, setAspectRatio] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(false);
    const [bigError, setBigError] = useState(false);
    const [selectedOption, setSelectedOption] = useState("website");
    const qrKey = push(ref(database, "links")).key;
    const options = [
        {
            label: "Website",
            value: "website",
        },
        {
            label: "SMS",
            value: "sms",
        },
        {
            label: "Phone",
            value: "phone",
        },
        {
            label: "File",
            value: "file",
        },
    ];

    const { register, setValue, setError, clearErrors, handleSubmit, control } = useForm();
    const { errors } = useFormState({
        control
    });

    const onFormSubmit = async(data) => {
        const { linkType, link } = data;
        const { uid } = currentUser;
        const timestamp = moment().valueOf();
        const formattedDate = moment().format("MMMM Do YYYY, h:mm:ss a");
        let generatedUrl;
        let size = 400;
        let bgColor = "FFFFFF";
        if (linkType === "file") {
            const urlName = `${currentConfig.hostedUrl}/qrlink/${currentUser.uid}/${qrKey}`;
            generatedUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`;
        }
        else {
            generatedUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${link}&size=${size}x${size}&bgcolor=${bgColor}`;
        }
        setQrUrl(generatedUrl);
        setValue("qr", generatedUrl);

        const qrData = {
            linkType,
            link,
            linkHeight: imgHeight,
            linkWidth: imgWidth,
            aspectRatio: aspectRatio,
            percent: percent,
            qr: generatedUrl,
            timestamp,
            formattedDate,
        }

        const updates = {};
        updates["/qr_codes/" + uid + "/" + qrKey] = qrData;  
        updates["/users/" + uid + "/qr_codes/" + qrKey] = true;
        await update(ref(database), updates);
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles?.[0];
        const fileTypeArr = file.name.split(".");
        const fileName = fileTypeArr[0];
        const fileType = fileTypeArr[1];
        const storageRef = cloudStorageRef(storage, `qrAssets/${currentUser.uid}/${qrKey}/${fileType}/${fileName}`);

        if (!file) {
            return
        }
  
        try {
            setBigError(false);
            setIsLoading(true);
            await uploadBytes(storageRef, file).then(async(snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setValue("link", url);
                    let v = new Image();
                    v.onload = function() {
                        alert("size: " + String(v.width) + "x" + String(v.height));
                        setImgWidth(v.width);
                        setImgHeight(v.height);
                        setAspectRatio(v.width / v.height);
                        if (v.width > 3000) {
                            setPercent(40);
                        }
                        if (v.width > 2000) {
                            setPercent(60);
                        }
                        if (v.width > 1000) {
                            setPercent(80);
                        }
                        else {
                            setPercent(100);
                        }
                    };
                    v.src = url;
                });
                setIsLoading(false);
                setFileName(file.name);
            });
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setBigError("Unable to upload file. Please try again.");
        }
    }, [])


    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div className="qr-form-container">
            <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} control={control}>
                <form onSubmit={handleSubmit(onFormSubmit)} className="create-vcard-form">
                    <h1 className="create-vcard-title">Create QR Link</h1>
                    <label className="link-card-label" htmlFor="linkType">What do you want to link to?</label>
                    {options.length !== 0 &&
                <select
                    className="select-styling"
                    id="linkType" 
                    name="linkType" 
                    {...register("linkType", { required: "This is required."})}
                    value={selectedOption}
                    onBlur={e => setSelectedOption(e.target.value)}>
                    {options.map(o => (
                        <option key={o.value} value={o.value}>{o.label}</option>
                    ))}
                </select>
                    }

                    {(selectedOption === "website") &&
              <>
                  <label htmlFor="link" className="link-card-label">Website</label>
                  <input name="link" className="input-styling" {...register("link")}/>
                  <ErrorMessage errors={errors} name="link" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>}/>
              </>
                    }
      
                    {(selectedOption === "sms") &&
              <>
                  <label htmlFor="link" className="link-card-label">SMS:</label>
                  <Controller
                      render={({ field }) => (
                          <NumberFormat 
                              type="tel"
                              format="sms:+1##########" 
                              mask="_" 
                              className="input-styling"
                              required
                              {...field} 
                          />
                      )}
                      name="link"
                      control={control}
                  />
                  <ErrorMessage errors={errors} name="link" render={() => <p className="error-message"><BiErrorCircle /> Please enter a valid phone number. </p>} />
              </>
                    }
              
                    {(selectedOption === "phone") &&
              <>
                  <label htmlFor="link" className="link-card-label">Phone:</label>
                  <Controller
                      render={({ field }) => (
                          <NumberFormat 
                              type="tel"
                              format="tel:+1##########" 
                              mask="_" 
                              className="input-styling"
                              required
                              {...field} 
                          />
                      )}
                      name="link"
                      control={control}
                  />
                  <ErrorMessage errors={errors} name="link" render={() => <p className="error-message"><BiErrorCircle /> Please enter a valid phone number. </p>} />
              </>
                    }

                    {(selectedOption === "file") &&
              <div className="upload-section">
                  <label htmlFor="file" className="link-card-label">File Upload:</label>
                  <div {...getRootProps()}>
                      {bigError && !fileName &&
                    <div className="upload-container">
                        <h1>{bigError}</h1>
                    </div>
                      }
                      {fileName && 
                    <div className="upload-container">
                        <h1>{fileName}</h1>
                    </div>
                      }
                      {!isLoading && !fileName && !bigError &&
                    <div className="upload-container">
                        <h1 className="drag-left">Drag </h1><BsPlusCircleFill className="plus-sign" /><h1 className="drag-right"> Drop</h1>
                    </div>
                      }
                      {isLoading && !fileName &&
                    <div className="upload-container">
                        <h1>Uploading...</h1>
                    </div>
                      }
                      <input {...register("file")} {...getInputProps()} />
                  </div>
                  <ErrorMessage errors={errors} name="link" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />
              </div>
                    }

                    <div className="submit-button">
                        <Button type="submit">Submit</Button>
                    </div>
                    {qrUrl && 
            <>
                <label htmlFor="qr" className="link-card-label">QR File:</label>
                <img src={qrUrl} alt="" height="200px" width="200px" />
                <input type="hidden" {...register("qr")} />
            </>
                    }
                </form>
            </FormProvider>
        </div>
    );
}

export default QrForm;