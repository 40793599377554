import {
    Spinner,
    Container,
    Flex
} from "@chakra-ui/react";
import { getDatabase, ref, get, child } from "firebase/database";
import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import "./Banner.css";

function Banner() {
    const { projectId, linkId } = useParams();
    const database = getDatabase();
    const [linkData, setLinkData] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {  
        const dbRef = ref(database);
        async function getLinkData() {
            const lData = await get(child(dbRef, `projects/${projectId}/links/${linkId}`)).catch((error) => console.error(error));
            return lData.val();
        }
        getLinkData().then((data) => {
            setLinkData(data);
        })
        setLoading(false);
    }, []);  


    return (
        <div id="monad-banner-wrapper">
            {!loading &&
                <>
                    <div id="banner-cta-header" className="large-bold-text">{linkData.ctaHeader}</div> 
                    <div id="banner-cta-subheader" className="normal-text">{linkData.ctaSubheader}</div>
                    {linkData.ctaBody1 && 
                        <div id="banner-cta-body-1" className="medium">{linkData.ctaBody1}</div>
                    }
                    {linkData.ctaBody2 &&
                        <div id="banner-cta-body-2" className="large">{linkData.ctaBody2}</div>
                    }
                </>
            }
            {loading && 
                <Container py="8" flex="1" height="100%" width="100%">
                    <Flex justify="center" align="center" height="100%" width="100%">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Flex>
                </Container>
            }
        </div>
    );
}

export default Banner;
