import { 
    MenuItem, 
    AlertDialog, 
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader, 
    AlertDialogBody, 
    AlertDialogFooter, 
    Flex,
    Button,
    Text,
    Heading,
    Link,
    useDisclosure,
    useToast 
} from "@chakra-ui/react";
import { 
    getDatabase, 
    ref,
    update, 
    child, 
    get
} from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link as RRLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function DemoToLive({ projectId, linkKey, linkName }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const cancelRef = React.useRef();
    const [metasiteTokens, setMetasiteTokens] = useState(false);
    const [userCredits, setUserCredits] = useState(false)
    const [isConverting, setIsConverting] = useState(false);
    const { handleSubmit } = useForm();

    useEffect(() => {
        async function getTokens(uid, type) {
            const tokenResult = await get(child(dbRef, `users/${uid}/tokens/${type}`)).catch((error) => console.log(error));
            return tokenResult.val();
        }

        async function getCredits(uid) {
            const creditsResult = await get(child(dbRef, `users/${uid}/credits`)).catch((error) => console.log(error));
            return creditsResult.val();
        }

        getTokens(currentUser.uid, "metasite").then((currentMetasiteTokens) => {
            return setMetasiteTokens(currentMetasiteTokens);
        });

        getCredits(currentUser.uid).then((currentUserCredits) => {
            return setUserCredits(currentUserCredits);
        });
    }, [])

    const onFormSubmit = async () => {
        setIsConverting(true);
        if (metasiteTokens >= 1) {
            const newMetasiteTokens = metasiteTokens - 1;
            await update(ref(database, "/users/" + currentUser.uid + "/tokens/"), {metasite: newMetasiteTokens});
        }

        if (metasiteTokens === 0) {
            const newCredits = userCredits - 500;
            await update(ref(database, "/users/" + currentUser.uid), {credits: newCredits});
        }

        await update(ref(database, "/projects/" + projectId + "/links/" + linkKey), {demo: false});
        await update(ref(database, "/links/" + linkKey), {demo: false});
        setIsConverting(false);

        toast({
            title: "MetaSite Upgraded",
            description: `${linkName} is now live!`,
            // status: "error",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });

        onClose();
    }
    return (
        <>
            <MenuItem colorScheme="red" onClick={onOpen}>
                Make Demo Live
            </MenuItem>
  
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <form onSubmit={handleSubmit(onFormSubmit)} className="switch-project-form">
                            <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                                <Heading size="sm">Make &quot;{linkName}&quot; Live</Heading>
                            </AlertDialogHeader>
  
                            <AlertDialogBody>
                                {!isConverting &&
                                    <>
                                        <Text size="xl">You have {metasiteTokens} MetaSite Tokens & {userCredits} credits.</Text>
                                        {(metasiteTokens >= 1 || userCredits >= 500) &&
                                            <Flex direction="column" justify="center" display="center">
                                                <Text>Click the button below to apply one of your MetaSite Tokens to this MetaSite.</Text>
                                                <Text>Immediately after upgrading you will be able to share the link with anyone.</Text>
                                            </Flex>
                                        }
                                        {(metasiteTokens === 0 || userCredits < 500) &&
                                            <Flex direction="column" justify="center" display="center">
                                                <Text>You do not have enough credits to upgrade a MetaSite. Head over to our Pricing page to buy more credits first.</Text>
                                                <Link as={RRLink} to={"/pricing"}>
                                                    <Button>Go to Pricing</Button>
                                                </Link>
                                            </Flex>
                                        }
                                    </>
                                }

                                {!!isConverting &&
                                    <Flex direction="column" justify="center" display="center">
                                        <Text>Updating MetaSite Data...</Text>
                                    </Flex>
                                }
                            </AlertDialogBody>
  
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                        Cancel
                                </Button>
                                <Button variant="primary" type="submit" ml={3} disabled={metasiteTokens === 0 || userCredits < 500}>
                                    Upgrade MetaSite
                                </Button>
                            </AlertDialogFooter>
                        </form>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

DemoToLive.propTypes = {
    projectId: PropTypes.string, 
    linkKey: PropTypes.string, 
    linkName: PropTypes.string, 
    user: PropTypes.object
}

export default DemoToLive;
