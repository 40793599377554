import { 
    Box,
    Image,
    Text,
    SimpleGrid,
    Heading,
    Badge,
    HStack,
    Button,
    Stack,
    AspectRatio,
    Skeleton,
    useBreakpointValue,
    useColorModeValue
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { DemoContext } from "../../../../context/DemoContext";

function DemoCgTraderSearchItem({ resultData }) {
    const { 
        handleSelectModel, 
        handleSetModelTabIndex 
    } = React.useContext(DemoContext);

    const selectModelClick = async (modelData) => {
        await handleSelectModel(modelData);
        await handleSetModelTabIndex(2);
    }

    return (
        <Stack
            spacing={useBreakpointValue({
                base: "4",
                md: "5",
            })}
            backgroundColor="white"
        >
            <Box position="relative">
                <AspectRatio ratio={4 / 3}>
                    <Image
                        src={resultData.thumbnails[0]} 
                        alt={resultData.title}
                        draggable="false"
                        fallback={<Skeleton />}
                        borderRadius={useBreakpointValue({
                            base: "md",
                            md: "xl",
                        })}
                    />
                </AspectRatio>
            </Box>

            <Stack height="250px">

                <Stack spacing="1" padding="5px">
                    <Heading size="xs" pl={2} pr={2}>
                            #{resultData.id}
                    </Heading>
                    <HStack pl={2} pr={2}>
                        {!resultData.prices.price_with_discount &&
                            <Badge variant="subtle" colorScheme="blue">
                                ${resultData.prices.download}
                            </Badge>
                        }
                        {(resultData.prices.price_with_discount && resultData.prices.download === resultData.prices.price_with_discount) &&
                            <Badge variant="subtle" colorScheme="blue">
                                ${resultData.prices.download}
                            </Badge>
                        }
                        {(resultData.prices.price_with_discount && resultData.prices.download !== resultData.prices.price_with_discount) &&
                            <Badge variant="subtle" colorScheme="blue">
                                <span style={{textDecoration: "line-through"}}>${resultData.prices.download}</span>
                            </Badge>
                        }
                        {(resultData.prices.price_with_discount && resultData.prices.download !== resultData.prices.price_with_discount) &&
                            <Badge variant="subtle" colorScheme="green">
                                    ${resultData.prices.price_with_discount}
                            </Badge>
                        }
                    </HStack>
                    <Text fontWeight="medium" color={useColorModeValue("gray.700", "gray.400")} pl={2} pr={2} pt={2}>
                        {resultData.title}
                    </Text>
                </Stack>

                <HStack padding="5px">
                    <SimpleGrid columns={3} overflow="scroll" padding={2}>
                        {resultData.availableFileExtensions.map((ext) => 
                            <>
                                {ext === "glb" || ext === "gltf" && 
                                        <Badge variant="subtle" colorScheme="green" mt="2" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                            {ext}
                                        </Badge>
                                }
                                {ext !== "glb" && ext !== "gltf" && 
                                        <Badge variant="subtle" colorScheme="red" mt="2" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                            {ext}
                                        </Badge>
                                }
                            </>
                        )}
                    </SimpleGrid>
                </HStack>

            </Stack>


            <Stack align="center">
                <Button isFullWidth colorScheme="blue"  onClick={() => selectModelClick(resultData)}>Select</Button>
            </Stack>

        </Stack>
    );
}

DemoCgTraderSearchItem.propTypes = {
    resultData: PropTypes.object,
}

export default DemoCgTraderSearchItem;
