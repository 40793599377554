// import { formatEther } from "@ethersproject/units";
import {
    Stack,
    Button,
    Icon,
    Heading,
    Text
} from "@chakra-ui/react";
import { 
    useEthers,
    //  useEtherBalance,
} from "@usedapp/core";
import { getDatabase, update, ref } from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaEthereum } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";

function EthLonger({ metamaskId }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const [accountId, setAccountId] = useState(false);

    const {activateBrowserWallet, account, active, deactivate } = useEthers();
    // const etherBalance = useEtherBalance(account);

    useEffect(() => {
        if (window.ethereum) {
            if (account) {
                setAccountId(account)
            }
            window.ethereum.on("accountsChanged", async (accounts) => {
                if (accounts.length > 0) {
                    setAccountId(accounts[0]);
                    const updates = {};
                    updates["/users/" + currentUser.uid + "/metamaskId/"] = accounts[0];  
                    await update(ref(database), updates);
                }
            });
        }
    }, []);

    if (metamaskId === "none" && !active) {
        return (
            <Stack>
                <Heading size="xs">Connect your crypto wallet to this account.</Heading><Button onClick={activateBrowserWallet}>Connect to a wallet <Icon as={FaEthereum} mr={2} boxSize={7} /></Button>
            </Stack>
        )}

    if (!!metamaskId && !active) {
        return (
            <Stack height="100%" justify="center">
                <Text>You have previously connected to the wallet with this public address:</Text>
                <Heading size="h4" mb="2">{metamaskId}</Heading>
                <Text>If you would like to connect with a different wallet, please click the button below.</Text>
                <Button mt="20px !important" width="fit-content" onClick={activateBrowserWallet}>Connect to a wallet <Icon as={FaEthereum} ml={2} boxSize={5}  /></Button>
            </Stack>
        )
    }

    if (!metamaskId && !!accountId) {
        return (
            <Stack height="100%" justify="center" overflow="hidden">
                <Text>You are currently connected through Metamask to the wallet with this public address:</Text>
                <Heading size="h4" mb="2" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{accountId}</Heading>
                <Text>We have saved this to our database and we will associate this address with your account.</Text>
            </Stack>
        )
    }

    if (!!metamaskId && !!active) {
        return (
            <Stack height="100%" justify="center">
                <Text>You have previously connected to the wallet with this public address:</Text>
                <Heading size="h4" mb="2">{metamaskId}</Heading>
                <Text>And are currently connected through Metamask to the wallet with this public address:</Text>
                <Heading size="h4" mb="2">{accountId}</Heading>
                <Text>If you would like to connect a different wallet to this account, first disconnect from this one by clicking this button and then disconnecting in your Metamask extension.</Text>
                <Button mt="20px !important" width="fit-content" onClick={deactivate}>Disconnect this wallet <Icon as={FaEthereum} ml={2} boxSize={5}  /></Button>
            </Stack>
        )
    }

    return <Loading size="medium"/>

}

EthLonger.propTypes = {
    metamaskId: PropTypes.string,
}

export default EthLonger;