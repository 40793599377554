import {
    Flex,
    Avatar,
    Badge,
    Heading,
    Icon,
    Box,
    Stack,
    Container,
    HStack,
    Text,
    Wrap,
    Tag,
    SimpleGrid,
    Divider,
    useMediaQuery
} from "@chakra-ui/react";
import { getDatabase, ref, get, child } from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaRegEnvelope, FaRegCalendarAlt } from "react-icons/fa";
import { AuthContext } from "../../../context/AuthContext";
  
function NewUserCard({ size }) {
    const { currentUser } = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [metasiteTokens, setMetasiteTokens] = useState(0);
    const [conversionTokens, setConversionTokens] = useState(0);
    const [userCredits, setUserCredits] = useState(0);
    const [isLargerThan480] = useMediaQuery("(min-width: 480px)")

    useEffect(() => {
        async function getTokens(uid, type) {
            const tokenResult = await get(child(dbRef, `users/${uid}/tokens/${type}`)).catch((error) => console.log(error));
            return tokenResult.val();
        }

        async function getCredits(uid) {
            const creditsResult = await get(child(dbRef, `users/${uid}/credits`)).catch((error) => console.log(error));
            return creditsResult.val();
        }

        getTokens(currentUser.uid, "metasite").then((currentMetasiteTokens) => {
            return setMetasiteTokens(currentMetasiteTokens);
        });
        getTokens(currentUser.uid, "conversion").then((currentConversionTokens) => {
            return setConversionTokens(currentConversionTokens);
        });
        getCredits(currentUser.uid).then((currentUserCredits) => {
            return setUserCredits(currentUserCredits);
        });
    }, []); 

    if (size === "full") {
        return (
            <Container
            // maxW="md"
                width="100%"
                bg="bg-surface"
                boxShadow="md"
                borderRadius="xl"
                height={{
                    base: "100%",
                    sm: "auto"
                }}
            >
                <SimpleGrid
                    width="100%"
                    columns={{base: 1, sm: 2}}
                    rowGap={{base: 0, sm: 8}}
                >
                    <Box
                        bg="white"
                        mx="auto"
                        p={{
                            base: "6",
                            md: "8",
                        }}
                        maxWidth="100%"
                    >

                        <Stack spacing="8">
                            <Avatar
                                name={currentUser.displayName}
                                src={currentUser.photoURL}
                                size="2xl"
                            />

                            <Stack spacing="6">
                                <Stack spacing="6">
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <Heading size="md" fontWeight="extrabold" letterSpacing="tight" marginEnd="6">
                                            {currentUser.displayName}
                                        </Heading>
                                    </Flex>
                                    <Box>
                                        {currentUser.role === "guest" && 
                                            <Badge variant="subtle" colorScheme="orange">
                                                Guest
                                            </Badge>
                                        }
                                        {currentUser.role === "user" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                User
                                            </Badge>
                                        }
                                        {currentUser.role === "creator" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                Creator
                                            </Badge>
                                        }
                                        {currentUser.role === "agency" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                Agency
                                            </Badge>
                                        }
                                        {currentUser.role === "institution" && 
                                            <Badge variant="subtle" colorScheme="blue">
                                                Institution
                                            </Badge>
                                        }
                                        {currentUser.role === "superadmin" && 
                                            <Badge variant="subtle" colorScheme="red">
                                                Superadmin
                                            </Badge>
                                        }
                                    </Box>
                                    <Stack spacing="1" mt="2">
                                        <HStack fontSize="md">
                                            <Icon as={FaRegEnvelope} color="gray.500"/>
                                            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{currentUser.email}</Text>
                                        </HStack>
                                        <HStack fontSize="md">
                                            <Icon as={FaRegCalendarAlt} color="gray.500"/>
                                            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{currentUser.metadata.creationTime}</Text>
                                        </HStack>
                                    </Stack>
                                </Stack>
                            </Stack>

                        </Stack>
                    </Box>
            

                    <Box
                        bg="white"
                        mx="auto"
                        p={{
                            base: "6",
                            md: "8",
                        }}
                        width="100%">
                        <Stack
                            direction={{
                                base: "column",
                                sm: "row",
                            }}
                            spacing={{
                                base: "4",
                                md: "10",
                            }}
                            width="100%"
                            justify="center"
                        >
                            {!isLargerThan480 &&
                            <Divider color="black" orientation="horizontal" width="100%" />
                            }
                            <Stack direction='row' height="100%" p={4}>
                                {isLargerThan480 &&
                                <Divider height="200px !important" color="black" orientation="vertical" mr={10} />
                                }
                                <Text fontWeight="semibold" mt="8" mb="2">
                                    Credits & Tokens
                                </Text>
                                <Wrap shouldWrapChildren>
                                    <Tag>{userCredits.toLocaleString("en-US")} credits</Tag>
                                    <Tag>{metasiteTokens} MetaSite tokens</Tag>
                                    <Tag>{conversionTokens} conversion tokens</Tag>
                                </Wrap>
                            </Stack>
                        </Stack>
                    </Box>

                </SimpleGrid>
 
            </Container>
        )
    }

    if (size === "small" || !size) {
        return (
            <Container
                maxW="md"
                bg="bg-surface"
                boxShadow="md"
                borderRadius="xl"
                height={{
                    base: "100%",
                    sm: "auto"
                }}
            >
                <Box
                    bg="white"
                    mx="auto"
                    p={{
                        base: "6",
                        md: "8",
                    }}
                    maxWidth="100%"
                >

                    <Stack spacing="8">
                        <Avatar
                            name={currentUser.displayName}
                            src={currentUser.photoURL}
                            size="2xl"
                        />

                        <Stack spacing="6">
                            <Stack spacing="6">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <Heading size="md" fontWeight="extrabold" letterSpacing="tight" marginEnd="6">
                                        {currentUser.displayName}
                                    </Heading>
                                </Flex>
                                <Box>
                                    {currentUser.role === "guest" && 
                                        <Badge variant="subtle" colorScheme="orange">
                                            Guest
                                        </Badge>
                                    }
                                    {currentUser.role === "user" && 
                                        <Badge variant="subtle" colorScheme="blue">
                                            User
                                        </Badge>
                                    }
                                    {currentUser.role === "creator" && 
                                        <Badge variant="subtle" colorScheme="blue">
                                            Creator
                                        </Badge>
                                    }
                                    {currentUser.role === "agency" && 
                                        <Badge variant="subtle" colorScheme="blue">
                                            Agency
                                        </Badge>
                                    }
                                    {currentUser.role === "institution" && 
                                        <Badge variant="subtle" colorScheme="blue">
                                            Institution
                                        </Badge>
                                    }
                                    {currentUser.role === "superadmin" && 
                                        <Badge variant="subtle" colorScheme="red">
                                            Superadmin
                                        </Badge>
                                    }
                                </Box>
                                <Stack spacing="1" mt="2">
                                    <HStack fontSize="md">
                                        <Icon as={FaRegEnvelope} color="gray.500"/>
                                        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{currentUser.email}</Text>
                                    </HStack>
                                    <HStack fontSize="md">
                                        <Icon as={FaRegCalendarAlt} color="gray.500"/>
                                        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{currentUser.metadata.creationTime}</Text>
                                    </HStack>
                                </Stack>

                                {/* <Text fontWeight="semibold" mt="8" mb="2">
                            Credits & Tokens
                                </Text>
                                <Wrap shouldWrapChildren>
                                    <Tag>{userCredits.toLocaleString("en-US")} credits</Tag>
                                    <Tag>{metasiteTokens} MetaSite tokens</Tag>
                                    <Tag>{conversionTokens} conversion tokens</Tag>
                                </Wrap> */}
                            </Stack>
                        </Stack>

                    </Stack>
                </Box>
 
            </Container>
        )
    }
}

export default NewUserCard;

NewUserCard.propTypes = {
    size: PropTypes.string
}
