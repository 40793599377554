import { 
    Container,
    HStack,
    Heading,
    Text,
    Input,
    Stack, 
    Icon, 
    InputGroup,
    Box,
    InputLeftElement
} from "@chakra-ui/react"
import { getDatabase, ref, get, child } from "firebase/database";
import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import FilteredUsers from "./FilteredUsers.js";

function SuperadminPanel() {
    const database = getDatabase();
    const dbRef = ref(database);
    const [searchString, setSearchString] = useState("");
    const [userResults, setUserResults] = useState(false);
    const [userResultsKeys, setUserResultsKeys] = useState(false);
    const [allUsers, setAllUsers] = useState(false);
    const [allUsersKeys, setAllUsersKeys] = useState(false);
    const [searching, setSearching] = useState(false);
    const placeholder = "Enter name or email...";

    useEffect(() => {
        async function getUsers() {
            const usersData = await get(child(dbRef, "users")).catch((error) => console.log(error));
            return usersData.val();
        }
        getUsers().then((usersVal) => {
            setAllUsers(Object.values(usersVal));
            setAllUsersKeys(Object.keys(usersVal));
        })
    }, [])

    const search = async () => {
        setSearching(true);
        const filteredUsers = allUsers.filter(user => {
            return (
                user.displayName.toLowerCase().includes(searchString) ||
          user.email.toLowerCase().includes(searchString)
            );
        });
        let keysArr = [];
        filteredUsers.map((user) => {
            if (allUsersKeys.includes(user.uid)) {
                keysArr.push(user.uid);
            }
        })
        if (filteredUsers) {
            setUserResults(filteredUsers);
            setUserResultsKeys(keysArr);
        }
        setSearching(false);
    }

    return (
        <Container py="8" flex="1">
            <Stack spacing={3} p={3} width="100%">

                <Stack
                    spacing="4"
                    direction={{
                        base: "column",
                        lg: "row",
                    }}
                    justify="space-between"
                    align={{
                        base: "start",
                        lg: "center",
                    }}
                >
                    <Stack spacing="1">
                        <Heading
                            size="sm"
                            fontWeight="medium"
                        >
                                Admin Panel
                        </Heading>
                        <Text color="muted">Search by User to view & edit their MetaSites</Text>
                    </Stack>
                    <HStack spacing="3" width={[300, 350]}>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <Icon as={FiSearch} boxSize="5" />
                            </InputLeftElement>
                            <Input placeholder={placeholder} onChange={(event) => setSearchString(event.target.value)} onKeyUp={(e) => {
                                if (e.code === "Enter") {
                                    search()
                                }
                            }
                            } />
                        </InputGroup>
                    </HStack>
                </Stack>
            </Stack>
            <Box mt={5}>
                {(!!userResultsKeys && !searching) &&
                    userResults.map((user, idx) => 
                        <FilteredUsers userKey={user.uid} userData={user} key={idx} />
                    )}
            </Box>
        </Container>
    );
}

export default SuperadminPanel;
