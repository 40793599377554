import {
    Button,
    Center,
    HStack,
    Icon,
    Square,
    Text,
    VStack,
    Flex
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone"
import { useFormState, useFormContext } from "react-hook-form";
import { BiErrorCircle } from "react-icons/bi";
import { FiUploadCloud } from "react-icons/fi";
import "./DemoAssetUploader.css";

function DemoAssetUploader({ newLinkKey, required, inputName }) {
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(false);
    const [bigError, setBigError] = useState(false);
    const fileTypeDictionary = {
        glb: ".glb or .gltf",
        usdz: ".usdz or .reality",
        img: ".jpg, .png, or .gif",
    };
    const storage = getStorage();
    const { register, setValue, control } = useFormContext();
    const { errors } = useFormState({
        control
    });
    let requiredObject = { required: "This is required."};
    if (required === false) {
        requiredObject = "";
    }

    let acceptedInputs = "img";
    if (inputName === "glb") {
        acceptedInputs = "glb";
    }
    if (inputName === "usdz") {
        acceptedInputs = "usdz";
    }

    useEffect(() => {
        register(inputName);
    }, [isLoading]);  
  
    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles?.[0];
        const storageRef = ref(storage, `monadDemoAssets/${newLinkKey}/${inputName}/${file.name}`);
        const fileTypeArr = file.name.split(".");
        const fileType = fileTypeArr[1];

        if (!file) {
            return
        }
  
        try {

            if ((acceptedInputs === "glb" && fileType === "glb") || (acceptedInputs === "glb" && fileType === "GLB") || (acceptedInputs === "glb" && fileType === "gltf") || (acceptedInputs === "glb" && fileType === "GLTF") || (acceptedInputs === "usdz" && fileType === "usdz") || (acceptedInputs === "usdz" && fileType === "USDZ") || (acceptedInputs === "usdz" && fileType === "reality") || (acceptedInputs === "usdz" && fileType === "REALITY")) {
                setBigError(false);
                setIsLoading(true);
                await uploadBytes(storageRef, file).then(() => {
                    setValue(inputName, file.name);
                    setIsLoading(false);
                    setFileName(file.name);
                }).catch((err) => console.log("upload error", err));
            }

            if (acceptedInputs === "img" || acceptedInputs === "qr") {
                if (fileType === "png" || fileType === "jpg" || fileType === "jpeg" || fileType === "gif" || fileType === "PNG" || fileType === "JPG" || fileType === "JPEG" || fileType === "GIF") {
                    setBigError(false);
                    setIsLoading(true);
                    await uploadBytes(storageRef, file).then(() => {
                        setValue(inputName, file.name);
                        setIsLoading(false);
                        setFileName(file.name);
                    });
                }
                else {
                    setIsLoading(false);
                    setBigError("Please upload an image file");
                }
            }
            else {
                setIsLoading(false);
                setBigError(`Please upload a ${fileTypeDictionary[acceptedInputs]} file`);
            }
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setBigError("Unable to upload file. Please try again.");
        }
    }, [])


    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <Flex direction="column" mb={5} width="100%">
            <div {...getRootProps()}>
                {bigError && !fileName &&
                    <Center
                        borderWidth="1px"
                        borderRadius="lg"
                        px="6"
                        py="4"
                        bg="white"
                    >
                        <VStack spacing="3">
                            <Square size="10" bg="bg-subtle" borderRadius="lg">
                                <Icon as={BiErrorCircle} color="red.500" boxSize="8" />
                            </Square>
                            <VStack spacing="1">
                                <Text fontSize="sm" color="muted">
                                    {bigError}
                                </Text>
                                <Text fontSize="sm" color="muted" />
                            </VStack>
                        </VStack>
                    </Center>
                }
                {fileName && 
                    <Center
                        borderWidth="1px"
                        borderRadius="lg"
                        px="6"
                        py="4"
                        bg="white"
                    >
                        <VStack spacing="3">
                            <Square size="10" bg="bg-subtle" borderRadius="lg">
                                <Icon as={FiUploadCloud} boxSize="8" color="muted" />
                            </Square>
                            <VStack spacing="1">
                                <Text fontSize="sm" color="muted">
                                    {fileName}
                                </Text>
                                <Text fontSize="xs" color="muted">
                                    successfully uploaded
                                </Text>
                            </VStack>
                        </VStack>
                    </Center>
                }
                {!isLoading && !fileName && !bigError &&
                    <Center
                        borderWidth="1px"
                        borderRadius="lg"
                        px="6"
                        py="4"
                        bg="white"
                    >
                        <VStack spacing="3">
                            <Square size="10" borderRadius="lg">
                                <Icon as={FiUploadCloud} boxSize="8" color="blue.500" />
                            </Square>
                            <VStack spacing="1">
                                <HStack spacing="1" whiteSpace="nowrap">
                                    <Button variant="link" colorScheme="blue" size="sm">
                                        Click to upload
                                    </Button>
                                    <Text fontSize="sm" color="muted">
                                        or drag and drop
                                    </Text>
                                </HStack>
                                <Text fontSize="xs" color="muted">
                                    {fileTypeDictionary[inputName]}
                                </Text>
                            </VStack>
                        </VStack>
                    </Center>
                }
                {isLoading && !fileName &&
                    <Center
                        borderWidth="1px"
                        borderRadius="lg"
                        px="6"
                        py="4"
                        bg="white"
                    >
                        <VStack spacing="3">
                            <Square size="10" bg="bg-subtle" borderRadius="lg">
                                <Icon as={FiUploadCloud} boxSize="8" color="muted" />
                            </Square>
                            <VStack spacing="1">
                                <Text fontSize="sm" color="muted">
                                    Uploading...
                                </Text>
                                <Text fontSize="xs" color="muted">
                                    ......
                                </Text>
                            </VStack>
                        </VStack>
                    </Center>
                }
                {required &&
                    <input {...register(inputName, requiredObject)}  {...getInputProps()} />
                }
                {!required &&
                    <input {...register(inputName)}  {...getInputProps()} />
                }
            </div>
            <ErrorMessage errors={errors} name={inputName} render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>} />
        </Flex>
    );
}

DemoAssetUploader.propTypes = {
    newLinkKey: PropTypes.string, 
    required: PropTypes.bool, 
    inputName: PropTypes.string
}

export default DemoAssetUploader;
