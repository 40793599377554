import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TwoCardsLayout from "./TwoCardsLayout.js";
import { PaymentContext } from "../../../context/PaymentContext";

function TwoCardsLayoutWrapper() {
    const navigate = useNavigate();
    const [paymentSchedule, setPaymentSchedule] = useState("yearly");
    const [selectedPlan, setSelectedPlan] = useState("");

    useEffect(() => {
        if (selectedPlan === "user") {
            navigate("/stripe/user", { state: { paymentSchedule: "yearly" } })
        }
        if (selectedPlan === "creator5") {
            navigate("/stripe/creator5", { state: { paymentSchedule: "monthly" } })
        }
        if (selectedPlan === "creator10") {
            navigate("/stripe/creator10", { state: { paymentSchedule: "monthly" } })
        }
        if (selectedPlan === "creator20") {
            navigate("/stripe/creator20", { state: { paymentSchedule: "yearly" } })
        }
        if (selectedPlan === "agency200") {
            navigate("/stripe/agency200", { state: { paymentSchedule: "monthly"  } })
        }
        if (selectedPlan === "agency500") {
            navigate("/stripe/agency500", { state: { paymentSchedule: "monthly"  } })
        }
        if (selectedPlan === "institution") {
            navigate("/stripe/institution", { state: { paymentSchedule: "monthly" } })
        }
    }, [selectedPlan])

    const handleSetPaymentSchedule = (data) => {
        setPaymentSchedule(data);
    }

    const handleSetSelectedPlan = (data) => {
        setSelectedPlan(data);
    }

    const props = {
        paymentSchedule,
        selectedPlan,
        handleSetPaymentSchedule,
        handleSetSelectedPlan
    }

    return (
        <PaymentContext.Provider value={props}>
            <TwoCardsLayout />
        </PaymentContext.Provider>
    );
}

export default TwoCardsLayoutWrapper;
