
import {  
    Box,
    Button,
    Link,
    Badge,
    Avatar,
    HStack,
    Stack,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useToast,
    useMediaQuery
} from "@chakra-ui/react";
import { getDatabase, ref, get, child, update } from "firebase/database";
import moment from "moment/min/moment-with-locales";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { 
    FaLink
} from "react-icons/fa";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { 
    Navigate,
    Link as RRLink
} from "react-router-dom";
import ChangeProject from "./ChangeProject.js";
import DemoToLive from "./DemoToLive.js";
import LinkCardModal from "./LinkCardModal.js";
import DeleteLinkDialog from "./ProjectLinks/Modals/DeleteLinkDialog.js";
import { AuthContext } from "../../context/AuthContext";
import GlobalLinksAnalytics from "../Analytics/GlobalLinksAnalytics";
import Loading from "../Loading/Loading.js";

function LinkCard({ linkKey, linkData, projectId, user, locationLinkIdProp, openModalProp }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const toast = useToast();
    const [isLargerThan500] = useMediaQuery("(min-width: 500px)")
    const [assetsData, setAssetsData] = useState({screenshot: "https://ldp.studio/website-ar/LDPlogo.png", img: "https://ldp.studio/website-ar/LDPlogo.png"});

    useEffect(() => {
        async function getAssetsData() {
            const aData = await get(child(dbRef, `assets_data/${projectId}/${linkKey}`)).catch((error) => console.log(error));
            return aData.val();
        }
        getAssetsData().then((assetsData) => {
            if (assetsData) {
                const assetsDataKeys = Object.keys(assetsData);
                if ((assetsDataKeys.includes("glb") && assetsDataKeys.includes("usdz")) || linkData.glbOnlyFlag) {
                    return setAssetsData(assetsData);
                }
                else {
                    return setAssetsData("error");
                }
            }
        });
    }, []);  
    

    const archiveLink = async(projectId, linkKey) => {
        const updates = {};
        updates["/links/" + linkKey + "/archived/"] = true;  // write to links
        updates["/projects/" + projectId + "/links/" + linkKey + "/archived/"] = true;  // write to projects/links
        updates["/users/" + currentUser.uid + "/projects/" + projectId + "/links/" + linkKey + "/archived/"] = true;  // write to users/projects/links
        await update(ref(database), updates);
        toast({
            title: "MetaSite Archived",
            description: `"${linkData.title}" has been archived.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
    }

    const restoreLink = async(projectId, linkKey) => {
        const updates = {};
        updates["/links/" + linkKey + "/archived/"] = false;  // write to links
        updates["/projects/" + projectId + "/links/" + linkKey + "/archived/"] = false;  // write to projects/links
        updates["/users/" + currentUser.uid + "/projects/" + projectId + "/links/" + linkKey + "/archived/"] = false;  // write to users/projects/links
        await update(ref(database), updates);
        toast({
            title: "Project Restored",
            description: `"${linkData.title}" has been restored.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
    }

    if (!assetsData) {
        return (
            <Loading size="small" />
        )
    }

    if (assetsData === "error") {
        const linkId = linkKey;
        return <Navigate to={`/error/projects/${projectId}/links/${linkId}`} />
    }
    
    if (assetsData)
    {
        return (
            <Box
                as="section"
                pb={{
                    base: "3",
                    md: "4",
                }}
            >
                <Box
                    bg="bg-surface"
                    px={{
                        base: "4",
                        md: "6",
                    }}
                    py="5"
                    boxShadow="sm"
                    borderRadius="lg"
                >
                    <Stack
                        spacing="4"
                        direction={{
                            base: "column",
                            sm: "row",
                        }}
                        justify="space-between"
                    >
                        <HStack spacing="4">
                            {assetsData.img &&
                                <Avatar
                                    src={assetsData.img.link}
                                    name={linkData.title}
                                    boxSize={{
                                        base: "12",
                                        sm: "14",
                                    }}
                                />
                            }
                            {!assetsData.img && assetsData.screenshot &&
                                <Avatar
                                    src={assetsData.screenshot.link}
                                    name={linkData.title}
                                    boxSize={{
                                        base: "12",
                                        sm: "14",
                                    }}
                                />
                            }
                            {!assetsData.img && !assetsData.screenshot &&
                                <Avatar
                                    name={linkData.title}
                                    boxSize={{
                                        base: "12",
                                        sm: "14",
                                    }}
                                />
                            }
                            <Box>
                                <HStack>
                                    <Text fontSize="lg" fontWeight="medium">
                                        {linkData.title}
                                    </Text>
                                    {(linkData.archived !== true && linkData.demo !== true) &&
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Live
                                                </Badge>
                                    }
                                    {linkData.archived === true &&
                                                <Badge variant="subtle" colorScheme="red">
                                                    Archived
                                                </Badge>
                                    }
                                    {linkData.demo === true &&
                                                <Badge variant="subtle" colorScheme="red">
                                                    Demo
                                                </Badge>
                                    }
                                </HStack>
                                <Text color="muted" fontSize="sm">
                                    {moment(linkData.createdAt).format("MM/D/YY")}
                                </Text>
                            </Box>
                        </HStack>
                        <Stack direction="row" spacing="3">
                            <Link as={RRLink} to={`/monad/${projectId}/${linkKey}`}><Button variant="secondary" ><FaLink /></Button></Link>
                            <GlobalLinksAnalytics linkId={linkKey} projectId={projectId} title={linkData.title} />
                            <LinkCardModal linkData={linkData} linkKey={linkKey} projectId={projectId} assetsData={assetsData} openModalProp={openModalProp} locationLinkIdProp={locationLinkIdProp} />
                            {isLargerThan500 &&
                            <Menu>
                                {({ isOpen }) => (
                                    <>
                                        <MenuButton isActive={isOpen} as={Button} variant="primary" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                            {isOpen ? "Close" : "Actions"}
                                        </MenuButton>
                                        {linkData.archived !== true && 
                                            <MenuList>
                                                {assetsData &&
                                                    <ChangeProject projectId={projectId} linkKey={linkKey} linkName={linkData.title} linkData={linkData} assetsData={assetsData} user={user} />
                                                }
                                                {linkData.demo &&
                                                    <DemoToLive projectId={projectId} linkKey={linkKey} linkName={linkData.title} />
                                                }
                                                <MenuItem onClick={() => archiveLink(projectId, linkKey)}>Archive</MenuItem>
                                            </MenuList>
                                        }
                                        {linkData.archived === true && 
                                            <MenuList>
                                                <MenuItem onClick={() => restoreLink(projectId, linkKey)}>Restore Link</MenuItem>
                                                <DeleteLinkDialog projectId={projectId} linkKey={linkKey} linkName={linkData.title} />
                                            </MenuList>
                                        }
                                    </>
                                )}
                            </Menu>
                            }
                        </Stack>
                        {!isLargerThan500 &&
                            <Stack>
                                <Menu>
                                    {({ isOpen }) => (
                                        <>
                                            <MenuButton isActive={isOpen} as={Button} variant="primary" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                                {isOpen ? "Close" : "Actions"}
                                            </MenuButton>
                                            {linkData.archived !== true && 
                                                <MenuList>
                                                    {assetsData &&
                                                    <ChangeProject projectId={projectId} linkKey={linkKey} linkName={linkData.title} linkData={linkData} assetsData={assetsData} user={user} />
                                                    }
                                                    <MenuItem onClick={() => archiveLink(projectId, linkKey)}>Archive</MenuItem>
                                                </MenuList>
                                            }
                                            {linkData.archived === true && 
                                                <MenuList>
                                                    <MenuItem onClick={() => restoreLink(projectId, linkKey)}>Restore Link</MenuItem>
                                                    <DeleteLinkDialog projectId={projectId} linkKey={linkKey} linkName={linkData.title} />
                                                </MenuList>
                                            }
                                        </>
                                    )}
                                </Menu>
                            </Stack>
                        }
                    </Stack>
                </Box>
            </Box>
        )
    }
    return null;
}

LinkCard.propTypes = {
    linkData: PropTypes.object, 
    linkKey: PropTypes.string, 
    projectId: PropTypes.string,
    user: PropTypes.object,
    openModalProp: PropTypes.bool,
    locationLinkIdProp: PropTypes.string
}
                                    

export default LinkCard;