import {
    Flex,
    Box,
    useBreakpointValue,
} from "@chakra-ui/react"
import { getDatabase, ref, get, child } from "firebase/database";
import moment from "moment/min/moment-with-locales";
import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import SidebarNew from "./SidebarNew.js";
import { AuthContext } from "../../context/AuthContext";
import { SidebarContext } from "../../context/SidebarContext";
import "./AuthenticatedApp.css";

function AuthenticatedApp() {
    const { currentUser } = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const smVariant = { navigation: "drawer", navigationButton: true }
    const mdVariant = { navigation: "sidebar", navigationButton: false }
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    const [userPromo, setUserPromo] = useState("");
    const [userPromoLine1, setUserPromoLine1] = useState("");
    const [userPromoLine2, setUserPromoLine2] = useState("");
    const [userPromoCta, setUserPromoCta] = useState("");
    const [userPromoLink, setUserPromoLink] = useState("");
    const variants = useBreakpointValue({ base: smVariant, xl: mdVariant })
    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

    useEffect(() => {
        async function getNextPaymentAttempt() {
            const subscriptions = await get(child(dbRef, `stripe_customers/${currentUser.uid}/subscriptions`)).catch((error) => console.log(error));
            return subscriptions.val();
        }
        
        if (currentUser.role === "guest") {
            setUserPromoLine1("Almost there!");
            setUserPromoLine2("Purchase credits to take your MetaSites live.")
            setUserPromoCta("Purchase Credits");
            setUserPromoLink("/pricing");
        }
        if (currentUser.role !== "guest") {
            getNextPaymentAttempt().then((npa) => {
                if (npa) {
                    const formattedNextPayment = moment.unix(npa).format("MM/DD/YY");
                    setUserPromoLine1("Payment scheduled");
                    setUserPromoLine2(`Your next payment will occur on ${formattedNextPayment}`);
                    setUserPromoCta("View Account");
                    setUserPromoLink("/account");
                }
            })
        }
    }, [currentUser.role, currentUser.uid, dbRef])

    const handleSetUserPromo = (data) => {
        setUserPromo(data);
    }

    const props = {
        userPromo,
        userPromoLine1,
        userPromoLine2,
        userPromoCta,
        userPromoLink,
        handleSetUserPromo
    }

    return (
        <Flex
            as="section"
            direction={{
                base: "column",
                lg: "row",
            }}
            height="100vh"
            bg="bg-canvas"
            overflowY="auto"
        >
            <SidebarContext.Provider value={props}>
                <SidebarNew
                    variant={variants?.navigation}
                    isOpen={isSidebarOpen}
                    onClose={toggleSidebar}
                />
            </SidebarContext.Provider>
            <Box ml={!variants?.navigationButton && 275} width="100%">
                <Header
                    showSidebarButton={variants?.navigationButton}
                    onShowSidebar={toggleSidebar}
                />
            </Box>
        </Flex>
    );
}

export default AuthenticatedApp;
