import { 
    Button, 
    Flex, 
    Heading, 
    Icon, 
    Link, 
    Text, 
    HStack,
    Box, 
    Stack,
    useColorModeValue as mode 
} from "@chakra-ui/react"
import { 
    getDatabase, 
    ref, 
    get, 
    child 
} from "firebase/database";
import capitalize from "lodash-es/capitalize";
import React, { useState, useEffect } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { Link as RRLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import horseheaddance from "../../assets/horseheaddance.gif";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";

export default function Success() {
    const database = getDatabase();
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(false);
    const { uid, paymentId, purchaseType } = useParams();
    const {currentUser} = React.useContext(AuthContext);
    const nameArr = currentUser.displayName.split(" ");
    const firstName = nameArr[0];

    useEffect(() => {
        const dbRef = ref(database);
        async function checkForPaymentSuccess() {
            const statusMessage = await fetch("/api/paymentSuccess", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ uid, paymentId }),
            }).then((res) => res.json()).then((data) => {
                return data;
            })
            return statusMessage
        }

        async function getPayment() {
            const payment = await get(child(dbRef, `stripe_customers/${uid}/payments/${paymentId}`)).catch((error) => console.log(error));
            return payment.val();
        }

        return checkForPaymentSuccess().then(async(paymentStatus) => {
            setStatus(paymentStatus.status);
            await getPayment().then(async(paymentVal) => {
                console.log("paymentVal", paymentVal);
                setLoading(false);
            });
        });
    }, []);

    if (!!loading && !status) {
        return (
            <Loading size="medium" />
        );
    }

    if (!loading && !status) {
        return (
            <Flex height="100%" width="100%" align="center" justify="center">
                <Heading size="xs">Waiting on status...</Heading>
            </Flex>
        );
    }

    if (!loading & status === "succeeded") {
        return (
            <Box
                as="section"
                bg={mode("gray.50", "gray.800")}
                pb="24"
                pos="relative"
                px={{
                    base: "6",
                    lg: "12",
                }}
                height={{       
                    base: "fit-content",
                    lg: "100vh"
                }}
            >   

                {/* left side */}
                <Box 
                    maxW="7xl" 
                    mx="auto" 
                    height={{       
                        base: "fit-content",
                        lg: "100%"
                    }}
                >  

                    {/* text and buttons */}
                    <Box
                        maxW={{
                            lg: "md",
                            xl: "xl",
                        }}
                        pt={{
                            base: "20",
                            lg: "80",
                        }}
                        pb={{
                            base: "16",
                            lg: "24",
                        }}
                    >
                        <Heading as="h1" size="3xl" lineHeight="1" fontWeight="extrabold" letterSpacing="tight">
                            <Box mb="6px">
                                    You&apos;re all set,
                            </Box>
                            <HStack>
                                <Box as="mark" color={mode("blue.500", "blue.300")} bg="transparent">
                                    {firstName}
                                </Box>
                                <Box mt="6px">
                                    !
                                </Box>
                            </HStack>
                        </Heading>
                        <Text color={mode("gray.600", "gray.400")} mt="5" fontSize="xl">
                            Your subscription has begun and your account has been upgraded to <Box as="mark" color={mode("blue.500", "blue.300")} bg="transparent" fontWeight="600">{capitalize(purchaseType)}.</Box>
                        </Text>
                        <Text color={mode("gray.600", "gray.400")} mt="5" fontSize="xl">
                            Return to the Dashboard now to get started seeing what you can do with your new abilities. We can&apos;t wait to experience what you make!
                        </Text>
                        <Stack
                            direction={{
                                base: "column",
                                sm: "row",
                            }}
                            spacing="4"
                            mt="8"
                        >
                            <Link as={RRLink} to="/dashboard" _hover={{textDecoration: "none"}}>
                                <Button size="lg" colorScheme="blue" fontSize="md">
                                    Return to Dashboard <Icon as={AiOutlineHome} ml={3} boxSize={5} />
                                </Button>
                            </Link>
                        </Stack>
                    </Box>

                </Box>
            
                {/* right side */}
                <Box
                    pos={{
                        lg: "absolute",
                    }}
                    insetY={{
                        lg: "0",
                    }}
                    insetEnd={{
                        lg: "0",
                    }}
                    bg="gray.50"
                    w={{
                        base: "full",
                        lg: "50%",
                    }}
                    height={{
                        base: "96",
                        lg: "full",
                    }}
                    sx={{
                        clipPath: {
                            lg: "polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%)",
                        },
                    }}
                >
                    <Box w="100%" h="100%" style={{ background: `linear-gradient(to top, #3182CE, #0000), url("${horseheaddance}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        {/* <Image src={horseheaddance} height="100%" width="100%" /> */}
                    </Box>
                </Box>
            </Box>
        );
    }
    return null
}
