import "@google/model-viewer";
import { 
    Accordion,
    useToast
} from "@chakra-ui/react";
import { 
    getDatabase, 
    update,
    ref, 
    get, 
    child
} from "firebase/database";
import moment from "moment/min/moment-with-locales";
import React, { useState, useEffect } from "react";
import { 
    useForm, 
    FormProvider, 
} from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { DemoContext } from "../../../context/DemoContext";
import { currentConfig } from "../../App/App.js";
import Form from "../../Demo/components/DemoSections/Form.js"
import Model from "../../Demo/components/DemoSections/Model.js";
import Orientation from "../../Demo/components/DemoSections/Orientation.js";
import Loading from "../../Loading/Loading.js";
import "./DemoAuthenticated.css";

function DemoAuthenticated() {
    const {currentUser} = React.useContext(AuthContext);
    const {
        demoPath, 
        demoKey, 
        currentStage, 
        modelTabIndex,
        // qrUrl,
        purchaseConfirmed, 
        handleSetCurrentStage,
        handleSetQrUrl,
        transferDemoAssets
    } = React.useContext(DemoContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const { 
        register, 
        setValue, 
        setError, 
        clearErrors, 
        reset, 
        handleSubmit, 
        control 
    } = useForm();

    useEffect(() => {
        setLoading(true);

        setValue("ctaBody1", "");
        setValue("ctaBody2", "");
        setValue("description1", "");
        setValue("description2", "");
        setValue("richText", "");
        setValue("richTextDesktop", "");
        let size = 400;
        let bgColor = "FFFFFF";
        let urlName = `${currentConfig.hostedUrl}/monad/${currentUser.uid}/${demoKey}`;
        let qrVal = `https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`;
        handleSetQrUrl(qrVal);
        setValue("qr", `https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`);

        setLoading(false);

    }, [demoKey, demoPath, currentStage])

    useEffect(() => {
        if (currentStage === "model" && loading === false) {
            const modelDom = document.getElementById("model");
            modelDom.click()
        }
        if (currentStage === "form" && loading === false) {
            const formDom = document.getElementById("form");
            formDom.click();
        }
    }, [currentStage])


    useEffect(() => {
        if (purchaseConfirmed) {
            handleSetCurrentStage("form");
        }
    }, [purchaseConfirmed])

    const onFormSubmit = async(data) => {
        if (currentStage !== "model" && modelTabIndex !== 1) {
            const { 
                title, 
                ctaLinkPath, 
                ctaHeader, 
                ctaSubheader, 
                ctaBody1, 
                ctaBody2, 
                description1, 
                description2, 
                richText,
                richTextDesktop, 
                glb, 
                usdz, 
                img, 
                qr, 
                hdr, 
                includeHdr, 
                includeCustomImage,
                rotation,
                zoom,
                autoplay 
            } = data;

            const timestamp = moment().valueOf();
            const formattedDate = moment().format("MMMM Do YYYY, h:mm:ss a");
            const ctaHeaderLowercase = ctaHeader.toLowerCase();
            const titleLowercase = title.toLowerCase();

            let hdrInformation;
            if (typeof hdr === "undefined" || includeHdr === false) {
                hdrInformation = includeHdr;
            }
            else {
                hdrInformation = hdr;
            }

            let imgValue;
            let statusValue = "unattempted";
            if (typeof img === "undefined" || includeCustomImage === false) {
                imgValue = false;
                statusValue = "processing";
            }
            else {
                imgValue = img;
            }

            const linkData = {
                title,
                titleLowercase,
                ctaLinkPath,
                ctaHeader,
                ctaHeaderLowercase,
                ctaSubheader,
                ctaBody1,
                ctaBody2,
                description1,
                description2,
                richText,
                richTextDesktop,
                rotation,
                zoom,
                autoplay,
                assets: {
                    glb,
                    usdz,
                    img: imgValue,
                    qr,
                    hdr: hdrInformation,
                },
                createdAt: timestamp,
                archived: false,
                formattedDate,
                screenshot: statusValue,
                demo: true,
                glbOnlyFlag: true,
                uid: currentUser.uid,
                projectId: currentUser.uid,
                displayName: currentUser.displayName
            };

            await update(ref(database, "/demo_sessions/" + demoKey + "/link_data/"), linkData).catch((err) => console.log("/demo_sessions err", err));

        
            await update(ref(database, "/links/" + demoKey), linkData).catch((err) => console.log("/links err", err));
            await update(ref(database, "/projects/" + currentUser.uid + "/links/" + demoKey), linkData).catch((err) => console.log("/projects err", err));
            await update(ref(database, "/users/" + currentUser.uid + "/projects/" + currentUser.uid + "/links/" + demoKey), { createdAt: moment().valueOf() }).catch((err) => console.log("/users/projects err", err));
            await update(ref(database, "/users/" + currentUser.uid + "/demos/" + demoKey), { createdAt: moment().valueOf() }).catch((err) => console.log("/users/projects err", err));
            
            if (demoPath === "example") {
                const demoAssetsData = await get(child(dbRef, `/assets_data_demo/${demoKey}`)).catch((error) => console.error(error));
                const demoAssetsDataVal = demoAssetsData.val();
                await update(ref(database, `/assets_data/${currentUser.uid}/${demoKey}`), demoAssetsDataVal).catch((err) => console.log("uploading assets_data_demo to normal assets_data err", err));
            }

            await transferDemoAssets(linkData, currentUser);

            toast({
                title: "MetaSite Created",
                description: `"${linkData.title}" has been created.`,
                status: "success",
                variant: "subtle",
                duration: 9000,
                isClosable: true,
            });
        }   
    }

    if (!demoPath || !demoKey || !currentStage || loading) {
        return <Loading size="fullscreen" />
    }
    
    if (demoPath && demoKey && currentStage) {
        return (
            <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} reset={reset} control={control}>
                <form onSubmit={handleSubmit(onFormSubmit)}> 
                    <Accordion defaultIndex={0}>
                        <Orientation />
                                
                        <div id="model">
                            <Model authenticated={true} />
                        </div>

                        <div id="form">
                            <Form authenticated={true} />
                        </div>

                    </Accordion>
                    <input type="hidden" {...register("rotation")} />
                    <input type="hidden" {...register("zoom")} />
                    <input type="hidden" {...register("autoplay")} />
                    <input type="hidden" {...register("ctaBody1")} />
                    <input type="hidden" {...register("ctaBody2")} />
                    <input type="hidden" {...register("description1")} />
                    <input type="hidden" {...register("description2")} />
                    <input type="hidden" {...register("richText")} />
                    <input type="hidden" {...register("richTextDesktop")} />
                    <input type="hidden" {...register("qr")} />
                </form>
            </FormProvider>                  
        );
    }
}

export default DemoAuthenticated;
