import { 
    Box, 
    Image, 
    Flex,
    Button,   
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Link,
    Badge,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormLabel,
    FormControl,
    Input,
    Textarea,
    Container,
    Stack,
    StackDivider,
    SimpleGrid,
    Heading,
    useToast,
    useMediaQuery
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { getDatabase, ref, update, child, get } from "firebase/database";
import sample from "lodash-es/sample";
import moment from "moment/min/moment-with-locales";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider, useFormState } from "react-hook-form";
import { BiErrorCircle } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { Link as RRLink } from "react-router-dom";
import DeleteProjectDialog from "./DeleteProjectDialog.js";
import { AuthContext } from "../../context/AuthContext";

function ProjectCard({projectKey, projectData}) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [isLargerThan400] = useMediaQuery("(min-width: 400px)");
    const [currentProjectData, setCurrentProjectData] = useState("empty");
    const [currentProjectImage, setCurrentProjectImage] = useState("https://ldp.studio/website-ar/LDPlogo.png");
    // const [promoStatus, setPromoStatus] = useState(false);
    // const [promoData, setPromoData] = useState(false);
    // const [defaultPromoLaunchDate, setDefaultPromoLaunchDate] = useState(new Date());
    // const [defaultWhitelistDate, setDefaultWhitelistDate] = useState(new Date());
    // const [defaultMintDate, setDefaultMintDate] = useState(new Date());
    const { 
        isOpen, 
        onOpen, 
        onClose 
    } = useDisclosure();
    const toast = useToast();

    useEffect(() => {
        setCurrentProjectData(projectData);
        // if (projectData.promoPage) {
        //     setPromoStatus(true);
        // }

        async function getChosenAssetsData(chosenKey) {
            const chosenAssets = await get(child(dbRef, `assets_data/${projectKey}/${chosenKey}`)).catch((error) => console.log(error));
            return chosenAssets.val();
        }
        // async function getPromoData() {
        //     const promoData = await get(child(dbRef, `promo/${projectKey}`)).catch((error) => console.log(error));
        //     return promoData.val();
        // }

        let chosenKey;
        if (projectData.links) {
            const linksKeys = Object.keys(projectData.links);
            chosenKey = sample(linksKeys);
            const assetsDataResult = getChosenAssetsData(chosenKey);
            assetsDataResult.then(function(assetsData) {
                // if (assetsData) {
                const assetsDataKeys = Object.keys(assetsData);
                if (assetsDataKeys.includes("img")) {
                    return setCurrentProjectImage(assetsData.img.link);
                }
                else {
                    return setCurrentProjectImage(assetsData.screenshot.link);
                }
                // }
                // if (!assetsData) {
                //     setCurrentProjectImage("https://ldp-studio-int.firebaseapp.com/static/media/ldp-logo-footer.d92c261f.png");
                // }
            });
        }

        // const promoValResponse = getPromoData();
        // promoValResponse.then((promoVal) => {
        //     if (promoVal) {
        //         setPromoData(promoVal);
        //         if (promoVal.promoLaunchDate) {
        //             setDefaultPromoLaunchDate(new Date(Date.parse(promoVal.promoLaunchDate)))
        //         }
        //         if (promoVal.whitelistDate) {
        //             setDefaultWhitelistDate(new Date(Date.parse(promoVal.whitelistDate)))
        //         }
        //         if (promoVal.mintDate) {
        //             setDefaultMintDate(new Date(Date.parse(promoVal.mintDate)))
        //         }
        //     }
        //     else {
        //         setPromoData("empty");
        //     }
        // })
    }, []);  

    // useEffect(() => {
    //     if (promoData !== "empty") {
    //         reset({
    //             projectName: projectData.projectName,
    //             projectDescription: projectData.projectDescription,
    //             includePromoPage: projectData.promoPage,
    //             includeBannerImage: promoData.includeBannerImage,
    //             bannerImg: promoData.bannerImg,
    //             tagline: promoData.tagline,
    //             longDescription: promoData.longDescription,
    //             mintPrice: promoData.mintPrice,
    //             whitelistPrice: promoData.whitelistPrice,
    //             whitelistCount: promoData.whitelistCount,
    //             // whitelistDate: promoData.whitelistDate,
    //             // promoLaunchDate: promoData.promoLaunchDate,
    //             // mintDate: promoData.mintDate,
    //             nftCount: promoData.nftCount,
    //             slogan: promoData.slogan,
    //             numberOfRoadmapItems: promoData.numberOfRoadmapItems,
    //             roadmapItem: promoData.roadmapItem,
    //             numberOfTeamItems: promoData.numberOfTeamItems,
    //             teamItem: promoData.teamItem,
    //             reasonsRichText: promoData.reasonsRichText,
    //             // numberOfReasonsItems: promoData.numberOfReasonsItems,
    //             // reasonsItem: promoData.reasonsItem,
    //             instagram: promoData.instagram,
    //             twitter: promoData.twitter,
    //             linkedin: promoData.linkedin,
    //             discord: promoData.discord,
    //             richTextPromo: promoData.richTextPromo,
    //             baseColor: promoData.baseColor,
    //             backgroundColor: promoData.backgroundColor,
    //             accentColor: promoData.accentColor,
    //             baseColorText: promoData.baseColorText,
    //             backgroundColorText: promoData.backgroundColorText,
    //             accentColorText: promoData.accentColorText,
    //             includeGradient: promoData.includeGradient,
    //             namePosition: promoData.namePosition,
    //             accentFont: promoData.accentFont,
    //         });
    //     }
    // }, [promoData]); 

    const { register, watch, setValue, setError, clearErrors, reset, handleSubmit, control } = useForm({
        defaultValues: {
            projectName: projectData.projectName,
            projectDescription: projectData.projectDescription,
            includePromoPage: projectData.promoPage,
        }
    });
    const { errors } = useFormState({
        control,
    });
    watch("projectName", false);

    const onFormSubmit = async (data) => {
        const { projectName, projectDescription, includePromoPage } = data;
        const { uid, displayName} = currentUser;
        const timestamp = Date.now();
        let projectColor = projectData.projectColor;

        const newProjectData = {
            projectName,
            projectDescription,
            uid,
            displayName,
            projectColor,
            createdAt: timestamp,
            archived: false,
            // promoPage: promoStatus,
        };
        setCurrentProjectData(newProjectData);

        await update(ref(database, "/projects/" + projectKey), newProjectData);
        await update(ref(database, "/users/" + uid + "/projects/" + projectKey), { projectName, projectDescription, projectColor, createdAt: timestamp, archived: false, promoPage: includePromoPage });
        // if (promoStatus) {
        //     await update(ref(database, "/promo/" + projectKey), data);
        // }
        toast({
            title: "Project Updated",
            description: `"${projectName}" has been successfully updated.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
        onClose();
    }

    const restoreProject = async(projectId) => {
        const dbRef = ref(database);
        const links = await get(child(dbRef, `projects/${projectId}/links`));
        const linksVal = links.val();
        if (linksVal) {
            const linksKeys = Object.keys(linksVal);
            linksKeys.map(async (lKey) => {
                let linksUpdates = {};
                linksUpdates["/links/" + lKey + "/archived/"] = false;  // write to links
                linksUpdates["/projects/" + projectId + "/links/" + lKey + "/archived/"] = false;  // write to projects/links
                linksUpdates["/users/" + currentUser.uid + "/projects/" + projectId + "/links/" + lKey + "/archived/"] = false;  // write to users/projects/links
                await update(ref(database), linksUpdates);
            });
        }
        const updates = {};
        updates["/projects/" + projectId + "/archived/"] = false;  // write to projects/
        updates["/users/" + currentUser.uid + "/projects/" + projectId + "/archived/"] = false;  // write to users/projects/
        await update(ref(database), updates);
        toast({
            title: "Project Restored",
            description: `"${currentProjectData.projectName}" has been restored.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
    }

    const archiveProject = async(projectId) => {
        const dbRef = ref(database);
        const links = await get(child(dbRef, `projects/${projectId}/links`));
        const linksVal = links.val();
        if (linksVal) {
            const linksKeys = Object.keys(linksVal);
            linksKeys.map(async (lKey) => {
                let linksUpdates = {};
                linksUpdates["/links/" + lKey + "/archived/"] = true;  // write to links
                linksUpdates["/projects/" + projectId + "/links/" + lKey + "/archived/"] = true;  // write to projects/links
                linksUpdates["/users/" + currentUser.uid + "/projects/" + projectId + "/links/" + lKey + "/archived/"] = true;  // write to users/projects/links
                await update(ref(database), linksUpdates);
            });
        }
        const updates = {};
        updates["/projects/" + projectId + "/archived/"] = true;  // write to projects/
        updates["/users/" + currentUser.uid + "/projects/" + projectId + "/archived/"] = true;  // write to users/projects/
        await update(ref(database), updates);
        toast({
            title: "Archive Complete",
            description: `"${currentProjectData.projectName}" is now archived.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
    }

    return (
        <Flex direction="column">
            <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader bg="blackAlpha.800">
                        <Heading size="sm" mb="2" color="white">Edit Project</Heading>
                    </ModalHeader>
                    <ModalCloseButton color="white" />
                    <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} reset={reset} control={control} watch={watch}>
                        <form onSubmit={handleSubmit(onFormSubmit)}> 
                            <ModalBody bg="gray.50">

                                <Container
                                    py={{
                                        base: "4",
                                        md: "8",
                                    }}
                                >
                                    <Stack spacing="5" divider={<StackDivider />}>
                                    
                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between"
                                        >
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                The Basics
                                                </Text>
                                                <Text fontSize="sm">
                                                Give your project a name and description 
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl"
                                            >
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>

                                                    <FormControl>
                                                        <FormLabel htmlFor="projectName">Project name:</FormLabel>
                                                        <Input name="projectName" {...register("projectName", { required: "This is required."})} />
                                                        <ErrorMessage errors={errors} name="projectName" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>}/>
                                                    </FormControl>
                                                
                                                    <FormControl>
                                                        <FormLabel htmlFor="projectDescription">Project description:</FormLabel>
                                                        <Textarea name="projectDescription" {...register("projectDescription")} />
                                                        <ErrorMessage errors={errors} name="projectDescription" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>}/>
                                                    </FormControl>

                                                    {/* <FormControl>
                                                        <Checkbox size='lg' colorScheme='blue' mb={4} mt={5} fontWeight="500" fontSize="md" {...register("includePromoPage")} onChange={e => {
                                                            if (e.target.checked) {
                                                                setPromoStatus(true);
                                                            }
                                                            else {
                                                                setPromoStatus(false);
                                                            }
                                                        }} >
                                                            <FormLabel>Create Public Project Promo Page?</FormLabel>
                                                        </Checkbox>
                                                    </FormControl> */}
                                                </Stack>
                                            </Box>
                                        </Stack>
                                                                            
                                    </Stack>
                                </Container>
                            </ModalBody>

                            <ModalFooter bg="gray.50">
                                <Button variant="primary" type="submit">Submit</Button>
                                <Button variant="outline" ml={3} onClick={onClose}>
                                        Close
                                </Button>
                            </ModalFooter>
                        </form>
                    </FormProvider>
                </ModalContent>
            </Modal>
            

            <Box
                minH="3xs"
                bg="bg-surface"
                boxShadow="sm"
                borderRadius="lg"
            >

                        
                <Image src={currentProjectImage} alt={currentProjectData.projectName} objectFit="cover" boxSize="380" borderTopLeftRadius="lg" borderTopRightRadius="lg" />
                <Box p="6">

                    <Flex mt="1" justifyContent="space-between" alignContent="center">
                        <Box
                            fontSize="2xl"
                            fontWeight="semibold"
                            as="h4"
                            lineHeight="tight"
                            isTruncated>
                            {currentProjectData.projectName}
                        </Box>

                        {!!currentProjectData.archived &&
                        <Box d="flex" alignItems="baseline">
                            <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="red">
                            Archived
                            </Badge>
                        </Box>
                        }
                    </Flex>

                    <Flex justifyContent="space-between" alignContent="center">
                        {moment(currentProjectData.createdAt).format("MM/DD/YY")}
                    </Flex>

                    <SimpleGrid
                        columns={{
                            base: 2,
                            md: 1,
                            lg: 1,
                            xl: 2,
                        }}
                        gap={2}
                        mt={2}
                    >
                        {!currentProjectData.archived && 
                            <Link as={RRLink} to={`/projects/${projectKey}/links`}>
                                <Button variant="secondary" leftIcon={<CgWebsite fontSize="1.25rem" />}>
                                    MetaSites
                                </Button>
                            </Link>
                        }

                        {!!currentProjectData.archived &&
                            <Tooltip
                                label="Restore project to view MetaSites"
                                bg="white"
                                placement={"bottom"}
                                color={"gray.800"}>
                                <span>
                                    <Button variant="secondary" disabled leftIcon={<CgWebsite fontSize="1.25rem" />}>
                                        MetaSites
                                    </Button>
                                </span>
                            </Tooltip>
                        }

                        {isLargerThan400 && projectKey !== currentUser.uid &&
                            <Flex justify={{base: "flex-end", md: "flex-start", lg: "flex-start", xl: "flex-end"}}>
                                <Menu>
                                    {({ isOpen }) => (
                                        <>
                                            <MenuButton width="fit-content" isActive={isOpen} as={Button} variant="primary" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                                {isOpen ? "Close" : "Actions"}
                                            </MenuButton>
                                            {!currentProjectData.archived && 
                                                    <MenuList>
                                                        <MenuItem onClick={onOpen}>Edit Project</MenuItem>
                                                        <MenuItem onClick={() => archiveProject(projectKey)}>Archive</MenuItem>
                                                    </MenuList>
                                            }
                                            {!!currentProjectData.archived && 
                                                    <MenuList>
                                                        <MenuItem onClick={() => restoreProject(projectKey)}>Restore Project</MenuItem>
                                                        <DeleteProjectDialog projectId={projectKey} projectName={currentProjectData.projectName} />
                                                    </MenuList>
                                            }
                                        </>
                                    )}
                                </Menu>
                            </Flex>
                        }
                    </SimpleGrid>
                    
                    {!isLargerThan400 && projectKey !== currentUser.uid &&
                        <Menu>
                            {({ isOpen }) => (
                                <>
                                    <MenuButton mt={2} isActive={isOpen} as={Button} variant="primary" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                        {isOpen ? "Close" : "Actions"}
                                    </MenuButton>
                                    {!currentProjectData.archived && 
                                            <MenuList>
                                                <MenuItem onClick={onOpen}>Edit Project</MenuItem>
                                                <MenuItem onClick={() => archiveProject(projectKey)}>Archive</MenuItem>
                                            </MenuList>
                                    }
                                    {!!currentProjectData.archived && 
                                            <MenuList>
                                                <MenuItem onClick={() => restoreProject(projectKey)}>Restore Project</MenuItem>
                                                <DeleteProjectDialog projectId={projectKey} projectName={currentProjectData.projectName} />
                                            </MenuList>
                                    }
                                </>
                            )}
                        </Menu>
                    }
                </Box>
          
            </Box>
        </Flex>
    )
}

ProjectCard.propTypes = {
    projectKey: PropTypes.string, 
    projectData: PropTypes.object,
}

export default ProjectCard;