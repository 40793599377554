import "@google/model-viewer";
import { 
    Container
} from "@chakra-ui/react";
import { getDatabase, ref, child, get } from "firebase/database";
import React, { useEffect } from "react";
import DemoCgTraderSearch from "./DemoCgTraderSearch.js";
import { AuthContext } from "../../../../context/AuthContext";

function DemoCgTrader() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);

    useEffect(() => {
        async function getTokens() {
            const result = await get(child(dbRef, `users/${currentUser.uid}/apis/cgTrader`)).catch((error) => console.error(error));
            return result.val();
        }

        async function getCode() {            
            await fetch("/api/getCode", {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
                .then((res) => res.json())
                .then((data) => {
                    window.location.href = data.uri;
                })
                .catch((err) => console.log("error", err));
        }

        getTokens().then(async (result) => {
            if (!result) {
                await getCode();
            }
        })

    }, []);

    return (
        <Container py="8" flex="1">
            <DemoCgTraderSearch />
        </Container>
    );
}

export default DemoCgTrader;
