
   
import {  
    Flex,
    Container
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import Loading from "../../../../Loading/Loading.js";
import LinkCard from "../../../LinkCard.js";

function CurrentPageResults({ currentPageLinks, currentPageKeys, projectId, locationLinkIdProp, openModalProp }) {
    const {currentUser} = React.useContext(AuthContext);
    if (currentPageKeys.length === 0 || Object.keys(currentPageLinks) === 0) {
        return (
            <Loading size="small" />
        )
    }

    if (currentPageKeys.length !== 0 && Object.keys(currentPageLinks) !== 0) {
        return (
            <Container>
                <Flex direction="column">
                    {currentPageKeys.map((linkKey, key) => 
                        <LinkCard linkKey={linkKey} linkData={currentPageLinks[linkKey]} projectId={projectId} user={currentUser} locationLinkIdProp={locationLinkIdProp} openModalProp={openModalProp} key={key} />
                    )}  
                </Flex>
            </Container>
        )}
    return null;
}

CurrentPageResults.propTypes = {
    currentPageLinks: PropTypes.object, 
    currentPageKeys: PropTypes.array,
    projectId: PropTypes.string,
    openModalProp: PropTypes.bool,
    locationLinkIdProp: PropTypes.string
}

export default CurrentPageResults;
