import { 
    Container,
    Flex,
    // Image,
    // Heading,
    Spinner
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
// import finalloading from "../../assets/finalloading.gif";

function Loading({ size }) {

  
    if (size === "fullscreen") {
        return (
            <Flex bg="rgb(38, 38, 38)">
                <Container bg="rgb(38, 38, 38)" height="100vh" width="100vw">
                    <Flex justify="center" direction="column" align="center" height="100%" width="100%">
                        {/* <Image src={finalloading} alt="Loading" />
                    <Heading size="sm">Loading...</Heading> */}
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Flex>
                </Container>
            </Flex>
        )}

    if (size === "medium") {
        return (
            <Container py="8" flex="1" height="100%" width="100%">
                <Flex justify="center" align="center" height="100%" width="100%">
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>
            </Container>
        )
    }

    if (size === "small") {
        return (
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='md'
            />
        )
    }
}

Loading.propTypes = {
    size: PropTypes.string,
}

export default Loading;