import {
    Box,
    Center,
    createIcon,
    Flex,
    Text,
    VStack,
    useColorModeValue as mode,
    useRadio,
    useRadioGroup,
} from "@chakra-ui/react";
import capitalize from "lodash-es/capitalize";
import PropTypes from "prop-types";
import * as React from "react";
import { ActiveIndicator } from "./ActiveIndicator";
import { PaymentContext } from "../../../../context/PaymentContext";

const CurvedLine = createIcon({
    viewBox: "0 0 38 20",
    path: (
        <path
            fill="none"
            d="M1.5 18.5H21C29.8366 18.5 37 11.3366 37 2.5V1"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    ),
})
  
export const RadioButton = (props) => {
    const { state, getInputProps, getCheckboxProps, getLabelProps } =
      useRadio(props)

    return (
        <Box as="label" pos="relative" {...getLabelProps()}>
            <input {...getInputProps()} />
            <Center
                {...getCheckboxProps()}
                cursor="pointer"
                pos="relative"
                zIndex={1}
                h="12"
                px="8"
                textAlign="center"
                transition="all 0.2s"
                minW="8rem"
                fontWeight="medium"
                _checked={{
                    color: mode("blue.600", "blue.200"),
                    fontWeight: "bold",
                }}
            >
                {capitalize(props.value)}
            </Center>
            {state.isChecked && (
                <ActiveIndicator
                    bg={mode("white", "gray.600")}
                    shadow="md"
                    layoutId="highlight"
                    transition={{
                        duration: "0.1",
                    }}
                />
            )}
        </Box>
    )
}

RadioButton.propTypes = {
    value: PropTypes.string
}

export const DurationSwitcher = (props) => {
    const {
        handleSetPaymentSchedule
    } = React.useContext(PaymentContext);

    const handleChange = (value) => {
        handleSetPaymentSchedule(value);        
    }

    const { getRadioProps, getRootProps } = useRadioGroup({
        defaultValue: "yearly",
        onChange: handleChange
    })
    return (
        <Box pos="relative">
            <Flex
                display="inline-flex"
                align="center"
                bg={mode("gray.200", "gray.700")}
                rounded="full"
                {...getRootProps(props)}
            >
                <RadioButton
                    {...getRadioProps({
                        value: "monthly",
                    })}
                >
                    Monthly
                </RadioButton>
                <RadioButton
                    {...getRadioProps({
                        value: "yearly",
                    })}
                >
                    Yearly
                </RadioButton>
            </Flex>

            <Box color={mode("blue.600", "blue.400")} pos="absolute" right={{base: "-6rem", md: "-7rem"}} top="6">
                <VStack>
                    <Text lineHeight="1" fontWeight="bold">
                        2x value
                    </Text>
                    <Text lineHeight="1" fontWeight="bold">
                        for Creator
                    </Text>
                </VStack>
                <CurvedLine fontSize="2.5rem" pos="relative" right="8" bottom="1" />
            </Box>
        </Box>
    )
}
