import { 
    Stack, 
    Text, 
    HStack, 
    Box, 
    Avatar, 
    Badge,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    Button,
    Accordion, 
    AccordionButton, 
    AccordionPanel, 
    AccordionItem, 
    AccordionIcon, 
} from "@chakra-ui/react";
import { getDatabase, ref, get, update, child } from "firebase/database";
import capitalize from "lodash-es/capitalize";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import GlobalProjectLinks from "../Links/GlobalLinks/GlobalProjectLinks.js";
import Loading from "../Loading/Loading.js";

function FilteredUsers({userKey, userData}) {
    const database = getDatabase();
    const dbRef = ref(database);
    const [roles, setRoles] = useState(false);
    const [roleNames, setRoleNames] = useState(false);
    const [roleColor, setRoleColor] = useState();
    const [projectsData, setProjectsData] = useState(false);
    const [projectsKeys, setProjectsKeys] = useState(false);

    useEffect(() => {
        async function getUserRoles() {
            const roles = await get(child(dbRef, "roles")).catch((error) => console.log(error));
            return roles.val();
        }

        async function getProjectsData() {
            const pData = await get(child(dbRef, `users/${userKey}/projects`)).catch((error) => console.log(error));
            return pData.val();
        }

        getUserRoles().then((userRolesVal) => {
            setRoles(userRolesVal);
            setRoleNames(Object.keys(userRolesVal));
            setRoleColor(userRolesVal[userData.role].color);
        })

        getProjectsData().then((projectsData) => {
            if (projectsData) {
                let projectsKeys = Object.keys(projectsData);
                setProjectsData(projectsData);
                setProjectsKeys(projectsKeys);
            }
            else {
                setProjectsData(false);
                setProjectsKeys(false);
            }
        });
    }, [])

    const changeRole = async(roleName) => {
        const newCreditsAmount = roles[roleName].credits;
        const updates = {};
        updates["/users/" + userKey + "/role/"] = roleName;
        updates["/users/" + userKey + "/credits/"] = newCreditsAmount;
        await update(ref(database), updates);
        setRoleColor(roles[roleName].color);
    }

    if (!userKey && !roles) {
        return <Loading size="medium" />
    }

    if (!!userKey && !!userData && !!roles && !!roleNames && !!roleColor) {
        return (
            <>
                <Box
                    as="section"
                    pb={{
                        base: "3",
                        md: "4",
                    }}
                >
                    <Box
                        bg="bg-surface"
                        px={{
                            base: "4",
                            md: "6",
                        }}
                        py="5"
                        boxShadow="sm"
                        borderRadius="lg"
                    >
                        <Accordion allowToggle>
                            <AccordionItem borderBottomWidth="0px !important" borderTopWidth={0}>
                                <Stack
                                    spacing="4"
                                    direction={{
                                        base: "column",
                                        sm: "row",
                                    }}
                                    justify="space-between"
                                >
                                    <HStack spacing="4">
                                        <Avatar
                                            src={userData.photoURL}
                                            name={userData.displayName}
                                            boxSize={{
                                                base: "12",
                                                sm: "14",
                                            }}
                                        />
                                        <Box>
                                            <HStack>
                                                <Text fontSize="lg" fontWeight="medium">
                                                    {userData.displayName}
                                                </Text>
                                                {userData.role === "guest" && 
                                                <Badge variant="subtle" colorScheme="orange">
                                                    Guest
                                                </Badge>
                                                }
                                                {userData.role === "user" && 
                                                <Badge variant="subtle" colorScheme="blue">
                                                    User
                                                </Badge>
                                                }
                                                {userData.role === "creator" && 
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Creator
                                                </Badge>
                                                }
                                                {userData.role === "agency" && 
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Agency
                                                </Badge>
                                                }
                                                {userData.role === "institution" && 
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Institution
                                                </Badge>
                                                }
                                                {userData.role === "superadmin" && 
                                                <Badge variant="subtle" colorScheme="red">
                                                    Superadmin
                                                </Badge>
                                                }
                                            </HStack>
                                        </Box>
                                    </HStack>
                                    <HStack direction="row" spacing="3">
                                        {projectsKeys &&
                                            <AccordionButton>
                                                <Button variant="secondary">Edit MetaSites</Button>
                                            </AccordionButton>
                                        }
                                        <Menu>
                                            {({ isOpen }) => (
                                                <>
                                                    <MenuButton isActive={isOpen} as={Button} variant="primary" minWidth="150px" rightIcon={<MdOutlineArrowDropDownCircle />}>
                                                        {isOpen ? "Close" : "Change Role"}
                                                    </MenuButton>
                                                    <MenuList>
                                                        {roleNames.map((roleName, idx) => 
                                                            <MenuItem  key={idx} onClick={() => changeRole(roleName)}>{capitalize(roleName)}</MenuItem>                                                    
                                                        )}
                                                    </MenuList>
                                                </>
                                            )}
                                        </Menu>
                    
        
                                    </HStack>
                                </Stack>
                                <AccordionPanel px={0}>
                                    <Accordion allowToggle mt={5} p={5}>
                                        {projectsKeys &&
                                            projectsKeys.map((key, idx) => 
                                                <AccordionItem key={idx} isDisabled={projectsData[key].archived}>
                                                    <AccordionButton _expanded={{ bg: "blue.500", color: "#000000" }} bg="white">
                                                        <Box flex='1' textAlign='left'>
                                                            <HStack>
                                                                <Text fontSize="lg" fontWeight="medium">{projectsData[key].projectName}</Text>
                                                                {projectsData[key].archived !== true && 
                                                        <Badge variant="subtle" colorScheme="blue">
                                                            Live
                                                        </Badge>
                                                                }
                                                                {projectsData[key].archived === true && 
                                                        <Badge variant="subtle" colorScheme="red">
                                                            Archived
                                                        </Badge>
                                                                }
                                                            </HStack>
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                    <AccordionPanel px={0}>
                                                        <Box mt={10}>
                                                            {projectsData[key].archived !== true &&
                                                        <GlobalProjectLinks projectKey={key} user={userData} />
                                                            }
                                                        </Box>
                                                    </AccordionPanel>
                                                </AccordionItem>
    
                                            )}
                                    </Accordion>
                                </AccordionPanel>
                            </AccordionItem>

                        </Accordion>
                    </Box>
                </Box>
            </>
        );
    }
    else {
        return null;
    }
}

FilteredUsers.propTypes = {
    userKey: PropTypes.string,
    userData: PropTypes.object,
}

export default FilteredUsers;
