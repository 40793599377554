import { ChevronDownIcon } from "@chakra-ui/icons";
import { 
    Text, 
    HStack,
    Input,
    Icon, 
    InputGroup,
    Box,
    SimpleGrid,
    InputLeftElement,
    InputRightElement,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import SearchItem from "./SearchItem.js";
import SearchItemGrid from "./SearchItemGrid.js";
import { AuthContext } from "../../context/AuthContext";

function CgTraderSearchScreen() {
    const {currentUser} = React.useContext(AuthContext);
    const [searchString, setSearchString] = useState("");
    const [searchResults, setSearchResults] = useState(false);
    const [searchResultsKeys, setSearchResultsKeys] = useState(false);
    const [searching, setSearching] = useState(false);
    const [glbOnlyStatus, setGlbOnlyStatus] = useState(false);
    const [rangeSliderLow, setRangeSliderLow] = useState(1);
    const [rangeSliderHigh, setRangeSliderHigh] = useState(100);
    const [isFreeStatus, setIsFreeStatus] = useState(false);
    const [sortByStatus, setSortByStatus] = useState("best_match");
    const placeholder = "Enter model search keywords...";
    const sortByDictionary = {
        best_match: "Best Match",
        sales: "Sales",
        newest: "Newest",
        oldest: "Oldest",
        lowest_price: "Lowest Price",
        highest_price: "Highest Price"
    };

    const isFreeDictionary = {
        true: "Free Models",
        false: "All Models",
    };

    const glbOnlyDictionary = {
        true: ".glb/.gltf Format",
        false: "All File Types",
    };


    const search = async () => {
        setSearching(true);

        await fetch("/api/getSearchResults", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ searchTerm: `${searchString}`, uid: currentUser.uid, glbType: glbOnlyStatus, isFree: isFreeStatus, sortBy: sortByStatus, lowPrice: rangeSliderLow, highPrice: rangeSliderHigh }),
        })
            .then((res) => res.json())
            .then((data) => {
                setSearchResults(data.response.models);
                setSearchResultsKeys(Object.keys(data.response.models));
                return data.response;
            }).catch((err) => console.log("getToken error", err));

        setSearching(false);
    }

    const setRangeValues = async (val) => {
        setRangeSliderLow(val[0]);
        setRangeSliderHigh(val[1]);
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                display={{
                    base: "none",
                    md: "flex",
                }}
            >
                <Flex direction="column">
                    <Text color={mode("gray.600", "gray.400")} spacing="4" fontWeight="medium" fontSize="sm" mb="2">
                    Filter by
                    </Text>
                    <SimpleGrid display="inline-grid" spacing="4" columns={4}>
                        <Menu height="fit-content">
                            {({ isOpen }) => (
                                <>
                                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                                        {isOpen ? "Search by" : `${sortByDictionary[sortByStatus]}`}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => setSortByStatus("best_match")}>Best Match</MenuItem>
                                        <MenuItem onClick={() => setSortByStatus("sales")}>Sales</MenuItem>
                                        <MenuItem onClick={() => setSortByStatus("newest")}>Newest</MenuItem>
                                        <MenuItem onClick={() => setSortByStatus("oldest")}>Oldest</MenuItem>
                                        <MenuItem onClick={() => setSortByStatus("lowest_price")}>Lowest Price</MenuItem>
                                        <MenuItem onClick={() => setSortByStatus("highest_price")}>Highest Price</MenuItem>
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                        <Menu height="fit-content">
                            {({ isOpen }) => (
                                <>
                                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                                        {isOpen ? "Model Cost" : `${isFreeDictionary[isFreeStatus]}`}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => setIsFreeStatus(true)}>Free Models</MenuItem>
                                        <MenuItem onClick={() => setIsFreeStatus(false)}>All Models</MenuItem>
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                        <Menu height="fit-content">
                            {({ isOpen }) => (
                                <>
                                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                                        {isOpen ? "Model Type" : `${glbOnlyDictionary[glbOnlyStatus]}`}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => setGlbOnlyStatus(true)}>.glb/.gltf Format</MenuItem>
                                        <MenuItem onClick={() => setGlbOnlyStatus(false)}>All File Types</MenuItem>
                                    </MenuList>
                                </>
                            )}
                        </Menu>
                    </SimpleGrid>
                </Flex>

                <Flex direction="column" mt="10" width="50%">
                    <HStack>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none">
                                <Icon as={FiSearch} boxSize="6" mt="1" />
                            </InputLeftElement>
                            <Input variant="outline" size="lg" placeholder={placeholder} onChange={(event) => setSearchString(event.target.value)} onKeyUp={(e) => {
                                if (e.code === "Enter") {
                                    search()
                                }
                            }
                            } />
                            <InputRightElement height="100%" width="100px">
                                <Button variant="primary" height="100%" width="100px" onClick={search}>
                                Search
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </HStack>
                </Flex>

            </Flex>
            
            {(isFreeStatus === false) &&
            <Flex direction="column" width="40%">
                <Text color={mode("gray.600", "gray.400")} fontWeight="medium" fontSize="sm" mb="2" mt="3">
                            Price Range
                </Text>
                <RangeSlider onChangeEnd={(val) => setRangeValues(val)} defaultValue={[1, 100]} min={1} max={500} ml="5" mr="5">
                    <RangeSliderTrack bg="blackAlpha.200">
                        <RangeSliderFilledTrack bg="brand.500" />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={10} index={0}>
                        <Box color="brand.500">${rangeSliderLow}</Box>
                    </RangeSliderThumb>
                    <RangeSliderThumb boxSize={10} index={1}>
                        <Box color="brand.500">${rangeSliderHigh}</Box>
                    </RangeSliderThumb>
                </RangeSlider>
            </Flex>
            }

            <Box
                maxW="7xl"
                mx="auto"
                px={{
                    base: "4",
                    md: "8",
                    lg: "12",
                }}
                py={{
                    base: "6",
                    md: "8",
                    lg: "12",
                }}
            >
                <SearchItemGrid>
                    {(!!searchResultsKeys && !searching) &&
                    searchResultsKeys.map((resultItemKey, idx) => 
                        <SearchItem resultKey={resultItemKey} resultData={searchResults[resultItemKey]} key={idx} />
                    )}
                </SearchItemGrid>
            </Box>
        </>
    );
}

export default CgTraderSearchScreen;
