import { Flex } from "@chakra-ui/react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichText.css";

function RichText({ onChange, value }) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if (!updated) {
            const defaultValue = value ? value : "";
            const blocksFromHtml = htmlToDraft(defaultValue);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
    }, [updated, value]);

    const onEditorStateChange = (editorState) => {
        setUpdated(true);
        setEditorState(editorState);

        return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    return (
        <Flex mb={5}>
            <React.Fragment>
                <div className="editor">
                    <Editor
                        spellCheck
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        toolbarClassName="toolbar-editor"
                        wrapperClassName="input-styling"
                        editorClassName="rich-text-editor"
                    />
                </div>
            </React.Fragment>
        </Flex>
    );
}

RichText.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
}

export default RichText;