import { 
    Box, 
    Text,
    Accordion, 
    AccordionButton, 
    AccordionPanel, 
    AccordionItem, 
    AccordionIcon, 
    Heading,
    Stack,
    Container,
    HStack,
    Badge,
} from "@chakra-ui/react";
import { getDatabase, ref, get, child } from "firebase/database";
import filter from "lodash-es/filter";
import React, { useState,  useEffect } from "react";
import EmptyPromptGlobalLinks from "./EmptyPromptGlobalLinks.js";
import GlobalProjectLinks from "./GlobalProjectLinks.js";
import { AuthContext } from "../../../context/AuthContext";
import Loading from "../../Loading/Loading.js";

function Links() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [userProjectsKeys, setUserProjectsKeys] = useState(false);
    const [userProjectsData, setUserProjectsData] = useState(false);
    const [linksDataExists, setLinksDataExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getUserProjects() {
            const existingProjects = await get(child(dbRef, `users/${currentUser.uid}/projects`)).catch((error) => console.log(error));
            return existingProjects.val();
        }

        if (currentUser.role !== "guest") {
            getUserProjects().then((projectData) => {
                let projectDataKeys;
                if (projectData) {
                    setUserProjectsData(projectData);
                    projectDataKeys = Object.keys(projectData);
                    const reversedKeys = projectDataKeys.reverse();
                    setUserProjectsKeys(reversedKeys);
                    const linksTest = filter(Object.values(projectData), "links");
                    if (linksTest) {
                        setLinksDataExists(true);
                    }
                }

                else {
                    setUserProjectsKeys("empty");
                    setUserProjectsData("empty");
                }
                setIsLoading(false)
            });
        }
    }, []);
  
    if (userProjectsData === false || isLoading) {
        return (
            <Loading size="medium" />
        )
    }

    if (!linksDataExists) {
        return <EmptyPromptGlobalLinks />
    }

    if (userProjectsData) {
        return (

            <Container py="8" flex="1">
                <Container py="8" flex="1">
                    <Stack
                        spacing={{
                            base: "8",
                            lg: "6",
                        }}
                    >
                        <Stack
                            spacing="4"
                            direction={{
                                base: "column",
                                lg: "row",
                            }}
                            justify="space-between"
                            align={{
                                base: "start",
                                lg: "center",
                            }}
                        >
                            <Stack spacing="1">
                                <Heading
                                    size="sm"
                                    fontWeight="medium"
                                >
                                    MetaSites
                                </Heading>
                                <Text color="muted">MetaSites organized by parent project</Text>
                            </Stack>
                            {currentUser.role !== "guest" &&
                                <HStack spacing="3" />
                            }
                        </Stack>
                        <Accordion allowToggle>
                            {userProjectsKeys.map((projectKey, idx) =>
                                <AccordionItem key={idx} isDisabled={userProjectsData[projectKey].archived}>
                                    <AccordionButton _expanded={{ bg: "blue.500", color: "#000000" }} bg="white">
                                        <Box flex='1' textAlign='left'>
                                            <HStack>
                                                <Text fontSize="lg" fontWeight="medium">{userProjectsData[projectKey].projectName}</Text>
                                                {userProjectsData[projectKey].archived !== true && 
                                                <Badge variant="subtle" colorScheme="blue">
                                                    Live
                                                </Badge>
                                                }
                                                {userProjectsData[projectKey].archived === true && 
                                                <Badge variant="subtle" colorScheme="red">
                                                    Archived
                                                </Badge>
                                                }
                                            </HStack>
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel px={0}>
                                        <Box mt={10}>
                                            {userProjectsData[projectKey].archived !== true &&
                                                <GlobalProjectLinks projectKey={projectKey} user={currentUser} />
                                            }
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            )
                            }
                        </Accordion>
                    </Stack>
                </Container>
            </Container>
        );
    }
}

export default Links;
