import {
    Box,
    Button,
    Drawer,
    DrawerOverlay,
    DrawerCloseButton,
    DrawerBody,
    DrawerContent,
    VStack,
    Link,
    HStack,
    Text,
    Stack,
    Icon,
    Progress,
    Image,
    Flex
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
    FiBarChart2,
    FiKey,
    FiFolder,
    FiHelpCircle,
    FiHome,
    FiLayout,
    FiSettings,
    FiUsers,
} from "react-icons/fi"
import { useLocation, Link as RRLink } from "react-router-dom";
import NavButton from "./Navigation/components/NavButton.js"
import UserProfile from "./Navigation/components/UserProfile.js"
import ldplogo from "../../assets/ldp-logo-footer.png";
import { AuthContext } from "../../context/AuthContext";
import { SidebarContext } from "../../context/SidebarContext";
  
const SidebarContent = () => {
    const { currentUser, logout } = React.useContext(AuthContext);
    const { userPromoLine1, userPromoLine2, userPromoCta, userPromoLink } = React.useContext(SidebarContext);
    const location = useLocation();
    const [showCreditsPromo, setShowCreditsPromo] = useState(false);

    useEffect(() => {
        if (userPromoLine1 !== "") {
            setShowCreditsPromo(true);
        }
    }, []);

    return (
        <VStack>
            <Flex height="72px" width="100%" flexDirection="row" alignItems="center" justifyContent="center" borderBottom="1px solid RGBA(0, 0, 0, 0.24)">
                <Image src={ldplogo} height="50px" ml={1} mr={1} />
            </Flex>
            <Stack justify="space-between" spacing="1">
                <Stack
                    spacing={{
                        base: "5",
                        sm: "6",
                    }}
                    shouldWrapChildren
                    mt={3}
                >

                    <Stack spacing="1">
                        <NavButton label="Dashboard" icon={FiHome} path="/dashboard" aria-current={location.pathname === "/dashboard" ? "page" : false }/>
                        <NavButton label="Projects" icon={FiFolder} path="/projects" aria-current={location.pathname === "/projects" ? "page" : false } />
                        <NavButton label="MetaSites" icon={FiLayout} path="/links" aria-current={location.pathname === "/links" ? "page" : false } />
                        <NavButton label="Analytics" icon={FiBarChart2} path="/links" aria-current={location.pathname === "/links" ? "page" : false } />
                        <NavButton label="Users" icon={FiUsers} path="/account" aria-current={location.pathname === "/account" ? "page" : false } />
                    </Stack>
                </Stack>
                <Stack
                    spacing={{
                        base: "5",
                        sm: "6",
                    }}
                >
                    <Stack spacing="1">
                        {currentUser.role !== "superadmin" &&
                        <>
                            <NavButton label="Get Help" icon={FiHelpCircle} path="/support" aria-current={location.pathname === "/support" ? "page" : false } />
                            <NavButton label="Upgrade" icon={FiKey} path="/pricing" aria-current={location.pathname === "/pricing" ? "page" : false } />
                        </>
                        }
                        {currentUser.role === "superadmin" &&
                            <NavButton label="Admin Panel" icon={FiHelpCircle} path="/admin" aria-current={location.pathname === "/admin" ? "page" : false } />
                        }
                        <NavButton label="Account" icon={FiUsers} path="/account" aria-current={location.pathname === "/account" ? "page" : false } />
                        <Button onClick={logout} variant="ghost-on-accent" justifyContent="start">
                            <HStack spacing="3">
                                <Icon as={FiSettings} boxSize="6" color="on-accent-subtle" />
                                <Text>Log Out</Text>
                            </HStack>
                        </Button>
                    </Stack>

                    {showCreditsPromo &&
                        <Box bg="bg-accent-subtle" px="4" py="5" borderRadius="lg">
                            <Stack spacing="4">
                                <Stack spacing="1">
                                    <Text fontSize="sm" fontWeight="medium" color="on-accent-muted">
                                        {userPromoLine1}
                                    </Text>
                                    <Text fontSize="sm" color="on-accent-muted">
                                        {userPromoLine2}
                                    </Text>
                                </Stack>
                                <Progress
                                    value={80}
                                    size="sm"
                                    variant="on-accent"
                                    aria-label="Profile Update Progress"
                                />
                                <HStack spacing="3">
                                    <Button mt={1} variant="link-on-accent" size="sm" color="on-accent-muted" onClick={() => setShowCreditsPromo(false)}>
                                        Dismiss
                                    </Button>
                                    <Link as={RRLink} to={userPromoLink} _hover={{textDecoration: "none"}}><Button variant="link-on-accent" size="sm">
                                        {userPromoCta}
                                    </Button></Link>
                                </HStack>
                            </Stack>
                        </Box>
                    }

                    <Box width="100%" borderBottom="1px solid RGBA(0, 0, 0, 0.24)" />
                    <UserProfile
                        name={currentUser.displayName}
                        image={currentUser.photoURL}
                        email={currentUser.email}
                    />
                </Stack>
            </Stack>
        </VStack>
    )
}
  
const SidebarNew = ({ isOpen, variant, onClose }) => {
    return variant === "sidebar" ? (
        <Box
            position="fixed"
            left={0}
            pl={5}
            pr={5}
            pb={5}
            w="275px"
            top={0}
            h="100%"
            bg="blackAlpha.800"
        >
            <SidebarContent />
        </Box>
    ) : (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} width="275px">
            <DrawerOverlay>
                <DrawerContent bg="rgb(38, 38, 38)">
                    <DrawerCloseButton color="white" />
                    <DrawerBody>
                        <SidebarContent />
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    )
}

SidebarNew.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    variant: PropTypes.string
}
  
export default SidebarNew;
