import PropTypes from "prop-types";
import React from "react";
import Countdown from "react-countdown";
import { Button } from "reactstrap";
import JoinWhitelist from "../JoinWhitelist/JoinWhitelist.js";
import "./PromoCountdown.css";

function PromoCountdown({ promoData, projectId, countdownType, colorScheme }) {
    const fonts = {
        robotoMono: "'Roboto Mono', monospace",
        belleza: "'Belleza', sans-serif", 
        raleway: "'Raleway', sans-serif", 
        montserrat: "'Montserrat', sans-serif",
        playfairDisplay: "'Playfair Display', serif",
        permanentMarker: "'Permanent Marker', cursive",
    };

    let countdownBackground;
    let countdownText;
    let countdownTitle;
    let countdownEnds;

    if (colorScheme === "background") {
        countdownBackground = promoData.backgroundColor;
        countdownText = promoData.backgroundColorText;
    }

    if (colorScheme === "accent") {
        countdownBackground = promoData.accentColorText;
        countdownText = promoData.accentColor;
    }

    if (colorScheme === "base") {
        countdownBackground = promoData.baseColor;
        countdownText = promoData.baseColorText;
    }

    if (countdownType === "launch") {
        countdownTitle = "Whitelist Opens & Roadmap Posted";
        countdownEnds = promoData.promoLaunchDate;
    }

    if (countdownType === "whitelist") {
        countdownTitle = "Whitelist Mint Opens";
        countdownEnds = promoData.whitelistDate;
    }

    if (countdownType === "mint") {
        countdownTitle = "Mint Opens";
        countdownEnds = promoData.mintDate;
    }

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
        // Render a completed state
            if (countdownType === "launch") {
                return <JoinWhitelist promoData={promoData} projectId={projectId} />;
            }
            if (countdownType === "whitelist") {
                return <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button"><span className="promo-cta-button-text">Whitelist Mint Open - Mint Now</span></Button>
            }
            if (countdownType === "mint") {
                return <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button"><span className="promo-cta-button-text">Mint Now</span></Button>
            }
        } else {
        // Render a countdown
            return (
                <>
                    <section style={{backgroundColor: `${countdownBackground}`, display: "flex", flexDirection: "column"}}>
                        <div>
                            <div className="countdown-tagline">
                                <h5 style={{color: `${countdownText}`, textAlign: "center"}}>{countdownTitle}</h5>
                            </div>

                            <div className="accent-hr">
                                <div style={{width: "50px", borderBottom: `2px solid ${countdownText}`}}></div>
                            </div>
                        </div>

                        <div className="countdown-component">
                            <div className="stats-group-1">
                                <div className="details-stats-box">
                                    <h1 style={{color: `${countdownText}`}}>{days}</h1>
                                    <h5 style={{color: `${countdownText}`}}>Days</h5>
                                </div>
                                <div className="details-stats-box">
                                    <h1 style={{color: `${countdownText}`}}>{hours}</h1>
                                    <h5 style={{color: `${countdownText}`}}>Hours</h5>
                                </div>
                            </div>
                            <div className="stats-group-2">
                                <div className="details-stats-box">
                                    <h1 style={{color: `${countdownText}`}}>{minutes}</h1>
                                    <h5 style={{color: `${countdownText}`}}>Minutes</h5>
                                </div>
                                <div className="details-stats-box">
                                    <h1 style={{color: `${countdownText}`}}>{seconds}</h1>
                                    <h5 style={{color: `${countdownText}`}}>Seconds</h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className="countdown-title" style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <span style={{letterSpacing: '1.5px'}}>Until Whitelist Opens</span>
              </div> */}
                    </section>
                </>
            );
        }
    };

    return (
        <>
            <Countdown
                date={countdownEnds}
                renderer={renderer}
            />
        </>
    )
}

PromoCountdown.propTypes = {
    promoData: PropTypes.object, 
    projectId: PropTypes.string, 
    countdownType: PropTypes.string, 
    colorScheme: PropTypes.string
}

export default PromoCountdown;
