import { getDatabase, ref, get, child } from "firebase/database";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import Loading from "../Loading/Loading.js";
import "./QrPage.css";

function QrPage() {
    const database = getDatabase();
    const [qrData, setQrData] = useState(false);
    const [qrMeta, setQrMeta] = useState(false);
    const { uid, qrKey } = useParams();

    useEffect(() => {
        const dbRef = ref(database);
        async function getAssets() {
            const assets = await get(child(dbRef, `qr_assets_data/${uid}/${qrKey}`)).catch((error) => console.log(error));
            return assets.val();
        }
        async function getQrMeta() {
            const qrMeta = await get(child(dbRef, `qr_codes/${uid}/${qrKey}`)).catch((error) => console.log(error));
            return qrMeta.val();
        }

        getAssets().then((qrDataVal) => {
            if (qrDataVal) {
                setQrData(qrDataVal);
            }
        })

        getQrMeta().then((qrMetaVal) => {
            if (qrMetaVal) {
                setQrMeta(qrMetaVal);
            }
        })
    }, []);  

    if (!qrMeta) {
        return <Loading size="medium" />
    }

    if (!!qrMeta && !qrData) {
        return (
            <div className="public-qr-container">
                <div className="qr-layout-container">
                    <img src={qrMeta.qr} alt="QR code" />
                </div>
            </div>
        )
    }

    if (!!qrData && !!qrMeta) {
        return (
            <div className="public-qr-container">
                <div className="qr-layout-container">
                    {(qrData.fileExt !== "glb" && qrData.fileExt !== "usdz" && qrData.fileExt !== "pdf") && 
        <div className="polaroid">
            <img className="polaroid-img" src={qrData.link} alt="Polaroid" />
            <div className="polaroid-name">{qrData.fileName}.{qrData.fileExt}</div>
        </div>
                    }
                    {(qrData.fileExt === "pdf") && 
            <embed src={qrData.link} width="800px" height="2100px" />
                    }
                    {(qrData.fileExt === "glb") && 
        <div id="ar-qr-container">
            <model-viewer
                id="model-viewer-qr-link"
                src={qrData.link} 
                alt="A 3D model of an astronaut"
                ar
                autoplay
                camera-controls>
            </model-viewer>
        </div>
                    }
                </div>
                <div className="button-container">
                    <a href={qrData.link}><Button className="qr-download-button">Download {qrData.fileName}.{qrData.fileExt}</Button></a>
                </div>
            </div>
        );
    }
    return null;
}

export default QrPage;