import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import PropTypes from "prop-types";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function DeviceDoughnutViews({ reportData }) {

    const data = {
        labels: reportData.labels,
        datasets: [
            {
                label: "# of Votes",
                data: reportData.values,
                backgroundColor: [
                    "#3182ce",
                    "rgba(0, 0, 0, 0.80)",
                    "#ffc107",
                ],
                borderColor: [
                    "#000000",
                    "#000000",
                    "#000000",
                ],
                borderWidth: 1,
            },
        ],
    };
  
    return (
        <div style={{width: "50%", padding: "10px"}}>
            <Doughnut data={data} />
        </div>
    );
}

DeviceDoughnutViews.propTypes = {
    reportData: PropTypes.object,
}

export default DeviceDoughnutViews;