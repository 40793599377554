import {
    VStack,
    Box,
    Flex,
    Heading
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import MonadSplitter from "./MonadSplitter.js";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";
import "./Monad.css";

function MonadSplitterDemo({ monadData, assetsData, mobileOS, projectId, linkId }) {
    const { currentUser } = React.useContext(AuthContext);

    useEffect(() => {
    }, [monadData, assetsData, currentUser])

    if (monadData && assetsData) {
        return (
            <>
                {currentUser &&
                    <MonadSplitter monadData={monadData} assetsData={assetsData} mobileOS={mobileOS} projectId={projectId} linkId={linkId} />
                }
    
                {!currentUser &&
                    <VStack bg="rgb(38, 38, 38)" margin={0} color="#FFFFFF" height="100vh" width="100vw">
                        <Box width="100%" height="100%" py="10px">
                            <Flex direction="column" align="center" justify="center" height="100%" ml="5%" mr="5%" flex="1">
                                <Heading>This MetaSite is only a demo!</Heading>
                                <Heading size="md">Upgrade your account to publish the MetaSite so others can see it.</Heading>
                            </Flex>
                        </Box>
                    </VStack>
                }
            </>
        )
    }

    if (!monadData || !assetsData) {
        return <Loading size="fullscreen" />
    }

}
  

export default MonadSplitterDemo;

MonadSplitterDemo.propTypes = {
    monadData: PropTypes.object,
    assetsData: PropTypes.object,
    mobileOS: PropTypes.string,
    projectId: PropTypes.string,
    linkId: PropTypes.string,
}
