import { ChevronRightIcon } from "@chakra-ui/icons";
import { 
    Box, 
    Center, 
    IconButton, 
    Image, 
    Flex, 
    Container
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import {Routes, Route} from "react-router-dom";
import ldplogo from "../../assets/ldp-logo-footer.png";
import Account from "../Account/Account";
import AccountAssets from "../Account/AccountAssets/AccountAssets";
import NotFound from "../App/NotFound.js";
import CgTraderOrder from "../CgTrader/CgTraderOrder/CgTraderOrder.js";
import CgTraderSuccess from "../CgTrader/CgTraderSuccess.js";
import CgTraderWrapper from "../CgTrader/CgTraderWrapper.js";
import Dashboard from "../Dashboard/Dashboard.js";
import DemoAuthenticatedWrapper from "../Demo/DemoAuthenticated/DemoAuthenticatedWrapper.js";
import DemoUnauthenticatedWrapper from "../Demo/DemoUnauthenticated/DemoUnauthenticatedWrapper.js";
import Links from "../Links/GlobalLinks/Links.js";
import ProjectLinks from "../Links/ProjectLinks/ProjectLinks";
import BadData from "../Links/ProjectLinks/SpecialCases/BadData/BadData.js";
// import ConnectButton from "../Manifold/ConnectButton.js";
import ModelRender from "../ModelRender/ModelRender.js";
import Banner from "../Monad/Banner";
import Monad from "../Monad/Monad";
import Pricing from "../Pricing/Pricing";
import Projects from "../Projects/Projects.js";
import PromoPage from "../Promo/PromoPage.js";
import QrForm from "../QR/QrForm.js";
import QrList from "../QR/QrList.js";
import QrPage from "../QR/QrPage.js";
import Stripe from "../Stripe/Stripe.js";
import Success from "../Stripe/Success.js";
import SuperadminPanel from "../SuperadminPanel/SuperadminPanel.js";
import Support from "../Support/Support";
import Transfer from "../Transfer/Transfer.js";
import VCard from "../VCard/VCard.js";
import VCardPage from "../VCard/VCardPage.js";

const Header = ({ showSidebarButton = true, onShowSidebar }) => {
    return (
        <>
            {showSidebarButton && (
                <Flex bg="blackAlpha.800" p={4} color="white" justifyContent="center">
                    <Box flex="1">
                        <IconButton
                            icon={<ChevronRightIcon w={8} h={8} />}
                            colorScheme="blackAlpha"
                            variant="outline"
                            onClick={onShowSidebar}
                        />
                    </Box>
                    <Center flex="1" h="40px">
                        <Image src={ldplogo} height="38px" />
                    </Center>
                    <Box flex="1" />
                </Flex>
            )}
            <Container flex="1" overflow="scroll" px={0} py={0} maxWidth="100%">
                <AppRoutes />
            </Container>
        </>
    )
}

Header.propTypes = {
    onShowSidebar: PropTypes.func,
    showSidebarButton: PropTypes.bool
}

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:projectId/links" element={<ProjectLinks />} />
            <Route path="/error/projects/:projectId/links/:linkId" element={<BadData />} />
            <Route path="/links" element={<Links />} />
            <Route path="/analytics" element={<Links />} />
            <Route path="/account" element={<Account />} />
            <Route path="/account/assets" element={<AccountAssets />} />
            <Route path="/support" element={<Support />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/monad/:projectId/:linkId" element={<Monad />} />
            <Route path="/monad/:projectId/:linkId/banner" element={<Banner />} />
            <Route path="/model/:projectId/:linkId" element={<ModelRender />} />
            <Route path="/vcard" element={<VCard />} />
            <Route path="/vcard/:uid" element={<VCardPage />} />
            <Route path="/qrlink" element={<QrForm />} /> 
            <Route path="/qrlist" element={<QrList />} /> 
            <Route path="/qrlink/:uid/:qrKey" element={<QrPage />} />
            <Route path="/admin" element={<SuperadminPanel />} />
            <Route path="/stripe/:purchaseType" element={<Stripe />} />
            <Route path="/success/:uid/:paymentId/:purchaseType" element={<Success />} />
            <Route path="/transfer/:projectId/:linkId" element={<Transfer />} />
            <Route path="/promo/:projectId" element={<PromoPage />} />
            <Route path="/cgTrader" element={<CgTraderWrapper />} />
            <Route path="/cgTraderOrder" element={<CgTraderOrder />} />
            <Route path="/requestToken/" element={<CgTraderSuccess />} />
            <Route path="/demo" element={<DemoUnauthenticatedWrapper />} />
            <Route path="/tutorial" element={<DemoAuthenticatedWrapper />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default Header;
