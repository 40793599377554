import { Button } from "@chakra-ui/react";
import { getDatabase, ref, onValue } from "firebase/database";
import parse from "html-react-parser";
import sampleSize from "lodash-es/sampleSize";
import moment from "moment/min/moment-with-locales";
import React, { useState, useEffect } from "react";
import { FaDiscord } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { useParams } from "react-router-dom";
import "@google/model-viewer";
import PromoCountdown from "./Countdown/PromoCountdown.js";
import JoinWhitelist from "./JoinWhitelist/JoinWhitelist.js";
import ldpLogoFooter from "../../assets/ldp-logo-footer.png";
import Loading from "../Loading/Loading.js";
import "./PromoPage.css";

function PromoPage() {
    const database = getDatabase();
    const { projectId } = useParams();
    const [promoData, setPromoData] = useState(false);
    const [promoAssets, setPromoAssets] = useState(false);
    const [carousel1, setCarousel1] = useState(false);
    const [carousel2, setCarousel2] = useState(false);
    const [carousel3, setCarousel3] = useState(false);
    const [carousel1Assets, setCarousel1Assets] = useState(false);
    const [carousel2Assets, setCarousel2Assets] = useState(false);
    const [carousel3Assets, setCarousel3Assets] = useState(false);
    const [formattedWhitelistDate, setFormattedWhitelistDate] = useState(false);
    const [promoLaunchDateOpen, setPromoLaunchDateOpen] = useState(false);
    const [whitelistDateOpen, setWhitelistDateOpen] = useState(false);
    const [mintDateOpen, setMintDateOpen] = useState(false);
    const fonts = {
        robotoMono: "'Roboto Mono', monospace",
        belleza: "'Belleza', sans-serif", 
        raleway: "'Raleway', sans-serif", 
        montserrat: "'Montserrat', sans-serif",
        playfairDisplay: "'Playfair Display', serif",
        permanentMarker: "'Permanent Marker', cursive",
    };

    function checkIfOpen(promoDate) {
        const date1 = new Date(promoDate);
        const date2 = new Date();
        const difference = date1.getTime() - date2.getTime();
        const days = Math.ceil(difference / (1000 * 3600 * 24));
        if (days <= 0) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        let selected3;
        let carousel1Key;
        let carousel2Key;
        let carousel3Key;
        let sample1;
        let sample2;
        let sample3;
        const promoRef = ref(database, "promo/" + projectId);
        const promoAssetsRef = ref(database, "promo_data/" + projectId);
        onValue(promoRef, async (snapshot) => {
            let promoDataVal = snapshot.val();

            if (promoDataVal) {
                setPromoData(promoDataVal);

                setPromoLaunchDateOpen(checkIfOpen(promoDataVal.promoLaunchDate));
                setWhitelistDateOpen(checkIfOpen(promoDataVal.whitelistDate));
                setMintDateOpen(checkIfOpen(promoDataVal.mintDate));

                const formattedWhitelist = moment(new Date(promoDataVal.whitelistDate)).format("MM/DD");
                setFormattedWhitelistDate(formattedWhitelist);
            }

            else {
                setPromoData("empty");
            }
        });
        onValue(promoAssetsRef, (snapshot) => {
            let promoAssetsVal = snapshot.val();

            if (promoAssetsVal) {
                setPromoAssets(promoAssetsVal);
            }

            else {
                setPromoAssets("empty");
            }
        });

        const linksRef = ref(database, `projects/${projectId}/links`);
        onValue(linksRef, (snapshot) => {
            let linksData = snapshot.val();
            let linksDataKeys;
            if (linksData) {
                linksDataKeys = Object.keys(snapshot.val());
                selected3 = sampleSize(linksDataKeys, 3);
                carousel1Key = selected3[0];
                carousel2Key = selected3[1];
                carousel3Key = selected3[2];
                sample1 = linksData[selected3[0]];
                sample2 = linksData[selected3[1]];
                sample3 = linksData[selected3[2]];
                setCarousel1(sample1);
                setCarousel2(sample2);
                setCarousel3(sample3);

                const sample1AssetsRef = ref(database, `assets_data/${projectId}/${carousel1Key}`);
                onValue(sample1AssetsRef, (snapshot) => {
                    let sample1Val = snapshot.val();

                    if (sample1Val) {
                        let sample1Copy = sample1Val;
                        if (!sample1Val.screenshot && !!sample1Val.img) {
                            sample1Copy.screenshot = sample1Val.img;
                        }
                        if (!sample1Val.img && !sample1Val.screenshot) {
                            sample1Copy.screenshot = {link: "https://ldp.studio/website-ar/LDPlogo.png"}
                        }
                        setCarousel1Assets(sample1Copy);
                    }

                    else {
                        setCarousel1Assets("empty");
                    }
                });
              
                const sample2AssetsRef = ref(database, `assets_data/${projectId}/${carousel2Key}`);
                onValue(sample2AssetsRef, (snapshot) => {
                    let sample2Val = snapshot.val();

                    if (sample2Val) {
                        let sample2Copy = sample2Val;
                        if (!sample2Val.screenshot && !!sample2Val.img) {
                            sample2Copy.screenshot = sample2Val.img;
                        }
                        if (!sample2Val.img && !sample2Val.screenshot) {
                            sample2Copy.screenshot = {link: "https://ldp.studio/website-ar/LDPlogo.png"}
                        }
                        setCarousel2Assets(sample2Copy);
                    }

                    else {
                        setCarousel2Assets("empty");
                    }
                });

                const sample3AssetsRef = ref(database, `assets_data/${projectId}/${carousel3Key}`);
                onValue(sample3AssetsRef, (snapshot) => {
                    let sample3Val = snapshot.val();

                    if (sample3Val) {
                        let sample3Copy = sample3Val;
                        if (!sample3Val.screenshot && !!sample3Val.img) {
                            sample3Copy.screenshot = sample3Val.img;
                        }
                        if (!sample3Val.img && !sample3Val.screenshot) {
                            sample3Copy.screenshot = {link: "https://ldp.studio/website-ar/LDPlogo.png"}
                        }
                        setCarousel3Assets(sample3Copy);
                    }

                    else {
                        setCarousel3Assets("empty");
                    }
                });
            }
        });
    }, [database, projectId]);  

    function thumbnailClick(event, name) {
        let element = event.target;
        const modelViewer = document.querySelector("model-viewer");
        modelViewer.src = name.glb.link;
        modelViewer.poster = name.screenshot.link;
        // if (stringName === 'carousel1Assets') {
        //   modelViewer.camera-orbit = "150deg 108deg auto";
        // }
        // if (stringName === 'carousel2Assets') {
        //   modelViewer.camera-orbit = "90deg 100deg auto";
        // }
        // if (stringName === 'carousel3Assets') {
        //   modelViewer.camera-orbit = "60deg 108deg auto";
        // }
        const slides = document.querySelectorAll(".slide");
        slides.forEach((element) => {element.classList.remove("selected");});
        element.classList.add("selected");
  
        document.querySelector(".slider").addEventListener("beforexrselect", (ev) => {
            // Keep slider interactions from affecting the XR scene.
            ev.preventDefault();
        });
    }

    if ((!promoData && !promoAssets) || promoData === "empty" || promoAssets === "empty") {
        return <Loading size="medium" />
    }

    if (!!promoData && !!promoAssets && !promoLaunchDateOpen) {
        return (
            <>
                <div className="promo-container" style={{backgroundColor: promoData.backgroundColor, color: promoData.backgroundColorText}}>
                    {(!!promoData.includeBannerImage && !promoData.includeGradient) &&
        <section className="showcase" style={{ background: `url("${promoAssets.bannerImg.link}")`,   backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
            <div className="container" style={{paddingTop: "20px"}}>
                {(promoData.namePosition === "onHeaderImage") &&
            <div style={{backgroundColor: promoData.baseColor, color: promoData.baseColorText, width: "fit-content", fontFamily: fonts[promoData.accentFont]}}>
                <span style={{fontSize: "60px"}}>{promoData.projectName}</span>
            </div>
                }
            </div>
        </section>
                    }
  
                    {(!!promoData.includeBannerImage && !!promoData.includeGradient) &&
        <section className="showcase" style={{ background: `linear-gradient(to top, #0000, ${promoData.accentColor}), url("${promoAssets.bannerImg.link}")`,  backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
            <div className="container" style={{paddingTop: "20px"}}>
                {(promoData.namePosition === "onHeaderImage") &&
            <div style={{backgroundColor: promoData.baseColor, color: promoData.baseColorText, width: "fit-content", fontFamily: fonts[promoData.accentFont]}}>
                <span style={{fontSize: "60px"}}>{promoData.projectName}</span>
            </div>
                }
            </div>
        </section>
                    }
  
                    <section className="project-details-stats" style={{backgroundColor: `${promoData.accentColor}`}}>
                        <div className="stats-group-1">
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{promoData.whitelistPrice}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Whitelist Price</h5>
                            </div>
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{promoData.mintPrice}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Mint Price</h5>
                            </div>
                        </div>
                        <div className="stats-group-2">
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{promoData.nftCount}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Total NFTs</h5>
                            </div>
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{formattedWhitelistDate}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Whitelist Mint Date</h5>
                            </div>
                        </div>
                    </section>
  
                    {(promoData.namePosition === "standard") &&
        <section className="project-name" style={{backgroundColor: "#000000", color: promoData.accentColor}}>
            <h1 style={{fontFamily: fonts[promoData.accentFont], textAlign: "center"}}>{promoData.projectName}</h1>
        </section>
                    }
  
                    {(!!carousel1 && !!carousel1Assets && !!carousel2 && !!carousel2Assets && !!carousel3 && !!carousel3Assets) &&
        <section className="ar-carousel">
            <div id="model-viewer-container">
                <model-viewer style={{ backgroundColor: "#000000"}} id="promo-model-viewer" src={carousel1Assets.glb.link} poster={carousel1Assets.screenshot.link} shadow-intensity="1" ar ar-modes="webxr scene-viewer quick-look" camera-controls autoplay animation-name="Animation" alt="A 3D model carousel">
              
                    <div className="progress-bar hide" slot="progress-bar">
                        <div className="update-bar"></div>
                    </div>
                  
                    <button slot="ar-button" id="ar-button">
                View in your space
                    </button>
                  
                    <div id="ar-prompt">
                        <img src="https://modelviewer.dev/assets/hand.png" alt="hand"/>
                    </div>
                  
                    <button id="ar-failure">
                AR is not tracking!
                    </button>
                  
                    <div className="slider">
                        <div className="slides">
                            <button className="slide selected" onClick={(e) => thumbnailClick(e, carousel1Assets, "carousel1Assets")} style={{background: `url("${carousel1Assets.screenshot.link}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></button>
                            <button className="slide" onClick={(e) => thumbnailClick(e, carousel2Assets, "carousel2Assets")} style={{backgroundImage: `url("${carousel2Assets.screenshot.link}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></button>
                            <button className="slide" onClick={(e) => thumbnailClick(e, carousel3Assets, "carousel3Assets")} style={{backgroundImage: `url("${carousel3Assets.screenshot.link}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></button>
                        </div>
                    </div>
                </model-viewer>
            </div>
        </section>
                    }
        
                    <section>
                        <PromoCountdown promoData={promoData} projectId={projectId} countdownType={"launch"} colorScheme={"accent"} />
                    </section>
                </div>
            </>
        )
    }

    if (!!promoData && !!promoAssets && !!promoLaunchDateOpen) {
        return (
            <>
                <div className="promo-container" style={{backgroundColor: promoData.backgroundColor, color: promoData.backgroundColorText}}>
                    {(!!promoData.includeBannerImage && !promoData.includeGradient) &&
      <section className="showcase" style={{ background: `url("${promoAssets.bannerImg.link}")`,   backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <div className="container" style={{paddingTop: "20px"}}>
              {(promoData.namePosition === "onHeaderImage") &&
          <div style={{backgroundColor: promoData.baseColor, color: promoData.baseColorText, width: "fit-content", fontFamily: fonts[promoData.accentFont]}}>
              <span style={{fontSize: "60px"}}>{promoData.projectName}</span>
          </div>
              }
          </div>
      </section>
                    }

                    {(!!promoData.includeBannerImage && !!promoData.includeGradient) &&
      <section className="showcase" style={{ background: `linear-gradient(to top, #0000, ${promoData.accentColor}), url("${promoAssets.bannerImg.link}")`,  backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <div className="container" style={{paddingTop: "20px"}}>
              {(promoData.namePosition === "onHeaderImage") &&
          <div style={{backgroundColor: promoData.baseColor, color: promoData.baseColorText, width: "fit-content", fontFamily: fonts[promoData.accentFont]}}>
              <span style={{fontSize: "60px"}}>{promoData.projectName}</span>
          </div>
              }
          </div>
      </section>
                    }

                    <section className="project-details-stats" style={{backgroundColor: `${promoData.accentColor}`}}>
                        <div className="stats-group-1">
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{promoData.whitelistPrice}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Whitelist Price</h5>
                            </div>
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{promoData.mintPrice}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Mint Price</h5>
                            </div>
                        </div>
                        <div className="stats-group-2">
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{promoData.nftCount}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Total NFTs</h5>
                            </div>
                            <div className="details-stats-box">
                                <h1 style={{color: `${promoData.accentColorText}`}}>{formattedWhitelistDate}</h1>
                                <h5 style={{color: `${promoData.accentColorText}`}}>Whitelist Mint Date</h5>
                            </div>
                        </div>
                    </section>

                    {(promoData.namePosition === "standard") &&
      <section className="project-name" style={{backgroundColor: "#000000", color: promoData.accentColor}}>
          <h1 style={{fontFamily: fonts[promoData.accentFont], textAlign: "center"}}>{promoData.projectName}</h1>
      </section>
                    }

                    {(!!carousel1 && !!carousel1Assets && !!carousel2 && !!carousel2Assets && !!carousel3 && !!carousel3Assets) &&
      <section className="ar-carousel">
          <div id="model-viewer-container">
              <model-viewer style={{ backgroundColor: "#000000"}} id="promo-model-viewer" src={carousel1Assets.glb.link} poster={carousel1Assets.screenshot.link} shadow-intensity="1" ar ar-modes="webxr scene-viewer quick-look" camera-controls autoplay animation-name="Animation" alt="A 3D model carousel">
            
                  <div className="progress-bar hide" slot="progress-bar">
                      <div className="update-bar"></div>
                  </div>
                
                  <button slot="ar-button" id="ar-button">
              View in your space
                  </button>
                
                  <div id="ar-prompt">
                      <img src="https://modelviewer.dev/assets/hand.png" alt="hand" />
                  </div>
                
                  <button id="ar-failure">
              AR is not tracking!
                  </button>
                
                  <div className="slider">
                      <div className="slides">
                          <button className="slide selected" onClick={(e) => thumbnailClick(e, carousel1Assets, "carousel1Assets")} style={{background: `url("${carousel1Assets.screenshot.link}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></button>
                          <button className="slide" onClick={(e) => thumbnailClick(e, carousel2Assets, "carousel2Assets")} style={{backgroundImage: `url("${carousel2Assets.screenshot.link}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></button>
                          <button className="slide" onClick={(e) => thumbnailClick(e, carousel3Assets, "carousel3Assets")} style={{backgroundImage: `url("${carousel3Assets.screenshot.link}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></button>
                      </div>
                  </div>
              </model-viewer>
          </div>
      </section>
                    }
      
                    {!whitelistDateOpen &&
      <>
          <section>
              <PromoCountdown promoData={promoData} projectId={projectId} countdownType={"whitelist"} colorScheme={"accent"} />
          </section>
          <section>
              <JoinWhitelist promoData={promoData} projectId={projectId} />
          </section>
      </>
                    }
                    {(!!whitelistDateOpen && !mintDateOpen) &&
      <>
          <section>
              <PromoCountdown promoData={promoData} projectId={projectId} countdownType={"mint"} colorScheme={"accent"} />
          </section>
          <section>
              <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button"><span className="promo-cta-button-text">Whitelist Mint Open - Mint Now</span></Button>
          </section>
      </>
                    }
                    {!!mintDateOpen &&
      <>
          <section>
              <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button"><span className="promo-cta-button-text">Mint Now</span></Button>
          </section>
      </>
                    }

                    <div className="tagline container flex">
                        <h2 style={{color: `${promoData.backgroundColorText}`, textAlign: "center"}}>{promoData.tagline}</h2>
                    </div>

                    <div className="accent-hr">
                        <div style={{width: "50px", borderBottom: `4px solid ${promoData.accentColor}`}}></div>
                    </div>

                    <div className="container" style={{backgroundColor: promoData.backgroundColor}}>
                        <div className="long-description">
                            {!!promoData.longDescription &&
            <>
                {parse(promoData.longDescription)}
            </>
                            }
                            {!promoData.longDescription &&
            <p>{promoData.projectDescription}</p>
                            }
                        </div>
                    </div>
      
                    {(promoData.numberOfRoadmapItems !== "") &&
      <div className="container">
          <section className="roadmap">
              {promoData.roadmapItem.map((roadmapItem, idx) => 
                  <>
                      {(idx % 2 === 0) &&
              <div className="roadmap-item-odd" style={{backgroundColor: promoData.baseColor, color: promoData.baseColorText}}>
                  <div className="roadmap-image-div-container">
                      <div className="roadmap-image-div" style={{ backgroundImage: `url("${promoAssets[`roadmapItem-${idx}-image`].link}")`,   backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                  </div>
                  <div className="roadmap-text-container-odd">
                      <h5 style={{margin: "20px"}}>{roadmapItem.text}</h5>
                  </div>
              </div>
                      }
                      {(idx % 2 !== 0) &&
              <div className="roadmap-item-even" style={{backgroundColor: promoData.baseColor, color: promoData.baseColorText}}>
                  <div className="roadmap-text-container-even">
                      <h5 style={{margin: "20px"}}>{roadmapItem.text}</h5>
                  </div>
                  <div className="roadmap-image-div-container">
                      <div className="roadmap-image-div" style={{ backgroundImage: `url("${promoAssets[`roadmapItem-${idx}-image`].link}")`,   backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                  </div>
              </div>
                      }
                  </>
              )}
          </section>
      </div>
                    }

                    {(promoData.numberOfTeamItems !== "") &&
      <div className="lower-container container">
          <section className="stats">
              <div className="meet-the-team-title">
                  <h1 className="slogan">Meet the Team</h1>
                  <div className="accent-hr">
                      <div style={{width: "50px", borderBottom: `4px solid ${promoData.accentColor}`}}></div>
                  </div>
              </div>
              <div className="flex">
                  {promoData.teamItem.map((teamMember, idx) => 
                      <div className="stat" key={idx} style={{backgroundColor: promoData.baseColor, color: promoData.baseColorText}}>
                          <h3 className="team-member-text">{teamMember.name}</h3>
                          <div className="bio-image-div" style={{ backgroundImage: `url("${promoAssets[`teamItem-${idx}-image`].link}")`,   backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                          <h5 className="team-member-text">{teamMember.bio}</h5>
                          <a href={teamMember.instagram}><FaInstagram size={40} color={promoData.baseColorText} /></a>
                      </div>
                  )}
              </div>
          </section>
      </div>
                    }

                    <div className="slogan-container container">
                        <h1 className="slogan">{promoData.slogan}</h1>
                    </div>

                    {(!!promoData.reasonsRichText && promoData.reasonsRichText !== "<p></p>\n") &&
      <div className="accent-hr">
          <div style={{width: "50px", borderBottom: `4px solid ${promoData.accentColor}`}}></div>
      </div>
                    }

                    <div className="container">
                        {(!!promoData.reasonsRichText && promoData.reasonsRichText !== "<p></p>\n") &&
        <section className="reasons">
            <>
                {parse(promoData.reasonsRichText)}
            </>
        </section>
                        }

                        {!whitelistDateOpen &&
      <section>
          <JoinWhitelist promoData={promoData} projectId={projectId} />
      </section>
                        }

                        {(!!whitelistDateOpen && !mintDateOpen) &&
      <section>
          <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button"><span className="promo-cta-button-text">Whitelist Mint Open - Mint Now</span></Button>
      </section>
                        }

                        {!!mintDateOpen &&
      <section>
          <Button style={{fontFamily: fonts[promoData.accentFont], backgroundColor: promoData.accentColor}} className="promo-cta-button"><span className="promo-cta-button-text">Mint Now</span></Button>
      </section>
                        }

                        {(!!promoData.richTextPromo && promoData.richTextPromo !== "<p></p>\n") &&
    <section className="rich-text-promo-page">
        <>
            {parse(promoData.richTextPromo)}
        </>
    </section>
                        }

                        <section>
                            <div className="social-media-footer">
                                <a href={promoData.instagram} className="promo-social-icon"><FaInstagram size={40} color="black" /></a>
                                <a href={promoData.twitter} className="promo-social-icon"><FaTwitter size={40} color="black" /></a>
                                <a href={promoData.linkedin} className="promo-social-icon"><FaLinkedinIn size={40} color="black" /></a>
                                <a href={promoData.discord} className="promo-social-icon"><FaDiscord size={40} color="black" /></a>
                            </div>
                        </section>

                    </div>
                    {!promoData.includeGradient &&
    <footer style={{ background: `url("${promoAssets.bannerImg.link}")`,   backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        <div className="container grid-3">
            <div className="copyright">
                <div className="promo-footer-logo-container">
                    <p style={{fontFamily: fonts[promoData.accentFont]}} className="white-text">Copyright &copy; 2022</p>
                    <p style={{fontFamily: fonts[promoData.accentFont]}} className="white-text">LDP Studio</p>
                    <img src={ldpLogoFooter} alt="logo" className="promo-footer-logo" />
                </div>
            </div>
            <nav className="links">
            </nav>
            <div className="profiles">
                <a href="https://www.instagram.com/ldp.studio_a_metaverse_co/"><FaInstagram /></a>
                <a href="https://twitter.com/LDP_Studio"><FaTwitter /></a>
                <a href="https://www.linkedin.com/company/ldpstudio"><FaLinkedinIn /></a>
                {/* <a href=""><FaDiscord /></a> */}
            </div>
        </div>
    </footer>
                    }
                    {!!promoData.includeGradient &&
  <footer style={{ background: `linear-gradient(to top, #0000, ${promoData.accentColor}), url("${promoAssets.bannerImg.link}")`,   backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
      <div className="container grid-3">
          <div className="copyright">
              <div className="promo-footer-logo-container">
                  <p style={{fontFamily: fonts[promoData.accentFont]}} className="white-text">Copyright &copy; 2022</p>
                  <p style={{fontFamily: fonts[promoData.accentFont]}} className="white-text">LDP Studio</p>
                  <img src={ldpLogoFooter} alt="logo" className="promo-footer-logo" />
              </div>
          </div>
          <nav className="links">
          </nav>
          <div className="profiles">
              <a href="https://www.instagram.com/ldp.studio_a_metaverse_co/"><FaInstagram /></a>
              <a href="https://twitter.com/LDP_Studio"><FaTwitter /></a>
              <a href="https://www.linkedin.com/company/ldpstudio"><FaLinkedinIn /></a>
              {/* <a href=""><FaDiscord /></a> */}
          </div>
      </div>
  </footer>
                    }
                </div>
            </>
        );
    }
    return null;
}

export default PromoPage;
