import { Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import AssetCard from "./AssetCard";

function AccountAssets() {
    const [isConnected, setIsConnected] = useState(false);
    const [tokens, setTokens] = useState(false);

    async function authenticatedListener() {
        window.addEventListener("m-authenticated", async (event) => {
            setIsConnected(true);
            const client = event.detail.client;
            const userTokens = await client.getNFTsOfOwner({
                // filters: [
                //   {
                //     contractAddress: "0x9619dabdc2eb3679943b51afbc134dec31b74fe8",
                //   },
                // ],
            });
            console.log("user tokens", userTokens)
            setTokens(userTokens);
        });
    }

    async function unauthenticatedListener() {
        window.addEventListener("m-unauthenticated", () => {
            setIsConnected(false);
        });
    }

    useEffect(() => {
        authenticatedListener();
        unauthenticatedListener();
      
    }, [tokens])

    return (
        <Flex direction="column" align="center">
            {!isConnected &&
            <Flex direction="column" width="90%" mt="10">
                <Heading size="3xl" mb="2">Can We See Your Collection?</Heading>
                <Text fontSize="xl" mb="1">Turn the art you <b>already</b> own into a marketing powerhouse.</Text>
                <Divider />
                <Text fontSize="lg" mt="1" mb="3">We may have the ability to transform your NFTs into 3D augmented reality experiences complete with custom CTA. To get started, simply click the Connect Wallet button at the very top right of the page.</Text>
                <Text fontSize="lg" mt="1" mb="3">If you are already connected and do not see your assets rendered on this page, try refreshing.</Text>
            </Flex>
            }

            {(!!isConnected && !!tokens) &&
            <Flex direction="column" width="90%" mt="10">
                {tokens.map((token, idx) => 
                    <AssetCard token={token} key={idx} />
                )}
            </Flex>
            }
        </Flex>
    );
}

export default AccountAssets;
