import React from "react";
import "./NotFound.css"

function NotFound() {
    return (
        <>
            <div id="error-screen">
                <div id="error-screen-text">
                    <h1 style={{backgroundColor: "#282c34", color: "#fffaf0", padding: "10px", width: "fit-content", textTransform: "uppercase"}}>Something went wrong!</h1> 
                    <h3 style={{backgroundColor: "#282c34", color: "#fffaf0", padding: "10px", width: "fit-content"}}>Please check your URL path.</h3>
                </div>
            </div>
        </>
    );
}

export default NotFound;
