import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";
import MonadAndroid from "../Monad/MonadAndroid.js";
import MonadContent from "../Monad/MonadContent.js";
import MonadiOS from "../Monad/MonadiOS.js";
import "./Monad.css";

function MonadSplitter({ monadData, assetsData, mobileOS, projectId, linkId }) {
    const {currentUser} = React.useContext(AuthContext);
    const [currentUserUid, setCurrentUserUid] = useState("");
    useEffect(() => {
        if (currentUser) {
            setCurrentUserUid(currentUser.uid);
        }
    }, [monadData, assetsData])

    if (monadData && assetsData) {
        return (
            <>
                {mobileOS === "iOS" &&
                    <MonadiOS monadData={monadData} assetsData={assetsData} projectId={projectId} linkId={linkId} currentUserUid={currentUserUid} />
                }

                {mobileOS === "Android" &&
                    <MonadAndroid monadData={monadData} assetsData={assetsData} projectId={projectId} linkId={linkId} currentUserUid={currentUserUid} />
                }

                {mobileOS === "Other" &&
                    <MonadContent monadData={monadData} assetsData={assetsData} projectId={projectId} linkId={linkId} currentUserUid={currentUserUid} />
                }
            </>
        )
    }

    if (!monadData || !assetsData) {
        return <Loading size="fullscreen" />
    }
}
  

export default MonadSplitter;

MonadSplitter.propTypes = {
    monadData: PropTypes.object,
    assetsData: PropTypes.object,
    mobileOS: PropTypes.string,
    projectId: PropTypes.string,
    linkId: PropTypes.string
}
