import { 
    Link,
    Box,
    Text,
} from "@chakra-ui/react";
import { getDatabase, ref, get, child, query, orderByKey, limitToLast } from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link as RRLink } from "react-router-dom";
import Loading from "../../Loading/Loading.js";
import LinkCard from "../LinkCard.js";

function GlobalProjectLinks({projectKey, user}) {
    const database = getDatabase();
    const [linksKeys, setLinksKeys] = useState(false);
    const [linksData, setLinksData] = useState(false);
    const [projectsKeys, setProjectsKeys] = useState(false);
    const [tooManyLinks, setTooManyLinks] = useState(false);
    const dbRef = ref(database);

    useEffect(() => {
        async function userFunction() {
            const linksQuery = await get(
                query(ref(database, `projects/${projectKey}/links`), orderByKey(), limitToLast(25))
            );
            return linksQuery.val();
        }

        async function getProjects() {
            const allProjects = await get(child(dbRef, `users/${user.uid}/projects`));
            return allProjects.val();
        }

        userFunction().then((userLinks) => {
            let userLinksKeys;
            if (userLinks) {
                userLinksKeys = Object.keys(userLinks);
                setLinksKeys(userLinksKeys);
                setLinksData(userLinks);
                if (userLinksKeys.length === 25) {
                    setTooManyLinks(true);
                }
            }
            if (!userLinks) {
                setLinksKeys("empty");
                setLinksData("empty");
            }

            const allProjects = getProjects();
            const allProjectsKeys = Object.keys(allProjects);
            setProjectsKeys(allProjectsKeys);
        });
    }, [projectKey]);

  
    if (!linksData) {
        return <Loading size="small"/>
    }

    if (linksKeys === "empty") {
        return (
            <Box mb={5}>
                <Text fontSize="xl" textAlign="center">No MetaSites have been created for this project yet.</Text>
            </Box>
        )
    }

    if (!!linksData && linksKeys !== "empty" && !!projectsKeys) {
        return (
            <Box
                as="section"
                pb={{
                    base: "3",
                    md: "4",
                }}
            >
                {linksKeys.map((linkKey, key) =>
                    (linksData[linkKey].archived !== true) && 
                            <LinkCard linkData={linksData[linkKey]} linkKey={linkKey} projectId={projectKey} user={user} key={key} />
                )}

                {!!tooManyLinks &&
                    <Box mb={5}>
                        <Text fontSize="xl" textAlign="center">The most recent 25 MetaSites for this project are shown. Go to the <Link as={RRLink} to={`/projects/${projectKey}/links`}>MetaSites section for this project</Link> in order to see all of them.</Text>
                    </Box>
                }
            </Box>
        );
    }
    return null;
}

GlobalProjectLinks.propTypes = {
    projectKey: PropTypes.string, 
    user: PropTypes.object,
}

export default GlobalProjectLinks;
