import {
    Container,
    Box,
    Text,
    Heading,
    SimpleGrid
} from "@chakra-ui/react";
import React from "react";
// import { DurationSwitcher } from "./DurationSwitcher"
import { PricingCard } from "./PricingCard"

function PricingPromo() {

    return (
        <Container py="8" flex="1">
            <Box
                maxW={{
                    base: "xl",
                    md: "7xl",
                }}
                mx="auto"
                px={{
                    base: "6",
                    md: "8",
                }}
            >
                {/* <Heading
                    as="h1"
                    size="xl"
                    fontWeight="extrabold"
                    textAlign={{
                        sm: "center",
                    }}
                > */}
                <Heading as="h1" size="lg" lineHeight="1" fontWeight="extrabold" letterSpacing="tight" textAlign={{ sm: "center" }} >
                    Pricing Plans
                </Heading>
                <Text
                    mt="4"
                    maxW="xl"
                    mx="auto"
                    fontSize="xl"
                    color="gray.600"
                    textAlign={{
                        sm: "center",
                    }}
                >
                        Start building and previewing MetaSites for free, then add credits to go live.
                </Text>
                {/* <DurationSwitcher mt="8" /> */}
                <SimpleGrid
                    alignItems="flex-start"
                    mt={{
                        base: "10",
                        lg: "24",
                    }}
                    columns={{
                        base: 1,
                        lg: 3,
                    }}
                    spacing={{
                        base: "12",
                        lg: "8",
                    }}
                >
                    <PricingCard
                        name="Conversion Token"
                        description="Modify existing files and assets to be Web3 compliant."
                        price={250}
                        features={[
                            "As simple as turning a png or jpg file into a cross-device compatible augmented reality experience.",
                            "Conversion tokens can also be put toward modeling out 3D models or objects from scratch. Typical cost is between 1 and 3 Conversion tokens.",
                            "Conversion tokens can also be used to enhance existing assets (for example, to animate an augmented reality scene).",
                        ]}
                        link="/stripe/conversion"
                        form={false}
                    />
                    <PricingCard
                        popular
                        name="MetaSite Token"
                        description="MetaSites are landing pages for the metaverse."
                        price={500}
                        features={[
                            "Comes with a call to action banner that is viewable during viewing of the AR asset on a mobile device. This CTA can be linked to anywhere you would like.",
                            "The content of the AR landing page can be instantly changed at any time simply with a form inside the app. Instant change.",
                            "Each MetaSite comes with analytics tracking page views, AR scene views, and CTA clicks.",
                        ]}
                        link="/stripe/metasite"
                        form={false}
                    />
                    <PricingCard
                        name="Buy Credits"
                        description="Purchase à la carte to be converted into tokens."
                        price={1}
                        features={[
                            "Choose the amount of credits you want to buy at checkout. A minimum of 250 is needed for a Conversion Token and a minimum of 500 is needed for a MetaSite Token.",
                            "Credits have a 1:1 correspondence with USD. $1 = 1 credit.",
                            "Once purchased, you can convert credits into tokens in the Account section of the app.",
                        ]}
                        form={true}
                    />
                </SimpleGrid>
            </Box>
        </Container>
    );
}

export default PricingPromo;