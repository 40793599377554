import {
    FormLabel,
    Select,
    Stack
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";

function TokensToCredits({ currentTokens, fieldName }) {
    const [potentialRefund, setPotentialRefund] = useState(false);

    const { register } = useFormContext();

    useEffect(() => {
        setPotentialRefund(Array.from({length: currentTokens}, (_, i) => i + 1));
    }, [currentTokens]);  


    return (
        <Stack>
            {!!potentialRefund &&
            <>
                <FormLabel htmlFor={`${fieldName}`}>Select the number of tokens you wish to convert back into credits:</FormLabel>
                <Select {...register(`${fieldName}`)}>
                    {potentialRefund.map(num => (
                        <option key={num.value} value={num.value}>{num}</option>
                    ))}
                </Select>
            </>
            }
        </Stack>
    );
}

TokensToCredits.propTypes = {
    currentTokens: PropTypes.number,
    fieldName: PropTypes.string,
}

export default TokensToCredits;
