import { 
    Button, 
    Flex, 
    Icon,
    InputGroup,
    Input,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormLabel,
    Textarea,
    Heading,
    Text,
    Container,
    Box,
    HStack,
    InputLeftElement,
    StackDivider,
    FormControl,
    Tooltip,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { getDatabase, push, ref, update, child, get } from "firebase/database";
import React, { useState, useEffect } from "react";
import { 
    useForm, 
    FormProvider,
    useFormState, 
} from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai"
import { BiErrorCircle } from "react-icons/bi";
import { BsPlusCircleFill, BsSearch } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import ProjectCardsList from "./ProjectCardsList.js";
import ProjectsDemoPromo from "./ProjectsDemoPromo.js";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";
import "./Projects.css";

function Projects() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const projectsRef = ref(database, "projects");
    const [loading, setLoading] = useState(true);
    const [projectKeys, setProjectKeys] = useState([]);  
    const [project, setProject] = useState({});
    const [newProjectKey, setNewProjectKey] = useState(push(projectsRef).key);
    const [showSearch, setShowSearch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [projectsSearchResults, setProjectsSearchResults] = useState({});
    const [numberOfSearches, setNumberOfSearches] = useState(0);
    // const [promoStatus, setPromoStatus] = useState(false);
    const dbRef = ref(database);
    const colors = ["#8ecae6", "#219ebc", "#023047", "#ffb703", "#fb8500"];
    const placeholder = "Enter search...";
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { register, watch, setValue, setError, clearErrors, reset, handleSubmit, control } = useForm();
    const { errors } = useFormState({
        control,
    });
    watch("projectName", false);
    // watch("whitelistDate");
    // watch("mintDate");

    useEffect(() => {
        async function getProjects(uid) {
            const result = await get(child(dbRef, `users/${uid}/projects`)).catch((error) => console.error(error));
            return result.val();
        }

        const allProjects = getProjects(currentUser.uid); 
        allProjects.then(function(projectData) {
            if (projectData) {
                const userProjectKeys = Object.keys(projectData);
                setProjectKeys(userProjectKeys);
                setProject(projectData);
            }
        });

        setLoading(false);
    }, []);  

    const onFormSubmit = async (data) => {
        const { 
            projectName, 
            projectDescription, 
            // includePromoPage 
        } = data;
        const { uid, displayName} = currentUser;
        const timestamp = Date.now();
        const colorKey = await get(child(dbRef, `users/${currentUser.uid}/nextProjectColor`));
        const colorKeyVal = colorKey.val();
        let projectColor = colors[colorKeyVal];

        const createdProjectData = {
            projectName,
            projectDescription,
            uid,
            displayName,
            projectColor,
            createdAt: timestamp,
            archived: false,
            // promoPage: includePromoPage,
        };

        let projectCopy = project;
        let projectKeysCopy = projectKeys;
        projectCopy[newProjectKey] = createdProjectData;
        Object.assign(project, projectCopy)
        setProjectKeys(projectKeysCopy);
        setProject(projectCopy);

        let nextColor;
        if (colorKeyVal === 4) {
            nextColor = 0;
        }
        else {
            nextColor = colorKeyVal + 1;
        }

        await update(ref(database, "/projects/" + newProjectKey), createdProjectData);
        await update(ref(database, "/users/" + uid + "/projects/" + newProjectKey), { 
            projectName, 
            projectDescription, 
            projectColor, 
            createdAt: timestamp, 
            archived: false, 
            // promoPage: includePromoPage 
        });
        await update(ref(database, "/users/" + uid), {nextProjectColor: nextColor});
        // if (promoStatus) {
        //     await update(ref(database, "/promo/" + newProjectKey), data);
        // }

        setNewProjectKey(push(projectsRef).key);
        toast({
            title: "Project Created",
            description: `"${projectName}" has been created.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
        onClose();
    }

    const search = async () => {
        setSearching(true);
        let searchType = "projectNameLowercase";

        await fetch("/api/searchProjects", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ searchType: searchType, searchString: searchString }),
        })
            .then((res) => res.json())
            .then((data) => {
                const { results } = data;
                setProjectsSearchResults(results);
            })
            .catch((error) => console.log("error after posting to searchProjects", error));
        const newNumber = numberOfSearches + 1;
        setNumberOfSearches(newNumber);
        setSearching(false);
    }

    if (loading || Object.keys(project).length === 0) {
        return <Loading size="medium" />
    }

    if (!loading && Object.keys(project).length !== 0) {
        return (
            <Container py="8" flex="1">
                <Container py="8" flex="1">
                    <Stack
                        spacing={{
                            base: "8",
                            lg: "6",
                        }}
                    >
                        <Stack
                            spacing="4"
                            direction={{
                                base: "column",
                                lg: "row",
                            }}
                            justify="space-between"
                            align={{
                                base: "start",
                                lg: "center",
                            }}
                        >
                            <Stack spacing="1">
                                <Heading
                                    size="sm"
                                    fontWeight="medium"
                                >
                                    Projects
                                </Heading>
                                <Text color="muted">Collections of MetaSites</Text>
                            </Stack>
                            
                            {(currentUser.role !== "guest") &&
                                <HStack spacing="3">
                                    {!showSearch &&
                                    <Button variant="secondary" leftIcon={<BsSearch fontSize="1.25rem" />} onClick={() => setShowSearch(true)}>
                                        Search
                                    </Button>
                                    }
                                    {!!showSearch &&
                                    <>
                                        <HStack spacing="3" width={[300, 350]}>
                                            <InputGroup>
                                                <InputLeftElement pointerEvents="none">
                                                    <Icon as={FiSearch} boxSize="5" />
                                                </InputLeftElement>
                                                <Input placeholder={placeholder} onChange={(event) => setSearchString(event.target.value)} onKeyUp={(e) => {
                                                    if (e.code === "Enter") {
                                                        search()
                                                    }
                                                }
                                                } />
                                            </InputGroup>
                                        </HStack>
                                        <Button variant="secondary" leftIcon={<AiFillCloseCircle fontSize="1.25rem" />} onClick={() => setShowSearch(false)}>
                                            Close Search
                                        </Button>
                                    </>
                                    }
                                    <Button variant="primary" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />} onClick={onOpen}>Create Project</Button>
                                </HStack>
                            }

                            {(currentUser.role === "guest") &&
                                <HStack spacing="3">
                                    <Tooltip label="Publish at least 1 MetaSite and you&apos;ll be upgraded to User." placement="auto" closeDelay={500} shouldWrapChildren>
                                        <Button variant="primary" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />} disabled>Create Project</Button>
                                    </Tooltip>
                                </HStack>
                            }
                        </Stack>
                    </Stack>
                </Container>

                <Flex direction="column">
                    {currentUser.role === "guest" &&
                        <ProjectsDemoPromo />
                    }

                    <Flex m={10}>
                        {!showSearch &&
                            <ProjectCardsList allProjectsData={project} />
                        }

                        {(!!showSearch && !searching && Object.keys(projectsSearchResults).length !== 0) &&
                            <ProjectCardsList allProjectsData={projectsSearchResults} />
                        }

                        {!!searching &&
                            <Flex direction="column" justify="center">
                                <a style={{color: "#000000"}} href ="https://www.youtube.com/watch?v=kKhzsx2gVgM"><h2 style={{textAlign: "center", width: "100%"}}>Searching...</h2></a>
                            </Flex>
                        }

                        {(Object.keys(projectsSearchResults).length === 0 && !searching && numberOfSearches !== 0) &&
                            <Flex direction="column" justify="center">
                                <h2 style={{textAlign: "center", width: "100%"}}>No results were found for this search.</h2>
                                <h3 style={{textAlign: "center", width: "100%"}}> Please try again.</h3>
                            </Flex>
                        }
                    </Flex>

                    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader bg="blackAlpha.800">
                                <Heading size="sm" mb="2" color="white">Create Project</Heading>
                            </ModalHeader>
                            <ModalCloseButton color="white" />
                            <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} reset={reset} control={control} watch={watch}>
                                <form onSubmit={handleSubmit(onFormSubmit)}> 
                                    <ModalBody bg="gray.50">

                                        <Container
                                            py={{
                                                base: "4",
                                                md: "8",
                                            }}
                                        >
                                            <Stack spacing="5" divider={<StackDivider />}>
                                                
                                                <Stack
                                                    direction={{
                                                        base: "column",
                                                        lg: "row",
                                                    }}
                                                    spacing={{
                                                        base: "5",
                                                        lg: "8",
                                                    }}
                                                    justify="space-between"
                                                >
                                                    <Box flexShrink={0} width={{
                                                        base: "100%",
                                                        lg: "25%",
                                                    }}>
                                                        <Text fontSize="lg" fontWeight="medium">
                                                            The Basics
                                                        </Text>
                                                        <Text fontSize="sm">
                                                            Give your project a name and description 
                                                        </Text>
                                                    </Box>
                                                    <Box
                                                        bg="bg-surface"
                                                        boxShadow="sm"
                                                        borderRadius="lg"
                                                        flex="1"
                                                        maxWidth="3xl"
                                                    >
                                                        <Stack
                                                            spacing="5"
                                                            px={{
                                                                base: "4",
                                                                md: "6",
                                                            }}
                                                            py={{
                                                                base: "5",
                                                                md: "6",
                                                            }}>
                                                            <FormControl id="projectName">
                                                                <FormLabel htmlFor="projectName">Project Name:</FormLabel>
                                                                <Input name="projectName" {...register("projectName", { required: "This is required."})} />
                                                                <ErrorMessage errors={errors} name="projectName" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>}/>
                                                            </FormControl>
                                                            <FormControl id="projectDescription">
                                                                <FormLabel htmlFor="projectDescription">Project Description:</FormLabel>
                                                                <Textarea name="projectDescription" {...register("projectDescription")} />
                                                                <ErrorMessage errors={errors} name="projectDescription" render={({ message }) => <p className="error-message"><BiErrorCircle /> {message} </p>}/>
                                                            </FormControl>
                                                            {/* <FormControl id="includePromoPage">
                                                                <Checkbox size='lg' colorScheme='blue' mb={4} mt={5} fontWeight="500" {...register("includePromoPage")} onChange={e => {
                                                                    if (e.target.checked) {
                                                                        setPromoStatus(true);
                                                                    }
                                                                    else {
                                                                        setPromoStatus(false);
                                                                    }
                                                                }} >
                                                                    <FormLabel pt={2}>Create Public Project Promo Page?</FormLabel>
                                                                </Checkbox>
                                                            </FormControl> */}

                                                        </Stack>
                                                    </Box>
                                                </Stack>
                                            
                                            </Stack>
                                        </Container>
                                    </ModalBody>

                                    <ModalFooter bg="gray.50">
                                        <Button 
                                            variant="primary" 
                                            type="submit"
                                            onClick={() => {
                                                if (Object.keys(control._formState.errors).length !== 0) {
                                                    toast({
                                                        title: "Error Submitting Form",
                                                        description: "Fix the highlighted errors in the above fields.",
                                                        status: "error",
                                                        variant: "subtle",
                                                        duration: 9000,
                                                        isClosable: true,
                                                    });
                                                }
                                            }}
                                        >Submit</Button>
                                        <Button variant="outline" ml={3} onClick={onClose}>
                                        Close
                                        </Button>
                                    </ModalFooter>
                                </form>
                            </FormProvider>
                        </ModalContent>
                    </Modal>
                </Flex>
            </Container>
        );
    }

    return null;
}

export default Projects;
