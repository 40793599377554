import { 
    Button, 
    Divider, 
    Flex, 
    Heading, 
    Text, 
    Link,
    Container,
    Box,
    Stack,
    Avatar,
    useMediaQuery,
    useColorModeValue as mode
} from "@chakra-ui/react";
import { getDatabase, ref, get, child } from "firebase/database";
import capitalize from "lodash-es/capitalize";
import React, { useState, useEffect } from "react";
import { BsClipboardCheck } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { Link as RRLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function Dashboard() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [isLargerThan500] = useMediaQuery("(min-width: 500px)")
    const [firstName, setFirstName] = useState(" ");
    const [welcomeText, setWelcomeText] = useState("You must be new here! It looks like you don't have any projects yet. To get started with creating AR landing pages you must first create a project. From there, you can fill out a form to create a live MetaSite.");

    useEffect(() => {
        async function getExistingProjects() {
            const userProjects = await get(child(dbRef, `users/${currentUser.uid}/projects`)).catch((error) => console.log(error));
            return userProjects.val();
        }

        if (currentUser.role !== "guest") {
            getExistingProjects().then((projects) => {
                if (projects) {
                    setWelcomeText("You can pick up where you last left off by going to your projects page. Select an existing project to work in or create a new project if you wish.")
                }
            })    
        }

        if (currentUser.role === "guest") {
            setWelcomeText(`Everyone starts out as a ${capitalize(currentUser.role)} account. We've created a demo to walk you through making your first MetaSite. In just two minutes, you'll have published your first augmented reality landing page!`)
        }

        const nameArr = currentUser.displayName.split(" ");
        const fName = nameArr[0];
        setFirstName(fName);
    }, []); 

    return (
        <Container py="8" flex="1">
            <Container py="8" flex="1">
                <Stack
                    spacing={{
                        base: "8",
                        lg: "6",
                    }}
                >        
                    <Box
                        minH="3xs"
                        bg="bg-surface"
                        boxShadow="sm"
                        borderRadius="lg"
                        padding={7}
                    >
                        <Flex direction="column">
                            <Flex direction="row" alignItems="center"  mb={5}>
                                <Heading size="lg"> Welcome, <Box as="mark" color={mode("blue.500", "blue.300")} bg="transparent">{firstName}</Box>!</Heading>
                                {isLargerThan500 &&
                                    <Avatar name={currentUser.displayName} src={currentUser.photoURL} ml={5} />
                                }
                            </Flex>
                            <Text fontSize="lg">This portal is where you will manage the content for your MetaSites. Through a simple form you can swap out the CTA link, the copy, or the AR assets themselves. Any change is instantly published!</Text>
                            <Divider mb={3} mt={3} />
                            <Text fontSize="md" mb={6}>{welcomeText}</Text>

                            <Stack
                                direction={{
                                    base: "column",
                                    sm: "row",
                                }}
                                spacing={4}
                            >
                                <Link as={RRLink} to={"/projects"} _hover={{textDecoration: "none"}}>
                                    <Button size="lg" colorScheme="blue" fontSize="md" leftIcon={<BsClipboardCheck fontSize="1.25rem" />}>
                                        Go to Projects Page
                                    </Button>
                                </Link>
                                {currentUser.role === "guest" &&
                                    <Link as={RRLink} to={"/tutorial"} _hover={{textDecoration: "none"}}>
                                        <Button
                                            size="lg"
                                            bg="white"
                                            color="gray.800"
                                            _hover={{
                                                bg: "gray.50",
                                            }}
                                            shadow="base"
                                            fontSize="md"
                                            leftIcon={<CgWebsite fontSize="1.25rem" />}
                                        >
                                            Start Demo
                                        </Button>
                                    </Link>
                                }
                            </Stack>

                        </Flex>
                    </Box>
                </Stack>
            </Container>
        </Container>
    );
}

export default Dashboard;
