import { 
    getDatabase, 
    ref, 
    push,
    get,
    child,
    update
} from "firebase/database";
import React, { useState, useEffect } from "react";
import DemoUnauthenticated from "./DemoUnauthenticated.js";
import { DemoContext } from "../../../context/DemoContext";

function DemoUnauthenticatedWrapper() {
    const database = getDatabase();
    const dbRef = ref(database);
    const [selectedModel, setSelectedModel] = useState(null);
    const [demoPath, setDemoPath] = useState("example");
    const [demoKey, setDemoKey] = useState(localStorage.getItem("localDemoKey"));
    const [currentStage, setCurrentStage] = useState("orientation");
    const [modelTabIndex, setModelTabIndex] = useState(0);
    const [formTabIndex, setFormTabIndex] = useState(0);
    const [demoSessionData, setDemoSessionData] = useState(false);
    const [qrUrl, setQrUrl] = useState("");
    const [customImageStatus, setCustomImageStatus] = useState(false);
    const [hdrStatus, setHdrStatus] = useState(false);
    const [purchaseConfirmed, setPurchaseConfirmed] = useState(false);
    const [confirmAttempted, setConfirmAttempted] = useState(false);
    const [purchaseId, setPurchaseId] = useState(false);
    const [freeGlb, setFreeGlb] = useState(false);
    const [selectedExampleName, setSelectedExampleName] = useState("mixer");

    useEffect(() => {
        const localDemoKey = localStorage.getItem("localDemoKey");

        if (!localDemoKey) {
            const generatedDemoKey = push(ref(database, "demo_sessions")).key;
            localStorage.setItem("localDemoKey", generatedDemoKey);
            handleSetDemoKey(generatedDemoKey);
            handleSetDemoPath("example");
            handleSetCurrentStage("orientation");
            handleSetModelTabIndex(0);
            handleSetFormTabIndex(0);
        }

        if (localDemoKey) {
            const getDemoSessionData = async () => {
                const demoSession = await get(child(dbRef, `demo_sessions/${localDemoKey}`));
                return demoSession.val();
            }

            getDemoSessionData().then((dsd) => {
                if (dsd) {
                    handleSetDemoKey(localDemoKey);
                    handleSetDemoSessionData(dsd);
                    if (dsd.demoPath) {
                        handleSetDemoPath(dsd.demoPath)
                    }
                    if (!dsd.demoPath) {
                        handleSetDemoPath("example");
                    }
                    if (dsd.currentStage) {
                        handleSetCurrentStage(dsd.currentStage);
                    }
                    if (!dsd.currentStage) {
                        handleSetCurrentStage("orientation");
                    }
                    if (dsd.modelTabIndex) {
                        handleSetModelTabIndex(dsd.modelTabIndex);
                    }
                    if (!dsd.modelTabIndex) {
                        handleSetModelTabIndex(0)
                    }
                    if (dsd.formTabIndex) {
                        handleSetFormTabIndex(dsd.formTabIndex);
                    }
                    if (!dsd.formTabIndex) {
                        handleSetFormTabIndex(0);
                    }
                }
                if (!dsd) {
                    handleSetDemoKey(localDemoKey);
                    handleSetDemoPath("example")
                    handleSetCurrentStage("orientation");
                    handleSetModelTabIndex(0);
                    handleSetFormTabIndex(0);
                }
            }).catch((err) => console.log("error setting demo session data", err));
        }

    }, [])

    const handleSetQrUrl = async (url) => {
        setQrUrl(url);
        await update(ref(database, "/demo_sessions/" + demoKey + "/link_data/assets"), { qr: url });
    }

    const handleSelectModel = async (modelData) => {
        setSelectedModel(modelData);
        await update(ref(database, "/demo_sessions/" + demoKey), {selectedModel: modelData});
    }

    const handleSetDemoPath = async (option) => {
        setDemoPath(option);
        await update(ref(database, "/demo_sessions/" + demoKey), {demoPath: option, demoKey: demoKey, currentStage: currentStage});
    }

    const handleSetDemoKey = async (key) => {
        setDemoKey(key);
        await update(ref(database, "/demo_sessions/" + demoKey), {demoKey: demoKey});
    }

    const handleSetCurrentStage = async (stage) => {
        setCurrentStage(stage);
        await update(ref(database, "/demo_sessions/" + demoKey), {currentStage: stage});
    }

    const handleSetModelTabIndex = async (idx) => {
        setModelTabIndex(idx);
        await update(ref(database, "/demo_sessions/" + demoKey), {modelTabIndex: idx});
    }

    const handleSetFormTabIndex = async (idx) => {
        setFormTabIndex(idx);
        await update(ref(database, "/demo_sessions/" + demoKey), {formTabIndex: idx});
    }

    const handleSetDemoSessionData = (data) => {
        setDemoSessionData(data);
    }

    const handleSetCustomImageStatus = (data) => {
        setCustomImageStatus(data);
    }

    const handleSetHdrStatus = (data) => {
        setHdrStatus(data);
    }

    const handleSetPurchaseConfirmed = (data) => {
        setPurchaseConfirmed(data);
    }

    const handleSetConfirmAttempted = (data) => {
        setConfirmAttempted(data);
    }

    const handleSetPurchaseId = (data) => {
        setPurchaseId(data);
    }
    
    const handleSetFreeGlb = (data) => {
        setFreeGlb(data);
    }

    const handleSetSelectedExampleName = (data) => {
        setSelectedExampleName(data);
    }

    const props = {
        selectedModel,
        demoPath,
        demoKey,
        currentStage, 
        modelTabIndex,
        formTabIndex,
        demoSessionData,
        qrUrl,
        customImageStatus, 
        hdrStatus, 
        purchaseConfirmed, 
        confirmAttempted, 
        purchaseId, 
        freeGlb, 
        selectedExampleName,
        handleSelectModel,
        handleSetDemoPath,
        handleSetDemoKey,
        handleSetCurrentStage,
        handleSetModelTabIndex,
        handleSetFormTabIndex,
        handleSetDemoSessionData,
        handleSetQrUrl,
        handleSetCustomImageStatus, 
        handleSetHdrStatus, 
        handleSetPurchaseConfirmed, 
        handleSetConfirmAttempted, 
        handleSetPurchaseId, 
        handleSetFreeGlb, 
        handleSetSelectedExampleName
    }

    return (
        <DemoContext.Provider value={props}>
            {demoPath && demoKey && currentStage &&
                <DemoUnauthenticated />
            }
        </DemoContext.Provider>
    );
}

export default DemoUnauthenticatedWrapper;
