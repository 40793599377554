import queryString from "query-string";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";

function CgTraderSuccess() {
    const {currentUser} = React.useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [jsonResponse, setJsonResponse] = useState(false);

    useEffect(() => {
        const value = queryString.parse(location.search);
        const code = value.code;

        async function makeRequests() {
            await fetch("/api/getToken", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ code: code, location: location, uid: currentUser.uid }),
            })
                .then((res) => res.json())
                .then((tokenRes) => {
                    setJsonResponse(tokenRes);
                }).catch((err) => console.log("getToken error", err));
        }

        makeRequests();
    }, [])

    useEffect(() => {
        if (jsonResponse) {
            return navigate("/cgTrader", { replace: true });  // possibly check for history coming from demo to route to correct place
        }
    }, [jsonResponse])

    return <Loading size="medium" />
}

export default CgTraderSuccess;
