import PropTypes from "prop-types";
import React, { useState } from "react";
import {
    Card, CardImg, CardBody,
    CardTitle, CardSubtitle, Button, Tooltip
} from "reactstrap";

function AssetCard({ token }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    return (
        <Card className="project-card">
            {!!token.image && 
            <CardImg top className="card-image" src={token.image} alt="Card image cap" />
            }
            {!token.image && 
            <div className="color-image" style={{ backgroundColor: "#000000" }} ></div>
            }
            <CardBody>
                {!!token.name &&
                <CardTitle tag="h5">{token.name}</CardTitle>
                }
                {!token.name &&
                <CardTitle tag="h5">{token.tokenId}</CardTitle>
                }
                <CardSubtitle tag="h6" className="mb-2 text-muted">{token.contractAddress}</CardSubtitle>
                <div className="project-card-buttons">
                    {!!token.image && 
                <div>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSddBiEJZH110jHSez1eyFCjrk1ih4XRBM0xu2T56hksGLPcRg/viewform"><Button>Convert to AR</Button></a>
                </div>
                    }
                    {!token.image && 
                <>
                    <span href="#" id="tooltip-trigger"><Button disabled>Convert to AR</Button></span>
                    <Tooltip placement="bottom" isOpen={!!tooltipOpen} target="tooltip-trigger" toggle={() => setTooltipOpen(!tooltipOpen)}>
                    This NFT does not have an image and therefore cannot be converted into an AR experience.
                    </Tooltip>
                </>
                    }
                </div>
            </CardBody>
        </Card>
    )
}

AssetCard.propTypes = {
    token: PropTypes.object,
};

export default AssetCard;