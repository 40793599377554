
import { theme as proTheme } from "@chakra-ui/pro-theme"
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { DAppProvider } from "@usedapp/core";
import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/inter/variable.css"
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import "./components/Global/styles/styles.css";
<link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />

const theme = extendTheme({
    colors: {
        brand: {
            100: "#d4af37",
            // ...
            900: "#282c34",
        },
    },
    fonts: {
        heading: "'GraphikSemibold', sans-serif",
        body: "'Graphik', sans-serif",
    },
    components: {
        Button: {
            // sizes: {
            //     sm: {
            //         fontSize: "md"
            //     }
            // },
            variants: {
                base: {
                    fontFamily: "Graphik",
                    // bg: "teal.500",
                    // fontSize: "md"
                },
                blueLDP: {
                    bg: "gray.100",
                    _hover: {
                        bg: "blue.500",
                        color: "white",
                    }
                }
                // primary: {
                //     fontFamily: "GraphikSemibold"
                // },
                // secondary: {
                //     fontFamily: "GraphikSemibold"
                // },
                // md: {
                //     bg: "orange.500",
                //     fontSize: "xl"
                // }
            }
        },
        FormLabel: {
            sizes: {
                md: {
                    fontFamily: "GraphikMedium",
                    fontSize: "16px"
                },
            },
        },
        Input: {
            sizes: {
                md: {
                    marginTop: "0.75rem"
                }
            }
        }
    },
},
proTheme,
);

ReactDOM.render(
    <React.StrictMode>
        <DAppProvider config={{}}>
            <ChakraProvider theme={theme}>
                <App />
            </ChakraProvider>
        </DAppProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
