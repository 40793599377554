import {
    Flex,
    Stack,
    Container
} from "@chakra-ui/react";
import { getDatabase, ref, get, child } from "firebase/database";
import React, { useState, useEffect } from "react";
import VCardsJS from "vcards-js";
import VCardForm from "./VCardForm.js";
import { AuthContext } from "../../context/AuthContext";

function VCard() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [vCardData, setVCardData] = useState(VCardsJS());

    useEffect(() => {
        async function getHasVCard() {
            const hasVCard = await get(child(dbRef, `users/${currentUser.uid}/vCard`)).catch((error) => console.log(error));
            return hasVCard.val();
        }

        async function getVCard() {
            const vCard = await get(child(dbRef, `vcards/${currentUser.uid}`)).catch((error) => console.log(error));
            return vCard.val();
        }

        getHasVCard().then(async(hasVCardVal) => {
            if (hasVCardVal) {
                await getVCard().then((vCardDataVal) => {
                    if (vCardDataVal) {
                        setVCardData(vCardDataVal);
                    }
                });
            }
        });
    }, []);  

    return (
        <Container py="8" flex="1">
            <Stack
                spacing={{
                    base: "8",
                    lg: "6",
                }}
            >
                <Flex direction="column" align="center">
                    <Flex direction="column" width="90%" mt="10">
                        <VCardForm vCardData={vCardData} />
                    </Flex>
                </Flex>
            </Stack>
        </Container>
    );
}

export default VCard;
