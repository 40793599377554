import {
    Flex,
    Button,
    Stack,
    Image,
    Container,
    Divider,
    HStack,
    Text,
    Link,
    useBreakpointValue,
    useColorModeValue,
} from "@chakra-ui/react"
import PropTypes from "prop-types";
import * as React from "react";
import { 
    Link as RRLink
} from "react-router-dom";
import google from "../../assets/google.svg";
import ldplogo from "../../assets/ldp-logo-footer.png";
import metamask from "../../assets/metamask.svg";
import { AuthContext } from "../../context/AuthContext";
  
function SignInForm({ homepage }) {
    const {signInWithGoogle, signInWithMetaMask} = React.useContext(AuthContext)
    return (
        <Container
            maxW="md"
            py={{
                base: "8",
                sm: "8",
            }}
            px={{
                base: "4",
                sm: "10",
            }}
            bg={useBreakpointValue({
                base: "blackAlpha.800",
                sm: "bg-surface",
            })}
            boxShadow={{
                base: "none",
                sm: useColorModeValue("md", "md-dark"),
            }}
            borderRadius={{
                base: "xl",
                sm: "xl",
            }}
            height={{
                base: "100%",
                sm: "auto"
            }}
        >

            <Stack spacing="8">
                <Stack spacing="6" align="center">
                    <Image src={ldplogo} width="300px" />
                </Stack>

                <Stack spacing="6">
                    <Stack spacing="6">
                        <HStack>
                            <Divider />
                            <Text fontSize="sm" whiteSpace="nowrap" color={{base: "white", sm: "muted"}}>
                                Sign Up or Log In with
                            </Text>
                            <Divider />
                        </HStack>

                        <Flex direction="column">
                            <Button mb={3} mt={6} bgColor="brand.900" color="white" onClick={signInWithGoogle} _hover={{
                                background: "brand.100",
                                color: "black",
                            }}><Image src={google} mr={2} />Google</Button>
                            <Button onClick={signInWithMetaMask} _hover={{
                                background: "brand.100",
                                color: "black",
                            }}><Image src={metamask} htmlHeight="30px" htmlWidth="30px" mr={2} />MetaMask</Button>
                        </Flex>

                        {homepage &&
                        <HStack>
                            <Divider />
                            <Text fontSize="sm" whiteSpace="nowrap" color={{base: "white", sm: "muted"}}>
                                Try a Demo First
                            </Text>
                            <Divider />
                        </HStack>
                        }

                        {homepage &&
                        <Flex>
                            <Link as={RRLink} to={"/demo"} width="100%" _hover={{textDecoration: "none !important"}}>
                                <Button mb={3} width="100%" bgColor="brand.900" color="white" _hover={{
                                    background: "brand.100",
                                    color: "black",
                                }}><Image src={ldplogo} height="30px" mr={2} />Guest Mode</Button>
                            </Link>
                        </Flex>
                        }
                    </Stack>
                </Stack>

            </Stack>
 
        </Container>
    )
}

export default SignInForm;

SignInForm.propTypes = {
    homepage: PropTypes.bool
}
