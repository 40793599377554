import "@google/model-viewer";
import { 
    Text, 
    HStack,
    Box,
    Badge,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import DownloadPastPurchase from "./DownloadPastPurchase.js";

function CgTraderSyncScreen({ dbPurchases, cgTraderUser, freePurchases }) {
    const [freePurchasesKeys, setFreePurchasesKeys] = useState(false);
    const [dbPurchasesKeys, setDbPurchasesKeys] = useState(false);

    useEffect(() => {
        if (freePurchases) {
            setFreePurchasesKeys(Object.keys(freePurchases));
        }
        if (dbPurchases) {
            setDbPurchasesKeys(Object.keys(dbPurchases));
        }

    }, [])

    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            <HStack spacing="3">
                                <HStack spacing="1">
                                    <Text>Purchase #</Text>
                                    {/* <Icon as={IoArrowDown} color="muted" boxSize="4" /> */}
                                </HStack>
                            </HStack>
                        </Th>
                        <Th>Status</Th>
                        <Th>User</Th>
                        <Th>Model ID</Th>
                        <Th>Glb File</Th>
                        <Th>Download</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {dbPurchasesKeys &&
                    dbPurchasesKeys.map((purchase) => (
                        <Tr key={purchase}>
                            <Td>
                                <HStack spacing="3">
                                    <Box>
                                        <Text fontWeight="medium">{purchase}</Text>
                                    </Box>
                                </HStack>
                            </Td>
                            <Td>
                                <Badge size="sm" colorScheme={dbPurchases[purchase].status === "paid" ? "green" : "red"}>
                                    {dbPurchases[purchase].status}
                                </Badge>
                            </Td>
                            <Td>
                                <Text color="muted">{cgTraderUser.email}</Text>
                            </Td>
                            <Td>
                                <Text color="muted">{dbPurchases[purchase].models[0].id}</Text>
                            </Td>
                            <Td> 
                                {dbPurchases[purchase].glb_models &&
                                    <Text color="muted">
                                        {dbPurchases[purchase].glb_models.name}
                                    </Text>
                                }
                                {!dbPurchases[purchase].glb_models &&
                                    <Text color="muted">
                                        No .glb models
                                    </Text>
                                }
                            </Td>
                            <Td>
                                <HStack spacing="1">
                                    {dbPurchases[purchase].glb_models &&
                                        <DownloadPastPurchase dlUrl={dbPurchases[purchase].glb_models.download_url} />
                                    }
                                    {!dbPurchases[purchase].glb_models &&
                                        <Button disabled>No Download</Button>
                                    }
                                </HStack>
                            </Td>
                        </Tr>
                    ))}
                    {freePurchasesKeys &&
                    freePurchasesKeys.map((purchaseKey) => (
                        <Tr key={purchaseKey}>
                            <Td>
                                <HStack spacing="3">
                                    <Box>
                                        <Text fontWeight="medium">{purchaseKey}</Text>
                                    </Box>
                                </HStack>
                            </Td>
                            <Td>
                                <Badge size="sm" colorScheme="blue">
                                    free
                                </Badge>
                            </Td>
                            <Td>
                                <Text color="muted">{cgTraderUser.email}</Text>
                            </Td>
                            <Td>
                                <Text color="muted">{freePurchases[purchaseKey].files[0].id}</Text>
                            </Td>
                            <Td>
                                {freePurchases[purchaseKey].glb_models &&
                                <Text color="muted">
                                    {freePurchases[purchaseKey].glb_models.name}
                                </Text>
                                }
                                {!freePurchases[purchaseKey].glb_models &&
                                    <Text color="muted">
                                        No .glb models
                                    </Text>
                                }
                            </Td>
                            <Td>
                                {freePurchases[purchaseKey].glb_models &&
                                    <HStack spacing="1">
                                        <DownloadPastPurchase dlUrl={freePurchases[purchaseKey].glb_models.download_url} />
                                    </HStack>   
                                }
                                {!freePurchases[purchaseKey].glb_models &&
                                    <Button disabled>No Download</Button>
                                }
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}

CgTraderSyncScreen.propTypes = {
    paidPurchases: PropTypes.object,
    dbPurchases: PropTypes.object,
    cgTraderUser: PropTypes.object,
    freePurchases: PropTypes.object
}

export default CgTraderSyncScreen;
