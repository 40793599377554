
import {
    VStack,
    Box,
    Flex,
    Link,
    IconButton,
    Tooltip,
    Heading
} from "@chakra-ui/react";
import { getAnalytics, logEvent } from "firebase/analytics";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { Link as RRLink } from "react-router-dom";
import ldplogo from "../../assets/ldp-logo-footer.png";
import { currentConfig } from "../App/App.js";
import "@google/model-viewer";
import "./MonadiOS.css";

function MonadiOS({monadData, assetsData, projectId, linkId, currentUserUid}) {
    const [zoomSnippet, setZoomSnippet] = useState(null);
    const [rotationSnippet, setRotationSnippet] = useState(null);
    const [listenerAdded, setListenerAdded] = useState(false);
    const [bannerId, setBannerId] = useState("banner-fallback");
    const [leaving, setLeaving] = useState(false);
    const hdrLinks = {
        sunrise: "https://modelviewer.dev/shared-assets/environments/spruit_sunrise_1k_HDR.hdr",
        pillars: "https://modelviewer.dev/shared-assets/environments/pillars_1k.hdr",
        hall: "https://modelviewer.dev/shared-assets/environments/music_hall_01_1k.hdr",
        workshop: "https://modelviewer.dev/shared-assets/environments/aircraft_workshop_01_1k.hdr",
        creek: "https://modelviewer.dev/shared-assets/environments/whipple_creek_regional_park_04_1k.hdr",
    };
    useEffect(() => {
        if (monadData.rotation === "left") {
            setRotationSnippet("-35deg 90deg");
        }
        if (monadData.rotation === "right") {
            setRotationSnippet("35deg 90deg");
        }
        if (monadData.zoom === "in") {
            setZoomSnippet("0m 0m -.7m");
        }
        if (monadData.zoom === "out") {
            setZoomSnippet("0m 0m .7m");
        }
    }, [])
    const bannerPath = `${currentConfig.hostedUrl}/monad/${projectId}/${linkId}/banner`;
    const bannerHref = `${assetsData.usdz.link}#custom=${bannerPath}`;
    const analytics = getAnalytics();

    useEffect(() => {
        if (assetsData.img) {
            setBannerId("banner-img");
            document.getElementById("banner-img").addEventListener("message", function (event) {   
                if (event.data == "_apple_ar_quicklook_button_tapped") {
                    setTimeout(function(){ 
                        window.location.href = monadData.ctaLinkPath; 
                    }, 1000);
                    setLeaving(true);
                }
            }, false);
            setListenerAdded(true);
        }

        if (!assetsData.img && !!assetsData.screenshot) {
            setBannerId("banner-screenshot");
            document.getElementById("banner-screenshot").addEventListener("message", function (event) {   
                if (event.data == "_apple_ar_quicklook_button_tapped") {
                    setTimeout(function(){ 
                        window.location.href = monadData.ctaLinkPath; 
                    }, 1000);
                    setLeaving(true);
                }
            }, false);
            setListenerAdded(true);
        }

        if (!assetsData.img && !assetsData.screenshot) {
            setBannerId("banner-fallback");
            document.getElementById("banner-fallback").addEventListener("message", function (event) {   
                if (event.data == "_apple_ar_quicklook_button_tapped") {
                    setTimeout(function(){ 
                        window.location.href = monadData.ctaLinkPath; 
                    }, 1000);
                    setLeaving(true);
                }
            }, false);
            setListenerAdded(true);
        }
    }, [])

    useEffect(() => {
        if (listenerAdded === true) {
            document.getElementById(bannerId).click();
        }
    }, [listenerAdded])
    
    return (
        <>
            {monadData.uid === currentUserUid &&
                <Flex direction="row" justifyContent="flex-end" width="100%" padding="20px" backgroundColor="rgb(38, 38, 38)">
                    <Link as={RRLink} to={`/projects/${projectId}/links`} state={{ locationLinkId: linkId, openModal: true }}>
                        <Tooltip label="Edit MetaSite. Only MetaSite owners logged in to their account can see this." placement="auto" closeDelay={500} shouldWrapChildren>
                            <IconButton
                                variant="outline"
                                aria-label="Edit MetaSite"
                                borderWidth="2px"
                                backgroundColor="transparent"
                                icon={<FaPencilAlt color="white" />}
                            />
                        </Tooltip>
                    </Link>
                </Flex>
            }
            <VStack bg="rgb(38, 38, 38)" margin={0} color="#FFFFFF" minHeight="100vh" width="100%" overflow="scroll">
                <Box width="100%" py="10px">
                    {!leaving &&
                <Flex direction="column" align="center" ml="5%" mr="5%" flex="1">
                
                    <div id="ar-qr-container">
                        {!monadData.assets.hdr && !monadData.autoplay &&
                            <model-viewer
                                id="model-viewer-desktop"
                                src={assetsData.glb.link}
                                alt="A 3D model of an astronaut"
                                ar
                                camera-controls
                                shadow-intensity="1"
                                autoplay
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                        }
                        {!monadData.assets.hdr && monadData.autoplay === "false" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model"
                                ar
                                camera-controls
                                shadow-intensity="1" 
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                        }
                        {!monadData.assets.hdr && monadData.autoplay === "true" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model"
                                ar
                                camera-controls
                                shadow-intensity="1" 
                                autoplay
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                        }
                        {!!monadData.assets.hdr && monadData.autoplay === "false" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model"
                                ar
                                environment-image={hdrLinks[monadData.assets.hdr]}
                                poster="poster.webp" 
                                shadow-intensity="1" 
                                skybox-image={hdrLinks[monadData.assets.hdr]}
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                        }
                        {!!monadData.assets.hdr && monadData.autoplay === "true" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model"
                                ar
                                environment-image={hdrLinks[monadData.assets.hdr]}
                                poster="poster.webp" 
                                shadow-intensity="1" 
                                skybox-image={hdrLinks[monadData.assets.hdr]}
                                autoplay
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                        }
                    </div>
        
                    <div id="instructions" className="text-container">
                        <div className="large-bold-text">To view an experiential scene, click on the image below.</div>
                        <div className="instruction-subheader">To view an individual asset, click on the upper righthand corner of the image below.</div>
                    </div>
                            
                    {/* {!monadData.glbOnlyFlag && */}
                    <div id="iosContainer">
                        {!!assetsData.img &&
                            <a rel="ar" style={{display: "flex", justifyContent: "center"}}  href={bannerHref} onClick={() => logEvent(analytics, "ios_cta_clicked")} id="banner-img">
                                <img src={assetsData.img.link} id="cover-image" alt="AR Cover" /> 
                            </a>
                        }
                        {(!assetsData.img && !!assetsData.screenshot) &&
                            <a rel="ar" style={{display: "flex", justifyContent: "center"}} href={bannerHref} onClick={() => logEvent(analytics, "ios_cta_clicked")} id="banner-screenshot">
                                <img src={assetsData.screenshot.link} id="cover-image" alt="AR Cover" /> 
                            </a>
                        }
                        {(!assetsData.img && !assetsData.screenshot) &&
                            <a rel="ar" style={{display: "flex", justifyContent: "center"}} href={bannerHref} onClick={() => logEvent(analytics, "ios_cta_clicked")} id="banner-fallback">
                                <img src={ldplogo} id="cover-image" alt="AR Cover" /> 
                            </a>
                        }
                    </div>
                    {/* } */}

                    <div id="ios-desktop-cta-container">
                        <a href={monadData.ctaLinkPath} onClick={() => logEvent(analytics, "ios_desktop_cta_clicked")} id="ios-desktop-cta-link" className="ios-desktop-cta">
                            <div id="ios-desktop-cta-header" className="large-bold-text" style={{width: "100%", textAlign: "center"}}>{monadData.ctaHeader}</div>
                            <div id="ios-desktop-cta-subheader" className="normal-text" style={{width: "100%", textAlign: "center"}}>{monadData.ctaSubheader}</div>
                            {monadData.ctaBody1 && 
                                <div id="ios-desktop-cta-body-1" className="medium">{monadData.ctaBody1}</div>
                            }
                            {monadData.ctaBody2 &&
                                <div id="ios-desktop-cta-body-2" className="large">{monadData.ctaBody2}</div>
                            }
                        </a>
                    </div>
        
                    <div id="description" className="text-container">
                        {monadData.description1 &&
                            <p id="description-1">{monadData.description1}</p>
                        }
                        {monadData.description2 &&
                            <p id="description-2">{monadData.description2}</p>
                        }
                        {monadData.richText &&
                            <div id="rich-text">
                                {parse(monadData.richText)}
                            </div>
                        }
                    </div>
                
                </Flex>
                    }

                    {!!leaving && 
                    <Flex direction="column" ml="5%" mr="5%" flex="1" height="100vh" justifyContent="center" alignItems="center" overflow="hidden">
                        <Heading size="sm">Navigating to...</Heading>
                        <Heading size="xs" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{monadData.ctaLinkPath}</Heading>
                    </Flex>
                    }
                </Box>
            </VStack>
        </>
    );
}

MonadiOS.propTypes = {
    monadData: PropTypes.object, 
    assetsData: PropTypes.object, 
    projectId: PropTypes.string, 
    linkId: PropTypes.string,
    currentUserUid: PropTypes.string
}

export default MonadiOS;
