import { initializeAnalytics } from "@firebase/analytics";
import detectEthereumProvider from "@metamask/detect-provider";
import { initializeApp } from "firebase/app";
import { 
    getAuth, 
    signOut, 
    onAuthStateChanged, 
    GoogleAuthProvider, 
    signInWithPopup, 
    getAdditionalUserInfo,
    signInWithCustomToken, 
} from "firebase/auth";
import { getDatabase, ref, set, get, child } from "firebase/database";
import { getStorage } from "firebase/storage";
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import { 
    // rachelFirebaseConfig, 
    // intFirebaseConfig, 
    // stageFirebaseConfig,
    prodFirebaseConfig,
    // arPetsFirebaseConfig 
} from "../../config.js"
import {AuthContext} from "../../context/AuthContext.js"
import AuthenticatedApp from "../AuthenticatedApp/AuthenticatedApp.js";
import UnauthenticatedApp from "../UnauthenticatedApp/UnauthenticatedApp.js";

export const currentConfig = prodFirebaseConfig;
const app = initializeApp(currentConfig);
const database = getDatabase(app);
const auth = getAuth();
const storage = getStorage(app);
initializeAnalytics(app);
const provider = new GoogleAuthProvider();


function App() {
    const [currentUser, setUser] = React.useState(null);

    React.useEffect(() => {
        const dbRef = ref(database);
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userRole = await get(child(dbRef, `users/${user.uid}/role`));
                const userCredits = await get(child(dbRef, `users/${user.uid}/credits`));
                const userTokens = await get(child(dbRef, `users/${user.uid}/tokens`));
                const userName = await get(child(dbRef, `users/${user.uid}/displayName`));
                const accountType = await get(child(dbRef, `users/${user.uid}/accountType`));
                let userData = user;
                userData.role = userRole.val();
                userData.credits = userCredits.val();
                userData.displayName = userName.val();
                userData.accountType = accountType.val();
                userData.tokens = userTokens.val();
                if (userData.displayName === "Anonymous MetaMask") {
                    userData.photoURL = "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1024px-MetaMask_Fox.svg.png?20201112074605";
                }
                return setUser(userData);
            } else {
                return setUser(null);
            }
        });
    }, []);

    function toHex(stringToConvert) {
        return stringToConvert
            .split("")
            .map((c) => c.charCodeAt(0).toString(16).padStart(2, "0"))
            .join("");
    }

    const signInWithMetaMask = async () => {
        let ethereum;
        const dbRef = ref(database);
        const provider = await detectEthereumProvider();
        if (!provider) {
            alert("Please install MetaMask");
        }
        ethereum = provider;
        // const requestedAccounts = await ethereum.request({ method: "eth_requestAccounts" });
        let nonceToSign;
        await fetch("/api/getNonceToSign", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ address: ethereum.selectedAddress }),
        })
            .then((res) => res.json())
            .then((data) => {
                nonceToSign = data.nonce;
            })
            .catch((error) => console.log("error after posting to getNonceToSign", error));

        const sig = await ethereum.request({
            method: "personal_sign",
            params: [
                `0x${toHex(nonceToSign)}`,
                ethereum.selectedAddress,
            ],
        });

        let customToken;
        await fetch("/api/verifySignedMessage", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ address: ethereum.selectedAddress, signature: sig }),
        })
            .then((res) => res.json())
            .then((data) => {
                customToken = data.token;
                return data.token;
            })
            .catch((error) => console.log("error after posting to verifySignedMessage", error));

        await signInWithCustomToken(auth, customToken);
        const userInfo = await get(child(dbRef, `users/${ethereum.selectedAddress}/`));
        const userInfoVal = userInfo.val();
        return setUser(userInfoVal);
    }

    const signInWithGoogle = async () => {
        await signInWithPopup(auth, provider).then(async(res) => {
            let userData = res.user;
            const details = getAdditionalUserInfo(res);
            const uid = userData.uid;
            const dbRef = ref(database);
            if (!details.isNewUser) {
                const userRole = await get(child(dbRef, `users/${uid}/role`));
                const userCredits = await get(child(dbRef, `users/${uid}/credits`));
                const userTokens = await get(child(dbRef, `users/${uid}/tokens`));
                userData.role = userRole.val();
                userData.credits = userCredits.val();
                userData.tokens = userTokens.val();
            }
            if (details.isNewUser) {
                userData.role = "guest";
                userData.credits = 0;
                userData.tokens = {
                    conversion: 0,
                    metasite: 0,
                }
                set(ref(database, "users/" + uid), {
                    uid,
                    displayName: res.user.displayName,
                    email: res.user.email,
                    photoUrl: res.user.photoURL,
                    role: "guest",
                    nextProjectColor: 0,
                    credits: 0,
                    tokens: {
                        conversion: 0,
                        metasite: 0,
                    }
                })
            }
            return setUser(userData)
        }).catch((error) => {
            console.log(error.message)
            return
        })
    }

    const logout = (navigate) => {
        signOut(auth).then(() => {
            setUser(null)
        }).then(() => {
            navigate("/")
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error, errorCode, errorMessage)
        });
    }
    const props = {
        currentUser, 
        setUser, 
        logout, 
        signInWithGoogle, 
        signInWithMetaMask, 
        database, 
        storage
    }
    return (
        <AuthContext.Provider value={props}>
            {currentUser ? (
                <Router>
                    <AuthenticatedApp {...props} />
                </Router>
            ) : (
                <Router>
                    <UnauthenticatedApp {...props} /> 
                </Router>
            )}
        </AuthContext.Provider>
    )
}

export default App;
