import { getDatabase, get, push, ref, remove, update, child } from "firebase/database";
import moment from "moment/min/moment-with-locales";
import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
    Card, CardBody, CardHeader, Button, Collapse,
    Input, InputGroup, InputGroupAddon
} from "reactstrap";
import { currentConfig } from "../App/App.js";
import LinkCard from "../Links/LinkCard.js"
import Loading from "../Loading/Loading.js";
import "./Transfer.css";

function Transfer() {
    const { projectId, linkId } = useParams();
    const database = getDatabase();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [linkData, setLinkData] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [userResults, setUserResults] = useState(false);
    const [userResultsKeys, setUserResultsKeys] = useState(false);
    const [allUsers, setAllUsers] = useState(false);
    const [allUsersKeys, setAllUsersKeys] = useState(false);
    const [fileNames, setFileNames] = useState(false);
    const [hasImage, setHasImage] = useState(false);
    const [hasScreenshot, setHasScreenshot] = useState(false);
    const [isTransferring, setIsTransferring] = useState(false);
    const placeholder = "Enter name or email...";
    const dbRef = ref(database);

    useEffect(() => {
        async function getLink() {
            const link = await get(child(dbRef, `projects/${projectId}/links/${linkId}`)).catch((error) => console.log(error));
            return link.val();
        }

        async function getAssetsData() {
            const assets = await get(child(dbRef, `assets_data/${projectId}/${linkId}`)).catch((error) => console.log(error));
            return assets.val();
        }

        async function getUsers() {
            const users = await get(child(dbRef, "users")).catch((error) => console.log(error));
            return users.val();
        }

        getLink().then((linkVal) => {
            if (linkVal) {
                setLinkData(linkVal);
            }
            if (!linkVal) {
                setLinkData("empty");
            }
        });

        let glbName;
        let usdzName;
        let hasImage = false;
        let hasScreenshot = false;
        let imgName = "";
        let screenshotName = "";

        getAssetsData().then((assetsDataVal) => {
            glbName = assetsDataVal.glb.fileName;
            usdzName = assetsDataVal.usdz.fileName;
            if (assetsDataVal.img) {
                hasImage = true;
                setHasImage(true);
                imgName = assetsDataVal.img.fileName;
            }
            if (assetsDataVal.screenshot) {
                hasScreenshot = true;
                setHasScreenshot(true);
                screenshotName = assetsDataVal.screenshot.fileName;
            }
        });

        if (!!hasImage && !hasScreenshot) {
            setFileNames({glb: glbName, usdz: usdzName, img: imgName});
        }
        if (!hasImage && !!hasScreenshot) {
            setFileNames({glb: glbName, usdz: usdzName, screenshot: screenshotName});
        }
        if (!!hasImage && !!hasScreenshot) {
            setFileNames({glb: glbName, usdz: usdzName, img: imgName, screenshot: screenshotName});
        }

        getUsers().then((usersVal) => {
            setAllUsers(Object.values(usersVal));
            setAllUsersKeys(Object.keys(usersVal));
        });
        setLoading(false)
    }, []);

    const search = async () => {
        const filteredUsers = allUsers.filter(user => {
            return (
                user.displayName.toLowerCase().includes(searchString) ||
          user.email.toLowerCase().includes(searchString)
            );
        });
        let keysArr = [];
        filteredUsers.map((user) => {
            if (allUsersKeys.includes(user.uid)) {
                keysArr.push(user.uid);
            }
        })
        if (filteredUsers) {
            setUserResults(filteredUsers);
            setUserResultsKeys(keysArr);
        }
    }

    const transferLink = async (user) => {
        setIsTransferring(true);
        const originalUid = linkData.uid;
        let modifiedLinkData = linkData;
        const newTransferKey = push(child(ref(database), `links/${linkId}/transfers`)).key;
        let size = 400;
        let bgColor = "FFFFFF";
        let urlName = `${currentConfig.hostedUrl}/monad/${user.uid}/${linkId}`
        modifiedLinkData.assets.qr = `https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`;
        modifiedLinkData.uid = user.uid;
        modifiedLinkData.projectId = user.uid;
        modifiedLinkData.displayName = user.displayName;
        let transferData;

        if (!!hasImage && !hasScreenshot) {
            transferData = {
                fromUser: originalUid,
                toUser: user.uid,
                createdAt: moment().valueOf(),
                projectId: projectId,
                linkId: linkId,
                usdzName: fileNames.usdz,
                glbName: fileNames.glb,
                imgName: fileNames.img,
            };
        }
        if (!hasImage && !!hasScreenshot) {
            transferData = {
                fromUser: originalUid,
                toUser: user.uid,
                createdAt: moment().valueOf(),
                projectId: projectId,
                linkId: linkId,
                usdzName: fileNames.usdz,
                glbName: fileNames.glb,
                screenshotName: fileNames.screenshot,
            };
        }
        if (!!hasImage && !!hasScreenshot) {
            transferData = {
                fromUser: originalUid,
                toUser: user.uid,
                createdAt: moment().valueOf(),
                projectId: projectId,
                linkId: linkId,
                usdzName: fileNames.usdz,
                glbName: fileNames.glb,
                imgName: fileNames.img,
                screenshotName: fileNames.screenshot,
            };
        }
        const projectAndLinkData = {
            uid: user.uid,
            projectColor: "#FD0A00",
            projectDescription: "This is where any MetaSites transferred to you arrive.",
            projectName: "Inbox",
            displayName: user.displayName,
        }

        await update(ref(database, "/links/" + linkId + "/transfers/" + newTransferKey), transferData);
        await update(ref(database, "/links/" + linkId), {uid: user.uid, projectId: user.uid});
        await update(ref(database, "/projects/" + user.uid), projectAndLinkData);
        await update(ref(database, "/projects/" + user.uid + "/links/" + linkId), modifiedLinkData);  // 4
        await update(ref(database, "/users/" + user.uid + "/projects/" + user.uid), projectAndLinkData);
        await update(ref(database, "/users/" + user.uid + "/projects/" + user.uid + "/links/" + linkId), modifiedLinkData);  // 6

        await remove(ref(database, "projects/" + projectId + "/links/" + linkId));
        await remove(ref(database, "users/" + originalUid + "/projects/" + projectId + "/links/" + linkId));
        await remove(ref(database, "assets_data/" + projectId + "/" + linkId));
        setIsTransferring(false);
        return navigate(-1);
    }

    if (loading || isTransferring) {
        return <Loading size="medium" />
    }
  
    if (!loading && !isTransferring) {
        return (
            <div className="transfer-container">
                <div className="content-container">
                    <h3>The MetaSite that you have selected to transfer is:</h3>
                    <Card className="link-card">
                        <CardHeader className="header" onClick={() => setToggle(!toggle)}>
                            <div className="card-title"><span className="ultrabold">{moment(linkData.createdAt).format("MM/D/YY")}</span> - {linkData.title}</div>
                            <div className="buttons-section">
                                <div className="button-left">
                                    <Button tag={Link} to={`/monad/${linkData.projectId}/${linkId}`}><FaLink />  View Live MetaSite</Button>
                                </div>
                                <div className="button-left-mobile">
                                    <Button tag={Link} to={`/monad/${linkData.projectId}/${linkId}`}><FaLink />  MetaSite</Button>
                                </div>
                            </div>
                        </CardHeader>
                        <Collapse isOpen={toggle === true ? true : false}>
                            <CardBody className="link-card-container">
                                <LinkCard linkData={linkData} linkKey={linkId} projectId={projectId} />
                            </CardBody>
                        </Collapse>
                    </Card>
                    {/* <Button onClick={() => navigate(-1)}>Go Back</Button> */}
                    <div className="transfer-search-container">
                        <h3>Search for the user you wish to transfer this MetaSite to, then hit the &quo;Transfer&quo; button next to the selected user&apos;s name.</h3>
                        <InputGroup className="search-input-group">
                            <Input size="lg" style={{ borderTop: "2px solid #000000", borderBottom: "2px solid #000000", borderLeft: "2px solid #000000", borderRadius: "0px", fontWeight: "600" }} placeholder={placeholder} onChange={(event) => setSearchString(event.target.value)} />
                            <InputGroupAddon addonType="append"><Button size="lg" style={{ border: "2px solid #000000", borderRadius: "0px", fontWeight: "600", backgroundColor: "#282c34", color: "#FFFFFF" }} color="secondary" onClick={() => search()}><BsSearch /> Search</Button></InputGroupAddon>
                        </InputGroup>
                        {!!userResultsKeys &&
            userResults.map((user, idx) => 
                <Card className="filtered-users-card" key={idx}>
                    <CardHeader>
                        <div className="all-header-elements">
                            <div className="elements-left">
                                <img className="avatar-medium" src={user.photoUrl} alt="headshot" />
                                <h4>{user.displayName}</h4>
                            </div>
                            <div className="elements-right">
                                <div className="button-left">
                                    <Button onClick={() => transferLink(user)}>Transfer  <FaLink /></Button>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                </Card>
            )}
                    </div>
                </div>
            </div>
        );
    }
    return null;
}

export default Transfer;
