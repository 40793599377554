import { 
    MenuItem, 
    AlertDialog, 
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader, 
    AlertDialogBody, 
    AlertDialogFooter, 
    Button,
    Text,
    Heading,
    Center,
    useDisclosure,
    useToast 
} from "@chakra-ui/react";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Gif } from "@giphy/react-components"
import { getDatabase, ref, update, child, remove, get } from "firebase/database";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AuthContext } from "../../context/AuthContext";

function DeleteProjectDialog({ projectId, projectName }) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const cancelRef = React.useRef();
    const gf = new GiphyFetch("sY6DKyVRR0D8dcVwmg9pkwGIk6166B2I");
    const [trashGif, setTrashGif] = useState();

    useEffect(() => {
        async function getGif() {
            try {
                const result = await gf.search("trash", { limit: 1 });
                const gifId = result.data[0].id
                const { data } = await gf.gif(gifId)
                return data;
            } catch (error) {
                console.error("search", error);
            }
        }

        getGif().then((result) => setTrashGif(result));
    }, [])

    const permanentlyDeleteProject = async(projectId) => {
        const projectLinks = await get(child(dbRef, `projects/${projectId}/links`));
        const projectLinksVal = projectLinks.val();
        if (projectLinksVal) {
            const projectLinksKeys = Object.keys(projectLinksVal);
            const linksLength = projectLinksKeys.length;
            const creditsBack = 500 * linksLength;
            const creditsToSet = currentUser.credits + creditsBack;
            if (linksLength !== 0) {
                projectLinksKeys.map(async (linkKey) => {
                    await remove(ref(database, "links/" + linkKey));
                })
            }
            await update(ref(database, "users/" + currentUser.uid), {credits: creditsToSet});
        }

        await remove(ref(database, "projects/" + projectId));
        await remove(ref(database, "users/" + currentUser.uid + "/projects/" + projectId));

        toast({
            title: "Project Deleted",
            description: `"${projectName}" has been permanently deleted.`,
            status: "error",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
        onClose();
    }
  
    return (
        <>
            <MenuItem colorScheme="red" onClick={onOpen}>
                Permanenetly Delete
            </MenuItem>
  
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                            <Heading size="sm">Permanently Delete</Heading>
                            <Heading size="xs">&quot;{projectName}&quot; Project</Heading>
                        </AlertDialogHeader>
  
                        <AlertDialogBody>
                            <Center mb={10}><Gif gif={trashGif} width={300} /></Center>
                            <Text>Are you sure? You can&apos;t undo this action afterwards. If you only want to disable your project please opt to archive it instead.</Text>
                        </AlertDialogBody>
  
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                            </Button>
                            <Button bg='red' onClick={() => permanentlyDeleteProject(projectId)} ml={3}>
                  Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

DeleteProjectDialog.propTypes = {
    projectId: PropTypes.string, 
    projectName: PropTypes.string,
}

export default DeleteProjectDialog;