import { getDatabase, ref, onValue } from "firebase/database";
import capitalize from "lodash-es/capitalize";
import React, { useState, useEffect } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { BsClipboardCheck} from "react-icons/bs";
import { Link } from "react-router-dom"
import { Jumbotron, Button } from "reactstrap";
import QrListCard from "./QrListCard.js";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";
import "./QrList.css";

function QrList() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const [qrData, setQrData] = useState({});
    const [qrMeta, setQrMeta] = useState({});
    const [qrMetaKeys, setQrMetaKeys] = useState([]);
    const nameArr = currentUser.displayName.split(" ");
    const firstName = nameArr[0];

    useEffect(() => {
        if (currentUser.role !== "guest") {  
            const qrDataRef = ref(database, "qr_assets_data/" + currentUser.uid);
            const qrMetaRef = ref(database, "qr_codes/" + currentUser.uid);
            onValue(qrDataRef, (snapshot) => {
                let qrDataVal = snapshot.val();
                setQrData(qrDataVal);
            });
            onValue(qrMetaRef, (snapshot) => {
                let qrMetaVal = snapshot.val();
                setQrMeta(qrMetaVal);
                if (qrMetaVal) {
                    const qrMetaKeysVal = Object.keys(snapshot.val());
                    setQrMetaKeys(qrMetaKeysVal);
                }
                if (!qrMetaVal) {
                    setQrMetaKeys([]);
                }
            });
        }
    }, []);  

    if (qrMetaKeys.length === 0 && currentUser.role !== "guest") {
        return <Loading size="medium" />
    }

    if (qrMetaKeys.length === 0) {
        return (
            <div className="qr-list-container">
                <div className="content-container">
                    <Jumbotron>
                        <h1 className="display-3">Hey there, {firstName}...</h1>
                        <p className="lead">It looks like you don&apos;t have any QR codes yet. No worries!</p>
                        <hr className="my-2" />
                        <p>To get started, simply click the button below. Choose the type of media you want to link to in the dropdown, fill out the field, then hit Submit. Your QR code will appear immediately. Save it now or return later when you&apos;re ready. A list of QR links you have previously created is always available.</p>
                        <p className="lead">
                            <Link to="/qrlink"><Button>Create Your First QR Link  <BsPlusCircleFill /></Button></Link>
                        </p>
                    </Jumbotron>
                </div>
            </div>
        )
    }

    if (qrMetaKeys.length !== 0 && currentUser.role !== "guest") {
        return (
            <div className="qr-list-container">
                <div className="content-container">
                    <Link to="/qrlink"><Button className="btn-lg"><BsPlusCircleFill /> Create QR Link</Button></Link>
                    <div className="all-cards">
                        {qrMetaKeys.map((qrKey, idx) =>
                            <div className="qr-cards-container" key={idx}>
                                <QrListCard qrKey={qrKey} qrData={qrData} qrMeta={qrMeta[qrKey]} />
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        );
    }

    if (currentUser.role === "guest") {
        return (
            <div className="qr-list-container">
                <div className="content-container">
                    <Jumbotron>
                        <h1 className="display-3">Hello again, {currentUser.displayName}!</h1>
                        <p className="lead">Here you can easily create a QR code that links to either a website, a file, an image, or even makes a text message or starts a phone call to the phone number of your choice. Your users have never been in closer reach... it all happens in just one scan.</p>
                        <p>As a <span className="current-role">{capitalize(currentUser.role)}</span> account level you are not authorized to create QR Links. Check out our Pricing page to learn how you can get unlimited QR code links.</p>
                        <p className="lead">
                            <Button tag={Link} to='/pricing'>Go to Pricing page  <BsClipboardCheck /></Button>
                        </p>
                    </Jumbotron>
                </div>
            </div>
        );
    }

    return null;
}

export default QrList;
