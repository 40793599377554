import { 
    MenuItem, 
    AlertDialog, 
    AlertDialogOverlay, 
    AlertDialogContent, 
    AlertDialogHeader, 
    AlertDialogBody, 
    AlertDialogFooter, 
    Button,
    Text,
    Heading,
    FormLabel,
    Select,
    useDisclosure,
    useToast 
} from "@chakra-ui/react";
import { 
    getDatabase, 
    ref,
    update, 
    child, 
    remove, 
    get, 
    push 
} from "firebase/database";
import moment from "moment/min/moment-with-locales";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { currentConfig } from "../App/App.js";

function ChangeProject({ projectId, linkKey, linkName, user }) {
    const database = getDatabase();
    const dbRef = ref(database);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const cancelRef = React.useRef();
    const [isTransferring, setIsTransferring] = useState(false);
    const [projectsKeys, setProjectsKeys] = useState([]);
    const [allProjectsData, setAllProjectsData] = useState(false);
    const { register, handleSubmit } = useForm();

    useEffect(() => {
        async function getProjects() {
            const allProjects = await get(child(dbRef, `users/${user.uid}/projects`));
            return allProjects.val();
        }

        getProjects().then((allProjects) => {
            setAllProjectsData(allProjects);
            const allProjectsKeys = Object.keys(allProjects);
            setProjectsKeys(allProjectsKeys);
        })

    }, [])

    const onFormSubmit = async (data) => {
        setIsTransferring(true);
        const selectedLink = {project: projectId, link: linkKey}

        const lData = await get(child(dbRef, `links/${linkKey}`));
        const selectedLinkData = lData.val();

        const aData = await get(child(dbRef, `assets_data/${projectId}/${linkKey}`));
        const assetsData = aData.val();


        let glbName;
        let usdzName;
        if (assetsData) {
            glbName = assetsData.glb.fileName;
            usdzName = assetsData.usdz.fileName;
        }

        let hasImage = false;
        let hasScreenshot = false;
        let imgName = "";
        let screenshotName = "";

        if (assetsData.img) {
            hasImage = true;
            imgName = assetsData.img.fileName;
        }
        if (assetsData.screenshot) {
            hasScreenshot = true;
            screenshotName = assetsData.screenshot.fileName;
        }

        let fileNames;
        if (!!hasImage && !hasScreenshot) {
            fileNames = {glb: glbName, usdz: usdzName, img: imgName}
        }
        if (!hasImage && !!hasScreenshot) {
            fileNames = {glb: glbName, usdz: usdzName, screenshot: screenshotName}
        }
        if (!!hasImage && !!hasScreenshot) {
            fileNames = {glb: glbName, usdz: usdzName, img: imgName, screenshot: screenshotName}
        }


        let modifiedLinkData = selectedLinkData;
        modifiedLinkData.projectId = data.newProject;
        let size = 400;
        let bgColor = "FFFFFF";
        let urlName = `${currentConfig.hostedUrl}/monad/${data.newProject}/${selectedLink.link}`
        modifiedLinkData.assets.qr = `https://api.qrserver.com/v1/create-qr-code/?data=${urlName}&size=${size}x${size}&bgcolor=${bgColor}`;
        let transferProjectData;

        if (!!hasImage && !hasScreenshot) {
            transferProjectData = {
                fromProject: selectedLink.project, 
                toProject: data.newProject,
                linkId: selectedLink.link,
                usdzName: fileNames.usdz,
                glbName: fileNames.glb,
                imgName: fileNames.img,
                createdAt: moment().valueOf(),
                uid: selectedLinkData.uid,
            }
        }

        if (!hasImage && !!hasScreenshot) {
            transferProjectData = {
                fromProject: selectedLink.project, 
                toProject: data.newProject,
                linkId: selectedLink.link,
                usdzName: fileNames.usdz,
                glbName: fileNames.glb,
                screenshotName: fileNames.screenshot,
                createdAt: moment().valueOf(),
                uid: selectedLinkData.uid,
            }
        }

        if (!!hasImage && !!hasScreenshot) {
            transferProjectData = {
                fromProject: selectedLink.project, 
                toProject: data.newProject,
                linkId: selectedLink.link,
                usdzName: fileNames.usdz,
                glbName: fileNames.glb,
                imgName: fileNames.img,
                screenshotName: fileNames.screenshot,
                createdAt: moment().valueOf(),
                uid: selectedLinkData.uid,
            }
        }

        const newTransferKey = push(child(ref(database), `projects/${selectedLink.project}/transfers`)).key;

        await update(ref(database, "/projects/" + selectedLink.project + "/transfers/" + newTransferKey), transferProjectData);
        await update(ref(database, "/links/" + selectedLink.link),{ projectId: data.newProject });
        await update(ref(database, "/projects/" + data.newProject + "/links/" + selectedLink.link), modifiedLinkData);
        await update(ref(database, "/users/" + selectedLinkData.uid + "/projects/" + data.newProject + "/links/" + selectedLink.link), modifiedLinkData); 

        await remove(ref(database, "projects/" + selectedLink.project + "/links/" + selectedLink.link));
        await remove(ref(database, "users/" + selectedLinkData.uid + "/projects/" + selectedLink.project + "/links/" + selectedLink.link));
        await remove(ref(database, "assets_data/" + selectedLink.project + "/" + selectedLink.link));
        setIsTransferring("complete");

        toast({
            title: "Project Deleted",
            description: "Transfer complete.",
            // status: "error",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });

        onClose();
    }

    if (projectsKeys.length !== 0 ) {
        return (
            <>
                <MenuItem colorScheme="red" onClick={onOpen}>
            Transfer Link
                </MenuItem>
  
                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <form onSubmit={handleSubmit(onFormSubmit)} className="switch-project-form">
                                <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                                    <Heading size="sm">Transfer Link</Heading>
                                    <Heading size="xs">{linkName}</Heading>
                                </AlertDialogHeader>
  
                                <AlertDialogBody>
                                    {!isTransferring &&
                                    <>
                                        <FormLabel htmlFor={"newProject"}>Choose the project to move this MetaSite to:</FormLabel>
                                        <Select {...register("newProject")}>
                                            {projectsKeys.map((projectKey, key) => 
                                                <option value={projectKey} key={key}>{allProjectsData[projectKey].projectName}</option>
                                            )}
                                        </Select>
                                    </>
                                    }

                                    {!!isTransferring &&
                                    <>
                                        <Text>Loading...</Text>
                                    </>
                                    }
                                </AlertDialogBody>
  
                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" ml={3}>
                                        Transfer
                                    </Button>
                                </AlertDialogFooter>
                            </form>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        )
    }

    return null;
}

ChangeProject.propTypes = {
    projectId: PropTypes.string, 
    linkKey: PropTypes.string, 
    linkName: PropTypes.string, 
    user: PropTypes.object
}

export default ChangeProject;
