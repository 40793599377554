import { 
    Flex, 
    VStack 
} from "@chakra-ui/react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import UnauthenticatedScreen from "./UnauthenticatedScreen.js";
import DemoUnauthenticatedWrapper from "../Demo/DemoUnauthenticated/DemoUnauthenticatedWrapper.js";
import ModelRender from "../ModelRender/ModelRender.js";
import Banner from "../Monad/Banner";
import Monad from "../Monad/Monad";
import PromoPage from "../Promo/PromoPage.js";
import QrPage from "../QR/QrPage.js";
import VCardPage from "../VCard/VCardPage.js";
import "./UnauthenticatedApp.css";


function UnauthenticatedApp() {
  
    return (
        <VStack
            justify="space-between"
            minHeight="100vh"
            minWidth="100vw"
        >
            <Flex direction="column" width="100%">
                <UnauthenticatedAppRoutes />
            </Flex>
        </VStack>
    );
}

function UnauthenticatedAppRoutes() {
    return (
        <Routes>
            <Route path="*" element={<UnauthenticatedScreen />} />
            <Route path="/demo" element={<DemoUnauthenticatedWrapper />} />
            <Route path="/monad/:projectId/:linkId" element={<Monad />} />
            <Route path="/monad/:projectId/:linkId/banner" element={<Banner />} />
            <Route path="/model/:projectId/:linkId" element={<ModelRender />} />
            <Route path="/vcard/:uid" element={<VCardPage />} />
            <Route path="/qrlink/:uid/:qrKey" element={<QrPage />} />
            <Route path="/promo/:projectId" element={<PromoPage />} />
        </Routes>
    )
}

export default UnauthenticatedApp;
