import { 
    Button, 
    Divider, 
    Flex, 
    Heading, 
    Text, 
    Link,
    Container,
    Box,
    Stack,
    useColorModeValue as mode
} from "@chakra-ui/react";
import React from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { Link as RRLink } from "react-router-dom";

function StartDemo() {

    return (
        <Container py="8" flex="1">
            <Box
                minH="3xs"
                bg="bg-surface"
                boxShadow="sm"
                borderRadius="lg"
                padding={7}
            >
                <Flex direction="column">
                    <Heading size="sm" mb={5}>You are logged in with a <Box as="mark" color={mode("blue.500", "blue.300")} bg="transparent">Guest</Box> account.</Heading>
                    <Divider mb={3} />
                    <Text fontSize="md" mb={2}><span style={{fontWeight: "600"}}>Guests</span> are limited to creating MetaSites within their personal Inbox. We&apos;ve created an amazing demo to walk you through the process of setting up your first MetaSite.</Text>
                    <Text fontSize="md" mb={6}>Once you see the magic yourself, you&apos;ll have the option to publish your MetaSite, which will automatically upgrade your account to <span style={{fontWeight: "600"}}>User</span>.</Text>

                    <Stack
                        direction={{
                            base: "column",
                            sm: "row",
                        }}
                        spacing={4}
                    >
                        <Link as={RRLink} to={"/tutorial"} _hover={{textDecoration: "none"}}>
                            <Button size="md" colorScheme="blue" fontSize="md" leftIcon={<BsPlusCircleFill fontSize="1.25rem" />}>
                                Create MetaSite
                            </Button>
                        </Link>
                    </Stack>

                </Flex>
            </Box>
        </Container>
    );
}

export default StartDemo;