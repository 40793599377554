import { getDatabase, ref, child, get, update } from "firebase/database";
import React, { useState, useEffect } from "react";
import CgTrader from "./CgTrader";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../Loading/Loading.js";
import "./CgTrader.css";

function CgTraderWrapper() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [cgTraderUser, setCgTraderUser] = useState(false);
    const [tokens, setTokens] = useState(false);
    const [pastPurchases, setPastPurchases] = useState(false);
    const [paidPurchases, setPaidPurchases] = useState(false);

    useEffect(() => {
        async function getTokens() {
            const result = await get(child(dbRef, `users/${currentUser.uid}/apis/cgTrader`)).catch((error) => console.error(error));
            setTokens(result.val());
            return result.val();
        }

        async function getCode() {            
            await fetch("/api/getCode", {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
                .then((res) => res.json())
                .then((data) => {
                    window.location.href = data.uri;
                })
                .catch((err) => console.log("getCode error", err));
        }

        async function getCgTraderUser() {
            await fetch(`/api/getUser?uid=${currentUser.uid}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((res) => res.json())
                .then((data) => {
                    setCgTraderUser(data.response);
                    return data;
                }).catch((err) => console.log("getUser error", err));
        }

        getTokens().then(async (result) => {
            if (!result) {
                await getCode();
            }
            if (result) {
                getCgTraderUser();
            }
        })

    }, []);

    useEffect(() => {
        async function getCgTraderUidData() {
            const cgTraderUidData = await get(child(dbRef, `cg_trader_purchases/${currentUser.uid}`)).catch((error) => console.log(error));
            if (cgTraderUidData) {
                return cgTraderUidData.val(); 
            }
            if (!cgTraderUidData) {
                return false;            
            }
        }

        async function getOrdersAndUpdateRtdb() {
            await fetch(`/api/getOrders?uid=${currentUser.uid}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((res) => res.json())
                .then(async (data) => {
                    const purchases = data.response.orders;
                    setPastPurchases(purchases);

                    function filterByStatus(item) {
                        if (item.status === "paid") {
                            return true
                        }
                        return false;
                    }

                    const paid = purchases.filter(filterByStatus)
                    setPaidPurchases(paid);

                    getCgTraderUidData().then((cgTraderAssets) => {
                        if (cgTraderAssets) {
                            const cgTraderAssetsKeys = Object.keys(cgTraderAssets);
                            paid.map(async (paidPurchase) => {
                                if (!cgTraderAssetsKeys.includes(paidPurchase.id)) {
                                    await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${paidPurchase.id}`), paidPurchase);

                                    paidPurchase.models.map((model) => {
                                        model.files.map(async (file) => {
                                            (file.name.endsWith(".glb") || file.name.endsWith(".gltf")) &&
                                            await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${paidPurchase.id}/glb_models`), file);
                                        })
                                    })
                                }
                            })
                        }

                        if (!cgTraderAssets) {
                            paid.map(async (paidPurchase) => {
                                await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${paidPurchase.id}`), paidPurchase);
                            

                                paidPurchase.models.map(async (model) => {
                                    await model.files.map(async (file) => {
                                        (file.name.endsWith(".glb") || file.name.endsWith(".gltf")) &&
                                        await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${paidPurchase.id}/glb_models`), file);
                                    })
                                })

                            })
                        }  
                    })

                }).catch((err) => console.log("getOrders error", err));

        }
        return getOrdersAndUpdateRtdb();
    }, [cgTraderUser])

    if (tokens && cgTraderUser) {
        return <CgTrader tokens={tokens} cgTraderUser={cgTraderUser} pastPurchases={pastPurchases} paidPurchases={paidPurchases} />
    }

    if (!tokens || !cgTraderUser) {
        return <Loading size="fullscreen" />
    }
}

export default CgTraderWrapper;
