import "@google/model-viewer";
import { 
    Button, 
    Input,
    Textarea,
    Stack, 
    Flex, 
    Heading, 
    Icon, 
    Text,
    Checkbox,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    RadioGroup,
    Container,
    StackDivider,
    Divider,
    Box,
    Select,
    useDisclosure,
    useMediaQuery,
    useToast
} from "@chakra-ui/react";
import { getDatabase, ref, get, update, child } from "firebase/database";
import moment from "moment/min/moment-with-locales";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { BsPlusCircleFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import LinkAssetUploader from "./FormParts/LinkAssetUploader/LinkAssetUploader.js";
import RichText from "./FormParts/RichText/RichText.js";
import horsefail from "../../assets/horsefail2.gif";
import sleepyhorse from "../../assets/sleepyhorse.gif";
import winnerhorse from "../../assets/winnerhorse.gif";
import { AuthContext } from "../../context/AuthContext";
import { RadioButton, RadioButtonGroup } from "../Global/RadioButtonGroup/RadioButtonGroup.js"
import Loading from "../Loading/Loading.js";


function LinkCardModal({linkData, linkKey, projectId, openModalProp, locationLinkIdProp}) {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const [isLargerThan500] = useMediaQuery("(min-width: 500px)")
    const [assetsData, setAssetsData] = useState(false);
    const [screenshotStatus, setScreenshotStatus] = useState(linkData.screenshot);
    const [hdrStatus, setHdrStatus] = useState(linkData.assets.hdr);
    const [autoplayValue, setAutoplayValue] = useState("true");
    const [rotationValue, setRotationValue] = useState("none");
    const [zoomValue, setZoomValue] = useState("none");
    const [glbFlagVal, setGlbFlagVal] = useState(false);
    const [demoFlagVal, setDemoFlagVal] = useState(false);
    const hdrLinks = {
        sunrise: "https://modelviewer.dev/shared-assets/environments/spruit_sunrise_1k_HDR.hdr",
        pillars: "https://modelviewer.dev/shared-assets/environments/pillars_1k.hdr",
        hall: "https://modelviewer.dev/shared-assets/environments/music_hall_01_1k.hdr",
        workshop: "https://modelviewer.dev/shared-assets/environments/aircraft_workshop_01_1k.hdr",
        creek: "https://modelviewer.dev/shared-assets/environments/whipple_creek_regional_park_04_1k.hdr",
    };
    const hdrOptions = [
        {
            label: "Sunrise",
            value: "sunrise",
        },
        {
            label: "Pillars",
            value: "pillars",
        },
        {
            label: "Music Hall",
            value: "hall",
        },
        {
            label: "Aircraft Workshop",
            value: "workshop",
        },
        {
            label: "Creek",
            value: "creek",
        },
    ];
    // const fileTypeDictionary = {
    //     glb: "Android/Web File(.glb or .gltf)",
    //     usdz: "iOS File(.usdz or .reality)",
    //     img: ".jpg, .png, or .gif",
    //     descriptive: {
    //         glb: "Android/Web File",
    //         usdz: "iOS File",
    //         img: "Image File",
    //     },
    //     acceptedTypes: {
    //         glb: ".glb or .gltf",
    //         usdz: ".usdz or .reality",
    //         img: ".jpg, .png, or .gif",
    //     }
    // };
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { register, setValue, setError, clearErrors, handleSubmit, control } = useForm({
        defaultValues: {
            glb: linkData.assets.glb,
            usdz: linkData.assets.usdz,
            img: linkData.assets.img,
            qr: linkData.assets.qr,
            hdr: linkData.assets.hdr,
            includeHdr: linkData.assets.hdr,
            title: linkData.title,
            ctaLinkPath: linkData.ctaLinkPath,
            ctaHeader: linkData.ctaHeader,
            ctaSubheader: linkData.ctaSubheader,
            description1: linkData.description1,
            description2: linkData.description2,
            richText: linkData.richText,
            richTextDesktop: linkData.richTextDesktop
        }
    });

    useEffect(() => {
        async function getAssetsData() {
            const aData = await get(child(dbRef, `assets_data/${projectId}/${linkKey}`)).catch((error) => console.log(error));
            return aData.val();
        }

        getAssetsData().then((assetsData) => {
            if (assetsData) {
                const assetsDataKeys = Object.keys(assetsData);
                if (assetsDataKeys.includes("glb") && assetsDataKeys.includes("usdz")) {
                    return setAssetsData(assetsData);
                }
                if (assetsDataKeys.includes("glb") && !assetsDataKeys.includes("usdz") && !!linkData.glbOnlyFlag) {
                    return setAssetsData(assetsData);
                }
                else {
                    return setAssetsData("error");
                }
            }
        })

        if (linkData.autoplay) {
            setAutoplayValue(linkData.autoplay);
        }
        if (linkData.rotation) {
            setRotationValue(linkData.rotation);
        }
        if (linkData.zoom) {
            setZoomValue(linkData.zoom);
        }
        if (linkData.demo) {
            setDemoFlagVal(linkData.demo);
        }
        if (linkData.glbOnlyFlag) {
            setGlbFlagVal(linkData.glbOnlyFlag);
        }

        if (openModalProp === true && locationLinkIdProp === linkKey) {
            onOpen();
        }
    }, []);  

    useEffect(() => {
    }, [autoplayValue, rotationValue, zoomValue, setAutoplayValue, setRotationValue, setZoomValue])

    async function syncScreenshot() {
        const dbRef = ref(database);
        await get(child(dbRef, `assets_data/${projectId}/${linkKey}`)).then(async (snapshot) => {
            setAssetsData(snapshot.val());
            setScreenshotStatus("complete");
        }).catch((error) => {
            console.error(error);
        });
    }

    async function generateScreenshot() {
        setScreenshotStatus("loading");
        await fetch("/api/generateScreenshotReq", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ projectId: projectId, linkId: linkKey }),
        })
            .then((res) => res.json())
            .then(async (data) => {
                setScreenshotStatus(data.status);
            }).catch(() => {
                setScreenshotStatus("error");
            });
    }

    const onFormUpdateSubmit = async (data) => {
        const { 
            title, 
            ctaLinkPath, 
            ctaHeader, 
            ctaSubheader, 
            description1, 
            description2, 
            richText, 
            richTextDesktop, 
            glb, 
            usdz, 
            img, 
            qr, 
            includeHdr, 
            hdr
        } = data;
        const { uid, displayName} = currentUser;
        const timestamp = moment().valueOf();
        const formattedDate = moment().format("MMMM Do YYYY, h:mm:ss a");
        const ctaHeaderLowercase = ctaHeader.toLowerCase();
        const titleLowercase = title.toLowerCase();
        let hdrInformation;
        if (typeof hdr === "undefined" || includeHdr === false) {
            hdrInformation = false;
        }
        else {
            hdrInformation = hdr;
        }
        let imgValue;
        if (typeof img === "undefined") {
            imgValue = false;
        }
        else {
            imgValue = img;
        }
        const updateLinkData = {
            title,
            titleLowercase,
            ctaLinkPath,
            ctaHeader,
            ctaHeaderLowercase,
            ctaSubheader,
            demo: demoFlagVal,
            description1,
            description2,
            glbOnlyFlag: glbFlagVal,
            richText,
            richTextDesktop, 
            rotation: rotationValue,
            zoom: zoomValue,
            autoplay: autoplayValue,
            uid,
            displayName,
            assets: {
                glb,
                usdz,
                img: imgValue,
                qr,
                hdr: hdrInformation,
            },
            createdAt: timestamp,
            formattedDate,
            projectId
        };
        const updates = {};
        updates["/links/" + linkKey] = updateLinkData;  // write to links
        updates["/projects/" + projectId + "/links/" + linkKey] = updateLinkData;  // write to projects/links
        updates["/users/" + uid + "/projects/" + projectId + "/links/" + linkKey] = { createdAt: timestamp };  // write to users/projects/links
        await update(ref(database), updates);
        toast({
            title: "MetaSite Updated",
            description: `"${title}" has been successfully edited.`,
            status: "success",
            variant: "subtle",
            duration: 9000,
            isClosable: true,
        });
        onClose();
    }
 
    if (assetsData === "error") {
        const linkId = linkKey;
        return <Navigate to={`/error/projects/${projectId}/links/${linkId}`} />
    }

    if (!linkData) {
        return <Loading size="small" />;
    }

    return (
        <>
            <Button variant="secondary" onClick={onOpen}><FaEdit /></Button>

            <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader bg="blackAlpha.800">
                        <Heading size="sm" mb="2" color="white">Edit MetaSite</Heading>
                    </ModalHeader>
                    <ModalCloseButton color="white" />
                    <FormProvider register={register} setValue={setValue} setError={setError} clearErrors={clearErrors} control={control}>
                        <form onSubmit={handleSubmit(onFormUpdateSubmit)}>
                            <ModalBody bg="gray.50">

                                <Container
                                    py={{
                                        base: "4",
                                        md: "8",
                                    }}>
                                    <Stack spacing="5" divider={<StackDivider />}>

                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                        AR Assets
                                                </Text>
                                                <Text fontSize="sm">
                                                        Upload AR files for iOS and Android and an optional thumbnail image
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>

                                                    <FormLabel htmlFor="glb">Android/Web File(.glb or .gltf):</FormLabel>
                                                    <Stack
                                                        direction={{
                                                            base: "column",
                                                            lg: "row",
                                                        }}>
                                                        <Box w={["100%", "50%"]}>
                                                            {(assetsData !== false && !linkData.assets.hdr) &&
                                                            <model-viewer
                                                                id="model-viewer"
                                                                src={assetsData.glb.link}
                                                                alt="A 3D model of an astronaut"
                                                                ar
                                                                autoplay
                                                                camera-controls>
                                                            </model-viewer>
                                                            }

                                                            {(assetsData !== false && !!linkData.assets.hdr) &&
                                                            <model-viewer
                                                                id="model-viewer"
                                                                src={assetsData.glb.link}
                                                                alt="A 3D model of an astronaut"
                                                                ar
                                                                autoplay
                                                                camera-controls
                                                                environment-image={hdrLinks[linkData.assets.hdr]}
                                                                poster="poster.webp" 
                                                                shadow-intensity="1" 
                                                                skybox-image={hdrLinks[linkData.assets.hdr]}>
                                                            </model-viewer>
                                                            }
                                                        </Box>
                                                        <Box w={["100%", "50%"]}>
                                                            <LinkAssetUploader projectKey={projectId} newLinkKey={linkKey} required inputName="glb" />
                                                        </Box>
                                                    </Stack>

                                                    <Divider color="gray.50" />
                                                    
                                                    <FormLabel htmlFor="usdz">iOS File(.usdz or .reality):</FormLabel>
                                                    <Stack
                                                        direction={{
                                                            base: "column",
                                                            lg: "row",
                                                        }}>
                                                        <Box w={["100%", "50%"]}>
                                                            {(assetsData !== false && !linkData.glbOnlyFlag) &&
                                                                <Flex direction="column">
                                                                    <a rel="ar" className="usdz-link" href={assetsData.usdz.link}>{linkData.assets.usdz}</a>
                                                                    <Text mt={3}>(.usdz/.reality files are not viewable in the browser. Click file name to view on iPhone or download file on desktop/Android)</Text>
                                                                </Flex>
                                                            }
                                                            {linkData.glbOnlyFlag &&
                                                                <Text>A .usdz file was not uploaded during MetaSite creation. You can upload a .usdz file if you wish to enhance the quality of your MetaSite&apos;s experience.</Text>
                                                            }
                                                        </Box>
                                                        {!linkData.glbOnlyFlag &&
                                                        <Box w={["100%", "50%"]}>
                                                            <LinkAssetUploader projectKey={projectId} newLinkKey={linkKey} required inputName="usdz" />
                                                        </Box>
                                                        }
                                                        {linkData.glbOnlyFlag &&
                                                        <Box w={["100%", "50%"]}>
                                                            <LinkAssetUploader projectKey={projectId} newLinkKey={linkKey} inputName="usdz" />
                                                        </Box>
                                                        }
                                                    </Stack>

                                                    <Divider color="gray.50" />
                                                    
                                                    <FormLabel htmlFor="img">Image File (.png or .jpg):</FormLabel>
                                                    <Stack
                                                        direction={{
                                                            base: "column",
                                                            lg: "row",
                                                        }}>
                                                        <Box w={["100%", "50%"]}>
                                                            {(assetsData !== false && !!assetsData.img && screenshotStatus !== "loading" && screenshotStatus !== "complete") &&
                                                                <img src={assetsData.img.link} width="200px" alt={`${linkData.title}`} />
                                                            }

                                                            {(assetsData !== false && !!assetsData.screenshot && !assetsData.img) &&
                                                                <img src={assetsData.screenshot.link} width="200px" alt="Fail!" />
                                                            }

                                                            {(assetsData !== false && !assetsData.screenshot && !assetsData.img && screenshotStatus === "error") &&
                                                                <>
                                                                    <img src={horsefail} width="200px" alt="Fail!" />
                                                                    <h4>Oop! Something went wrong.</h4>
                                                                    <h5>Choose from the options below to fix the issue.</h5>
                                                                </>
                                                            }

                                                            {(assetsData !== false && !assetsData.screenshot && screenshotStatus === "loading") &&
                                                                <>
                                                                    <img src={sleepyhorse} width="200px" alt="Processing" />
                                                                    <h4>Almost there...</h4>
                                                                    <h5>This takes a little while.</h5>
                                                                </>
                                                            }

                                                            {(assetsData !== false && !assetsData.screenshot && screenshotStatus === "processing") &&
                                                                <>
                                                                    <img src={sleepyhorse} width="200px" alt="Processing" />
                                                                    <h4>Your autogenerated image is processing!</h4>
                                                                    <h5>This takes a couple of minutes. Can&apos;t wait? Upload a custom image instead.</h5>
                                                                </>
                                                            }

                                                            {(assetsData !== false && !assetsData.screenshot && screenshotStatus === "complete") &&
                                                                <>
                                                                    <img src={winnerhorse} width="200px" alt="Success!"/>
                                                                    <h4>Success!</h4>
                                                                    <h5>Click the button below to sync your new image.</h5>
                                                                </>
                                                            }

                                                            {(!assetsData.screenshot && !assetsData.img && screenshotStatus !== "complete" && screenshotStatus !== "processing") &&
                                                                <Button size="sm" width="fit-content" mt={10} disabled={screenshotStatus === "loading"} onClick={() => generateScreenshot()}><Icon as={BsPlusCircleFill}  mr={3} /> Generate Image from Model</Button>
                                                            }

                                                            {(!assetsData.screenshot && screenshotStatus === "complete") &&
                                                                <Button size="sm" width="fit-content" mt={10} disabled={screenshotStatus === "loading"} onClick={() => syncScreenshot()}><Icon as={BsPlusCircleFill}  mr={3} /> Sync Image Capture</Button>
                                                            }
                                                        </Box>
                                                        <Box w={["100%", "50%"]}>
                                                            <LinkAssetUploader projectKey={projectId} newLinkKey={linkKey} inputName="img" />
                                                        </Box>
                                                    </Stack>

                                                </Stack>
                                            </Box>
                                        </Stack>
                                                        
                                        
                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                    AR Options
                                                </Text>
                                                <Text fontSize="sm">
                                                    Optionally place your AR model in an environment or position it in space
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>
                    
                                                    <Checkbox size="lg" colorScheme="blue" mb={4} mt={5} fontWeight="500" fontSize="md" {...register("includeHdr")} onChange={e => {
                                                        if (e.target.checked) {
                                                            setHdrStatus(true);
                                                        }
                                                        else {
                                                            setHdrStatus(false);
                                                        }
                                                    }} >
                                                        <FormLabel mb={0}>Include HDR Background Image?</FormLabel>
                                                    </Checkbox>

                                                    {hdrStatus &&
                                                        <Select name="hdr" mb="10px !important" mt="5px !important" {...register("hdr")}>
                                                            {hdrOptions.map(o => (
                                                                <option key={o.value} value={o.value}>{o.label}</option>
                                                            ))}
                                                        </Select>
                                                    }

                                                    <FormLabel>WebAR Options:</FormLabel>
                                                    <RadioGroup name="rotation" defaultValue={rotationValue}>
                                                        <RadioButtonGroup size="lg" name="rotation" defaultValue={rotationValue} onChange={(e) => setRotationValue(e)}>
                                                            <RadioButton value="left">{isLargerThan500 ? <Text>Rotate Left</Text> : <Text>Left</Text> }</RadioButton>
                                                            <RadioButton value="none">{isLargerThan500 ? <Text>No Rotation</Text> : <Text>None</Text> }</RadioButton>
                                                            <RadioButton value="right">{isLargerThan500 ? <Text>Rotate Right</Text> : <Text>Right</Text> }</RadioButton>
                                                        </RadioButtonGroup>
                                                    </RadioGroup>

                                                    <RadioGroup name="zoom" defaultValue={zoomValue}>
                                                        <RadioButtonGroup size="lg" name="zoom" defaultValue={zoomValue} onChange={(e) => setZoomValue(e)}>
                                                            <RadioButton value="out">{isLargerThan500 ? <Text>Zoom Out</Text> : <Text>Out</Text> }</RadioButton>
                                                            <RadioButton value="none">{isLargerThan500 ? <Text>No Zoom</Text> : <Text>None</Text> }</RadioButton>
                                                            <RadioButton value="in">{isLargerThan500 ? <Text>Zoom In</Text> : <Text>In</Text> }</RadioButton>
                                                        </RadioButtonGroup>
                                                    </RadioGroup>

                                                    <RadioGroup name="autoplay" defaultValue={autoplayValue}>
                                                        <RadioButtonGroup size="lg" name="autoplay" defaultValue={autoplayValue} onChange={(e) => setAutoplayValue(e)}>
                                                            <RadioButton value="true">{isLargerThan500 ? <Text>Autoplay Animation</Text> : <Text>Autoplay</Text> }</RadioButton>
                                                            <RadioButton value="false">{isLargerThan500 ? <Text>No Autoplay Animation</Text> : <Text>None</Text> }</RadioButton>
                                                        </RadioButtonGroup>
                                                    </RadioGroup>

                                                </Stack>
                                            </Box>
                                        </Stack>
                                        
                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                    The Basics
                                                </Text>
                                                <Text fontSize="sm">
                                                    Write a CTA and choose the link you want it to point to. Additional text fields optional. 
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>
                                                    <FormControl>
                                                        <FormLabel htmlFor="title">MetaSite Title (internal use only):</FormLabel>
                                                        <Input name="title" {...register("title")} />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel htmlFor="ctaLinkPath">Call to Action Link:</FormLabel>
                                                        <Input name="ctaLinkPath" {...register("ctaLinkPath")} />
                                                    </FormControl>
              
                                                    <FormControl>
                                                        <FormLabel htmlFor="ctaHeader">Call to Action Button Text:</FormLabel>
                                                        <Input name="ctaHeader" {...register("ctaHeader")} />
                                                    </FormControl>
   
                                                    <FormControl>
                                                        <FormLabel htmlFor="ctaSubheader">Call to Action Button Text Line 2:</FormLabel>
                                                        <Input name="ctaSubheader" {...register("ctaSubheader")} />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel htmlFor="description1">Description First Paragraph:</FormLabel>
                                                        <Textarea name="description1" {...register("description1")} />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel htmlFor="description2">Description Second Paragraph:</FormLabel>
                                                        <Textarea name="description2" {...register("description2")} />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel htmlFor="richText">Rich Text</FormLabel>
                                                        <Controller
                                                            render={({ field }) => <RichText {...field} />}
                                                            name="richText"
                                                            control={control}
                                                            defaultValue=""
                                                        />
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel htmlFor="richTextDesktop">Rich Text - Desktop Only</FormLabel>
                                                        <Controller
                                                            render={({ field }) => <RichText {...field} />}
                                                            name="richTextDesktop"
                                                            control={control}
                                                            defaultValue=""
                                                        />
                                                    </FormControl>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        
                                        <Stack
                                            direction={{
                                                base: "column",
                                                lg: "row",
                                            }}
                                            spacing={{
                                                base: "5",
                                                lg: "8",
                                            }}
                                            justify="space-between">
                                            <Box flexShrink={0} width={{
                                                base: "100%",
                                                lg: "25%",
                                            }}>
                                                <Text fontSize="lg" fontWeight="medium">
                                                    You&apos;re Done!
                                                </Text>
                                                <Text fontSize="sm">
                                                    Save this QR code to easily link to your MetaSite
                                                </Text>
                                            </Box>
                                            <Box
                                                bg="bg-surface"
                                                boxShadow="sm"
                                                borderRadius="lg"
                                                flex="1"
                                                maxWidth="3xl">
                                                <Stack
                                                    spacing="5"
                                                    px={{
                                                        base: "4",
                                                        md: "6",
                                                    }}
                                                    py={{
                                                        base: "5",
                                                        md: "6",
                                                    }}>
                                                    <FormLabel htmlFor="qr">QR File:</FormLabel>
                                                    <img src={linkData.assets.qr} alt="" height="200px" width="200px" />
                                                </Stack>
                                            </Box>
                                        </Stack>

                                    </Stack>
                                </Container>
                            </ModalBody>

                            <ModalFooter bg="gray.50">
                                <Button variant="primary" type="submit">Submit</Button>
                                <Button variant="outline" ml={3} onClick={onClose}>
                                        Close
                                </Button>
                            </ModalFooter>
                        </form>
                    </FormProvider>
                </ModalContent>
            </Modal>
        </>
    )
}

LinkCardModal.propTypes = {
    linkData: PropTypes.object, 
    linkKey: PropTypes.string, 
    projectId: PropTypes.string,
    openModalProp: PropTypes.bool,
    locationLinkIdProp: PropTypes.string
}

export default LinkCardModal;
