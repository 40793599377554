export const rachelFirebaseConfig = {
    apiKey: "AIzaSyCqdp8f_Ra04H4Vdj1RHatErOTMCusisas",
    authDomain: "ldp-cms.firebaseapp.com",
    databaseURL: "https://ldp-cms-default-rtdb.firebaseio.com",
    projectId: "ldp-cms",
    storageBucket: "ldp-cms.appspot.com",
    messagingSenderId: "790063370721",
    appId: "1:790063370721:web:fef2be24b6b9d24ef7c2a0",
    measurementId: "G-525YT4NPQS",
    hostedUrl: "https://ldp-cms.web.app/",
    stripeKey: "pk_test_51K23WoKLuXMdIPEzw2aWoeArceWWKQxwAorkPAi71omrvNY61WnPXYR3BFEOFVzSzpa0bPffgwjmXwKkjWX4mKNJ00VAmTnyli",
};
  
export const intFirebaseConfig = {
    apiKey: "AIzaSyB4L6bRREdU1rkQ1dSzGLsxmoJ6tmEeMRI",
    authDomain: "ldp-studio-int.firebaseapp.com",
    databaseURL: "https://ldp-studio-int-default-rtdb.firebaseio.com",
    projectId: "ldp-studio-int",
    storageBucket: "ldp-studio-int.appspot.com",
    messagingSenderId: "994321857427",
    appId: "1:994321857427:web:de901bbf624fc958824666",
    measurementId: "G-KNM7SY3821",
    hostedUrl: "https://ldp-studio-int.web.app/",
    stripeKey: "pk_test_51K23WoKLuXMdIPEzw2aWoeArceWWKQxwAorkPAi71omrvNY61WnPXYR3BFEOFVzSzpa0bPffgwjmXwKkjWX4mKNJ00VAmTnyli",
    manifoldClientId: "cfd70999446dc53da561ee5ad1e9bc1b8190a186da7586dfa399ab7cd9b689dd",
    manifoldAppName: "LDP Studio Int",
    manifoldRedirect: "https://ldp-studio-int.firebaseapp.com/",
    rpcUrlRinkeby: "https://rinkeby.infura.io/v3/d6b6635995af43ce866f6463cd834860",
    etherscanKey: "6BHJB1B3HIHHWCIHNY3IFC31F58WIXTFHM",
    metamaskTestKey: "3194098a8df1c6e00ef5a4b0cc8aea8f61d1065b3fd44fc1e9bf8a6ad5db571a",
};
  
export const stageFirebaseConfig = {
    apiKey: "AIzaSyDVmsFZJvl0GZHIyIai-zJlterVzWX2Flk",
    authDomain: "ldp-studio-stage.firebaseapp.com",
    databaseURL: "https://ldp-studio-stage-default-rtdb.firebaseio.com",
    projectId: "ldp-studio-stage",
    storageBucket: "ldp-studio-stage.appspot.com",
    messagingSenderId: "215930345509",
    appId: "1:215930345509:web:b9640ccc5a7c666a6087cc",
    measurementId: "G-VFMTCHKSKF",
    hostedUrl: "https://ldp-studio-stage.web.app/",
    stripeKey: "pk_test_51K23WoKLuXMdIPEzw2aWoeArceWWKQxwAorkPAi71omrvNY61WnPXYR3BFEOFVzSzpa0bPffgwjmXwKkjWX4mKNJ00VAmTnyli",
    manifoldClientId: "5e23dfb8b3769bda5328947d0e5fc822358a9f6df9be8a83daddb2b95099fb66",
    manifoldAppName: "LDP Studio Stage",
    manifoldRedirect: "https://ldp-studio-stage.firebaseapp.com/",
    rpcUrlRinkeby: "https://rinkeby.infura.io/v3/d6b6635995af43ce866f6463cd834860",
    etherscanKey: "6BHJB1B3HIHHWCIHNY3IFC31F58WIXTFHM",
    metamaskTestKey: "3194098a8df1c6e00ef5a4b0cc8aea8f61d1065b3fd44fc1e9bf8a6ad5db571a",
};
  
export const prodFirebaseConfig = {
    apiKey: "AIzaSyCHTnlglZrr9qmrJ7M-vBJBa6PxXSzocug",
    authDomain: "ldp-studio-prod.firebaseapp.com",
    databaseURL: "https://ldp-studio-prod-default-rtdb.firebaseio.com",
    projectId: "ldp-studio-prod",
    storageBucket: "ldp-studio-prod.appspot.com",
    messagingSenderId: "299262447167",
    appId: "1:299262447167:web:66d3eea2b750f2aabb728a",
    measurementId: "G-HMTWS7QNTP",
    hostedUrl: "https://ldp-studio-prod.web.app/",
    stripeKey: "pk_live_51K23WoKLuXMdIPEzt1anCgkgiFun65bkuvxz4iHl2gU3BlYPVDnTXALPn2UP5ue2qlqd8SIgZGzlBghOTqdUqy6M00TPJO2fZr",
    manifoldClientId: "1ff0b79024286def2c2529eaad011cbe6c30d3210dfb8befeec254195465e7c4",
    manifoldAppName: "LDP Studio Prod",
    manifoldRedirect: "https://ldp-studio-prod.firebaseapp.com/",
    rpcUrlRinkeby: "https://mainnet.infura.io/v3/a2bb2a0401374bda85c8db1bbc741810",
    etherscanKey: "56M1HPHB8FHBK9Z75XUEEICDUFJR53ZDR6",
    metamaskTestKey: "3194098a8df1c6e00ef5a4b0cc8aea8f61d1065b3fd44fc1e9bf8a6ad5db571a",
};
  
export const arPetsFirebaseConfig = {
    apiKey: "AIzaSyDXmsqc2b0I7OmwpdfDUp5FR7GHGPN81F0",
    authDomain: "augmented-reality-pets.firebaseapp.com",
    databaseURL: "https://augmented-reality-pets-default-rtdb.firebaseio.com",
    projectId: "augmented-reality-pets",
    storageBucket: "augmented-reality-pets.appspot.com",
    messagingSenderId: "266776813164",
    appId: "1:266776813164:web:3d9289a4bc095cda000249",
    measurementId: "G-V331FWETTJ",
    hostedUrl: "https://augmented-reality-pets.web.app/",
    stripeKey: "pk_test_51K23WoKLuXMdIPEzw2aWoeArceWWKQxwAorkPAi71omrvNY61WnPXYR3BFEOFVzSzpa0bPffgwjmXwKkjWX4mKNJ00VAmTnyli",
    manifoldClientId: "811743e70a34facfbee2bdf425f702e5aea2360611e09f259710076156c02cc3",
    manifoldAppName: "AR Pet Club",
    manifoldRedirect: "https://augmented-reality-pets.firebaseapp.com/",
    rpcUrlRinkeby: "https://mainnet.infura.io/v3/a8e6a6f6c35a4f7794ce1d0c221fbade",
    etherscanKey: "AK4ZUKX8KCBA3X4X4DDBUR9Q5E274HCYJX",
    metamaskTestKey: "3194098a8df1c6e00ef5a4b0cc8aea8f61d1065b3fd44fc1e9bf8a6ad5db571a",
};