import {
    VStack,
    Box,
    Flex,
    Link,
    Tooltip,
    IconButton
} from "@chakra-ui/react";
import { getAnalytics, logEvent } from "firebase/analytics";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { Link as RRLink, useParams } from "react-router-dom";
import "@google/model-viewer";
import "./MonadAndroid.css";

function MonadAndroid({monadData, assetsData, currentUserUid}) {
    const { projectId, linkId } = useParams();
    const analytics = getAnalytics();
    const [zoomSnippet, setZoomSnippet] = useState(null);
    const [rotationSnippet, setRotationSnippet] = useState(null);
    const hdrLinks = {
        sunrise: "https://modelviewer.dev/shared-assets/environments/spruit_sunrise_1k_HDR.hdr",
        pillars: "https://modelviewer.dev/shared-assets/environments/pillars_1k.hdr",
        hall: "https://modelviewer.dev/shared-assets/environments/music_hall_01_1k.hdr",
        workshop: "https://modelviewer.dev/shared-assets/environments/aircraft_workshop_01_1k.hdr",
        creek: "https://modelviewer.dev/shared-assets/environments/whipple_creek_regional_park_04_1k.hdr",
    };
    useEffect(() => {
        if (monadData.rotation === "left") {
            setRotationSnippet("-35deg 90deg");
        }
        if (monadData.rotation === "right") {
            setRotationSnippet("35deg 90deg");
        }
        if (monadData.zoom === "in") {
            setZoomSnippet("0m 0m -.7m")
        }
        if (monadData.zoom === "out") {
            setZoomSnippet("0m 0m .7m");
        }
    }, [])

    return (
        <>
            {monadData.uid === currentUserUid &&
                <Flex direction="row" justifyContent="flex-end" width="100%" padding="20px" backgroundColor="rgb(38, 38, 38)">
                    <Link as={RRLink} to={`/projects/${projectId}/links`} state={{ locationLinkId: linkId, openModal: true }}>
                        <Tooltip label="Edit MetaSite. Only MetaSite owners logged in to their account can see this." placement="auto" closeDelay={500} shouldWrapChildren>
                            <IconButton
                                variant="outline"
                                aria-label="Edit MetaSite"
                                borderWidth="2px"
                                backgroundColor="transparent"
                                icon={<FaPencilAlt color="white" />}
                            />
                        </Tooltip>
                    </Link>
                </Flex>
            }

            <VStack bg="rgb(38, 38, 38)" margin={0} color="#FFFFFF" minHeight="100vh" width="100%" overflow="scroll">
                <Box width="100%" py="10px">
                    <Flex direction="column" align="center" ml="5%" mr="5%" flex="1">
                
                        <div id="ar-qr-container">
                            {!monadData.assets.hdr && !monadData.autoplay &&
                            <model-viewer
                                id="model-viewer-desktop"
                                src={assetsData.glb.link}
                                alt="A 3D model of an astronaut"
                                ar
                                camera-controls
                                shadow-intensity="1"
                                autoplay
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                            }
                            {!monadData.assets.hdr && monadData.autoplay === "false" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model of an astronaut"
                                ar
                                camera-controls
                                shadow-intensity="1" 
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                            }
                            {!monadData.assets.hdr && monadData.autoplay === "true" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model of an astronaut"
                                ar
                                camera-controls
                                shadow-intensity="1" 
                                autoplay
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                            }
                            {!!monadData.assets.hdr && monadData.autoplay === "false" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model of an astronaut"
                                ar
                                environment-image={hdrLinks[monadData.assets.hdr]}
                                poster="poster.webp" 
                                shadow-intensity="1" 
                                skybox-image={hdrLinks[monadData.assets.hdr]}
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                            }
                            {!!monadData.assets.hdr && monadData.autoplay === "true" &&
                            <model-viewer
                                id="model-viewer"
                                src={assetsData.glb.link}
                                alt="A 3D model of an astronaut"
                                ar
                                environment-image={hdrLinks[monadData.assets.hdr]}
                                poster="poster.webp" 
                                shadow-intensity="1" 
                                skybox-image={hdrLinks[monadData.assets.hdr]}
                                autoplay
                                camera-target={`${zoomSnippet}`}
                                camera-orbit={`${rotationSnippet}`}
                            >
                            </model-viewer>
                            }
                        </div>

                        <div id="instructions" className="text-container">
                            <div className="large-bold-text">Click the cube in the corner of the image.</div>
                            <div className="instruction-subheader">Acclimate camera to the surroundings.</div>
                        </div>

                        <div id="android-cta-container">
                            <a href={monadData.ctaLinkPath} onClick={() => logEvent(analytics, "android_cta_clicked")} id="android-cta-link" className="android-cta">
                                <div id="android-cta-header" className="large-bold-text">{monadData.ctaHeader}</div>
                                <div id="android-cta-subheader" className="normal-text">{monadData.ctaSubheader}</div> 
                                {monadData.ctaBody1 &&
                                <div id="android-cta-body-1" className="medium">{monadData.ctaBody1}</div>
                                }
                                {monadData.ctaBody2 &&
                                <div id="android-cta-body-2" className="large">{monadData.ctaBody2}</div>
                                }
                            </a>
                        </div>

                        <div id="description" className="text-container">
                            {monadData.description1 &&
                            <p id="description-1">{monadData.description1}</p>
                            }

                            {monadData.description2 &&
                            <p id="description-2">{monadData.description2}</p>
                            }
                
                            {monadData.richText &&
                    <div id="rich-text">
                        {parse(monadData.richText)}
                    </div>
                            }
                        </div>
                
                    </Flex>
                </Box>
            </VStack>
        </>
    );
}

MonadAndroid.propTypes = {
    monadData: PropTypes.object, 
    assetsData: PropTypes.object,
    currentUserUid: PropTypes.string
}

export default MonadAndroid;
