import {
    Avatar,
    AvatarGroup,
    Box,
    Center,
    DarkMode,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    Image,
    useBreakpointValue,
    useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import SignInForm from "./SignInForm.js";
import ldplogo from "../../assets/ldp-logo-footer.png";
  
function UnauthenticatedScreen(){
    return (
        <Flex
            minH={{
                base: "100vh",
            }}
            bgGradient={useBreakpointValue({
                sm: "blackAlpha.800",
                md: mode(
                    "linear(to-r, blackAlpha.800 50%, white 50%)",
                    "linear(to-r, blackAlpha.800 50%, gray.900 50%)",
                ),
            })}
        >
            <Flex maxW="8xl" mx="auto" width="full">
                <Box
                    flex="1"
                    display={{
                        base: "none",
                        md: "block",
                    }}
                >
                    <DarkMode>
                        <Flex
                            direction="column"
                            px={{
                                base: "4",
                                md: "8",
                            }}
                            height="full"
                            color="on-accent"
                        >
                            <Flex flex="1" align="center">
                                <Stack spacing="8">
                                    <Stack spacing="6">
                                        <Image src={ldplogo} width="300px" />
                                        <Heading
                                            size={useBreakpointValue({
                                                md: "lg",
                                                xl: "xl",
                                            })}
                                        >
                                            Augmented Reality Made Simple
                                        </Heading>
                                        <Text fontSize="lg" maxW="md" fontWeight="medium">
                                            Create an account and transform YOUR vision into Web3. We&rsquo;ve done the hard stuff so you can start playing and launch your AR campaign tonight.
                                        </Text>
                                    </Stack>
                                    <HStack spacing="4">
                                        <AvatarGroup
                                            size="md"
                                            max={useBreakpointValue({
                                                base: 2,
                                                lg: 5,
                                            })}
                                            borderColor="on-accent"
                                        >
                                            <Avatar name="Rachel M" src="https://livingduality.com/ldp/Media/avatarsapp/rachelside.png" />
                                            <Avatar name="Brant Hindman" src="https://livingduality.com/ldp/Media/avatarsapp/brantface.png" />
                                            <Avatar name="Jessee Black" src="https://livingduality.com/production/wp-content/uploads/2021/02/jessee.jpg" />
                                            <Avatar name="Philip Burrow" src="https://volleyballmag.com/wp-content/uploads/2017/08/AF9I9462.jpg" />
                                            <Avatar name="Jupiter Jackson" src="https://livingduality.com/ldp/Media/avatarsapp/jupitersuperman2.png" />
                                        </AvatarGroup>
                                        <Text fontWeight="medium">Join the AR Revolution</Text>
                                    </HStack>
                                </Stack>
                            </Flex>
                            <Flex align="center" h="24">
                                <Text color="on-accent-subtle" fontSize="sm">
                                    © 2022 LDP Studio. All rights reserved.
                                </Text>
                            </Flex>
                        </Flex>
                    </DarkMode>
                </Box>

                <Center flex="1">
                    <Flex direction="column">
                        <SignInForm
                            px={{
                                base: "4",
                                md: "8",
                            }}
                            py={{
                                base: "12",
                                md: "48",
                            }}
                            width="full"
                            maxW="md"
                            homepage={true}
                        />
                    </Flex>
                </Center>

            </Flex>
        </Flex>
    )
}

export default UnauthenticatedScreen;
