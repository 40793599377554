import { Avatar, Box, HStack, Text } from "@chakra-ui/react"
import PropTypes from "prop-types";
import * as React from "react";

function UserProfile({ name, image, email }) {
    return (
        <HStack spacing="3" ps="2">
            <Avatar name={name} src={image} boxSize="10" />
            <Box maxW={150}>
                <Text color="on-accent" fontWeight="medium" w="100%" fontSize="sm">
                    {name}
                </Text>
                <Text color="on-accent-muted" fontSize="sm" w="100%" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                    {email}
                </Text>
            </Box>
        </HStack>
    );
}

UserProfile.propTypes = {
    name: PropTypes.string,
    image: PropTypes.string,
    email: PropTypes.string,
};

export default UserProfile;