import "@google/model-viewer";
import { 
    Container, 
    Heading,
    Box,
    Stack,
    Flex,
    Button,
    Link
} from "@chakra-ui/react";
import { getDatabase, ref, child, get, update } from "firebase/database";
import queryString from "query-string";
import React, { useState, useEffect } from "react";
import { useLocation, Link as RRLink } from "react-router-dom";
import { Step } from "./components/Step";
import { useStep } from "./components/useStep"
import { AuthContext } from "../../../context/AuthContext";

function CgTraderOrder() {
    const {currentUser} = React.useContext(AuthContext);
    const database = getDatabase();
    const dbRef = ref(database);
    const location = useLocation();
    const [pastPurchases, setPastPurchases] = useState(false);
    const [paidPurchases, setPaidPurchases] = useState(false);
    const [dbPurchases, setDbPurchases] = useState(false);
    const [glbDownload, setGlbDownload] = useState(false);
    const [glbDownloadUrl, setGlbDownloadUrl] = useState(false);
    const [modelId, setModelId] = useState(false);
    const queryParams = queryString.parse(location.search);
    const [currentStep, { setStep }] = useStep({
        maxStep: 4,
        initialStep: 1,
    })

    const steps = [
        {
            title: "Step 1",
            description: "Initiate order on CGTrader.com",
        },
        {
            title: "Step 2",
            description: "Complete order on CGTrader.com",
        },
        {
            title: "Step 3",
            description: "Sync orders with LDP Studio",
        },
        {
            title: "Step 4",
            description: "Download your file and make your MetaSite!",
        }
    ]

    useEffect(() => {
        setStep(1);

        const getPastPurchases = async (thisPurchaseId) => {    
            async function getCgTraderUidData() {
                const cgTraderUidData = await get(child(dbRef, `cg_trader_purchases/${currentUser.uid}`)).catch((error) => console.log(error));
                if (cgTraderUidData) {
                    return cgTraderUidData.val(); 
                }
                if (!cgTraderUidData) {
                    return false;            
                }
            }
    
            await fetch(`/api/getOrders?uid=${currentUser.uid}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((res) => res.json())
                .then(async (data) => {
                    const purchases = data.response.orders;
                    setPastPurchases(purchases);
    
                    function filterByStatus(item) {
                        if (item.status === "paid") {
                            return true
                        }
                        return false;
                    }
    
                    const paid = purchases.filter(filterByStatus);
                    setPaidPurchases(paid);
    
                    getCgTraderUidData().then(async (cgTraderAssets) => {
                        let cgTraderAssetsKeys = [];
                        if (cgTraderAssets) {
                            cgTraderAssetsKeys = Object.keys(cgTraderAssets);
                        }

                        await paid.map(async (paidPurchase) => {
                            if (!cgTraderAssetsKeys.includes(thisPurchaseId)) {
                                await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${thisPurchaseId}`), paidPurchase);
                            }
                            paidPurchase.models.map(async (model) => {
                                setModelId(model.id);
                                await model.files.map(async (file) => {
                                    (file.name.endsWith(".glb") || file.name.endsWith(".gltf")) &&
                                        await update(ref(database, `/cg_trader_purchases/${currentUser.uid}/${thisPurchaseId}/glb_models`), file);
                                })
                            })
                        })
                    })
    
                    // return data;
                }).catch((err) => console.log("getOrders error", err));
            const dbData = await getCgTraderUidData();
            setDbPurchases(dbData);
        }

        if (queryParams.uuid && queryParams.id) {
            getPastPurchases(queryParams.id).then(() => setStep(2));
        }
    }, []);

    useEffect(() => {
        const getModelFiles = async () => {
            await fetch(`/api/getModelFiles?model_id=${modelId}&uid=${currentUser.uid}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((res) => res.json())
                .then(async (data) => {
                    // return data;
                    await data.response.files.map((file) => {
                        if (file.name.endsWith(".glb") || file.name.endsWith(".gltf")) {
                            setGlbDownload(file);
                            return file;
                        }
                    })
                }).catch((err) => console.log("getOrders error", err));
        }

        if (currentStep === 2 && modelId) {
            console.log("dbPurchases", dbPurchases);
            console.log("pastPurchases", pastPurchases);
            console.log("paidPurchases", paidPurchases);
            getModelFiles().then(() => setStep(3));
        }

    }, [setStep, modelId])

    useEffect(() => {
        const getGlbFile = async () => {
        // window.open(freeGlb.download_url, "_blank")
            await fetch(`/api/getGlbFile?model_id=${modelId}&id=${glbDownload.id}&uid=${currentUser.uid}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
            // .then((res) => res.json())
                .then(async (data) => {
                    setGlbDownloadUrl(data.url);
                    await window.open(data.url, "_blank");
                }).catch((err) => console.log("getGlbFile error", err));
        }

        if (currentStep === 3 && glbDownload) {
            getGlbFile().then(() => setStep(4));
        }
    }, [setStep, glbDownload])

    useEffect(() => {
        if (glbDownloadUrl) {
            console.log("currentStep in completion", currentStep);
            console.log("glbDownloadUrl", glbDownloadUrl);
        }
    }, [glbDownloadUrl, setStep])

    return (
        <Container py="8" flex="1">
            <Box mt={20}>
                <Heading as="h1" size="md" mt="8" fontWeight="extrabold" textAlign="center">
                    Completing Your Order
                </Heading>
            </Box>

            <Box bg="bg-surface" mt={10}>
                <Container
                    py={{
                        base: "4",
                        md: "8",
                    }}
                >
                    <Stack
                        spacing="0"
                        direction={{
                            base: "column",
                            md: "row",
                        }}
                    >
                        {steps.map((step, id) => (
                            <Step
                                key={id}
                                cursor="pointer"
                                // onClick={() => setStep(id)}
                                title={step.title}
                                description={step.description}
                                isActive={currentStep === id}
                                isCompleted={currentStep > id}
                                isFirstStep={id === 0}
                                isLastStep={steps.length === id + 1}
                            />
                        ))}
                    </Stack>
                </Container>
            </Box>
                
            {glbDownloadUrl &&
                <Flex direction="row" mt={5} justify="center">
                    <Button size="lg" variant="primary" mr={3} onClick={() => window.open(glbDownloadUrl, "_blank")}>Download .glb File</Button>
                    <Link as={RRLink} to={"/cgTrader"}><Button size="lg" variant="secondary">Return to Models Page</Button></Link>
                </Flex>
            }
        </Container>
    );
}

export default CgTraderOrder;
